import * as React from 'react';
import { InputGroup, FormText } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input';
import {
  isMobile,
  isDesktop,
  isMacOs,
  isIOS,
  osVersion,
  browserVersion,
  browserName,
} from 'react-device-detect';
import CurrencyInputField from 'currencyInputField';
import { TransactionCurrency } from '@app/App';
import { useTranslation } from 'react-i18next';
import { DonationPageType } from '@root/enums';
import { DonationPageDetails } from '@root/modules/donation/types';
import { getCurrencyFormats } from '@utils/index';
import NumberFormatter from '@components/atoms/numberFormatter';
import cx from 'classnames';
import { CampaignPageDetails } from '@root/api/campaign';
import useStyles from './inputAmountField.style';

export type Props = {
  inputAmount: string;
  occurrence: string;
  minimumAmount?: number;
  defaultAmount?: string;
  onFocus?: any;
  onKeyPress?: any;
  onKeyUp?: any;
  customAmount?: boolean;
  handleChange?: any;
  onBlur?: any;
  color?: string;
  dataTestId?: string;
  name?: string;
  locale?: string;
  hideMinimumAmount?: boolean;
  // states
  valid?: boolean;
  error?: boolean;
  disabled?: boolean;

  pageDetails: DonationPageDetails & CampaignPageDetails;
};

const voidFunction = () => {};

const InputAmountField: React.FC<Props> = ({
  minimumAmount,
  inputAmount,
  occurrence = 'CAD',
  customAmount = false,
  handleChange = voidFunction,
  onBlur = voidFunction,
  onFocus = voidFunction,
  color = '',
  pageDetails,
  locale,
  hideMinimumAmount = false,
  // states
  valid = false,
  error = false,
  disabled = false,
}) => {
  const { t } = useTranslation();
  const transactionCurrency = React.useContext(TransactionCurrency);
  const { currencySymbol, languageCode, isoCurrencyCode } = transactionCurrency;
  const { thousandSeparator, decimalSeparator, prefix, suffix } =
    getCurrencyFormats({
      languageCode,
      currencySymbol,
      isoCurrencyCode,
    });

  const osVersionStr = osVersion && osVersion.split('.')[0];

  const isDP =
    pageDetails &&
    pageDetails.donationPageTypeId &&
    pageDetails.donationPageTypeId === DonationPageType.DP;

  const classes = useStyles({ color, isDP });

  const formGroupClasses = cx({
    [classes.default]: true,
    [classes.valid]: valid,
    [classes.error]: error,
    [classes.disabled]: disabled || !customAmount,
  });

  return (
    <div className={formGroupClasses}>
      <InputGroup
        className={customAmount && 'parent-focus'}
        size={isDP ? undefined : 'lg'}
      >
        {(isDesktop &&
          isMacOs &&
          browserName === 'Safari' &&
          parseInt(browserVersion, 10) <= 12) ||
        (isMobile &&
          isIOS &&
          osVersionStr &&
          osVersionStr.length > 0 &&
          (parseInt(osVersionStr, 10) === 12 ||
            parseInt(osVersionStr, 10) === 11)) ? (
          <CurrencyInput
            value={inputAmount}
            prefix={prefix || ''}
            suffix={suffix || ''}
            decimalSeparator={decimalSeparator}
            thousandSeparator={thousandSeparator}
            className={`form-control ${
              customAmount && 'textColor'
            } ${formGroupClasses}`}
            id="customAmount"
            disabled={!customAmount}
            onChangeEvent={(e) => {
              const [firstVal, secondVal] =
                e.target.value.split(currencySymbol);

              let res = firstVal;
              if (prefix) {
                res = secondVal;
              } else {
                res = firstVal;
              }
              if (
                decimalSeparator === ',' &&
                (languageCode === 'nl' ||
                  languageCode === 'fr' ||
                  languageCode === 'de')
              ) {
                res = res && res.replace(/,/g, '.');
                res = res && res.replace(/\s/g, '');
              } else {
                res = res && res.replace(/,/g, '');
              }
              handleChange(res);
            }}
            onBlur={onBlur}
            onFocus={onFocus}
          />
        ) : (
          <CurrencyInputField
            className={`form-control ${
              customAmount && 'textColor'
            } ${formGroupClasses}`}
            id="customAmount"
            name={t('input_amount_field_name')}
            aria-label={t('aria_input_amount_field')}
            value={inputAmount}
            defaultValue={inputAmount}
            allowNegativeValue={false}
            decimalsLimit={2}
            intlConfig={{
              locale: locale,
              currency: transactionCurrency.isoCurrencyCode,
            }}
            onValueChange={(value) => {
              handleChange(value);
            }}
            onBlur={onBlur}
            onFocus={onFocus}
            disabled={!customAmount}
          />
        )}

        <InputGroup.Append>
          <InputGroup.Text
            className={`${customAmount && 'textColor'} ${
              isDP && customAmount && classes.textSmall
            }`}
          >
            {occurrence}
          </InputGroup.Text>
        </InputGroup.Append>
      </InputGroup>
      {/* {!isDP && ( */}
      {!hideMinimumAmount && (
        <FormText className="text-muted">
          {`${t('minimum_donation_of')} `}
          <NumberFormatter value={minimumAmount} hideLocale />
        </FormText>
      )}
      {/* )} */}
    </div>
  );
};

export default React.memo(InputAmountField);
