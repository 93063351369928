import { MissionTheme } from '../../interfaces/MissionTheme';
import { Font } from '../../interfaces/Font';
import defaultStyle from './default';

require('typeface-montserrat');

const primaryFont: Font = {
  name: 'Montserrat',
  fontFamily: '"Source Serif Pro", serif',
};

const theme: MissionTheme = {
  ...defaultStyle,
  colorPrimary: '#0098CD',
  colorSecondary: '#7c7c7c',
  ...primaryFont,
  headingFont: primaryFont,
  headerBackgroundColor: 'white',
  headerTitleColor: '#7c7c7c',
};

export default theme;
