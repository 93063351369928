import { MissionTheme, Font } from '@root/interfaces';
import globals from '../default';

require('typeface-roboto');

const primaryFont: Font = {
  name: 'Roboto',
  fontFamily: '"Roboto", serif',
};

// Blue header bar: 01457b
// Dark blue header bar: 323946
// Purple background : c0c9e6
// Donate Once: Blue: 01457b
// Donate Monthly: Yellow: f1b228

const colors = {
  colorPrimary: '#01457B',
  colorSecondary: '#323946',
  colorTertiary: '#c0c9e6',
  colorText: '#7c7c7c',
  colorBackground: '#323946',
};

const theme: MissionTheme = {
  ...globals,
  ...colors,
  clientFullName: 'Archdiocese of Halifax-Yarmouth',
  isSVG: false,
  isDisableFullHeader: false,
  extraBoldFrequency: false,
  useSecThemeColor: false,
  addCustomColor: false,
  primaryFont: primaryFont,
  headingFont: primaryFont,
  headerBackgroundColor: 'white',
  headerTitleColor: '#7c7c7c',
  headerLogoHeight: 103,
  headingFontSize: '1.5rem',
  headingBold: true,
  subHeadingFontSize: '1rem',
  buttons: {
    ...globals.buttons,
    defaults: {
      ...globals.buttons.defaults,
    },
  },
  globalFontSize: 14,
  toggleFontFamily: primaryFont.fontFamily,
};

export default theme;
