import { createUseStyles } from 'react-jss';
import { MissionTheme } from '@root/interfaces/MissionTheme';

export default createUseStyles<any, any>((theme: MissionTheme) => ({
  root: { marginBottom: 30 },

  frequencySection: {
    paddingTop: 30,
    backgroundColor: '#fff',
    position: 'relative',
    zIndex: 1,
  },

  pTag: {
    marginTop: 15,
    marginBottom: 15,
  },
  subTitlesWeight: {
    fontWeight: theme.headingBold ? 600 : 400,
  },
  sectionTag: {
    fontSize: theme.subHeadingFontSize,
  },
  donationMessage: {
    margin: '15px 0',
  },
  buttonLink: {
    borderBottom: `1px solid ${theme.colorPrimary}`,
  },
}));
