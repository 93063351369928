export const addressToBilling = (values) => {
  return {
    billingCity: values.address1City,
    billingCountry: values.address1Country,
    billingLine1: values.address1Line1,
    billingLine2: values.address1Line2,
    billingPostalCode: values.address1PostalCode,
    billingStateOrProvince: values.address1StateOrProvince,
    // ...values,
  };
};
