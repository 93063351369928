import { DonationPageTheme } from '@root/interfaces';
import { createUseStyles } from 'react-jss';
import color from 'color';

export default createUseStyles<any, any>((theme: DonationPageTheme) => ({
  container: {
    margin: 0,
    padding: {
      right: 15,
      left: 15,
    },
  },

  donationLayoutContainer: {
    display: 'none',
    width: '100%',
    height: '100%',
    backgroundColor: theme.colorBackground || theme.colorTertiary,
    '@media (min-width: 768px)': {
      display: 'flex',
      position: 'fixed',
    },
  },

  opacity: {
    display: 'none',
    '@media (min-width: 750px)': {
      display: 'block',
      position: 'absolute',
      width: '100%',
      maxWidth: 834,
      height: '100vh',
      zIndex: 2,
      backgroundColor: color(theme.colorPrimary).fade(0.5).toString(),
    },
  },

  opacityLeft: {
    left: 0,
  },

  opacityRight: {
    right: 0,
  },

  opacitySkeleton: {
    display: 'none',
    '@media (min-width: 750px)': {
      display: 'block',
      position: 'absolute',
      width: '100%',
      maxWidth: 834,
      height: '100vh',
      backgroundColor: color('gray').fade(0.5).toString(),
    },
  },

  themeColor: {
    flex: '0 0 682px',
    minWidth: 682,
    background: `linear-gradient(0deg, ${color(theme.colorPrimary).lighten(
      0.05
    )} 0%, ${color(theme.colorPrimary).lighten(0.1)} 100%)`,
  },

  themeColorLeft: {
    order: 0,
  },

  themeColorRight: {
    order: 1,
  },

  themeColorSkeleton: {
    display: 'none',
    '@media (min-width: 750px)': {
      display: 'block',
      position: 'absolute',
      width: '84.2%',
      maxWidth: 726,
      height: '100vh',
      background: `linear-gradient(0deg, ${color('gray').lighten(
        0.05
      )} 0%, ${color('gray').lighten(0.1)} 100%)`,
    },
  },

  hideHeader: {
    height: 100,
  },
  headerContainer: {
    width: '37.5%',
    maxWidth: 185,
    position: 'relative',
    top: '-3.55487vw',
    left: 40,
    '@media (min-width: 640px)': {
      top: -23,
      maxWidth: '28vw',
    },
    '@media (min-width: 834px)': {
      maxWidth: 215,
    },

    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: '-1.6vw',
      width: 0,
      height: 0,
      borderBottom: '3.555vw solid #dddddd',
      borderLeft: '1.547vw solid transparent',

      '@media (min-width: 640px)': {
        left: -10,
        borderBottom: '23px solid #dddddd',
        borderLeft: '10px solid transparent',
      },
    },
  },

  progressIndicatorContainer: {
    maxWidth: 750,
    height: 1,
    position: 'relative',
    zIndex: 0,
    bottom: '60%',
    background: `linear-gradient(to right, ${color(
      theme.colorPrimary
    )}, ${color(theme.colorPrimary).lighten(0.1)})`,
    '& .col': {
      padding: 0,
      '@media (min-width: 750px)': {
        paddingLeft: 15,
        paddingRight: 15,
      },
    },
  },

  progressIndicatorContainerCol: {
    flex: '0 0 58%',
    maxWidth: '58%',
    marginLeft: '44%',
    '@media (min-width: 567px)': {
      flex: '0 0 59%',
      maxWidth: '59%',
      marginLeft: '40%',
    },
    '@media (min-width: 640px)': {
      flex: '0 0 66%',
      maxWidth: '66%',
      marginLeft: '34%',
    },
  },

  imageContainer: {
    overflow: 'hidden',
    flex: '0 1 auto',
  },

  imageContainerLeft: {
    order: 1,
  },

  imageContainerRight: {
    order: 0,
  },

  caruselItem: {
    transition: '250ms ease-in-out left',
  },

  caruselImage: {
    display: 'block',
    width: 'auto',
    height: '100vh',
  },

  contentContainer: {
    position: 'relative',
    width: '100%',
    maxWidth: 834,
    paddingTop: 42,
    paddingBottom: 42,
    background: `linear-gradient(0deg, ${color(theme.colorPrimary).lighten(
      0.05
    )} 0%, ${color(theme.colorPrimary).lighten(0.1)} 100%)`,
    overflowX: 'hidden',
    '@media (min-width: 834px)': {
      overflowX: 'initial',
      position: 'absolute',
      background: 'transparent',
    },
  },

  contentContainerLeft: {
    left: 0,
  },
  contentContainerRight: {
    right: 0,
  },

  contentRow: {
    justifyContent: 'center',
    border: 'none',
  },
  contentCol: {
    height: 'auto',
    backgroundColor: 'white',
    maxWidth: 750,
    position: 'relative',
  },

  contentSubCol: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 40,
    paddingRight: 40,
  },

  label: {
    display: 'block',
    position: 'absolute',
    bottom: -33,
    width: 70,
  },

  labelLeft: {
    left: 10,
    '@media (min-width: 790px)': {
      left: 0,
    },
  },
  labelRight: {
    right: 10,
    '@media (min-width: 790px)': {
      right: 0,
    },
  },
}));
