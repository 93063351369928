import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@app/RootState';
import { Form, Col, Alert } from 'react-bootstrap';
import UpdatePaymentSchedule from '@components/molecules/updatePaymentSchedule';
import CancelPaymentSchedule from '@components/molecules/cancelPaymentSchedule';
import {
  DonationPageDetails,
  PaymentSchedulePost,
} from '@modules/donation/types';
import { setCancellationStatus } from '@app/app.slice';
import { postPaymentSchedule } from '@modules/donation/donation.slice';
import Button from '@components/atoms/button';

const { lazy, Suspense } = React;
const OverlaySpinnerText = lazy(
  () => import('@components/atoms/overlaySpinnerText')
);

interface PaymentScheduleProps {
  pageDetails: DonationPageDetails;
}

const PaymentSchedule: React.FC<PaymentScheduleProps> = ({ pageDetails }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const { paymentSchedule } = useSelector((state: RootState) => state.donation);
  const { contactDetails, updateCompleted } = useSelector(
    (state: RootState) => state.app
  );

  const [paymentDate, setPaymentDate] = React.useState(new Date());
  const [amount, setAmount] = React.useState('0.00');
  const [totalAmount, setTotalAmount] = React.useState(0);
  const [reason, setReason] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [note, setNote] = React.useState('');
  const [isCancel, setIsCancel] = React.useState(false);

  const handleOnChangeState = (updateStateValue) => {
    switch (updateStateValue.name) {
      case 'amount':
        setAmount(updateStateValue.value);
        break;
      case 'date':
        setPaymentDate(updateStateValue.value);
        break;
      case 'reason':
        setReason(updateStateValue.value);
        break;
      case 'notes':
        setNote(updateStateValue.value);
        break;
      default:
        updateStateValue.value = null;
    }
  };

  const onClickHandler = () => {
    setIsCancel(!isCancel);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const updatePaymentSchedule: PaymentSchedulePost = {
      ...paymentSchedule,
      ...contactDetails,
      cancellationCode: reason,
      cancellationNote: note,
      recurringAmount: Number(amount),
      nextPaymentDate: paymentDate,
    };

    dispatch(postPaymentSchedule(updatePaymentSchedule, contactDetails));

    if (isCancel) {
      dispatch(setCancellationStatus(isCancel));
      history.push('contact');
    }
  };

  React.useEffect(() => {
    if (loading && !updateCompleted) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [updateCompleted, loading]);

  React.useEffect(() => {
    if (paymentSchedule) {
      setPaymentDate(
        paymentSchedule.nextPaymentDate
          ? paymentSchedule.nextPaymentDate
          : new Date()
      );
      setAmount(
        paymentSchedule.recurringAmount
          ? paymentSchedule.recurringAmount.toFixed(2)
          : '0.00'
      );
    }
  }, [paymentSchedule]);

  React.useEffect(() => {
    if (paymentSchedule) {
      const amountValue = Number(amount);
      const membershipAmount = paymentSchedule.membershipAmount
        ? paymentSchedule.membershipAmount
        : 0;
      setTotalAmount(amountValue + membershipAmount);
    }
  }, [amount]);

  return (
    <Suspense fallback={<div>{t('loading')}</div>}>
      <OverlaySpinnerText
        text={t('processingPaymentSchedule')}
        loading={loading}
      />
      {!paymentSchedule ? (
        <Alert variant="danger">{t('notPaymentScheduleMessage')}</Alert>
      ) : (
        <Form className="flexDefaultLayout" onSubmit={handleSubmit}>
          <Form.Row>
            <Col>
              {isCancel ? (
                <>
                  <CancelPaymentSchedule
                    pageDetails={pageDetails}
                    reason={reason}
                    note={note}
                    handleOnChangeState={handleOnChangeState}
                  />
                </>
              ) : (
                <>
                  <UpdatePaymentSchedule
                    pageDetails={pageDetails}
                    paymentDate={paymentDate}
                    recurringAmount={amount}
                    membershipAmount={paymentSchedule.membershipAmount}
                    totalAmount={totalAmount}
                    handleOnChangeState={handleOnChangeState}
                  />
                </>
              )}
            </Col>
          </Form.Row>
          <Form.Row>
            <Col sm={4}>
              <Button type="button" onClick={onClickHandler} block>
                {isCancel ? t('back') : t('cancel_donation')}
              </Button>
            </Col>
            <Col sm={{ span: 4, offset: 4 }}>
              <Button
                type="submit"
                block
                disabled={(reason === '' || reason === t('select')) && isCancel}
              >
                {t('update')}
              </Button>
            </Col>
          </Form.Row>
        </Form>
      )}
    </Suspense>
  );
};

export default PaymentSchedule;
