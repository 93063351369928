import { createUseStyles } from 'react-jss';
import color from 'color';
import { MissionTheme } from '../interfaces/MissionTheme';
import fonts from './fonts';
import headings from './elements/headings';

export default createUseStyles((theme: MissionTheme) => {
  let themeFonts = fonts[theme.primaryFont.name.toLowerCase()];
  if (
    theme.headingFont.name !== theme.primaryFont.name &&
    !theme.headingFont.googleFont
  ) {
    themeFonts = {
      ...themeFonts,
      ...fonts[theme.headingFont.name.toLowerCase()],
    };
  }

  const themeGlobals = theme.globals ? theme.globals : {};

  return {
    '@global': {
      'html, body': {
        fontSize: theme.globalFontSize,
        fontFamily: theme.primaryFont.fontFamily,
        fontWeight: theme.primaryFont.fontWeight || 'normal',
        color: theme.colorText,
        height: 'auto',
        minHeight: '100%',
        backgroundColor: theme.isDP ? '#fff' : '#a9a9a9',
        scrollBehavior: 'smooth',
      },
      'a, .link': {
        color: theme.colorPrimary,
        // fontSize: theme.globalFontSize,
      },
      p: {
        fontFamily: theme.primaryFont.fontFamily,
        fontWeight: 'normal',
        // fontSize: theme.globalFontSize,
        color: theme.colorText,
      },
      strong: {
        fontFamily: theme.toggleFontFamily,
      },
      // span: {
      //   fontFamily: theme.primaryFont.fontFamily,
      // },
      '.form-control': {
        ...theme.inputs.defaults,
      },
      '.btn:hover': {
        '@media(hover: none)': {
          color: '#fff',
        },
      },
      'a.btn:hover': {
        '@media(hover: none)': {
          color: theme.colorPrimary,
        },
      },
      button: {
        '&:focus': {
          outline: 'none',
        },
      },
      '#root': {
        height: '100%',
      },
      '.title': {
        color: theme.colorPrimary,
        padding: {
          bottom: 20,
        },
      },
      '.popover': {
        fontFamily: theme.primaryFont.fontFamily,
      },
      '.DateInput.DateInput_1': {
        width: 136,
        '& .DateInput_input': {
          fontFamily:
            theme && theme.primaryFont
              ? theme.primaryFont.fontFamily
              : 'inherit',
          fontWeight: 400,
          fontSize: '1.25rem',
          '&.DateInput_input__focused': {
            borderBottomColor: theme.colorPrimary,
          },
        },
      },
      '.DayPickerKeyboardShortcuts_show__bottomRight::before': {
        borderRightColor: theme.colorPrimary,
      },
      '.CalendarDay__selected': {
        background: theme.colorPrimary,
        borderColor: theme.colorPrimary,
        '&:hover': {
          background: color(theme.colorPrimary).darken(0.1).toString(),
          borderColor: color(theme.colorPrimary).darken(0.1).toString(),
        },
      },
      '.CalendarDay__selected_span, .CalendarDay__hovered_span, .CalendarDay__hovered_span:hover':
        {
          color: 'white',
          background: color(theme.colorPrimary).lighten(0.3).toString(),
          borderColor: color(theme.colorPrimary).lighten(0.3).toString(),
          '&:hover': {
            background: color(theme.colorPrimary).lighten(0.1).toString(),
            borderColor: color(theme.colorPrimary).lighten(0.1).toString(),
          },
        },
      '.flexDefaultLayout': {
        display: 'flex',
        flex: {
          direction: 'column',
          wrap: 'nowrap',
        },
        justifyContent: 'space-around',
        width: '100%',
        height: '100%',
      },
      '.setHeight': {
        height: '100%',
      },
      '.setWidth': {
        width: '100%',
      },
      '.gateText': {
        textAlign: 'center',
        fontSize: 'large',
      },
      '.DayPickerInput input': {
        // Bootstrap - form control, form control lg
        borderRadius: '3px',
        height: 'calc(1.5em + 1rem + 2px)',
        padding: '0.5rem 1rem',
        fontSize: '1.25rem',
        fontWeight: '400',
        lineHeight: '1.5',
        color: '#495057',
        backgroundColor: '#fff',
        backgroundClip: 'padding-box',
        border: '1px solid #ced4da',
      },
      '.DayPicker-Day--today': {
        color: color(theme.colorPrimary).darken(0.4).toString(),
      },
      ...headings(theme),
      ...themeGlobals,
      ...themeFonts,
    },
  };
});
