// ENGLISH US (en_US)

export default {
  company: 'Company',
  company_help_text:
    'The Company or Organization name which will appear on the acknowledgement and/or receipt.',
  employer: 'My Employer',
  salutation: 'Salutation',
  first_name: 'First Name',
  first_name_validation: 'Please enter your first name',
  last_name: 'Last Name',
  last_name_validation: 'Please enter your last name',
  email: 'Email',
  email_validation: 'Please enter a valid email address',
  phone: 'Phone',
  phone_validation:
    'Please enter a valid phone number without decimals or dashes',
  address: 'Address',
  address_validation: 'Please enter your address',
  suite: 'Suite',
  city: 'City',
  city_validation: 'Please enter your city',
  province: 'State',
  province_validation: 'Please enter your State',
  postal_code: 'Zipcode',
  postal_code_validation: 'Please enter a valid zipcode',
  country: 'Country',
  country_validation: 'Please enter a valid country',
  amount_raised: 'Amount Raised',
  goal: 'Goal',
  dedication_checkbox: 'My donation is in honor or in memory of someone',
  honour_dedication: 'In honor of...',
  memory_dedication: 'In memory of...',
  show_honour_message:
    "You've indicated you're making this donation in honor of someone",
  use_existing_credit_card: 'Use existing credit card',
  summary_link_description: `Click 'Your donation' to change the amount of your gift`,
  membership_order_label: 'Please select (optional)',
  select: 'Select',
  card: 'Card',
  next: 'Next',
  last: 'Last',
  cardType: 'Card Type',
  expiry: 'Expiry',
  additional_info_msg:
    'If you have any comments related to your donation, please include them here.',
  readMore: 'Read more',
  showLess: 'Show less',
  tribute_type: 'Would you like us to send a card?',
  no_sendTribute: 'No,\nthank you',
  email_sendTribute: 'Yes,\nby email',
  mail_sendTribute: 'Yes,\nby mail',
  addressee_name: 'Send Card To',
  addressee_name_validation: `Please enter recipient's name`,
};
