import { createUseStyles } from 'react-jss';
import { MissionTheme } from '@root/interfaces/MissionTheme';

export default createUseStyles<any, any>((theme: MissionTheme) => ({
  root: { marginBottom: 50 },
  textColor: {
    color: theme.colorPrimary,
  },
  pTag: {
    marginTop: 15,
    marginBottom: 15,
  },
  subTitlesWeight: {
    fontWeight: 600,
  },
  howOftenTag: {
    fontSize: 13,
  },
}));
