import * as React from 'react';
import { Row, Col } from 'react-bootstrap';
import { DonationPageType } from '@root/enums';
import { useResizeDetector } from 'react-resize-detector/build/withPolyfill';
import ParserHTML from '@components/atoms/parserHTML';
import { useTranslation } from 'react-i18next';
import useStyles from './footer.style';

export type Props = {
  page?: any;
  // donationPageTypeId?: DonationPageType;
  color?: string;
  elHeight?: string | number;
  dataTestId?: string;
};

const Footer: React.FC<Props> = ({
  page = null,
  color = '#E0E0E0',
  elHeight = 100,
  dataTestId = 'footer',
}) => {
  const { t } = useTranslation();
  const isDP =
    page &&
    page.donationPageTypeId &&
    page.donationPageTypeId === DonationPageType.DP;

  const classes = useStyles({ color, elHeight, isDP });

  const { height, ref } = useResizeDetector({
    handleHeight: false,
    refreshMode: 'debounce',
    refreshRate: 1000,
  });

  const rootProps = {
    'data-testid': dataTestId,
  };

  return (
    <Row
      as="footer"
      className={isDP ? classes.footerContainer : classes.footer}
      {...rootProps}
      ref={ref}
    >
      <Col
        xs={isDP ? 'auto' : 12}
        sm={isDP ? 'auto' : 7}
        className={!isDP && classes.termsCol}
      >
        {page && page.termsOfReference && page.termsOfReference.showPrivacy ? (
          <a
            className={classes.links}
            href={page.termsOfReference.privacyUrl || 'https://missioncrm.ca'}
            target="_blank"
            rel="noreferrer"
          >
            {t('privacy_policy')}
          </a>
        ) : null}

        {page &&
        page.termsOfReference &&
        page.termsOfReference.showTermsConditions ? (
          <>
            <span className={classes.separator}>|</span>
            <a
              className={classes.links}
              href={
                page.termsOfReference.termsConditionsUrl ||
                'https://missioncrm.ca'
              }
              target="_blank"
              rel="noreferrer"
            >
              {t('terms_conditions')}
            </a>
            {page && page.termsOfReference.footer && isDP && (
              <span
                style={{ display: height > 30 && 'none' }}
                className={classes.endSeparator}
              >
                |
              </span>
            )}
          </>
        ) : null}
      </Col>
      <Col
        xs={isDP ? 'auto' : 12}
        sm={isDP ? 'auto' : 5}
        className={isDP ? classes.footerText : classes.copyrightCol}
      >
        {page && page.termsOfReference && page.termsOfReference.footer ? (
          <div>
            <ParserHTML text={page.termsOfReference.footer} trim />
          </div>
        ) : null}
      </Col>
    </Row>
  );
};

export default Footer;
