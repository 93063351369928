import { createUseStyles } from 'react-jss';
import { MissionTheme } from '@root/interfaces/MissionTheme';

export default createUseStyles<any, any>((theme: MissionTheme) => ({
  root: {
    display: 'flex',
    overflowX: 'scroll',
    width: '100%',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '&.last': {
      marginRight: 0,
    },
  },
  carouselRow: {
    overflow: 'hidden',
    width: '100%',
    margin: 0,
  },
  carouselCol: {
    padding: 0,
  },
  subRoot: { margin: 'auto' },
  cardImage: {
    height: '13rem',
    backgroundColor: 'lightgray',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  arrowIconColor: {
    color: theme.colorPrimary,
  },
  carouselItems: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  action: {
    width: ({ itemsPerPage }) =>
      itemsPerPage ? `${(1 / itemsPerPage) * 100}%` : '100%',
    maxWidth: ({ isSm }) => {
      if (isSm) {
        return 290;
      }

      return 'none';
    },
    padding: '0 15px',
  },
}));
