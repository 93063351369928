import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import locales from './locales';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: locales,
    fallbackLng: 'en-CA',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: [
        'htmlTag',
        'localStorage',
        'cookie',
        'navigator',
        'querystring',
        'sessionStorage',
        'path',
        'subdomain',
      ],
    },
  });

export default i18n;
