import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ActionGroupSkeleton } from '@components/molecules/actionGroup';
import { CampaignPageDetails } from '@api/campaign';
import {
  setSelectedCampaignActionId,
  setDonationGroup,
} from '@modules/campaign/campaignSlice';

const { lazy, Suspense } = React;
const ActionGroup = lazy(() => import('@components/molecules/actionGroup'));

interface ActionsScreenProps {
  pageDetails?: CampaignPageDetails;
  isLoading: boolean;
}

const Actions: React.FC<ActionsScreenProps> = ({ pageDetails, isLoading }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const onSubmit = async (campaignActionId: string) => {
    dispatch(setSelectedCampaignActionId(campaignActionId));
    dispatch(setDonationGroup(null));
    history.push('donation');
  };
  if (isLoading) {
    return <ActionGroupSkeleton />;
  }

  return pageDetails ? (
    <Suspense fallback={<ActionGroupSkeleton />}>
      <ActionGroup handleClick={onSubmit} campaignPageDetails={pageDetails} />
    </Suspense>
  ) : null;
};

export default Actions;
