import whitney from './whitney';
import bbbsc from './bbbsc';
import arth from './arth';
import mission from './mission';
import tr from './tr';

export default {
  whitney,
  bbbsc,
  arth,
  mission,
  tr,
};
