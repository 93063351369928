import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useStyles from './signInModal.style';

import SignInForm from '../signInForm';

export type Props = {
  show?: boolean;
  onClose?: () => void;
  dataTestId?: string;
  configurationId?: string;
  mandateTelephone: boolean;
  showTelephone: boolean;
};

const SignInModal: React.FC<Props> = ({
  show,
  onClose = () => {},
  dataTestId = 'signInModal',
  configurationId = null,
  mandateTelephone = false,
  showTelephone = false,
}) => {
  const [currentPage, setCurrentPage] = React.useState<string>('sign_in');
  const { t } = useTranslation();
  const classes = useStyles();
  const rootProps = {
    className: classes.root,
    'data-testid': dataTestId,
  };

  const onChangePage = (page: string) => setCurrentPage(page);

  return (
    <Modal centered size="lg" show={show} onHide={onClose}>
      <Modal.Header closeButton {...rootProps}>
        <div>
          <Modal.Title>
            {t(
              currentPage === 'forgot_password'
                ? 'forgot_password_title'
                : currentPage
            )}
          </Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body className={classes.modalBody}>
        <SignInForm
          onChangePage={onChangePage}
          configurationId={configurationId}
          mandateTelephone={mandateTelephone}
          showTelephone={showTelephone}
        />
      </Modal.Body>
    </Modal>
  );
};

export default SignInModal;
