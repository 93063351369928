import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { DonationPageType, PageKind } from '@root/enums';
import { DonationPageDetails } from '@modules/donation/types';
import { Container, Row, Col } from 'react-bootstrap';
import { RootState } from '@app/RootState';
import { ContactHistorySkeleton } from '@components/molecules/contactHistory/contactHistory.skeleton';
import { HeaderSkeleton } from '@components/molecules/header/header.skeleton';
import { FooterSkeleton } from '@components/atoms/footer/footer.skeleton';
import { getOrderTotal } from '@utils/index';
import DonationRouter from '@modules/donation/router';
import globalStyles from '@styles/global';
import { DonationOCPSkeleton } from './ocpLayout.skeleton';
import useStyles from './ocpLayout.style';

const { lazy, Suspense } = React;
const Header = lazy(() => import('@components/molecules/header'));
const Footer = lazy(() => import('@components/atoms/footer'));
const ContactSearch = lazy(() => import('@components/molecules/contactSearch'));
const ContactHistory = lazy(
  () => import('@components/molecules/contactHistory')
);
const OcpSecondaryNavigation = lazy(
  () => import('@components/molecules/ocpSecondaryNavigation')
);
const Button = lazy(() => import('@components/atoms/button'));
const OrderSummary = lazy(() => import('@components/atoms/orderSummary'));

interface OCPLayoutScreenProps {
  pageDetails?: DonationPageDetails;
  donationPageTypeId?: DonationPageType;
  isOCPGate?: boolean;
  friendlyUrl?: any;
  handleResetProcessOnClick?: any;
}

const OCPLayout: React.FC<OCPLayoutScreenProps> = ({
  pageDetails = null,
  donationPageTypeId,
  isOCPGate = false,
  friendlyUrl,
  handleResetProcessOnClick = () => {},
}) => {
  globalStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { pathname } = useLocation();
  const defaultRoute = 'contact';
  const [showGivingComponent, setShowGivingComponent] = React.useState(false);
  const [route, setRoute] = React.useState(defaultRoute);
  const [orderTotal, setOrderTotal] = React.useState(null);
  const [updatedDonationAmount, setUpdatedDonationAmount] =
    React.useState(null);
  const { contactDetails, donationAmtAndFreq, coverCosts, selectedMembership } =
    useSelector((state: RootState) => state.app);
  const selectedNewGift: boolean = useSelector(
    (state: RootState) => state.app.selectedNewGift
  );

  React.useEffect(() => {
    const splitPath = pathname && pathname.split('/');
    setRoute(splitPath[splitPath.length - 1] || defaultRoute);
  }, [pathname]);

  React.useEffect(() => {
    if (donationAmtAndFreq) {
      let amount = donationAmtAndFreq.donationAmount;
      if (selectedMembership && selectedMembership.includeMembership) {
        let membershipAmount =
          selectedMembership.membershipBaseAmount +
          selectedMembership.membershipAmountTax;
        if (
          donationAmtAndFreq &&
          donationAmtAndFreq.givingFrequency !== 'once'
        ) {
          membershipAmount /= 12;
        }
        amount -= membershipAmount;
      }
      setUpdatedDonationAmount(amount);
    }
  }, [selectedMembership, donationAmtAndFreq]);

  React.useEffect(() => {
    const total = getOrderTotal({
      coverCosts,
      pageDetails,
      donationAmount: updatedDonationAmount,
      selectedMembership,
      givingFrequency: donationAmtAndFreq && donationAmtAndFreq.givingFrequency,
      pageKind: PageKind.Donation,
    });
    setOrderTotal(total);
  }, [
    donationAmtAndFreq,
    updatedDonationAmount,
    coverCosts,
    selectedMembership,
  ]);

  React.useEffect(() => {
    switch (route) {
      case 'payment':
        setShowGivingComponent(true);
        break;
      case 'giftAid':
        setShowGivingComponent(true);
        break;
      case 'membership':
        setShowGivingComponent(true);
        break;

      default:
        setShowGivingComponent(false);
        break;
    }
  }, [route]);

  const showHeader = pageDetails && pageDetails.showHeader;
  const marginTop = showHeader ? '' : 'mt-5';
  const classes = useStyles();

  return (
    pageDetails && (
      <Suspense fallback={<div>{t('loading')}</div>}>
        <Container fluid>
          <Row>
            <Col>
              <Suspense fallback={<HeaderSkeleton />}>
                <Header page={pageDetails} showDefaultHeader={showHeader} />
              </Suspense>
            </Col>
          </Row>
        </Container>
        <Container className={`${marginTop} ${classes.pageContainer}`} fluid>
          <Row>
            {!isOCPGate && (
              <Col md={6}>
                {donationPageTypeId === DonationPageType.OCP ? (
                  <Suspense fallback={<ContactHistorySkeleton />}>
                    <ContactSearch />
                    <OcpSecondaryNavigation />
                    <ContactHistory />
                  </Suspense>
                ) : (
                  <div>
                    <p />
                  </div>
                )}
              </Col>
            )}

            <Col md={{ span: isOCPGate ? 4 : 6, offset: isOCPGate ? 4 : 0 }}>
              <Suspense fallback={<DonationOCPSkeleton />}>
                <Row>
                  <Col>
                    {!isOCPGate &&
                      selectedNewGift &&
                      history.location.pathname !==
                        `/donationCaller/${friendlyUrl}/confirmation` && (
                        <Suspense fallback={<div>{t('loading')}</div>}>
                          <Row>
                            <Col>
                              <h2 className="title">{t('new_gift_title')}</h2>
                            </Col>
                            {selectedNewGift &&
                            donationPageTypeId === DonationPageType.OCP ? (
                              <Col sm="auto">
                                <Button
                                  type="button"
                                  to={selectedNewGift ? '.' : 'contact'}
                                  onClick={handleResetProcessOnClick}
                                  variant="link"
                                  block
                                >
                                  {t('cancel_btn')}
                                </Button>
                              </Col>
                            ) : null}
                          </Row>
                          {showGivingComponent && (
                            <OrderSummary
                              totalAmount={orderTotal}
                              toPage="contact"
                            />
                          )}
                        </Suspense>
                      )}
                    <DonationRouter
                      donationPageDetails={pageDetails}
                      donationPageTypeId={donationPageTypeId}
                      friendlyUrl={friendlyUrl}
                      hasContactDetails={!!contactDetails}
                      selectedNewGift={selectedNewGift}
                      orderTotal={orderTotal}
                    />
                  </Col>
                </Row>
              </Suspense>
            </Col>
          </Row>
        </Container>
        <Container className={classes.footerContainer} fluid>
          <Col>
            <Suspense fallback={<FooterSkeleton />}>
              <Footer page={pageDetails} />
            </Suspense>
          </Col>
        </Container>
      </Suspense>
    )
  );
};

export default OCPLayout;
