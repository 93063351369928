import axios, { AxiosRequestConfig } from 'axios';
import { objectToFormData } from '@utils/index';
import qs from 'qs';
import { authHeader } from '@utils/auth-header';

export interface Profile {
  pageOwnerId: string;
  firstName: string;
  lastName: string;
  emailadddress1?: string;
  telephone1?: string;
  billingLine1: string;
  billingLine2?: string;
  billingLine3?: string;
  billingCity: string;
  billingStateorProvince: string;
  billingPostalCode: string;
  billingCountry?: string;
  email?: string;
  telephone?: string;
  username: string;
  profilePictureId?: string;
  campaignPages?: any[];
}

export interface ProfilePostViewModel {
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  telephone1: string | null;
  oldPassword?: string | null;
  password?: string | null;
  uploadPhoto?: File | string;
  configurationId?: any;
  azureProfilePictureId?: string | null;
}

export interface ResetPasswordViewModel {
  email: string;
  oldPassword: string;
  newPassword: string;
}

export interface ILogin {
  access_token: string;
  refresh_token: string;
  expires_in: number;
  token_type: string;
  scope: string;
}

export interface ILogout {
  success: boolean;
}

export async function getUserProfile() {
  const requestOptions: AxiosRequestConfig = {
    method: 'get',
    headers: authHeader(),
    url: '/users/getUserProfile',
  };
  const { data } = await axios(requestOptions);
  return data;
}

export async function editUserProfile(
  email: string,
  firstName: string,
  lastName: string,
  telephone1: string,
  oldPassword?: any,
  password?: any,
  profilePictureId?: any,
  configurationId?: any,
  padlockToken?: string,
  webApiUrl?: string
) {
  const newProfilePicId = [];
  if (profilePictureId && profilePictureId !== '') {
    if (!webApiUrl && webApiUrl !== '') {
      return {
        success: false,
        message: "Couldn't edit user profile",
        passwordUpdated: false,
      };
    }

    const imgFormData = new FormData();
    imgFormData.append('file', profilePictureId.file);

    const imgRequestOptions: any = {
      method: 'post',
      crossDomain: true,
      processData: false,
      contentType: false,
      headers: { Accept: 'multipart/form-data', Padlock: padlockToken },
      url: `${webApiUrl}ImageStorage`,
      data: imgFormData,
    };
    const { data } = await axios(imgRequestOptions);
    const paths = data.fullPath.split('.blob.core.windows.net/');
    newProfilePicId.splice(1, 1, paths[1]);
  }

  const azureProfilePictureId =
    newProfilePicId && newProfilePicId[0] ? newProfilePicId[0] : '';

  const profileFormDetails: ProfilePostViewModel = {
    email,
    firstName,
    lastName,
    telephone1,
    oldPassword,
    password,
    configurationId,
    azureProfilePictureId,
  };

  const formData: FormData = objectToFormData(profileFormDetails);

  const requestOptions: AxiosRequestConfig = {
    method: 'post',
    headers: authHeader(),
    url: '/users/editUserProfile',
    data: formData,
  };
  const { data } = await axios(requestOptions);
  return data;
}

export async function userLogin(username: string, password: string) {
  const url = '/connect/token';
  const { data } = await axios({
    method: 'post',
    data: qs.stringify({
      username: username,
      password,
      grant_type: 'password',
      client_id: 'web_app',
      scope: 'offline_access',
    }),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    url,
  });
  return data;
}

export async function userRefreshToken() {
  const url = '/connect/token';
  const refresh_token = localStorage.getItem('refresh-token');

  const { data } = await axios({
    method: 'post',
    data: qs.stringify({
      refresh_token,
      grant_type: 'refresh_token',
      client_id: 'web_app',
    }),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    url,
  });
  return data;
}

export async function userSignUp(
  email: string,
  password: string,
  firstName: string,
  lastName: string,
  telephone1: string,
  configurationId: string
) {
  const url = '/users/signup';
  const { data } = await axios({
    method: 'post',
    data: qs.stringify({
      email,
      password,
      firstName,
      lastName,
      telephone1,
      configurationId,
    }),
    url,
  });
  return data;
}

export async function requestPasswordReset(email: string) {
  const requestOptions: AxiosRequestConfig = {
    method: 'post',
    headers: authHeader(),
    url: '/users/requestPasswordReset',
    data: qs.stringify({ email }),
  };
  const { data } = await axios(requestOptions);
  return data;
}

export async function sendPasswordReset(
  resetToken: string,
  email: string,
  newPassword: string
) {
  const requestOptions: AxiosRequestConfig = {
    method: 'post',
    headers: authHeader(),
    url: '/users/resetPassword',
    data: qs.stringify({ resetToken, email, newPassword }),
  };
  const { data } = await axios(requestOptions);
  return data;
}
