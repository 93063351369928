import { createUseStyles } from 'react-jss';
import { MissionTheme } from '@root/interfaces';

export default createUseStyles<any, any>((theme: MissionTheme) => ({
  donationPageAmounts: {
    '& .btn': {
      minHeight: 39,
    },
  },
  amountButtonRow: {
    marginLeft: -8,
    marginRight: -8,
  },

  amountButton: {
    paddingLeft: 8,
    paddingRight: 8,
  },

  subTitlesWeight: {
    fontWeight: theme.headingBold ? 600 : 400,
  },
  sectionTag: {
    fontSize: theme.subHeadingFontSize,
    marginBottom: 16,
    '@media (max-width: 576px)': {
      margin: '16px 0',
    },
  },
}));
