import { createUseStyles } from 'react-jss';

export default createUseStyles<any, any>({
  root: {
    borderTop: '8px solid #999999',
    borderRadius: 0,
  },
  modalBody: {
    borderRadius: 0,
    paddingLeft: 30,
    paddingRight: 30,
    borderBottom: '1px solid white',
  },
});
