import * as React from 'react';
import Parser from 'html-react-parser';

export type Props = {
  text?: string;
  trim?: boolean;
};

const ParserHTML: React.FC<Props> = ({ text, trim = false }) => {
  return (
    <>
      {Parser(text, {
        trim: trim,
        replace: ({ data }) => {
          if (data && data !== undefined) {
            return <>{data.replace(/[ ](?=[^ ]*$)/g, '\u00A0')}</>;
          }
          return null;
        },
      })}
    </>
  );
};

export default ParserHTML;
