import * as React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Skeleton from '@components/atoms/skeleton';
import { HeaderSkeleton } from '@components/molecules/header/header.skeleton';
import globalStyles from '@styles/global';
import useStyles from './donationLayout.style';

const DonationLayoutSkeleton = () => {
  globalStyles();
  const classes = useStyles({ route: 'donate' });
  return (
    <>
      <div className={classes.donationLayoutContainer}>
        <div className={classes.imageContainer} />
        <div className={classes.opacitySkeleton} />
        <div className={classes.themeColorSkeleton} />
      </div>
      <Container fluid className={classes.contentContainer}>
        <Row className={classes.contentRow}>
          <Col className={classes.contentCol} id="pageContent">
            <Row>
              <Col className={classes.headerSection}>
                <Row className={classes.headerContainer}>
                  <Col xs={12}>
                    <HeaderSkeleton />
                  </Col>
                </Row>
                <Row className={classes.contentSubRow}>
                  <Col className={classes.contentSubCol}>
                    <Row>
                      <Col xs={6} sm={6} lg={6}>
                        <Skeleton height={30} />
                      </Col>
                    </Row>
                    <br />
                    <Skeleton height={10} count={3} />
                    <br />
                    <Row>
                      <Col xs={6} sm={6} lg={6}>
                        <Skeleton height={30} />
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col xs={4} sm={4} lg={4} className="mb-4">
                        <Skeleton height={30} />
                      </Col>
                      <Col xs={4} sm={4} lg={4} className="mb-4">
                        <Skeleton height={30} />
                      </Col>
                      <Col xs={4} sm={4} lg={4} className="mb-4">
                        <Skeleton height={30} />
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col xs={6} sm={6} lg={6}>
                        <Skeleton height={30} />
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col xs={6} sm={4} lg={3} className="mb-4">
                        <Skeleton height={30} />
                      </Col>
                      <Col xs={6} sm={4} lg={3} className="mb-4">
                        <Skeleton height={30} />
                      </Col>
                      <Col xs={6} sm={4} lg={3} className="mb-4">
                        <Skeleton height={30} />
                      </Col>
                      <Col xs={6} sm={4} lg={3} className="mb-4">
                        <Skeleton height={30} />
                      </Col>
                      <Col xs={6} sm={4} lg={3} className="mb-4">
                        <Skeleton height={30} />
                      </Col>

                      <Col xs={6} sm={4} className="d-block d-md-none">
                        <Skeleton height={30} />
                      </Col>
                      <Col className="d-block d-md-none">
                        <Skeleton height={30} />
                      </Col>

                      <Col className="d-none d-md-block" md={12} lg={9}>
                        <Row>
                          <Col xs={6} sm={3} md={4}>
                            <Skeleton height={30} />
                          </Col>
                          <Col>
                            <Skeleton height={30} />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col xs={6} sm={6} lg={6}>
                        <Skeleton height={30} />
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col xs={4} sm={4} lg={4} className="mb-4">
                        <Skeleton height={30} />
                      </Col>
                      <Col xs className="mb-4">
                        <Skeleton height={30} />
                      </Col>
                    </Row>
                    <br />
                    <br />
                    <Row
                      style={{
                        flexDirection: 'row-reverse',
                      }}
                    >
                      <Col xs={2} sm={2} lg={2}>
                        <Skeleton height={30} />
                      </Col>
                    </Row>
                    <br />
                    <br />
                    <br />
                    <br />
                    <Row
                      style={{
                        justifyContent: 'center',
                      }}
                    >
                      <Col xs={8} sm={8} lg={8}>
                        <Skeleton height={10} />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export { DonationLayoutSkeleton };
