import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
  container: {
    margin: 0,
    padding: {
      right: 15,
      left: 15,
    },
    '@media (min-width: 576px)': {
      maxWidth: 540,
    },
    '@media (min-width: 768px)': {
      maxWidth: 720,
    },
    '@media (min-width: 992px)': {
      maxWidth: 960,
    },
    '@media (min-width: 1200px)': {
      maxWidth: 1240,
    },
  },
  main: {
    paddingTop: 5,
    // paddingBottom: 30,
    // paddingLeft: 0,
    // paddingRight: 0,
    padding: 0,
    backgroundColor: '#fff',
    width: '100%',
  },
  actionScreen: {
    margin: 0,
    padding: 0,
    maxWidth: '100%',
  },
  screens: {
    backgroundColor: (props: { matchedRoute: any }) =>
      props.matchedRoute && 'white',
    borderBottom: (props: { matchedRoute: any }) =>
      props.matchedRoute && '8px solid #999999',
    '@media (min-width: 576px)': {
      padding: (props: { matchedRoute: any }) => props.matchedRoute && 30,
      marginLeft: (props: { matchedRoute: any }) => props.matchedRoute && 30,
      marginRight: (props: { matchedRoute: any }) => props.matchedRoute && 30,
    },
    '@media (max-width: 576px)': {
      padding: (props: { matchedRoute: any }) => props.matchedRoute && 15,
      marginLeft: (props: { matchedRoute: any }) =>
        props.matchedRoute && '0.7rem',
      marginRight: (props: { matchedRoute: any }) =>
        props.matchedRoute && '0.7rem',
    },
  },
  actionContainer: {
    backgroundColor: '#E0E0E0',
    padding: 0,
  },
  screensPadding: {
    paddingTop: 30,
    paddingBottom: 30,
  },
  carouselContainer: {
    background: '#fff',
    textAlign: 'center',
  },
  colPadding: {
    padding: 0,
  },
  campaignSupportersContainer: {
    background: '#EDEDED',
    padding: 0,
  },
}));
