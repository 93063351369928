import * as React from 'react';
import { Carousel, Row, Col } from 'react-bootstrap';
import { ChevronLeft, ChevronRight } from 'react-bootstrap-icons';
import { useMediaQuery } from 'react-responsive';
// import ScrollContainer from 'react-indiana-drag-scroll';
import Card from '@components/atoms/card';
import { PageBackground } from '@root/interfaces';
import { PageBackgroundType } from '@root/enums';
import useStyles from './actionGroup.style';

export { ActionGroupSkeleton } from './actionGroup.skeleton';

export type Props = {
  campaignPageDetails: any;
  handleClick?: any;
  color?: string;
  dataTestId?: string;
};
const ActionGroup: React.FC<Props> = ({
  campaignPageDetails = null,
  handleClick = () => {},
  color = '',
}) => {
  const [activePageIndex, setActivePageIndex] = React.useState(0);
  const isLg = useMediaQuery({ minWidth: 993, maxWidth: 1200 });
  const isMd = useMediaQuery({ minWidth: 768, maxWidth: 992 });
  const isSm = useMediaQuery({ maxWidth: 767 });

  const data =
    campaignPageDetails && campaignPageDetails.campaignPageActions
      ? campaignPageDetails.campaignPageActions
      : [];

  const updatedData = [];
  data.forEach((element) => {
    if (element.defaultAmount) {
      updatedData.push(element);
    }
  });
  const itemsPerPage = (isSm && 1) || (isMd && 2) || (isLg && 3) || 4;
  let indexOfLastItem = 1 * itemsPerPage;
  let currentActions =
    updatedData &&
    updatedData.slice(indexOfLastItem - itemsPerPage, indexOfLastItem);
  const noOfPages = updatedData && Math.ceil(updatedData.length / itemsPerPage);
  const items = [];
  const classes = useStyles({ color, itemsPerPage, isSm, isMd });

  for (let i = 0; i < noOfPages; i += 1) {
    indexOfLastItem = (i + 1) * itemsPerPage;
    currentActions = updatedData.slice(
      indexOfLastItem - itemsPerPage,
      indexOfLastItem
    );

    items.push(
      <Carousel.Item key={i.toString()}>
        <div className={classes.carouselItems}>
          {currentActions.map((value, index) => {
            const background: PageBackground = value.cardImage;
            let heroProps: any = {
              fluid: true,
            };

            if (background) {
              if (background.type === PageBackgroundType.RemoteId) {
                heroProps = {
                  ...heroProps,
                  remoteImageId: background.value,
                };
              } else if (background.type === PageBackgroundType.Path) {
                heroProps = {
                  ...heroProps,
                  remoteImageUrl: background.value,
                };
              } else if (background.type === PageBackgroundType.URL) {
                heroProps = {
                  ...heroProps,
                  src: background.value,
                };
              }
            }

            return (
              <div key={index.toString()} className={classes.action}>
                <Card
                  {...heroProps}
                  displayTitle={value.displayTitle}
                  actionTitle={value.display}
                  message={value.message}
                  amount={value.defaultAmount}
                  handleClick={() => {
                    handleClick(value.campaignPageActionId);
                  }}
                />
              </div>
            );
          })}
        </div>
      </Carousel.Item>
    );
  }

  React.useEffect(() => {
    if (activePageIndex >= noOfPages) {
      setActivePageIndex(noOfPages - 1);
    }
  }, [noOfPages]);

  return (
    <div>
      <Row className={classes.carouselRow}>
        <Col className={classes.carouselCol}>
          <Carousel
            activeIndex={activePageIndex}
            onSelect={(selectedIndex) => setActivePageIndex(selectedIndex)}
            interval={null}
            indicators={false}
            wrap={false}
            style={{ width: '100%' }}
            prevIcon={
              <ChevronLeft size={40} className={classes.arrowIconColor} />
            }
            nextIcon={
              <ChevronRight size={40} className={classes.arrowIconColor} />
            }
          >
            {items}
          </Carousel>
        </Col>
      </Row>
    </div>
  );
};
// const ActionGroup: React.FC<Props> = ({
//   campaignPageDetails = null,
//   handleClick = () => {},
//   color = '',
//   dataTestId = 'actionGroup',
// }) => {
//   const classes = useStyles({ color });

//   const rootProps = {
//     className: classes.root,
//     'data-testid': dataTestId,
//     id: 'actions',
//   };

//   return (
//     <ScrollContainer {...rootProps}>
//       {campaignPageDetails && campaignPageDetails.campaignPageActions
//         ? campaignPageDetails.campaignPageActions.map((values) =>
//             values.defaultAmount ? (
//               <div
//                 className={classes.subRoot}
//                 key={values.campaignPageActionId}
//               >
//                 <Card
//                   imageId={values.smallImage}
//                   displayTitle={values.displayTitle}
//                   message={values.message}
//                   amount={values.defaultAmount}
//                   handleClick={() => {
//                     handleClick(values.campaignPageActionId);
//                   }}
//                 />
//               </div>
//             ) : null
//           )
//         : null}
//     </ScrollContainer>
//   );
// };

export default ActionGroup;
