import { createUseStyles } from 'react-jss';
import { MissionTheme } from '@root/interfaces/MissionTheme';

export default createUseStyles<any, any>((theme: MissionTheme) => ({
  progressIndicator: {
    width: 'auto',
    height: 1,
    position: 'relative',
    top: 0,
    left: 0,
    margin: 0,
    backgroundColor: theme.colorPrimary,
    background: theme.activeGradient,
    // '@media (min-width: 768px)': {
    //   marginLeft: -15,
    //   marginRight: -15,
    // },
  },
  stepCol: {
    position: 'relative',
    zIndex: 1,
    justifyContent: 'center',
  },
  step: {
    width: '2.192vw',
    height: '2.192vw',
    backgroundColor: 'white',
    border: {
      width: 1,
      style: 'solid',
      color: theme.colorPrimary,
    },
    borderRadius: '50%',
    position: 'relative',
    top: '-1.095462vw',
    '@media (min-width: 640px)': {
      top: -7,
      width: 14,
      height: 14,
    },
    // '@media (max-width: 768px)': {
    //   width: 16,
    //   height: 16,
    //   top: -8,
    // },
  },
  viewedStep: {
    background: theme.activeGradient,
    border: '1px solid transparent',
  },
  activeStep: {
    '&:before': {
      content: '""',
      width: '4.06379vw',
      height: '4.06379vw',
      background: theme.activeGradient,
      border: {
        width: 1,
        style: 'solid',
        color: theme.colorPrimary,
      },
      borderRadius: '50%',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      marginLeft: '-1.09375vw',
      zIndex: -2,
      '@media (min-width: 640px)': {
        width: 26,
        height: 26,
        marginLeft: -7,
      },

      // '@media (max-width: 768px)': {
      //   width: 30,
      //   height: 30,
      //   marginLeft: -8,
      // },
    },
    '&:after': {
      content: '""',
      width: '3.752vw',
      height: '3.752vw',
      backgroundColor: 'white',
      border: {
        width: 1,
        style: 'solid',
        color: 'transparent',
      },
      borderRadius: '50%',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      marginLeft: '-0.9375vw',
      zIndex: -1,
      '@media (min-width: 640px)': {
        width: 24,
        height: 24,
        marginLeft: -6,
      },

      // '@media (max-width: 768px)': {
      //   width: 28,
      //   height: 28,
      //   marginLeft: -7,
      // },
    },
  },
}));
