import * as React from 'react';
import NumberFormat from 'react-number-format';
import {
  isMobile,
  isIOS,
  isDesktop,
  isMacOs,
  osVersion,
  browserVersion,
  browserName,
} from 'react-device-detect';
import { TransactionCurrency } from '@app/App';
import { useTranslation } from 'react-i18next';
import { getCurrencyFormats, getAmountFormatter } from '@utils/index';
import useStyles from './numberFormatter.style';

export type Props = {
  value?: string | number;
  bold?: boolean;
  hideDecimalPlaces?: boolean;
  hideLocale?: boolean;
};

const NumberFormatter: React.FC<Props> = ({
  value = 0,
  bold = false,
  hideDecimalPlaces = false,
  hideLocale = false,
}) => {
  const { i18n } = useTranslation();
  const osVersionStr = osVersion && osVersion.split('.')[0];
  const transactionCurrency = React.useContext(TransactionCurrency);
  const { currencySymbol, languageCode, isoCurrencyCode } = transactionCurrency;
  const { thousandSeparator, decimalSeparator, prefix, suffix } =
    getCurrencyFormats({
      languageCode,
      currencySymbol,
      isoCurrencyCode,
    });

  const classes = useStyles({
    bold,
  });

  return (
    <span className={classes.numberFormatter}>
      {(isDesktop &&
        isMacOs &&
        browserName === 'Safari' &&
        parseInt(browserVersion, 10) <= 12) ||
      (isMobile &&
        isIOS &&
        osVersionStr &&
        osVersionStr.length > 0 &&
        (parseInt(osVersionStr, 10) === 12 ||
          parseInt(osVersionStr, 10) === 11)) ? (
        <NumberFormat
          value={value || 0}
          displayType="text"
          thousandSeparator={thousandSeparator}
          decimalScale={2}
          fixedDecimalScale={!hideDecimalPlaces}
          decimalSeparator={decimalSeparator}
          prefix={prefix}
          suffix={suffix}
        />
      ) : (
        <>
          {getAmountFormatter(
            value,
            i18n.language,
            transactionCurrency.isoCurrencyCode,
            hideDecimalPlaces,
            hideLocale
          )}
        </>
      )}
    </span>
  );
};

export default React.memo(NumberFormatter);
