import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setSelectedNewGift } from '@app/app.slice';
import Button from '@components/atoms/button';
import ContactSearch from '@components/molecules/contactSearch';

const OCPGate = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleNewContact = () => {
    dispatch(setSelectedNewGift(true));
  };

  return (
    <>
      <p className="gateText">{t('start_by')}</p>
      <ContactSearch />

      <p className="gateText">{t('or')}</p>
      <Button type="button" to="contact" onClick={handleNewContact}>
        {t('create_new_contact_btn')}
      </Button>
    </>
  );
};

export default OCPGate;
