import * as React from 'react';
import { useParams } from 'react-router';
import { IRouteParams } from '@app/IRouteParams';

const Details: React.FC = () => {
  const { friendlyUrl } = useParams<IRouteParams>();
  return (
    <div>
      Event Details for: <code>{friendlyUrl}</code>
    </div>
  );
};

export default Details;
