import { createUseStyles } from 'react-jss';
import { MissionTheme } from '@root/interfaces/MissionTheme';

export default createUseStyles<any, any>((theme: MissionTheme) => ({
  footer: {
    position: 'relative',
    fontFamily: theme.primaryFont.fontFamily,
    clear: 'both',
    bottom: 0,
    right: 0,
    paddingTop: 30,
    paddingBottom: 30,
    paddingLeft: 0,
    paddingRight: 0,
    height: (props: { height: string | number }) => `${props.height}` || 'auto',
    minHeight: 100,
    width: 'auto',
    backgroundColor: (props: { color: string; isDP: boolean }) =>
      props.color && !props.isDP ? props.color : '#fff',
  },

  footerContainer: {
    textAlign: 'center',
    justifyContent: 'center',
    marginTop: 'auto',
    marginBottom: '4vw',
    '@media (min-width: 768px)': {
      marginBottom: '2vw',
    },
    '@media (min-width: 992px)': {
      marginBottom: '1vw',
    },
  },

  termsCol: {
    paddingTop: '8px',
    display: 'flex',
    '@media (max-width: 576px)': {
      justifyContent: 'center',
    },
    '@media (min-width: 576px) and (max-width: 768px)': {
      flexDirection: 'column',
    },
  },
  copyrightCol: {
    paddingTop: '8px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignContent: 'flex-end',
    textAlign: 'end',
    color: theme.colorText,
    '@media (max-width: 576px)': {
      justifyContent: 'center',
      textAlign: 'center',
      paddingTop: 0,
    },
  },
  separator: {
    color: theme.colorText,
    marginLeft: '15px',
    marginRight: '15px',
    '@media (min-width: 576px) and (max-width: 768px)': {
      display: (props: { isDP: boolean }) => (props.isDP ? 'inline' : 'none'),
    },
  },

  endSeparator: {
    fontSize: 13,
    color: '#7C7C7C',
    marginLeft: '15px',
  },

  links: {
    fontSize: (props: { isDP: boolean }) => (props.isDP ? 13 : 'inherit'),
    color: theme.colorText || '#7C7C7C',
    textDecoration: (props: { isDP: boolean }) =>
      props.isDP ? 'underline' : 'none',
  },

  footerText: {
    fontSize: (props: { isDP: boolean }) => (props.isDP ? 13 : 'inherit'),
    alignSelf: (props: { isDP: boolean }) => (props.isDP ? 'end' : 'initial'),
    paddingLeft: (props: { isDP: boolean }) => (props.isDP ? 0 : '15'),
  },
  skeletonRow: {
    padding: 0,
  },
  skeletonCol: {
    padding: 0,
    margin: 0,
    marginTop: '-4px',
  },
}));
