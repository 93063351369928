import { MissionTheme } from '../../interfaces/MissionTheme';
import defaultStyle from './default';

const theme: MissionTheme = {
  ...defaultStyle,
  colorPrimary: '#050505',
  colorSecondary: '#E6E7E9',
  headerBackgroundColor: 'white',
  headerTitleColor: '#050505',
};

export default theme;
