import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { DonationPageType } from '@root/enums';
import { DonationPageDetails } from '../types';

type RouteProps = {
  donationPageDetails: DonationPageDetails;
  donationPageTypeId: DonationPageType;
  path: string;
  component: any;
  friendlyUrl: string;
  url: string;
  isDefault: boolean;
  hasContactDetails: boolean;
  selectedNewGift: boolean;
  orderTotal?: number;
};

const DonationRoute: React.FC<RouteProps> = ({
  donationPageDetails,
  donationPageTypeId,
  path,
  component,
  friendlyUrl,
  url,
  isDefault,
  hasContactDetails,
  selectedNewGift,
  orderTotal,
}) => {
  const Component = component;
  const isDP = donationPageTypeId === DonationPageType.DP;
  const isOCP = donationPageTypeId === DonationPageType.OCP;

  if (isOCP && isDefault) {
    return (
      <Route path={path}>
        {url.charAt(url.length - 1) === '/' ? (
          <Component
            pageDetails={donationPageDetails}
            donationPageTypeId={donationPageTypeId}
            orderTotal={orderTotal}
          />
        ) : (
          <Redirect to={`${url}/`} />
        )}
      </Route>
    );
  }

  return (
    <Route
      // exact={path === '/'}
      path={path}
    >
      {donationPageDetails && (hasContactDetails || selectedNewGift || isDP) ? (
        <Component
          pageDetails={donationPageDetails}
          donationPageTypeId={donationPageTypeId}
          orderTotal={orderTotal}
        />
      ) : (
        <Redirect
          to={`/${isOCP ? 'donationCaller' : 'donation'}/${friendlyUrl}/`}
        />
      )}
    </Route>
  );
};

export default DonationRoute;
