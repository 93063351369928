import * as React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Form, Modal, Alert, Spinner, Col } from 'react-bootstrap';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@app/RootState';
import { useHistory } from 'react-router-dom';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { CampaignPageCRUDViewModel, CampaignPageDetails } from '@api/campaign';
import {
  createNewCampaignPage,
  CampaignPageTypes,
  setSelectedCampaignPageId,
  clearChildPageCreation,
} from '@modules/campaign/campaignSlice';
import { fetchUserProfile } from '@modules/user/user.slice';
import validationSchema from './validationSchema';
import useStyles from './campaignForm.style';

const { lazy, Suspense } = React;
const InputFormGroup = lazy(
  () => import('@components/molecules/inputFormGroup')
);
const Button = lazy(() => import('@components/atoms/button'));
const InputAmountField = lazy(
  () => import('@components/atoms/inputAmountField')
);
const FloatingLabel = lazy(() => import('@components/atoms/floatingLabel'));

export type Props = {
  page: CampaignPageDetails;
  show?: boolean;
  modalType?: string;
  pageType?: number;
  handleClose?: any;
  dataTestId?: string;
};

const CampaignForm: React.FC<Props> = ({
  page = null,
  show = false,
  modalType = 'campaignForm',
  pageType = CampaignPageTypes.Individual,
  handleClose = () => {},
  dataTestId = 'campaignForm',
}) => {
  const { t, i18n } = useTranslation();
  const locale = i18n.language;
  const dispatch = useDispatch();
  const history = useHistory();
  const recaptchaRef: any = React.useRef();
  const { error, isLoading, childPageCreation, selectedCampaignPageId } =
    useSelector((state: RootState) => state.campaign);
  const [useRecaptcha, setUseRecaptcha] = React.useState(false);
  const [stateCampaignGoal, setStateCampaignGoal] = React.useState('0');
  const recaptchaSiteKey =
    page && page.recaptchaSiteKey ? page.recaptchaSiteKey : null;
  const currency =
    page && page.transactionCurrency && page.transactionCurrency.isoCurrencyCode
      ? page.transactionCurrency.isoCurrencyCode
      : 'CAD';
  React.useEffect(() => {
    setUseRecaptcha(window.location.hostname !== 'localhost');
  }, []);

  React.useEffect(() => {
    if (childPageCreation) {
      if (childPageCreation.success) {
        dispatch(
          setSelectedCampaignPageId(
            childPageCreation.data.campaignPage.campaignPageId
          )
        );
      }
    }
  }, [childPageCreation]);

  React.useEffect(() => {
    if (selectedCampaignPageId && childPageCreation) {
      dispatch(clearChildPageCreation());
      dispatch(fetchUserProfile());
      history.push('/user/editCampaign');
      handleClose();
    }
  }, [selectedCampaignPageId]);

  const handleMinimumAmount = () => {
    const convertAmount =
      stateCampaignGoal && stateCampaignGoal.replace(/,(?!.*,)/g, '.');
    let amount = convertAmount && Number(convertAmount);
    if (!amount || amount < 0) {
      amount = 0;
    }
    setStateCampaignGoal(amount.toFixed(2));
  };

  const handleFormSubmit = async (values) => {
    const token = useRecaptcha
      ? await recaptchaRef.current.executeAsync()
      : 'localhost';

    Object.assign(values, {
      ...values,
      campaignType:
        modalType === 'joinTeam' ? CampaignPageTypes.TeamMember : pageType,
    });

    const campaignFormDetails: CampaignPageCRUDViewModel = {
      name: values.campaignName,
      goal: values.campaignGoal,
      campaignPageTypeId: values.campaignType,
      campaignPageId: '00000000-0000-0000-0000-000000000000',
      parentCampaignPageId: page.campaignPageId,
      grandParentCampaignPageId:
        modalType === 'joinTeam' ? page.parentCampaignPageId : '',
      isNewCampaign: true,
    };

    if (token != null) {
      dispatch(createNewCampaignPage(campaignFormDetails));
    }
  };

  const initialValues = {
    campaignName: '',
    campaignGoal: '',
    campaignType: pageType,
  };

  const showError = (error, touched) => {
    let showError: boolean = false;
    if (error && touched) {
      showError = true;
    }
    return showError;
  };

  // Prevent negative/minus
  // const handleKeyPress = (e) => {
  //   if (e.charCode === 45 || e.charCode === 43) {
  //     e.preventDefault();
  //   }
  // };

  const classes = useStyles();
  const rootProps = {
    className: classes.root,
    'data-testid': dataTestId,
  };

  return page ? (
    <Modal size="lg" centered show={show} onHide={handleClose}>
      <Modal.Header closeButton {...rootProps}>
        <div
          style={{
            textAlign: 'center',
            width: '100%',
          }}
        >
          <Modal.Title>{t('campaign_title')}</Modal.Title>
          <h5 style={{ marginTop: 7 }}>
            {modalType === 'joinTeam' ? (
              <>
                {i18next.t('join_team_subtitle', {
                  name: page.displayTitle,
                })}
              </>
            ) : (
              <>
                {pageType === CampaignPageTypes.Team
                  ? t('team_subtitle')
                  : t('individual_subtitle')}
              </>
            )}
          </h5>
        </div>
      </Modal.Header>

      <Modal.Body className={classes.modalBody}>
        {error && !isLoading ? (
          <Alert variant="danger">{t(error)}</Alert>
        ) : null}

        <Formik
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            errors,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Suspense fallback={<div>{t('loading')}</div>}>
                <Form.Row className="mt-4">
                  <InputFormGroup
                    label={
                      pageType === CampaignPageTypes.Team &&
                      modalType !== 'joinTeam'
                        ? t('team_campaign_name')
                        : (pageType === CampaignPageTypes.Team &&
                            t('team_member_page_name_label')) ||
                          t('individual_page_name_label')
                    }
                    value={values.campaignName}
                    controlId="campaignName"
                    type="search"
                    maxLength={100}
                    xs={12}
                    inputFieldSize="lg"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    showError={showError(
                      errors.campaignName,
                      touched.campaignName
                    )}
                    feedback={t('campaign_name_validation')}
                  />
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="campaignGoal">
                    <FloatingLabel
                      label={
                        pageType === CampaignPageTypes.Team &&
                        modalType !== 'joinTeam'
                          ? t('team_campaign_goal')
                          : (pageType === CampaignPageTypes.Team &&
                              t('team_member_page_goal_label')) ||
                            t('individual_page_goal_label')
                      }
                      value={stateCampaignGoal}
                      controlId="campaignGoal"
                    >
                      <InputAmountField
                        inputAmount={stateCampaignGoal}
                        onBlur={handleMinimumAmount}
                        minimumAmount={0}
                        occurrence={currency}
                        locale={locale}
                        handleChange={(value) => {
                          values.campaignGoal = value;
                          // setFieldValue('campaignGoal', value);
                          setStateCampaignGoal(value);
                        }}
                        customAmount
                        pageDetails={page}
                        hideMinimumAmount
                      />
                    </FloatingLabel>
                  </Form.Group>
                </Form.Row>
                {/* <Form.Row>
                  input
                  <InputFormGroup
                    label={
                      pageType === CampaignPageTypes.Team &&
                      modalType !== 'joinTeam'
                        ? t('team_campaign_goal')
                        : (pageType === CampaignPageTypes.Team &&
                            t('team_member_page_goal_label')) ||
                          t('individual_page_goal_label')
                    }
                    value={values.campaignGoal}
                    controlId="campaignGoal"
                    min={0}
                    xs={12}
                    type="number"
                    inputFieldSize="lg"
                    currencyField
                    handleChange={(e) => {
                      setFieldValue(
                        'campaignGoal',
                        !!e.target.value &&
                          Math.abs(e.target.value) >= 0 &&
                          Math.abs(e.target.value) <= 100000000000 && // doesn't allow goal amount > 100 billion
                          Number(e.target.value)
                      );
                    }}
                    onKeyPress={handleKeyPress}
                    handleBlur={handleBlur}
                    showError={showError(
                      errors.campaignGoal,
                      touched.campaignGoal
                    )}
                    feedback={t('campaign_goal_validation')}
                  />
                </Form.Row> */}
                <hr />
                <Button
                  type="submit"
                  disabled={!(values.campaignName && values.campaignGoal)}
                >
                  {isLoading ? (
                    <Spinner animation="border" role="status">
                      <span className="sr-only">{t('loading')}</span>
                    </Spinner>
                  ) : (
                    <>
                      {modalType === 'startFundraising'
                        ? t('create_campaign_btn')
                        : t('join_team_btn')}
                    </>
                  )}
                </Button>
              </Suspense>
            </Form>
          )}
        </Formik>
        {useRecaptcha ? (
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey={recaptchaSiteKey}
          />
        ) : null}
      </Modal.Body>
    </Modal>
  ) : null;
};

export default CampaignForm;
