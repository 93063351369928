import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import NumberFormatter from '@components/atoms/numberFormatter';
import { RootState } from '@app/RootState';
import { DonationPageDetails } from '@modules/donation/types';
import { DateUtils } from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
// eslint-disable-next-line import/no-duplicates
import dateFnsFormat from 'date-fns/format';
// eslint-disable-next-line import/no-duplicates
import dateFnsParse from 'date-fns/parse';
import InputAmountField from '@components/atoms/inputAmountField';
import useStyles from './updatePaymentSchedule.style';

interface UpdatePaymentScheduleProps {
  pageDetails: DonationPageDetails;
  recurringAmount: string;
  handleOnChangeState: any;
  paymentDate: Date;
  membershipAmount: number;
  totalAmount: number;
}

const UpdatePaymentSchedule: React.FC<UpdatePaymentScheduleProps> = ({
  pageDetails,
  recurringAmount,
  handleOnChangeState,
  membershipAmount,
  totalAmount,
}) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const locale = i18n.language;

  const { paymentSchedule } = useSelector((state: RootState) => state.donation);

  const isMinimumAmount = !pageDetails.minimumAmount
    ? (Math.round(1 * 100) / 100).toFixed(2)
    : pageDetails.minimumAmount.toFixed(2);

  const currency =
    pageDetails &&
    pageDetails.transactionCurrency &&
    pageDetails.transactionCurrency.isoCurrencyCode
      ? pageDetails.transactionCurrency.isoCurrencyCode
      : 'CAD';

  const FORMAT = 'yyyy-MM-dd';

  function parseDate(str, format, locale) {
    const parsed = dateFnsParse(str, format, new Date(), { locale });
    if (DateUtils.isDate(parsed)) {
      return parsed;
    }
    return undefined;
  }

  function formatDate(date, format, locale) {
    return dateFnsFormat(date, format, { locale });
  }

  const handleValueChange = (e) => {
    const isAmount = e.currentTarget.value || isMinimumAmount;
    const updateStateValue = {
      value: isAmount,
      name: 'amount',
    };
    handleOnChangeState(updateStateValue);
  };

  const handleDateChange = (e) => {
    const updateStateValue = {
      value: e.toISOString(),
      name: 'date',
    };
    handleOnChangeState(updateStateValue);
  };

  const handleMinimumAmount = () => {
    const minimumAmount = pageDetails.minimumAmount
      ? pageDetails.minimumAmount
      : 1;
    const amount = Number(recurringAmount);
    if (amount < minimumAmount) {
      handleOnChangeState({
        value: minimumAmount.toFixed(2),
        name: 'amount',
      });
    }
  };

  const one = 1;

  return (
    <Form.Group controlId="updatePaymentSchedule">
      <InputAmountField
        minimumAmount={Number(isMinimumAmount)}
        inputAmount={recurringAmount || isMinimumAmount.toString()}
        defaultAmount={
          pageDetails.minimumAmount ? isMinimumAmount : one.toFixed(2)
        }
        occurrence={`${currency}/${t('month').toUpperCase()}`}
        locale={locale}
        handleChange={(value) =>
          handleValueChange({
            currentTarget: { name: 'amount', value },
          })
        }
        onBlur={handleMinimumAmount}
        customAmount
        pageDetails={pageDetails}
      />

      <div className={classes.amountTotals}>
        <Row>
          <Col xs={4}>
            <div>{`${t('Amount')} (${t('membership')}):`}</div>
          </Col>
          <Col>
            <div>
              <NumberFormatter value={membershipAmount} hideLocale />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <div>{`${t('Total')}:`}</div>
          </Col>
          <Col>
            <div>
              {<NumberFormatter value={totalAmount} hideLocale /> ||
                isMinimumAmount}
            </div>
          </Col>
        </Row>
      </div>
      <Form.Label className={classes.lable}>Next Payment</Form.Label>
      <div className="setWidth">
        <DayPickerInput
          formatDate={formatDate}
          format={FORMAT}
          parseDate={parseDate}
          placeholder={`${dateFnsFormat(
            (paymentSchedule &&
              paymentSchedule.nextPaymentDate &&
              new Date(paymentSchedule.nextPaymentDate)) ||
              new Date(),
            FORMAT
          )}`}
          onDayChange={handleDateChange}
        />
      </div>
    </Form.Group>
  );
};

export default UpdatePaymentSchedule;
