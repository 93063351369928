import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'react-jss';
import { ChevronRight } from 'react-bootstrap-icons';
import Toggle from '@components/atoms/toggle';
import { DonationPageType, PageKind } from '@root/enums';
import { DonationPageTheme } from '@root/interfaces';
import ParserHTML from '@components/atoms/parserHTML';
import { GivingFrequency } from '@app/types';
import { DonationPageDetails } from '@modules/donation/types';
import { CampaignPageDetails } from '@api/campaign';
import { getDefaultFrequencyDetails } from '@root/utils';
import Button from '@root/components/atoms/button';
import useStyles from './frequencySelector.style';

export type Props = {
  page?: DonationPageDetails & CampaignPageDetails;
  color?: string;
  defaultAmount?: number;
  givingFrequencyCode?: GivingFrequency;
  givingFrequency?: string;
  onChange?: any;
  donationPageTypeId?: DonationPageType;
  dataTestId?: string;
  handleToggleFn?: any;
};

const voidFunction = () => {};

const FrequencySelector: React.FC<Props> = ({
  page = null,
  defaultAmount = 1,
  givingFrequencyCode = GivingFrequency.SingleDefaultAllowMonthlyAllowAnnual,
  givingFrequency = null,
  onChange = voidFunction,
  color = '',
  donationPageTypeId,
  handleToggleFn = voidFunction,
  dataTestId = 'donationGroup',
}) => {
  const { t } = useTranslation();
  const theme: DonationPageTheme = useTheme();
  const [durations, setDurations] = React.useState({});
  const [selectedFrequency, setSelectedFrequency] = React.useState(null);

  const classes = useStyles({ color, selectedFrequency });
  const rootProps = {
    className: classes.root,
    'data-testid': dataTestId,
  };
  const isOCP =
    page &&
    page.kind === PageKind.Donation &&
    page.donationPageTypeId === DonationPageType.OCP;

  React.useEffect(() => {
    const frequencyDetails = getDefaultFrequencyDetails(givingFrequencyCode);
    setSelectedFrequency(frequencyDetails.selectedFrequencyName);
    setDurations(frequencyDetails.durations);
  }, [givingFrequencyCode]);

  const handleToggle = (frequency) => {
    onChange(defaultAmount ? defaultAmount.toFixed(2) : '', frequency);
    setSelectedFrequency(frequency);
  };

  const donateLinkStyles = {
    defaults: {
      padding: 0,
      width: 'auto',
      background: 'none',
      fontWeight: '400',
      fontSize: '0.85rem',
      color: theme.colorPrimary,
    },
    active: {
      background: 'none',
      boxShadow: 'none',
      color: theme.colorPrimary,
    },
    focus: {
      background: 'none',
      boxShadow: 'none',
      color: theme.colorPrimary,
    },
    hover: {
      background: 'none',
      boxShadow: 'none',
      color: theme.colorPrimary,
    },
  };

  return (
    <section className={classes.frequencySection}>
      <fieldset>
        <legend>
          <h2 className={`${classes.sectionTag} ${classes.subTitlesWeight}`}>
            {t('how_often_donate')}
          </h2>
        </legend>

        <div {...rootProps}>
          <Toggle
            handleToggle={
              donationPageTypeId === DonationPageType.DP
                ? handleToggle
                : handleToggleFn
            }
            toggleValue={givingFrequency}
            givingFrequencyCode={givingFrequencyCode}
            donationPageTypeId={donationPageTypeId}
            durations={durations}
          />
          {!isOCP && givingFrequency === 'once' && page.donateOnceMessage && (
            <>
              <div className={classes.donationMessage}>
                <ParserHTML text={page.donateOnceMessage} trim />
              </div>
              {givingFrequencyCode !== GivingFrequency.SingleOnly && (
                <Button
                  onClick={() =>
                    donationPageTypeId === DonationPageType.DP
                      ? handleToggle('monthly')
                      : handleToggleFn('monthly')
                  }
                  buttonStyles={donateLinkStyles}
                >
                  <span className={classes.buttonLink}>
                    {t('donateMonthlyLink')}
                  </span>
                  <ChevronRight aria-hidden="true" />
                </Button>
              )}
            </>
          )}
          {!isOCP && givingFrequency === 'monthly' && page.whyDonateMonthly && (
            <>
              <div className={classes.donationMessage}>
                <ParserHTML text={page.whyDonateMonthly} trim />
              </div>
              {givingFrequencyCode !== GivingFrequency.SingleOnly &&
                givingFrequencyCode !==
                  GivingFrequency.SingleDefaultAllowMonthly &&
                givingFrequencyCode !==
                  GivingFrequency.MonthlyDefaultAllowSingle &&
                givingFrequencyCode !== GivingFrequency.MonthlyOnly && (
                  <Button
                    onClick={() =>
                      donationPageTypeId === DonationPageType.DP
                        ? handleToggle('annually')
                        : handleToggleFn('annually')
                    }
                    buttonStyles={donateLinkStyles}
                  >
                    <span className={classes.buttonLink}>
                      {t('donateAnnuallyLink')}
                    </span>
                    <ChevronRight aria-hidden="true" />
                  </Button>
                )}
            </>
          )}
          {!isOCP &&
            givingFrequency === 'annually' &&
            page.donateAnnualMessage && (
              <div className={classes.donationMessage}>
                <ParserHTML text={page.donateAnnualMessage} trim />
              </div>
            )}
        </div>
      </fieldset>
    </section>
  );
};

export default FrequencySelector;
