import { MissionTheme } from '../../interfaces/MissionTheme';
import defaultStyle from './default';

require('typeface-zilla-slab');

const theme: MissionTheme = {
  ...defaultStyle,
  primaryFont: {
    name: 'zilla-slab',
    fontFamily: '"Zilla Slab", sans-serif',
  },
  globalFontSize: '16px',
  colorPrimary: '#2DCCD3',
  colorSecondary: '#7c7c7c',
  headingFont: {
    // name: 'steelfish',
    // fontFamily: 'Steelfish, sans-serif',
    // letterSpacing: '1.2px',
    name: 'zilla-slab',
    fontFamily: '"Zilla Slab", sans-serif',
  },
  headerBackgroundColor: 'white',
  headerTitleColor: '#7c7c7c',
};

export default theme;
