import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  DonationPageType,
  AddressVerificationServiceType,
  PaymentGatewayType,
  PaymentCountryContextCode,
  CustomerType,
} from '@root/enums';
import { ContactFormSkeleton } from '@components/organism/contactForm/contactForm.skeleton';
import { DonationPageDetails } from '@modules/donation/types';
import {
  updateContact,
  setSelectedNewGift,
  setAddressSuggestions,
  getContactDetailsSuccess,
  setCustomerDetails,
  fetchVerificationAddressResults,
} from '@app/app.slice';
import { setProgressIndicator } from '@modules/donation/donation.slice';
import { AddressVerification } from '@api/campaign';
import { addressToBilling, objectToFormData } from '@utils/index';
import { RootState } from '@app/RootState';
import { ContactDetails } from '@root/app/types';

const { lazy, Suspense } = React;
const ContactForm = lazy(() => import('@components/organism/contactForm'));
const OverlaySpinnerText = lazy(
  () => import('@components/atoms/overlaySpinnerText')
);
interface ContactScreenProps {
  pageDetails: DonationPageDetails;
  leftBtnHidden?: boolean;
  donationPageTypeId: DonationPageType;
}

const Contact: React.FC<ContactScreenProps> = ({
  pageDetails,
  donationPageTypeId,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [nextScreen, setNextScreen] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [addressResutls, setAddressResults] = React.useState(null);
  const [country, setCountry] = React.useState(null);
  const [addressVerificationService, setAddressVerificationService] =
    React.useState(null);
  const leftBtnHidden =
    donationPageTypeId === DonationPageType.OCP ? true : null;

  let prevScreen = null;
  const isDP =
    pageDetails &&
    pageDetails.donationPageTypeId &&
    pageDetails.donationPageTypeId === DonationPageType.DP;

  if (pageDetails.donationPageTypeId === DonationPageType.DP) {
    if (pageDetails && pageDetails.showGiftAid) {
      prevScreen = 'giftAid';
    } else {
      prevScreen = '.';
    }
  }

  const {
    contactDetails,
    updateCompleted,
    addressSuggestions,
    verificationAddressResult,
    selectedNewGift,
    stripeIntent,
  } = useSelector((state: RootState) => state.app);

  // const { ariaFocus } = useSelector((state: RootState) => state.donation);

  // const selectedNewGift: boolean = useSelector(
  //   (state: RootState) => state.app.selectedNewGift
  // );

  React.useEffect(() => {
    if (pageDetails) {
      // * Donation Page
      let next = 'payment';
      if (pageDetails.donationPageTypeId === DonationPageType.OCP) {
        next = 'donate';
      } else if (pageDetails.showMembership) {
        next = 'membership';
      } else if (
        isDP &&
        (pageDetails.showDescription || pageDetails.showCustomQuestions) &&
        !pageDetails.showMembership
      ) {
        next = 'additionalInfo';
      }
      setNextScreen(next);

      if (pageDetails.donationPageTypeId === DonationPageType.DP) {
        dispatch(setSelectedNewGift(true));
      }

      if (pageDetails.addressVerification) {
        switch (true) {
          case pageDetails.addressVerification ===
            AddressVerificationServiceType.Melissa:
            setAddressVerificationService(pageDetails.addressVerification);
            break;
          case pageDetails.addressVerification ===
            AddressVerificationServiceType.CanadaPost:
            setAddressVerificationService(pageDetails.addressVerification);
            break;
          default:
            setAddressVerificationService(null);
        }
      }

      switch (true) {
        case pageDetails.transactionCurrency.isoCurrencyCode === 'USD':
          setCountry(PaymentCountryContextCode.US);
          break;
        case pageDetails.transactionCurrency.isoCurrencyCode === 'CAD':
          setCountry(PaymentCountryContextCode.CA);
          break;
        default:
          setCountry(null);
          break;
      }
    }
  }, [pageDetails]);

  React.useEffect(() => {
    if (verificationAddressResult) {
      const results = JSON.parse(verificationAddressResult.data.results);
      if (
        verificationAddressResult.errorType === 'complete' &&
        contactDetails &&
        contactDetails.next !== 'screen'
      ) {
        if (
          addressVerificationService === AddressVerificationServiceType.Melissa
        ) {
          dispatch(setAddressSuggestions(results || null));
        }
        const address = results[0];
        // FOR CANADA POST
        dispatch(
          getContactDetailsSuccess({
            ...contactDetails,
            address1Line1: address.Line1,
            address1City: address.City,
            address1StateOrProvince: address.Province,
            address1PostalCode: address.PostalCode,
            address1Country: address.CountryName,
          })
        );
        if (contactDetails && contactDetails.next === 'screen') {
          if (pageDetails.donationPageTypeId !== DonationPageType.OCP) {
            dispatch(setProgressIndicator(nextScreen));
          }
          history.push(nextScreen);
        }
      } else if (verificationAddressResult.errorType !== 'no-match') {
        dispatch(setAddressSuggestions(results || null));
      }
    }
  }, [verificationAddressResult]);

  const onSubmit = async (values: ContactDetails) => {
    const token = 'localhost';
    const contactDetailsPost: any = addressToBilling(values);
    dispatch(getContactDetailsSuccess(values));
    if (pageDetails.paymentGatewayCode === PaymentGatewayType.Stripe) {
      dispatch(
        setCustomerDetails({
          ...stripeIntent,
          customerDetails: {
            customerName: `${values.firstName} ${values.lastName}` || null,
            customerEmail: values.emailAddress1 || null,
            customerPhone: values.telephone1 || null,
            customerId: null,
            customerType:
              values.isCompanyGift === 'yes' && values.organizationName
                ? CustomerType.Account
                : CustomerType.Contact,
          },
          address: {
            city: values.address1City || null,
            country: PaymentCountryContextCode[country],
            line1: values.address1Line1 || null,
            line2: values.address1Line2 || null,
            postalCode: values.address1PostalCode || null,
            state: values.address1StateOrProvince || null,
          },
        })
      );
    }
    if (values && values.next === 'complete') {
      // dispatch(getContactDetailsSuccess(values));
      return;
    }
    if (selectedNewGift) {
      const orderDetails: AddressVerification = {
        ...contactDetailsPost,
        donationPageId: pageDetails.donationPageId,
        canadaPostId: values.canadaPostId,
        action: 'autocomplete',
        next: values.next === 'screen' ? 'Retrieve' : values.next,
      };

      const formData: FormData = objectToFormData(orderDetails);
      if (token != null) {
        dispatch(fetchVerificationAddressResults(formData, 'Donation'));
      }

      if (values && values.next === 'screen') {
        dispatch(getContactDetailsSuccess({ ...contactDetails, ...values }));
        if (pageDetails.donationPageTypeId === DonationPageType.DP) {
          dispatch(setProgressIndicator(nextScreen));
        }
        history.push(nextScreen);
      }
    } else if (values && values.next === 'screen') {
      setLoading(true);

      dispatch(
        updateContact({
          ...contactDetails,
          ...contactDetailsPost,
          ...values,
          next: '',
        })
      );
    }
  };

  React.useEffect(() => {
    if (loading && !updateCompleted) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [updateCompleted, loading]);

  React.useEffect(() => {
    if (addressSuggestions) {
      setAddressResults(addressSuggestions);
    }
  }, [addressSuggestions]);

  return (
    <Suspense fallback={<ContactFormSkeleton />}>
      <OverlaySpinnerText text={t('processingCD')} loading={loading} />

      <ContactForm
        page={pageDetails}
        contactDetails={contactDetails}
        selectedNewGift={selectedNewGift}
        onSubmit={onSubmit}
        leftBtnHidden={leftBtnHidden}
        addressSuggestions={addressResutls}
        prevScreen={prevScreen}
      />
    </Suspense>
  );
};

export default Contact;
