import { createUseStyles } from 'react-jss';
import { MissionTheme } from '@root/interfaces/MissionTheme';

export default createUseStyles<any, any>((theme: MissionTheme) => ({
  root: {
    paddingTop: 60,
    height: '100vh',
    backgroundColor: 'white',
  },
  hTag: {
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 700,
    fontSize: 30,
    color: theme.colorPrimary,
  },
  pTag: {
    width: '100%',
    fontSize: 20,
    marginTop: '40px',
    color: 'black',
    display: 'flex',
    justifyContent: 'center',
  },
  gear: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 80,
  },
}));
