import { createUseStyles } from 'react-jss';
import { MissionTheme } from '@root/interfaces/MissionTheme';

export default createUseStyles<any, any>((theme: MissionTheme) => ({
  root: {
    paddingTop: 60,
    height: '100vh',
    width: '100vw',
    backgroundColor: 'white',
  },
  hTag: {
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 700,
    fontSize: 70,
    color: theme.colorPrimary,
  },
  pTag: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: 16,
    marginTop: 20,
    color: 'black',
  },
  aTag: {
    fontSize: 16,
    marginLeft: 5,
  },
  bottomDiv: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
    marginBottom: 60,
  },
}));
