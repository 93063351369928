import { MissionTheme, Font } from '@root/interfaces';
import globals from '../default';

const colors = {
  colorPrimary: '#88B04B',
  colorSecondary: '#FEE715',
  colorTertiary: '#7c7c7c',
  colorText: '#7c7c7c',
  colorBackground: '#88B04B',
};

const primaryFont: Font = {
  name: 'mission',
  fontFamily: 'Helvetica Roman',
};
const secondaryFont: Font = {
  name: 'mission',
  fontFamily: 'Helvetica Light',
};

const theme: MissionTheme = {
  ...globals,
  ...colors,
  clientFullName: 'Mission CRM',
  isSVG: false,
  isDisableFullHeader: false,
  extraBoldFrequency: false,
  useSecThemeColor: false,
  addCustomColor: false,
  headerBackgroundColor: 'white',
  headerTitleColor: '#7c7c7c',
  headerLogoHeight: 103,
  headingFontSize: '1.5rem',
  headingBold: true,
  subHeadingFontSize: '1rem',
  buttons: {
    ...globals.buttons,
    defaults: {
      ...globals.buttons.defaults,
      background: colors.colorPrimary,
    },
  },
  navButtons: {
    left: {
      ...globals.buttons,
      defaults: {
        ...globals.buttons.defaults,
        background: colors.colorPrimary,
      },
    },
    right: {
      ...globals.buttons,
      defaults: {
        ...globals.buttons.defaults,
        background: colors.colorPrimary,
      },
    },
  },

  primaryFont: primaryFont,
  headingFont: secondaryFont,
  globalFontSize: 14,
  toggleFontFamily: primaryFont.fontFamily,
};

export default theme;
