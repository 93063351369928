import * as React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '@app/RootState';

const { lazy, Suspense } = React;
const ProfileDetails = lazy(
  () => import('@components/organism/profileDetails')
);

interface ProfileScreenProps {}

const ProfileScreen: React.FC<ProfileScreenProps> = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    isLoggedIn,
    profile,
    error: profileError,
  } = useSelector((state: RootState) => state.user);

  React.useEffect(() => {
    if (!isLoggedIn) {
      history.push('/user/signin');
    }
  }, [isLoggedIn]);

  return (
    <Suspense fallback={<div>{t('loading')}</div>}>
      <Container fluid>
        <Row style={{ backgroundColor: '#E0E0E0' }}>
          <Col lg>{profileError || <ProfileDetails profile={profile} />}</Col>
        </Row>
      </Container>
    </Suspense>
  );
};

export default ProfileScreen;
