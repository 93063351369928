// GERMAN (de)

export default {
  // START Aria translation
  // aria_enter: 'Beginning donation section',
  // aria_flow:
  // 'Starting {{routeName}} section. Section {{sectionIndex}} of {{totalSteps}}',
  input_amount_field_name: 'Eingabebetragsfeld',
  aria_input_amount_field:
    'Eingabefeld: Benutzerdefinierten Spendenbetrag eingeben (auf den nächsten Dollar genau)',
  aria_amount_summary: 'Bestellbetrag Zusammenfassung',
  aria_member_summary: 'Zusammenfassung der Mitgliedsbeiträge',
  aria_help_icon: 'Informations-/Hilfe-Symbol',
  aria_required: 'Erforderlich',
  aria_designation_button: 'Benennen zu',
  aria_designation_selection_label: 'Wählen Sie eine andere Bezeichnung',
  aria_year: '2-stelliges Ablaufjahr',
  aria_month: '2-stelliger Ablaufmonat',
  aria_security_code:
    'Kreditkartensicherheitscode. 3- oder 4-stelliger Code auf der Rückseite Ihrer Karte',
  aria_unexpected_error_link:
    'Bitte klicken Sie hier und kontaktieren Sie umgehend unser Spenderservice-Team.',
  // END Aria translation
  internatl_error_500:
    '500 - Interner Serverfehler aufgetreten. Bitte wenden Sie sich umgehend an unser Team',
  stripe_promise_error: `Stripe Promise konnte nicht geladen werden`,
  loading: 'Laden von...',
  loading_image: 'Bild laden...',
  verification: 'Überprüfung läuft...',
  timeout:
    'Es ist ein Timeout-Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.',
  company: 'Name der Firma',
  company_valication: 'Bitte geben Sie den Firmennamen ein',
  company_help_text:
    'Der Name des Unternehmens oder der Organisation, der auf der Bestätigung und/oder Quittung erscheint.',
  employer: 'Mein Arbeitgeber',
  salutation: 'Anrede',
  first_name: 'Vorname',
  first_name_validation: 'Bitte geben Sie Ihren Vornamen ein',
  last_name: 'Nachname',
  last_name_validation: 'Bitte geben Sie ihren Nachnamen ein',
  email: 'E-Mail',
  email_validation: 'E-Mail Adresse ist leider nicht korrekt',
  email_help_text:
    'Ihre Bestätigung und/oder Steuerquittung wird hier per E-Mail gesendet.',
  phone: 'Telefon',
  phone_validation:
    'Bitte geben Sie eine gültige Telefonnummer ohne Dezimalstellen oder Bindestriche ein',
  address: 'Adresse',
  address2: 'Adresse 2',
  address_validation: 'Bitte geben Sie Ihre Adresse ein',
  suite: 'Suite',
  city: 'Stadt',
  city_validation: 'Bitte geben Sie Ihre Stadt ein',
  province: 'Wohnort',
  province_validation: 'Bitte geben Sie Ihre Provinz ein',
  postal_code: 'Postleitzahl',
  postal_code_validation: 'Bitte geben Sie eine gültige Postleitzahl ein',
  country: 'Land',
  country_validation: 'Bitte geben Sie ein gültiges Land ein',
  amount_raised: 'Erhöhter Betrag',
  goal: 'Ziel',
  dedication_checkbox:
    'Ja, meine Spende ist zu Ehren oder in Erinnerung an jemanden',
  honour_dedication: 'Zu Ehren von...',
  memory_dedication: 'In Erinnerung an...',
  show_honour_message:
    'Sie haben angegeben, dass Sie diese Spende zu Ehren von jemandem machen',
  show_memory_message:
    'Sie haben angegeben, dass Sie diese Spende in Erinnerung an jemanden machen',
  honouree_label: 'Bitte geben Sie ihren Namen ein',
  honouree_validation: 'Bitte geben Sie einen gültigen Namen ein',
  recipient_email: 'Bitte geben Sie die E-Mail-Adresse des Empfängers ein',
  special_message: 'Teilen Sie hier Ihre besondere Nachricht. (Optional)',
  acknowledgment_checkbox:
    'Ja, ich möchte eine Benachrichtigung über mein Geschenk senden',
  recipient_email_validation:
    'Bitte geben Sie die E-Mail-Adresse oder die physische Adresse des Empfängers ein',
  special_message_validation:
    'Bitte geben Sie eine Nachricht für den Empfänger ein',
  employer_matches_checkbox: 'Mein Arbeitgeber verdoppelt Spenden',
  no_dedication: 'Keine Widmung',
  honour: 'Ehre',
  memory: 'Erinnerung',
  anonymousHelptext:
    'Dadurch werden Ihr Name und Ihre Spenderinformationen vor allen öffentlichen Mitteilungen verborgen. Die Wohltätigkeitsorganisation erhält jedoch weiterhin Ihre Informationen.',
  giftAid_checkbox:
    'Ja, ich möchte zusätzlich zu meiner Spende von <2>{{donation}}</2> eine Geschenkhilfe in Höhe von <1>{{gift}}</1> (25% der Spende) hinzufügen.',
  month: 'Monat',
  year: 'Jahr',
  privacy_policy: 'Datenschutz-Bestimmungen',
  terms_conditions: 'Geschäftsbedingungen',
  minimum_amount: 'Mindestbetrag',
  donate_btn: 'Spenden',
  start_p2p_btn: 'Fundraising beginnen',
  next_btn: 'Weiter',
  prev_btn: 'Bisherige',
  thank_you: 'Danke, {{name}}!',
  donation_received: 'Ihre Spende wurde erhalten.',
  confirmationDetails_thank_you: 'Danke, {{name}}',
  confirmationDetails_donation_received:
    'Ihre Spende von <1>{{donationAmount}}</1> wurde erhalten.',
  confirmationDetails_receipt:
    'Eine Steuerbescheinigung wird an <em>{{email}}</em> gesendet.',
  donate_once: 'Einmal\nspenden',
  donate_monthly: 'Monatlich\nspenden',
  donate_annually: 'Jährlich\nSpenden',
  finish_btn: 'Beenden',
  cancel_btn: 'Abbrechen',
  redirectMessage: 'Sie werden weitergeleitet in:',
  seconds: 'sekunden',
  processing: 'Bearbeitung Ihrer Spende ...',
  processingEP: 'Bearbeitung Ihrer Registrierung ...',
  card_number: 'Kartennummer',
  card_validation: 'Bitte geben Sie eine gültige Kartennummer an',
  expiry_date_mm: 'MM',
  expiry_date_yy: 'JJ',
  expiry_date_mmyy: 'MM/JJ',
  expiry_date_mm_validation:
    'Bitte geben Sie ein gültiges Ablaufdatum an (MM). Die erste Ziffer bei einstelligen Monatszahlen ist die 0.',
  expiry_date_yy_validation:
    'Bitte geben Sie ein gültiges Ablaufdatum an (JJ).',
  card_security_code: 'CVV',
  card_security_code_validation: 'Bitte geben Sie den CVV ein',
  giving: '<1>{{currency}}</1> geben',
  share_btn: 'Teilen',
  share_on: 'Teilen auf',
  now_booking: 'Jetzt Buchen',
  book_tickets: 'Tickets Buchen',
  use_existing_credit_card: 'Bestehende Kreditkarte verwenden',
  select: 'Wählen',
  card: 'Karte',
  next: 'Nächster',
  last: 'Letzte',
  cardType: 'Speicherkarten-Typ',
  expiry: 'Ablauf',
  apple_pay: 'Apple Pay',
  google_pay: 'Google Pay',
  donate: 'Spenden',
  of_goal: 'von Goal',
  supporters: 'Unterstützer',
  days_left: 'Verbleibende Tage',
  minimum_donation_of: 'Mindestspende von',
  top_fundraising_teams: 'Top-Fundraising-Teams',
  top_individual_fundraisers: 'Einzelne Top-Fundraiser',
  individual_subtitle: 'Starten Sie noch heute Ihre individuelle Spendenaktion',
  team_subtitle: 'Starten Sie noch heute Ihre Team-Spendenaktion',
  campaign_title: 'Lass uns gehen!',
  individual_fundraiser_btn: 'Einzelkampagne',
  team_fundraiser_btn: 'Teamkampagne',
  campaign_name: 'Name der Fundraising-Seite',
  team_campaign_name: 'Name der Team-Spendenaktion',
  campaign_name_validation: 'Bitte geben Sie einen gültigen Kampagnennamen ein',
  campaign_goal: 'Fundraising-Ziel',
  team_campaign_goal: 'Team Fundraising Ziel',
  campaign_goal_validation: 'Bitte geben Sie ein gültiges Spendenziel ein',
  start_date: 'Anfangsdatum',
  end_date: 'Endtermin',
  create_campaign_btn: 'Kampagne erstellen',
  update_campaign_btn: 'Kampagne aktualisieren',
  delete_campaign_btn: 'Löschen Sie diese Kampagne',
  cancel_changes_btn: 'Änderungen abbrechen',
  password: 'Passwort',
  password_validation: 'Bitte geben Sie Ihr Passwort ein',
  sign_in: 'Anmelden',
  forgot_password: 'Passwort vergessen?',
  sign_up: 'Anmelden',
  dont_have: 'Sie haben noch keinen Account?',
  already_have: 'Sie haben bereits ein Konto?',
  go_back: 'Geh zurück',
  forgot_password_title: 'Passwort zurücksetzen',
  forgot_password_btn: 'Anweisungen zum Zurücksetzen senden',
  download_media_kit: 'Laden Sie das Media Kit herunter',
  learn_to_fundraise: 'Lerne Spenden zu sammeln',
  help_center: 'Hilfezentrum',
  days_left_to_donate: 'Noch Tage zu spenden',
  days_left_to_fundraise: 'Noch Tage bis zur Spendenaktion',
  quick_links: 'Quick Links',
  edit_campaign_btn: 'Kampagne bearbeiten',
  view_campaign_btn: 'Kampagne anzeigen',
  share_my_page_btn: 'Meine Seite teilen',
  team_campaigns: 'Teamkampagnen',
  your_campaigns: 'Ihre Kampagnen',
  activity: 'Aktivität',
  settings: 'die Einstellungen',
  birthday: 'Geburtstag (optional)',
  save_changes: 'Änderungen speichern',
  edit_profile_title: 'Profil bearbeiten / aktualisieren',
  profile_photo: 'Profilfoto',
  upload_photo: 'Foto hochladen',
  manage_prefs_title: 'Einstellungen verwalten',
  donation_updates: 'Spendenaktualisierungen und bevorstehende Veranstaltungen',
  phone_campaigns: 'Telefonkampagnen',
  updates_via_mail: 'Updates per Mail',
  old_password: 'Altes Passwort',
  new_password: 'Neues Passwort',
  old_password_validation: 'Bitte geben Sie Ihr altes Passwort ein',
  new_password_validation: 'Bitte geben Sie Ihr neues Passwort ein',
  change_password_title: 'Passwort ändern',
  edit_profile: 'Profil bearbeiten',
  my_profile: 'Mein Profil',
  edit_campaign: 'Kampagne bearbeiten',
  log_out: 'Ausloggen',
  start_a_campaign_btn: 'Starten Sie eine Kampagne',
  campaign_photo: 'Kampagnenfoto',
  recommended_photo_size: 'Empfohlene Fotogröße: 1240 x 600 Pixel',
  mission_statement: 'Leitbild',
  characters_remaining: 'Noch {{count}} Zeichen',
  edit_campaign_title_individual:
    'Bearbeiten Sie Ihre individuelle Fundraising-Seite',
  edit_campaign_title_team: 'Bearbeiten Sie Ihre Team Fundraising-Seite',
  edit_campaign_title_team_member:
    'Bearbeiten Sie die Fundraising-Seite Ihres Teammitglieds',
  deletion_confirmation_question:
    'Möchten Sie diese Kampagne wirklich löschen?',
  join_team_btn: 'Tritt dem Team bei',
  join_team_subtitle: 'Beitreten {{name}}',
  donated_by: 'Gespendet von',
  campaign_supporters: 'Unterstützer der Kampagne',
  display_name: 'Anzeigename (optional)',
  leave_message: 'Hinterlassen Sie eine Support-Nachricht (optional)',
  hide_name_checkbox: 'Zeigen Sie meinen Namen nicht auf der Kampagnenseite an',
  show_support_title: 'Möchten Sie eine Unterstützungsnachricht teilen?',
  creating_campaign: 'Erstellen Sie Ihre Kampagne ....',
  failed_campaign_creation: 'Kampagne konnte nicht erstellt werden',
  join_team_checkbox: 'Erlauben Sie anderen, sich Ihrem Team anzuschließen',
  credentials_error: 'Ungültige Emailadresse oder Passwort',
  email_exists: 'Diese E-Mail Adresse ist bereits registriert worden',
  about_campaign: 'Über die Kampagne',
  teams: 'Teams',
  team_members: 'Teammitglieder',
  participants: 'Teilnehmer',
  anonymous: 'Anonym',
  fundraising_team_for: 'Fundraising-Team für:',
  fundraising_for: 'Fundraising für:',
  fundraising_team_member: 'Mitglied von:',
  no_team_members: 'Niemand hat sich als Mitglied dieses Teams angemeldet.',
  fundraising_dates: 'Fundraising-Termine',
  return_back_btn: 'Zurück zum Profil',
  changes_saved_btn: 'Änderungen gespeichert!',
  no_changes_btn: 'Keine Änderungen zum Speichern',
  password_reset_success:
    'Anweisungen zum Zurücksetzen des Passworts wurden an {{email}} gesendet.',
  total_amount_raised: 'Gesamtbetrag erhöht',
  amount: '<1> {{currencySymbol}} {{amount}} </ 1>',
  individual_member_of: 'Einzelmitglied von:',
  team_member_of: 'Teammitglied von:',
  role_team_member: '(Rolle: Teammitglied)',
  role_team_captain: '(Rolle: Mannschaftskapitän)',
  no_individual_pages: 'Sie haben keine einzelnen Fundraising-Seiten.',
  no_team_pages: 'Sie haben keine Team-Fundraising-Seiten.',
  no_pages_to_display: 'Keine Fundraising-Seiten zum Anzeigen.',
  join_as_individual_btn: 'Als Einzelperson beitreten',
  create_a_team_btn: 'Erstelle ein Team',
  expired: 'abgelaufen',
  share_on_facebook: 'Auf Facebook teilen',
  share_on_twitter: 'Auf Twitter teilen',
  share_on_linkedin: 'Auf LinkedIn teilen',
  failure_message: 'Zahlung konnte nicht verarbeitet werden',
  search: 'Suche',
  constituentNumber: 'Konstituierende Nummer',
  contact_not_found: 'Bestandteil nicht gefunden',
  failed_password_change: 'Altes Passwort stimmt nicht überein',
  success_password_change: 'Passwort erfolgreich aktualisiert',
  lifetimeGiving: 'Lebensdauer',
  givingHistory: 'Geschichte geben',
  thisYearGiving: 'Dieses Jahr',
  lastGiftAmount: 'Letzter Geschenkbetrag',
  recurringGift: 'Wiederkehrendes Geschenk',
  creditCard: 'Kreditkarte',
  childRecurringGift: 'Status des wiederkehrenden Geschenks eines Kindes',
  membership: 'Mitgliedschaft',
  fetching_pages: 'Fundraising-Seiten abrufen ...',
  title_404: '404',
  subtitle_404: 'Entschuldigung, wir konnten Ihre Seite nicht finden.',
  message_404: 'Bring mich zurück zu -',
  home_page: 'Startseite',
  update_contact_info: 'Kontaktinformationen aktualisieren',
  update_payment_info: 'Zahlungsinformationen aktualisieren',
  update_payment_schedule: 'Zahlungsplan aktualisieren',
  update: 'Aktualisieren',
  update_saved_message: 'Update erfolgreich gespeichert',
  new_gift_btn: 'Neues Geschenk',
  new_gift_title: 'Ein neues Geschenk erstellen',
  create_new_contact_btn: 'Geschenk für neuen Spender erstellen',
  cancel_donation: 'Spende abbrechen',
  back: 'Zurück',
  start_by: 'Beginnen Sie mit der Eingabe von a',
  or: 'Oder',
  FeltPressure: 'Der Spender wurde von der Spendenaktion unter Druck gesetzt',
  NoLongerAfford: 'Kann es sich nicht mehr leisten',
  UnderMinimumWage: 'Unter Mindestalter',
  SpouseObjected: 'Ehepartner widersprach',
  ChangedMind: 'Geänderte Meinung',
  IntendedShortTerm: 'Spender für eine kurzfristige Spende vorgesehen',
  IntendedOneTime: 'Der Spender beabsichtigte eine einmalige Spende',
  Deceased: 'Verstorbene',
  Misunderstood: 'Missverstandenes Engagement',
  OtherCharities: 'Spenden an andere Wohltätigkeitsorganisationen',
  PaymentDeclined: 'Zahlung abgelehnt',
  Complaint: 'Beschwerde',
  AccountClosed: 'Konto geschlossen',
  AlreadyDonating: 'Schon gespendet',
  NoReason: 'Ohne Grund',
  continue_session: 'Halten Sie mich angemeldet',
  session_timeout: 'Session-Timeout',
  session_message:
    'Die aktuelle Sitzung läuft in <1>{{countdown}}</1> Sekunden ab.',
  continue_confirmation: 'Würdest du gerne weitermachen?',
  master_donate_btn: 'Spenden Sie für diese Kampagne',
  team_donate_btn: 'Spende an das Team',
  team_member_donate_btn: 'Spenden Sie an die Spendenaktion von {{name}}',
  note: 'Hinweis:',
  bg_image_note:
    'Dieses Bild erscheint als Hintergrund auf den Fundraising-Seiten. Es wird teilweise von der Fundraising Stats Component (Box) auf Bildschirmen in Desktop-Größe und zu etwa 90% auf kleineren Bildschirmgrößen abgedeckt.',
  sample_campaign_stats:
    'Dies ist eine Beispielkomponente für Fundraising-Statistiken, um zu demonstrieren, wie das Hintergrundbild auf einer Fundraising-Seite angezeigt wird.',
  preview: 'Vorschau',
  donation_through: 'Die Spende wurde durch gemacht',
  donation: 'Spende',
  view_fundraising_page: 'Fundraising-Seite anzeigen',
  how_often_donate: 'Wie oft möchten Sie spenden?',
  select_amount_donate: 'Wählen Sie den Betrag aus, den Sie geben möchten:',
  direct_your_gift: 'Wie möchten Sie Ihr Geschenk lenken?',
  once: 'Einmal',
  monthly: 'Monatlich',
  annually: 'Jährlich',
  why_donate_monthly: 'Welche Auswirkungen hat eine monatliche Spende?',
  eft: 'EFT',
  bank: 'Bank',
  bank_check: 'Scheck',
  bank_name: 'Bank Name',
  acc_no: 'Konto Nr.',
  routing_no: 'Transit- und Finanzinstitut Nr. (Bis zu 8 Stellen)',
  chequing: 'chequing',
  savings: 'Ersparnisse',
  select_acc_type: 'Wählen Sie Kontotyp',
  acc_no_validation: 'Konto Nr. erforderlich',
  routing_no_validation: 'Transit- und Finanzinstitut Nr. erforderlich',
  bank_name_validation: 'Bankname ist erforderlich',
  Completed: 'Abgeschlossen',
  Terminated: 'Abgesagt',
  Failed: 'Gescheitert',
  TransactionRefund: 'Rückerstattung',
  InProgress: 'In Bearbeitung',
  notPaymentScheduleMessage: 'Kein Zahlungsplan verfügbar',
  notPaymentMethodsMessage: 'Keine Zahlungsmethode verfügbar',
  Amount: 'Menge',
  Name: 'Name',
  Team: 'Mannschaft',
  gift_aid: 'Geschenkhilfe',
  donor_details: 'Spenderdetails',
  payment_details: 'Zahlungsdetails',
  Total: 'Gesamt',
  other_btn: 'Andere',
  anonymous_checkbox: 'Machen Sie meine Spende anonym',
  your_donation: 'Ihre Spende <1>{{currency}}</1>',
  your_monthly_donation: 'Ihre monatliche Spende <1>{{currency}}</1>',
  your_annually_donation: 'Ihre jährliche Spende <1>{{currency}}</1>',
  your_membership: 'Ihre Mitgliedschaft <1>{{currency}}</1>',
  include_membership_checkbox:
    'Nehmen Sie meine Mitgliedschaft in meine Spende <1>{{donation}}</1> <2>{{donationFrequency}}</2> auf.',
  share_generosity: 'Teilen Sie Ihre Großzügigkeit und inspirieren Sie andere:',
  help_further: 'Möchten Sie weiter helfen?',
  get_involved_title: 'Machen Sie mit',
  set_up_fundraising_btn: 'Richten Sie meine Spendenaktion ein',
  start_fundraising_accordian_title: 'Unterstützen Sie eine Kampagne',
  donateMonthlyLink: 'Ich möchte monatlich spenden',
  donateAnnuallyLink: 'Ich möchte jährlich spenden',
  enter_amount: 'Bitte geben Sie den Betrag ein',
  selection_label: 'Sonstiges',
  order_label: 'Bitte auswählen',
  membership_order_label: 'Bitte auswählen (optional)',
  support_display_all:
    'Bitte zeigen Sie meinen Namen, meine Nachricht und meinen Spendenbetrag an.',
  support_hide_amount:
    'Bitte zeigen Sie nur meinen Namen und meine Nachricht an (versteckt den Spendenbetrag).',
  support_hide_name:
    'Bitte zeigen Sie nur meinen Spendenbetrag und meine Nachricht an (versteckt den Namen).',
  support_hide_all:
    'Nein, bitte verstecke meinen Namen, meine Nachricht und meinen Spendenbetrag.',
  greatest_need: 'Größter Bedarf',
  background_image_alt_text: 'dynamisches Hintergrundthemenbild',
  summary_link_description: 'Klicken Sie hier, um Ihr Geschenk zu ändern',
  unexpected_error_message:
    'Ein Fehler ist aufgetreten. Ihre Transaktion wurde jedoch verarbeitet und erscheint auf Ihrem Kontoauszug. <1>Versuchen Sie es nicht noch einmal.</1><br /><br /><2>Klicken Sie hier</2> oder wenden Sie sich umgehend an unser Spenderservice-Team, um uns mitzuteilen, dass Sie diese Nachricht erhalten haben.',
  applePay_error: `Apple Pay ist nur verfügbar, wenn es mit iOS-kompatiblen Geräten (iPhone/iPad/Mac) aktiviert ist.`,
  googlePay_error:
    'GooglePay ist auf diesem Gerät nicht aktiviert und/oder verfügbar.',
  additionalInfo: 'Weitere Informationen',
  additional_info_msg:
    'Wenn Sie Anmerkungen zu Ihrer Spende haben, geben Sie diese bitte hier ein.',
  notes_or_direction: 'Hinweise oder Richtung',
  readMore: 'Weiterlesen',
  showLess: 'Zeige weniger',
  isCompanyGift: 'Dies ist eine Unternehmensspende',
  yes: 'Ja',
  no: 'Nein',
  tribute_type: 'Möchten Sie, dass wir Ihnen eine Karte zusenden?',
  no_sendTribute: 'Nein\ndanke',
  email_sendTribute: 'Ja,\nper E-Mail',
  mail_sendTribute: 'Ja,\nper Post',
  addressee_name: 'Karte senden an',
  addressee_name_validation: `Bitte geben Sie den Namen des Empfängers ein`,
  required_pwd: 'Bitte geben Sie Ihr Passwort ein',
  pwd_match:
    'Muss 8 Zeichen enthalten, einen Großbuchstaben, einen Kleinbuchstaben, eine Zahl und ein Sonderzeichen',
  MissionCRM_label: 'Missions-CRM-Label',
  recaptcha_token_error:
    'Der reCAPTCHA-Token konnte derzeit nicht abgerufen werden. Bitte versuchen Sie es später noch einmal.',
  recaptcha_failure:
    'Ungültige Anfrage, fehlerhaftes Recaptcha-Token. Bitte überprüfen Sie Ihre Angaben noch einmal und versuchen Sie es erneut.',
  required_message: 'Antworten auf die folgenden Fragen sind erforderlich.',
  custom_questions_error: 'Antworten auf alle Fragen sind erforderlich',
  double_donation: 'Sehen Sie, ob Ihr Unternehmen Ihre Spende verdoppelt!',
};
