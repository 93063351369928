import * as React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import Details from './screens/Details';
import Confirmation from './screens/Confirmation';

const EventRouter = () => {
  const match = useRouteMatch();

  return (
    <div>
      <Switch>
        <Route path={`${match.path}/confirmation`}>
          <Confirmation />
        </Route>

        <Route path={`${match.path}`}>
          <Details />
        </Route>
      </Switch>
    </div>
  );
};

export default EventRouter;
