import { PageKind } from '@root/enums';
import { DonationPageBackgroundCollection } from '@root/interfaces';

import defaults from './defaults';
import createDonationPageStyles from './createDonationPageStyles';

const theme = (backgrounds?: DonationPageBackgroundCollection) => ({
  defaults,
  [PageKind.Donation]: createDonationPageStyles(backgrounds),
  [PageKind.Campaign]: {
    ...defaults,
    buttons: {
      defaults: {
        ...defaults.buttons.defaults,
        fontFamily: defaults.headingFont.fontFamily,
        textTransform: 'uppercase',
        background: '#050505',
        color: '#fff',
        fontSize: '14px',
      },
    },
    isCP: true,
  },
  [PageKind.Event]: {
    ...defaults,
  },
});

export default theme;
