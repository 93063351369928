import * as React from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import { useTheme } from 'react-jss';
import { useSelector } from 'react-redux';
import { RootState } from '@app/RootState';
import { DonationPageType } from '@root/enums';
import { CampaignPageDetails } from '@api/campaign';
import { DonationPageDetails } from '@modules/donation/types';
import { useTranslation } from 'react-i18next';
import Button from '@components/atoms/button';
import DonateButton from '@components/molecules/donateButton';
import UserNavigation from '@root/components/molecules/userNavigation';
import { MissionTheme } from '@root/interfaces';
import useStyles from './header.style';

export type Props = {
  page?: CampaignPageDetails & DonationPageDetails;
  updatedData?: any;
  donationPageTypeId?: DonationPageType;
  color?: string;
  containerHeight?: number;
  logoHeight?: number;
  showDefaultHeader?: boolean;
  isProfilePage?: boolean;
  isLoggedIn?: boolean;
  onShowSignIn?: () => void;
  loadImg?: any;
  dataTestId?: string;
  showLogoOnly?: boolean;
};

const Header: React.FC<Props> = ({
  page = null,
  updatedData = null,
  donationPageTypeId,
  color,
  containerHeight = 150,
  logoHeight = 80,
  showDefaultHeader = false,
  isProfilePage,
  isLoggedIn = false,
  onShowSignIn = () => {},
  loadImg = () => {},
  dataTestId = 'header',
  showLogoOnly = false,
}) => {
  const { t } = useTranslation();
  const theme: MissionTheme = useTheme();
  const { clientCode } = useSelector((state: RootState) => state.app);
  const [logo, setLogo] = React.useState(null);
  const [showDonateButton, setShowDonateButton] = React.useState(false);
  const [logoHref, setLogoHref] = React.useState('https://missioncrm.ca');
  const [daysLeft, setDaysLeft] = React.useState(0);
  const isDP = donationPageTypeId === DonationPageType.DP;
  const isP2P = page && page.enableFundraising;
  const [disabled, setDisabled] = React.useState<boolean>(false);
  // const [profilePage, setProfilePage] = React.useState<boolean>(false);
  const [grandParentDisabled, setGrandParentDisabled] =
    React.useState<boolean>(false);

  // Determine if the theme is using an SVG logo which MUST be set in theme default
  const { isSVG } = theme;
  const loadLogo = async () => {
    if (clientCode) {
      if (isSVG) {
        const image = await import(
          `@assets/images/client-logos/${clientCode}-logo.svg`
        );
        setLogo(image.default);
      } else {
        const image = await import(
          `@assets/images/client-logos/${clientCode}-logo.png`
        );
        setLogo(image.default);
      }
    }
  };

  React.useEffect(() => {
    if (updatedData) {
      setDaysLeft(updatedData.daysLeft);
    }
  }, [updatedData]);

  // React.useEffect(() => {
  //   if (isProfilePage) {
  //     setProfilePage(isProfilePage);
  //   }
  // }, [isProfilePage]);

  React.useEffect(() => {
    if (page) {
      setGrandParentDisabled(page.grandParentRemoved);
      if (page.parentCampaignPage) {
        setDisabled(
          page.parentCampaignPage.removed || !page.parentCampaignPage.active
        );
      }

      if (
        page &&
        page.campaignPageId &&
        page.enableFundraising &&
        page.masterExternalUrl
      ) {
        if (!isProfilePage) {
          setShowDonateButton(true);
        }

        setLogoHref(page.masterExternalUrl);
      } else if (page.clientRedirectUrl && page.clientRedirectUrl.length > 0) {
        setLogoHref(page.clientRedirectUrl);
      }
    } else {
      setLogoHref(null);
    }
    loadLogo();
  }, [page, clientCode]);

  const classes = useStyles({
    color,
    containerHeight,
    logoHeight,
    isLoggedIn,
    showLogoOnly,
    isDP,
  });
  const rootProps = {
    className: classes.root,
    'data-testid': dataTestId,
  };

  return !showLogoOnly && (showDefaultHeader || (page && page.showHeader)) ? (
    <Row {...rootProps}>
      <Col sm={6} className={classes.topBanner}>
        <a
          href={logoHref}
          target={page && !page.enableFundraising ? '_blank' : undefined}
          rel="noreferrer"
        >
          <Image
            className={classes.logoImage}
            src={logo}
            alt={`${theme.clientFullName} logo`}
          />
        </a>
      </Col>

      {showDonateButton || isDP || showDefaultHeader ? (
        <Col
          sm={6}
          className={`${classes.shareCol} ${
            isDP ? classes.shareDonationCol : ''
          }`}
        >
          <Row>
            {showDonateButton && (
              <Col
                xl={{
                  span:
                    isP2P && daysLeft > 0 && !grandParentDisabled && !disabled
                      ? 6
                      : 12,
                }}
                lg={{
                  span:
                    isP2P && daysLeft > 0 && !grandParentDisabled && !disabled
                      ? 6
                      : 12,
                }}
                sm={12}
                className={`${classes.btnMargin} ${classes.shareCol}`}
              >
                <DonateButton width={180} page={page} />
              </Col>
            )}

            <Col
              xl={{ span: showDonateButton ? 6 : 12 }}
              lg={{ span: showDonateButton ? 6 : 12 }}
              sm={12}
              className={`${classes.btnMargin} ${classes.shareCol}`}
            >
              {isLoggedIn && !grandParentDisabled ? (
                <UserNavigation
                  showDefaultHeader={false}
                  isProfilePage={isProfilePage}
                />
              ) : (
                isP2P &&
                daysLeft > 0 &&
                !grandParentDisabled &&
                !disabled &&
                !showDefaultHeader && (
                  <Button onClick={onShowSignIn}>{t('sign_in')}</Button>
                )
              )}
            </Col>
          </Row>
        </Col>
      ) : null}
    </Row>
  ) : (
    ((showLogoOnly || isP2P) && (
      <Row className={classes.hiddenHeader}>
        {showLogoOnly ? (
          <Col
            className={`${classes.shareCol} ${
              isDP ? classes.shareDonationCol : ''
            }`}
          >
            <div
              className={`${classes.hiddenDiv} ${
                isDP ? classes.hiddenDivDonation : ''
              }`}
            >
              <a
                href={logoHref}
                target={page && !page.enableFundraising ? '_blank' : undefined}
                rel="noreferrer"
                className={classes.headerLink}
              >
                <Image
                  className={classes.logoImage}
                  src={logo}
                  onLoad={(e) => loadImg(e.isTrusted)}
                  alt={`${theme.clientFullName} logo`}
                />
              </a>
            </div>
            {/* <div className={classes.doantionBoxShadow} /> */}
          </Col>
        ) : (
          <Col className={`${classes.shareCol}`}>
            <div className={classes.hiddenDiv}>
              {isLoggedIn && daysLeft > 0 ? (
                <UserNavigation showDefaultHeader={false} />
              ) : (
                isP2P &&
                daysLeft > 0 &&
                !grandParentDisabled &&
                !disabled &&
                !showDefaultHeader && (
                  <Button onClick={onShowSignIn}>{t('sign_in')}</Button>
                )
              )}
            </div>
          </Col>
        )}
      </Row>
    )) ||
      null
  );
};

export default Header;
