import { ThemeOptions } from '@root/interfaces';

import mission from './themes/mission';
import uwkc from './themes/uwkc';
import bbbsc from './themes/bbbsc';
import ndp from './themes/ndp';
import nsNdp from './themes/ns-ndp';
import arch from './themes/arch';
import arth from './themes/arth';
import halifaxyarmouth from './themes/halifaxyarmouth';
import nwc from './themes/nwc';
import tr from './themes/tr';
import pion from './themes/pion';
import gcfd from './themes/gcfd';

const themes = {
  mission,
  uwkc,
  bbbsc,
  ndp,
  arth,
  arch,
  halifaxyarmouth,
  'ns-ndp': nsNdp,
  nwc,
  tr,
  pion,
  gcfd,
};

const loadTheme = (options: ThemeOptions) => {
  const code =
    options.clientCode === 'default' || options.clientCode === ''
      ? 'mission'
      : options.clientCode;
  const clientThemes =
    themes[code](options.backgrounds) || themes.mission(options.backgrounds);
  let selectedTheme = clientThemes.defaults;

  if (options.pageKind !== null && clientThemes[options.pageKind]) {
    selectedTheme = clientThemes[options.pageKind];

    if (options.pageType && clientThemes[options.pageKind][options.pageType]) {
      selectedTheme = clientThemes[options.pageKind][options.pageType];
    }
  }
  return selectedTheme;
};

export default loadTheme;
