import { createUseStyles } from 'react-jss';
import { MissionTheme } from '@root/interfaces/MissionTheme';

export default createUseStyles<any, any>((theme: MissionTheme) => ({
  containerMain: {
    backgroundColor: 'white',
    marginBottom: -1,
  },
  clearCol: {
    margin: 0,
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  imageConatiner: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: 600,
    overflow: 'hidden',
  },
  jumboCol: {
    position: 'absolute',
    bottom: '2rem',
    left: 0,
    right: 0,
    margin: 'auto',
    top: (props: { mobile: boolean }) => (props.mobile ? 130 : 'inherit'),
  },
  wrapper: {
    position: 'relative',
    height: '850px',
    backgroundColor: '#fff',
    '@media (min-width: 1199px)': {
      height: '800px',
    },
    '@media (max-width: 768px)': {
      height: (props: { showHeader: boolean }) =>
        props.showHeader ? 790 : 900,
    },
  },
  root: {
    boxShadow: '0 3px 6px 1px #999999',
    padding: '2rem',
    marginBottom: 0,
    borderRadius: 1,
    backgroundColor: '#fff',
    '@media screen and (max-width: 576px) and (orientation: portrait)': {
      alignItems: 'center',
    },
    '@media (max-width: 767px) and (min-width: 577px)': {
      marginLeft: 20,
      marginRight: 20,
    },
    '@media (min-width: 768px) and (max-width: 992px)': {
      marginLeft: 60,
      marginRight: 60,
    },
  },
  title: (props: any) => ({
    textAlign: 'center',
    wordBreak: 'break-word',
    marginBottom: props.withSubtitle ? '0.5rem' : '2rem',
    marginTop: props.loadingUpdatedData ? '-20px' : 0,
  }),
  subtitle: {
    textAlign: 'center',
    wordBreak: 'break-word',
    marginBottom: '2rem',
    fontSize: '1.4rem',
    '& a': {
      fontSize: '1.4rem',
    },
  },
  videoContainer: {
    textAlign: 'center',
    '& iframe': {
      height: '100%',
      maxWidth: '100%',
      // paddingBottom: '.5rem',
      '@media screen and (min-width: 992px)': {
        width: '100%',
      },
    },
  },
  amountRow: {
    '@media (max-width: 1199px)': {
      marginTop: 5,
    },
  },
  amountRaised: {
    fontSize: 17,
    fontWeight: 600,
  },
  progressBarContainer: {
    marginTop: '.5rem',
    marginBottom: '.5rem',
  },
  statContainer: {
    textAlign: 'center',
    '&:not(:last-child)': {
      borderRight: '1px solid #e0e0e0',
    },
  },
  statValue: {
    color: theme.colorPrimary,
  },
  buttonContainer: {
    paddingTop: '2rem',
    '& div button': {
      minHeight: 50,
      height: 'auto',
      '& span': {
        wordBreak: 'break-word',
      },
    },
  },
  cardImage: {
    height: '13rem',
    backgroundColor: 'lightgray',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    backgroundColor: (props: { color: string }) =>
      props.color || theme.colorPrimary,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  divImgContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    margin: 'auto',
    height: 115,
    '& img': {
      minWidth: 115,
      maxWidth: 115,
      minHeight: 115,
    },
  },
  backgroundImageSize: {
    height: '600px',
    width: '100%',
  },
  placeholderSize: {
    height: 150,
    width: 150,
  },
  shareIcons: {
    marginBottom: 10,
  },
  shareIconsCol: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  imageCol: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  imageColCenter: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  nameStyle: {
    marginTop: 10,
    lineHeight: 1.3,
    wordBreak: 'break-word',
  },
  headingTag: {
    fontSize: theme.headingFontSize,
    fontWeight: 600,
  },
}));
