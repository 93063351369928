import * as React from 'react';
import { Card as BootstrapCard, ImageProps } from 'react-bootstrap';
import { useHistory, useRouteMatch } from 'react-router';
import { useTheme } from 'react-jss';
import TruncateMarkup from 'react-truncate-markup';
import Parser from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { MissionTheme } from '@root/interfaces';
import Image from '../image';
import Button from '../button';
import useStyles from './card.style';

export type Props = {
  displayTitle?: string;
  actionTitle?: string;
  amount?: number;
  message?: string;
  remoteImageId?: string;
  remoteImageUrl?: string;
  handleClick?: any;
  color?: string;
  dataTestId?: string;
  width?: string | number;
} & ImageProps;

const Card: React.FC<Props> = ({
  displayTitle = '',
  actionTitle = '',
  message = '',
  remoteImageId,
  remoteImageUrl,
  handleClick = () => {},
  color = '',
  width = null,
  src,
  dataTestId = 'card',
}) => {
  const { t } = useTranslation();
  const classes = useStyles({ color, width });
  const history = useHistory();
  const match = useRouteMatch();
  const theme: MissionTheme = useTheme();

  const { isCP } = theme;

  const scrollToActions = () => {
    if (history.location.pathname !== match.url) {
      history.push(`${match.url}/`);
    }
    setTimeout(() => {
      const actionsElement = document.getElementById('actions');
      if (actionsElement) {
        actionsElement.scrollIntoView();
      }
    });
  };

  const rootProps = {
    className: classes.root,
    'data-testid': dataTestId,
  };

  return (
    <BootstrapCard {...rootProps}>
      <div className={classes.imagePlaceholder}>
        {(remoteImageId || remoteImageUrl || src) && (
          <Image
            card
            remoteImageId={remoteImageId}
            remoteImageUrl={remoteImageUrl}
            src={src}
          />
        )}
      </div>

      <BootstrapCard.Body>
        <h2>{actionTitle}</h2>
        {/* <BootstrapCard.Title> */}
        {displayTitle && (
          <TruncateMarkup lines={1}>
            <span>{Parser(displayTitle)}</span>
          </TruncateMarkup>
        )}
        {/* </BootstrapCard.Title> */}
        <hr />
        {/* <BootstrapCard.Text> */}
        {message && (
          <TruncateMarkup lines={4}>
            <span className={classes.message}>{Parser(message)}</span>
          </TruncateMarkup>
        )}
        {/* </BootstrapCard.Text> */}
        <div className={classes.btn}>
          <Button
            onClick={() => {
              if (isCP) {
                scrollToActions();
              }
              handleClick();
            }}
          >
            {t('donate_btn')}
          </Button>
        </div>
      </BootstrapCard.Body>
    </BootstrapCard>
  );
};

export default Card;
