import { createUseStyles } from 'react-jss';
import { MissionTheme } from '@root/interfaces/MissionTheme';

export default createUseStyles<any, any>((theme: MissionTheme) => ({
  root: {
    marginBottom: 20,
  },
  titleColor: {
    color: theme.useSecThemeColor ? theme.colorSecondary : theme.colorPrimary,
    fontWeight: theme.headingBold ? 600 : 400,
    fontSize: (props: { isDP: boolean }) => props.isDP && theme.headingFontSize,
  },
  givingAmountRow: { flexWrap: 'nowrap' },
  givingAmountCol: { paddingRight: 5 },
  givingAmount: {
    fontStyle: (props: { pageTitle: string }) => props.pageTitle && 'italic',
    cursor: (props: { pageTitle: string }) => props.pageTitle && 'pointer',
    color: (props: { pageTitle: string }) =>
      props.pageTitle && theme.colorPrimary,
    borderBottom: '1px solid',
    // fontWeight: 600,
    borderColor: theme.colorPrimary,
    '&:hover': {
      textDecoration: 'none',
      borderBottom: '1px solid',
      borderColor: theme.colorTertiary,
    },
  },
  donationAmt: {
    fontWeight: 600,
  },
  text: {
    textAlign: 'start',
    '@media (min-width: 576px)': {
      textAlign: 'end',
    },
  },
  helpTextCol: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: 5,
    paddingLeft: 5,
  },
}));
