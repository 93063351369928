import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import Skeleton from '../skeleton';

import useStyles from './footer.style';

const FooterSkeleton = () => {
  const classes = useStyles({
    color: null,
    height: 100,
  });
  return (
    <Row className={classes.skeletonRow}>
      <Col className={classes.skeletonCol}>
        <Skeleton height={100} borderRadius />
      </Col>
    </Row>
  );
};

export { FooterSkeleton };
