import { MissionTheme } from '../../interfaces/MissionTheme';
import { Font } from '../../interfaces/Font';
import defaultStyle from './default';

require('typeface-source-serif-pro');

const primaryFont: Font = {
  name: 'source-serif-pro',
  fontFamily: '"Source Serif Pro", serif',
};

const theme: MissionTheme = {
  ...defaultStyle,
  colorPrimary: '#f58220',
  colorSecondary: '#7c7c7c',
  ...primaryFont,
};

export default theme;
