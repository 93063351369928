import * as React from 'react';
import { useTheme } from 'react-jss';
import { useTranslation } from 'react-i18next';
import ParserHTML from '@components/atoms/parserHTML';
import Parser from 'html-react-parser';
import { useSelector } from 'react-redux';
import { RootState } from '@app/RootState';
import { ChevronRight, ChevronLeft } from 'react-bootstrap-icons';
import TruncateMarkup from 'react-truncate-markup';
import { DonationPageTheme } from '@root/interfaces';
import { DonationPageDetails } from '@modules/donation/types';
import { CampaignPageDetails } from '@api/campaign';
// import { calcHeight } from '@utils/index';
import Button from '@root/components/atoms/button';
import useStyles from './donationHeaderMessage.styles';

export type Props = {
  page?: DonationPageDetails & CampaignPageDetails;
};

const DonationHeaderMessage: React.FC<Props> = ({ page }) => {
  const { t } = useTranslation();
  const theme: DonationPageTheme = useTheme();
  const classes = useStyles();

  const [showMessage, setShowMessage] = React.useState(false);
  const [loadTruncate, setLoadTruncate] = React.useState(false);
  const [truncate, setTruncate] = React.useState(false);

  const { ariaFocus } = useSelector((state: RootState) => state.donation);

  // Element Refs
  const focusRef = React.useRef(null);

  const toggleTruncate = () => {
    setShowMessage(!showMessage);
    setLoadTruncate(false);
  };

  const donateLinkStyles = {
    defaults: {
      padding: 0,
      width: 'auto',
      background: 'none',
      fontWeight: '400',
      fontSize: '0.85rem',
      color: theme.colorPrimary,
      '&:hover': {
        color: `${theme.colorPrimary} !important`, // Needed for iPhones specifically
      },
    },
    active: {
      background: 'none',
      boxShadow: 'none',
      color: theme.colorPrimary,
    },
    focus: {
      background: 'none',
      boxShadow: 'none',
      color: theme.colorPrimary,
    },
    hover: {
      background: 'none',
      boxShadow: 'none',
      color: theme.colorPrimary,
    },
  };

  // Returning an empty ellipsis - replace with read more/less button
  // Check to see if original message being truncated
  const readMoreEllipsis = (node) => {
    const cleanMainMessage = page.message.replace(
      /<\/?[^>]+(>|$)|&nbsp;|\n/g,
      ' '
    );

    let setNode;
    try {
      setNode = node.props.children.props.children.props.children;
    } catch {
      setNode = node.props.children.props.children;
    }

    const currentMessageLength = setNode.length;
    if (currentMessageLength <= cleanMainMessage.trim().length) {
      setTruncate(true);
    }

    return <></>;
  };

  React.useEffect(() => {
    setLoadTruncate(true);
  }, [truncate]);

  React.useEffect(() => {
    if (ariaFocus === 0) {
      focusRef.current.focus();
    }
  }, [focusRef.current]);

  return (
    <section>
      {page.headerMessage ? (
        <h1
          role="banner"
          ref={focusRef}
          className={`${classes.textColor} ${classes.headerMessage}`}
        >
          <ParserHTML text={page.headerMessage} trim />
        </h1>
      ) : null}
      {page.message ? (
        <div className={classes.truncate}>
          <div className={classes.less}>
            <TruncateMarkup
              lines={3}
              ellipsis={readMoreEllipsis}
              tokenize="words"
            >
              <div>
                <div className={classes.pTag}>{Parser(page.message)}</div>
              </div>
            </TruncateMarkup>
          </div>
          <div
            className={`${classes.more} ${
              showMessage ? classes.readMore : classes.readLess
            }`}
            style={{
              maxHeight: !truncate ? 1000 : '',
              height: loadTruncate && truncate ? 58 : '',
            }}
          >
            <div
              className={classes.pTag}
              style={{ display: 'inline-block' }}
              aria-hidden={truncate && !showMessage}
            >
              {Parser(page.message)}
            </div>
          </div>
          {truncate && (
            <div className={classes.truncateButton}>
              <Button onClick={toggleTruncate} buttonStyles={donateLinkStyles}>
                {showMessage && <ChevronLeft aria-hidden="true" />}
                <span className={classes.buttonLink}>{`${
                  showMessage ? t('showLess') : t('readMore')
                }`}</span>
                {!showMessage && <ChevronRight aria-hidden="true" />}
              </Button>
            </div>
          )}
        </div>
      ) : null}
    </section>
  );
};

export default DonationHeaderMessage;
