import { createUseStyles } from 'react-jss';
import { MissionTheme } from '@root/interfaces/MissionTheme';
import { InputStates } from '@root/interfaces/InputStates';

interface StyleProps {
  inputStyles?: InputStates;
}

export default createUseStyles<any, any>((theme: MissionTheme) => {
  const getStylesValues =
    (state: 'defaults' | 'valid' | 'active' | 'error' | 'disabled') =>
    (props: StyleProps) => {
      const { inputStyles } = props;

      const customStyles: InputStates = {
        defaults: inputStyles ? inputStyles.defaults : {},
        active: inputStyles ? inputStyles.active : {},
        valid: inputStyles ? inputStyles.valid : {},
        error: inputStyles ? inputStyles.error : {},
        disabled: inputStyles ? inputStyles.disabled : {},
      };

      const activeStyles = {
        ...theme.inputs.active,
        ...customStyles.active,
      };

      const errorStyles = {
        ...theme.inputs.error,
        ...customStyles.error,
      };

      const validStyles = {
        ...theme.inputs.valid,
        ...customStyles.valid,
      };

      return {
        '& .form-control': {
          outline: 'none',
          boxShadow: 'none',
          width: 'auto',
          justifyContent: 'flex-end',
          ...theme.inputs.defaults,
          ...customStyles.defaults,
          ...theme.inputs[state],
          ...customStyles[state],

          '&:focus': {
            ...activeStyles,
            '&::-webkit-search-cancel-button': {
              opacity: 0.3,
              pointerEvents: 'all',
            },
          },
          /* Chrome, Safari, Edge, Opera */
          '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
          },
          '&::-webkit-search-cancel-button': {
            '-webkit-appearance': 'none',
            height: '0.8em',
            width: '0.8em',
            borderRadius: '50em',
            background:
              'url(https://pro.fontawesome.com/releases/v5.10.0/svgs/solid/times-circle.svg) no-repeat 50% 50%',
            backgroundSize: 'contain',
            opacity: 0,
            pointerEvents: 'none',
          },
          /* Firefox */
          '&[type=number]': {
            '-moz-appearance': 'textfield',
          },
        },
        '& .is-invalid': {
          ...errorStyles,
        },
        '& .is-valid-custom': {
          ...validStyles,
        },
        '& .error-span': {
          border: '0px !important',
        },
      };
    };

  return {
    default: getStylesValues('defaults'),
    valid: getStylesValues('valid'),
    active: getStylesValues('active'),
    error: () => {
      const output = getStylesValues('error');
      if (theme.isDP) {
        output['& .form-group'] = {
          backgroundColor: 'blue',
        };
      }
      return { ...output };
    },
    disabled: getStylesValues('disabled'),
    root: {
      position: 'relative',
    },
    container: {
      position: 'relative',
    },
    rowError: {
      // '& .form-group :not(valid) .form-label': {
      //   top: '30%',
      // },
    },
    inputGroup: {
      '& .invalid-feedback': {
        backgroundColor: '#dc3545',
        color: 'white',
        textAlign: 'end',
        paddingRight: 5,
        paddingLeft: 5,
        width: 'auto',
        position: 'relative',
        '&:after': {
          content: '""',
          width: 0,
          height: 0,
          borderLeft: '5px solid transparent',
          borderRight: '5px solid transparent',
          borderBottom: '6px solid #dc3545',
          position: 'absolute',
          top: -3,
          right: 10,
        },
      },
      // display: 'flex',
      justifyContent: 'flex-end',
    },
    input: {
      width: '100%',
      height: '35px',
      padding: '5px 10px',
      fontFamily: theme.primaryFont.fontFamily,
      fontSize: '15px',
      color: 'gray',
      border: '1px solid #CED4DA',
      borderRadius: '4px',
      webkitAppearance: 'none',
      // '&::ms-clear': {
      //   display: 'none',
      // },
    },
    inputFocused: {
      outline: 'none',
    },
    'react-autosuggest__input::-ms-clear': {
      display: 'none',
    },
    inputOpen: {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
    suggestionsContainerOpen: {
      display: 'block',
      position: 'absolute',
      top: '35px',
      width: '100%',
      border: '1px solid #CED4DA',
      backgroundColor: '#fff',
      fontFamily: 'Open Sans, Helvetica, Arial, sans-serif',
      fontSize: '14px',
      borderBottomLeftRadius: '4px',
      borderBottomRightRadius: '4px',
      maxHeight: '200px',
      zIndex: 1000,
      overflowY: 'auto',
    },
    suggestionsList: {
      margin: 0,
      padding: 0,
      listStyleType: 'none',
    },
    suggestion: {
      cursor: 'pointer',
      padding: '10px 20px',
    },
    suggestionHighlighted: {
      backgroundColor: '#ddd',
    },
    formGroupSkeleton: {
      marginBottom: '1rem',
    },
    inputSkeleton: {
      width: '100%',
      height: '35px',
      padding: '5px 10px',
    },
    anonymousRow: {
      marginTop: -12,
      marginBottom: 15,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    anonymousCol: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      '& span': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
      },
    },
    anonymousText: {
      fontSize: '0.7rem',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      padding: '0px !important',
      '& input.form-check-input': {
        marginTop: !theme.isCP && !theme.isDP && 0,
        marginLeft: !theme.isCP && !theme.isDP && 0,
      },
      '& label.form-check-label': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        '& div': {
          marginRight: 5,
        },
      },
      '& label.form-check-label:before': {
        content: '""',
        position: 'static',
        minWidth: 18,
        maxWidth: 18,
        marginRight: 5,
      },
    },
    toolTipHelpText: {
      position: 'relative',
      right: 5,
    },
    tributeType: {
      '&:not(:first-child)': { paddingLeft: 5 },
    },
    tributeTypeLable: {
      whiteSpace: 'pre-line',
      '@media (min-width: 750px)': { whiteSpace: 'normal' },
    },
    formControl: {},

    expandCol: {
      marginBottom: '1rem',
    },
    employerMatchesRow: {
      minHeight: 37,
    },
    employerMatchesCol: {
      marginBottom: '1rem',
      '@media (min-width: 768px)': { marginBottom: 0 },
    },
    employerMatchesEnter: {
      overflow: 'hidden',
      opacity: 0,
    },
    employerMatchesEnterActive: {
      opacity: 1,
      transition: 'all 700ms, ease-in-out',
    },
    employerMatchesExit: {
      opacity: 1,
    },
    employerMatchesExitActive: {
      opacity: 0,
      transition: 'all 700ms, ease-in-out',
    },
    deicationEnter: {
      overflow: 'hidden',
      opacity: 0,
      maxHeight: 0,
    },
    deicationEnterActive: {
      opacity: 1,
      maxHeight: 500,
      transition: 'all 700ms, ease-in-out',
    },
    deicationExit: {
      opacity: 1,
      maxHeight: 500,
    },
    deicationExitActive: {
      opacity: 0,
      maxHeight: 40,
      transition: 'all 700ms, ease-in-out',
    },
    notificationEnter: {
      overflow: 'hidden',
      opacity: 0,
      maxHeight: 0,
    },
    notificationEnterActive: {
      opacity: 1,
      maxHeight: 500,
      transition: 'all 700ms, ease-in-out',
    },
    notificationExit: {
      opacity: 1,
      maxHeight: 500,
    },
    notificationExitActive: {
      opacity: 0,
      maxHeight: 40,
      transition: 'all 700ms, ease-in-out',
    },
    isCompanyGiftLabel: {
      marginBottom: 0,
    },
    isCompanyGiftRow: {
      alignItems: 'center',
      minHeight: 33.5,
    },
    isCompanyGiftCol: {
      minHeight: 23,
      marginBottom: 0,
    },
    organization: {
      marginTop: 5,
      marginBottom: '1rem',
      // '@media (min-width: 768px)': { marginBottom: 0 },
    },
    organizationEnter: {
      overflow: 'hidden',
      opacity: 0,
    },
    organizationEnterActive: {
      opacity: 1,
      transition: 'all 700ms, ease-in-out',
    },
    organizationExit: {
      opacity: 1,
    },
    organizationExitActive: {
      opacity: 0,
      transition: 'all 700ms, ease-in-out',
    },
    doubleDonation: {
      '& input.dtd-search-input': {
        borderColor: theme.colorPrimary,
        borderRadius: 0,
      },
    },
  };
});
