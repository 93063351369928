import { createUseStyles } from 'react-jss';
import { MissionTheme } from '@root/interfaces/MissionTheme';

export default createUseStyles<any, any>((theme: MissionTheme) => ({
  root: {
    width: '100%',
  },
  toggleBtn: {
    position: 'relative',
    backgroundColor: 'white',
    color: (props: { isDP: boolean }) => (props.isDP ? '#999999' : 'black'),
    borderTop: (props: { isDP: boolean }) => props.isDP && 0,
    borderLeft: (props: { isDP: boolean }) => props.isDP && 0,
    borderRight: (props: { isDP: boolean }) => props.isDP && 0,
    borderBottom: (props: { isDP: boolean }) => props.isDP && '1px solid',
    borderRadius: (props: { isDP: boolean }) => props.isDP && 0,
    borderColor: (props: { isDP: boolean }) => !props.isDP && 'darkgray',
    fontSize: (props: { isDP: boolean }) => props.isDP && '1.25rem',
    fontFamily: theme.toggleFontFamily,
    letterSpacing: 'nromal',
    outline: 'none',
    whiteSpace: 'pre-line',
    '&.active,&:active': {
      boxShadow: 'none',
      fontWeight: (props: { isDP: boolean }) =>
        props.isDP && theme.extraBoldFrequency ? 900 : 600,
      letterSpacing: theme.extraBoldFrequency ? 0.5 : 'normal',
      backgroundColor: (props: { isDP: boolean }) =>
        props.isDP
          ? ['white', '!important']
          : [theme.colorPrimary, '!important'],
      color: (props: { isDP: boolean }) =>
        props.isDP && [theme.colorPrimary, '!important'],
    },
    '&.active:after,&:active:after': {
      content: '""',
      bottom: -2,
      left: 0,
      width: '100%',
      height: 3,
      position: 'absolute',
      borderColor: (props: { color: string }) =>
        props.color
          ? [props.color, '!important']
          : [theme.colorPrimary, '!important'],
      borderBottom: (props: { isDP: boolean }) => props.isDP && '3px solid',
    },
    '&.disabled': {
      backgroundColor: 'lightgray !important',
      borderColor: 'darkgray !important',
      color: [theme.colorText, '!important'],
    },
    '&:after': {
      content: '""',
      bottom: -2,
      left: 0,
      width: '100%',
      height: 3,
      position: 'absolute',
      borderColor: 'transparent !important',
      borderBottom: (props: { isDP: boolean }) => props.isDP && '3px solid',
    },
    '&:hover': {
      backgroundColor: 'white',
      color: 'black',
      borderColor: 'darkgray',
    },
    '@media (min-width: 576px)': {
      whiteSpace: (props: { fullSelection: boolean }) =>
        !props.fullSelection ? 'normal' : 'pre-line',
    },
    '@media (min-width: 768px)': {
      whiteSpace: (props: { fullSelection: boolean }) =>
        !props.fullSelection ? 'normal' : 'normal',
    },
  },
}));
