import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '@app/RootState';

const { lazy, Suspense } = React;
const SignInForm = lazy(() => import('@components/organism/signInForm'));

interface SignInScreenProps {}

const SignInScreen: React.FC<SignInScreenProps> = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { isLoggedIn } = useSelector((state: RootState) => state.user);
  const [currentPage, setCurrentPage] = React.useState(null);

  const onChangePage = (page: string) => {
    setCurrentPage(page);
  };

  React.useEffect(() => {
    if (isLoggedIn) {
      history.push('/user/profile');
    }
  }, [isLoggedIn]);

  return (
    <Suspense fallback={<div>{t('loading')}</div>}>
      <Container fluid>
        <Row>
          <Col md={{ span: 6, offset: 3 }}>
            <h1>
              {t(
                currentPage === 'forgot_password'
                  ? 'forgot_password_title'
                  : currentPage
              )}
            </h1>
          </Col>
          <Col md={{ span: 6, offset: 3 }}>
            <SignInForm
              onChangePage={onChangePage}
              mandateTelephone={false}
              showTelephone={false}
            />
          </Col>
        </Row>
      </Container>
    </Suspense>
  );
};

export default SignInScreen;
