import * as React from 'react';
import ParserHTML from '@components/atoms/parserHTML';
import { Container } from 'react-bootstrap';
import useStyles from './campaignMessage.style';

export type Props = {
  headerMessage?: string;
  message?: string;
  dataTestId?: string;
};

const CampaignMessage: React.FC<Props> = ({
  headerMessage,
  message,
  dataTestId,
}) => {
  const classes = useStyles();

  const rootProps = {
    className: classes.root,
    'data-testid': dataTestId,
  };

  return (
    <Container {...rootProps}>
      {headerMessage ? (
        <h2 className={classes.header}>
          <ParserHTML text={headerMessage} trim />
        </h2>
      ) : null}
      {message ? (
        <div className={classes.message}>
          <ParserHTML text={message} trim />
        </div>
      ) : null}
    </Container>
  );
};

export default CampaignMessage;
