import axios, { AxiosRequestConfig } from 'axios';
import qs from 'qs';
import { objectToFormData } from '@utils/index';
import { authHeader } from '@utils/auth-header';
import { FrequencyType } from '@root/enums/FrequencyType';
import { TransactionCurrency, TermsOfReference } from '@app/types';

import {
  PageKind,
  PaymentIdentityType,
  SignUp,
  AddressVerificationServiceType,
} from '@root/enums';
import { PageBackground, PageDetails } from '@root/interfaces';
import { CampaignPageTypes } from '@root/modules/campaign/campaignSlice';

export interface CampaignPageAction {
  campaignPageActionId?: string;
  transactionCurrencyId?: string;
  packageId?: string;
  display?: string;
  displayTitle?: string;
  message?: string;
  customAmount?: boolean;
  defaultAmount?: number;
  givingFrequencyCode?: number;
  smallImage?: string;
  cardImage?: PageBackground;
  minimumAmount?: number;
  order?: number;
  identifier?: string;
  transactionCurrency?: TransactionCurrency;
}

export interface CampaignPageDetails extends PageDetails {
  campaignPageId?: string;
  configurationId?: string;
  addressVerification?: AddressVerificationServiceType;
  actionHeader?: string | null;
  campaignStart?: Date | string | null;
  campaignEnd?: Date | string | null;
  carouselImageIds?: PageBackground[];
  coverCostAmount?: number;
  coverCostPercentage?: number;
  clientInfo?: ClientInfo;
  daysLeft?: number;
  displayTitle?: string;
  embedVideo?: string;
  friendlyUrl: string;
  forceRedirectTiming: number;
  forceRedirectUrl: string;
  giftAidAcceptence?: string;
  givingFrequencyCode?: number;
  goalAmount?: number;
  goalPercentage?: number;
  goalShowAmount?: boolean;
  goalShowCountdown?: boolean;
  goalShowPercent?: boolean;
  goalShowPercentageRaised?: number;
  goalShowProgressBar?: boolean;
  goalShowSupporters?: boolean;
  goalSupporterAmount?: number;
  goalSupporters?: number;
  headerMessage?: string;
  heroBackgroundImage?: PageBackground;
  profileBackgroundImage?: PageBackground;
  largeImage?: string;
  message?: string;
  setCoverCosts?: boolean;
  setAcceptNotice: boolean;
  setSignup?: boolean;
  showApple?: boolean;
  showCompany?: boolean;
  showCoverCosts?: boolean;
  showCreditCard?: boolean;
  showGiftAid?: boolean;
  showGoogle?: boolean;
  showInHonor?: boolean;
  showMembership?: boolean;
  showMatchDonation?: boolean;
  showPaymentNotice?: boolean;
  paymentNotice?: string;
  signup?: string;
  smallImage?: string;
  thankYou?: string;
  totalAmountRaised?: number;
  showHeader?: boolean;
  allowValidateAddress?: boolean;
  languageCode?: string;
  termsOfReference: TermsOfReference;
  transactionCurrency: TransactionCurrency;
  campaignPageActions?: CampaignPageAction[];
  event?: any;
  recaptchaSiteKey: string | null;
  globalPayGoogleMerchantId?: string;
  globalPayGoogleMerchantName?: string;
  globalPayMerchantId?: string;
  externalUrl?: string | null;
  fundraisingSignUpType?: string | null;
  fundraisingCampaignId?: string | null;
  fundraisingAppealId?: string | null;
  fundraisingPackageId?: string | null;
  enableFundraising?: boolean | null;
  imageModified?: boolean | null;
  messageModified?: boolean | null;
  messageApproved?: boolean | null;
  imageApproved?: boolean | null;
  allowIndividualCampaigns?: boolean | null;
  allowTeamCampaigns?: boolean | null;
  campaignPageTypeId?: CampaignPageTypes;
  profile?: any;
  parentCampaignPage?: CampaignPageDetails;
  parentCampaignPageId?: string | null;
  childCampaignPages?: ChildCampaignPageDetails[];
  campaignDonations?: CampaignDonations[];
  userProfile?: SimpleProfileViewModel;
  pageOwnerId?: string;
  allowToJoin?: boolean;
  membershipGroups?: any;
  removed?: boolean;
  active?: boolean;
  stateCode?: number;
  masterFriendlyUrl?: string;
  masterExternalUrl?: string;
  grandParentRemoved?: boolean;
}

export interface ChildCampaignPageDetails {
  campaignPageId?: string;
  pageOwnerId?: string;
  displayTitle?: string;
  largeImage?: string;
  totalAmountRaised?: number;
  campaignPageTypeId?: CampaignPageTypes;
  friendlyUrl?: string;
  parentFriendlyUrl?: string | null;
  parentDisplayTitle?: string | null;
  externalUrl?: string;
  goalAmount?: number;
  message?: string | null;
  daysLeft?: number;
  allowToJoin?: boolean;
  currencySymbol?: string | null;
  campaignStart?: Date | string | null;
  campaignEnd?: Date | string | null;
  hasMember?: boolean;
  recaptchaSiteKey?: string | null;
  parentCampaignPage?: ChildCampaignPageDetails;
}

export interface SimpleProfileViewModel {
  firstName: string | null;
  lastName: string | null;
  profilePictureId: string | null;
  email: string | null;
}

export interface CampaignPageCRUDViewModel {
  name: string | null;
  message?: string | null;
  goal: number;
  campaignPageId: string;
  parentCampaignPageId: string;
  grandParentCampaignPageId?: string | null;
  parentCampaignPage?: CampaignPageDetails;
  campaignPageTypeId: CampaignPageTypes;
  endDateString?: string;
  startDateString?: string;
  mainPhoto?: string;
  displayJoinTheTeam?: boolean;
  isParentCampaign?: boolean;
  isNewCampaign?: boolean;
  uploadPhotoCampaign?: File | string;
}

export interface CampaignPageType {
  campaignPageTypeId: string | null;
  campaignPageTypeOptionSetId: number | null;
  campaignPageTypeIdName: string | null;
}

export interface BuyerDetails {
  organizationName: string;
  salutation: string;
  firstName: string;
  lastName: string;
  emailaddress1: string;
  telephone1: string;
  billingLine1?: string;
  billingLine2?: string;
  billingCity: string;
  billingStateorProvince: string;
  billingPostalCode: string;
  billingCountry?: string;
  dedicate?: string;
  employerMatchesDonationCheckbox?: boolean;
  nameOfHonouree?: string;
  recipientEmail?: string;
  specialMessage?: string;
  next?: string;
  canadaPostId?: string;
  anonymousCheckbox?: boolean;
}

export interface ClientInfo {
  code?: string;
  homePage?: string;
}

export interface CampaignDonations {
  displayName: string | null;
  messageOfSupport: string | null;
  hideDisplayName: boolean;
  bookDate: Date | string | null;
  amount: number;
}

export interface SupportDetails {
  displayName?: string;
  message?: string;
  hideNameCheckbox?: boolean;
  hideDonationAmountCheckbox?: boolean;
  identifier?: string;
}

// export interface PaymentDetailsProps {
//   creditCardNo: string;
//   expMM: string;
//   expYY: string;
//   cvc: string;
//   coverCostsCheckbox: boolean;
//   signUpCheckbox?: SignUp | string | null;
//   acceptNoticeCheckbox: boolean;
// }

export interface GetOrderTotal {
  coverCosts?: boolean;
  pageDetails?: PageDetails;
  donationAmount?: number;
  selectedMembership?: SelectedMembership;
  givingFrequency?: any;
  pageKind?: PageKind;
}

export interface CampaignPagePost {
  campaignPageId: string;
  donationPageId?: string;
  packageId?: string | null;
  campaignId?: string | null;
  appealId?: string | null;
  designationId?: string | null;
  anonymousCheckbox?: boolean;
  amount: string;
  isRecurring: boolean;
  isoCurrencyCode: string;
  creditCardNo: string;
  expMM: string;
  expYY: string;
  cvc: string;
  matchDonationCheckbox?: string;
  employerMatchesDonationCheckbox?: string;
  dedicate?: string;
  coverCostsCheckbox?: string;
  paymentNoticeCheckbox?: string;
  signUpCheckbox?: SignUp | string | null;
  costPercentage?: string;
  costAmount?: string;
  giftAidAcceptCheckbox?: string;
  giftAidClaimAmount?: number;
  organizationName?: string;
  myEmployer?: string;
  salutation?: string;
  firstName: string;
  lastName: string;
  emailAddress1: string;
  billingStateOrProvince?: string;
  billingCountry?: string;
  billingLine1: string;
  billingLine2?: string;
  billingCity: string;
  billingPostalCode: string;
  telephone1?: string;
  recaptchaToken?: string;
  nameofHonouree?: string;
  recipientEmail?: string;
  specialMessage?: string;
  tributeAddressee?: string;
  tributeLine1?: string;
  tributeLine2?: string;
  tributeCity?: string;
  tributeStateOrProvince?: string;
  tributePostalCode?: string;
  tributeCountry?: string;
  tributeOptionsCode?: string | number | null;
  membershipAmount?: number;
  membershipAmountTax?: number;
  membershipBaseAmount?: number;
  membershipCategoryId?: string;
  payToken?: string;
  paymentType?: string;
  stripeId?: string;
  paymentStatus?: string;
  supportDetails?: SupportDetails;
  fundraisingTotal?: string;
  frequencyType?: FrequencyType;
  name?: string;
  isCompanyGift?: boolean;
  acknowledgementPaymentIdentityType?: PaymentIdentityType;
  doubleTheDonation?: boolean;
}

export interface AddressVerification {
  campaignPageId: string;
  companyName?: string;
  billingLine1?: string;
  billingLine2?: string;
  billingCity?: string;
  billingStateorProvince?: string;
  billingPostalCode?: string;
  billingCountry?: string;
  action: 'autocomplete' | 'verification';
  next?: string;
  canadaPostId?: string;
}

export interface SelectedMembership {
  membershipAmount?: number;
  membershipAmountTax?: number;
  membershipBaseAmount?: number;
  membershipId?: string;
  membership?: boolean;
  identifier?: string;
  includeMembership?: boolean;
}

export interface DonationGroupProps {
  donationAmount: number;
  givingFrequency: string;
}

export interface JsonResponseViewModel {
  success: boolean;
  message?: string | null;
  data?: any | null;
  errorType?: string | null;
}

export async function getCampaignPageDetails(friendlyUrl: string) {
  const requestOptions: AxiosRequestConfig = {
    method: 'post',
    headers: authHeader(),
    url: '/campaigns/getCampaignPageDetails',
    data: qs.stringify({ friendlyUrl }),
  };
  const { data } = await axios(requestOptions);
  return data;
}

export async function getSupportMessages(friendlyUrl: string) {
  const requestOptions: AxiosRequestConfig = {
    method: 'post',
    headers: authHeader(),
    url: '/campaigns/getSupportMessages',
    data: qs.stringify({ friendlyUrl }),
  };
  const { data } = await axios(requestOptions);
  return data;
}

export async function createCampaign(
  campaignPageDetails: CampaignPageCRUDViewModel,
  campaignPhoto?: any,
  padlockToken?: string,
  webApiUrl?: string
) {
  // if (campaignPhoto) {
  //   formData.append('uploadPhotoCampaign', campaignPhoto.file);
  // }

  const newCampaignPhotoId = [];
  if (campaignPhoto) {
    const imgFormData = new FormData();
    imgFormData.append('file', campaignPhoto.file);

    const imgRequestOptions: any = {
      method: 'post',
      crossDomain: true,
      processData: false,
      contentType: false,
      headers: { Accept: 'multipart/form-data', Padlock: padlockToken },
      url: `${webApiUrl}ImageStorage`,
      data: imgFormData,
    };
    const { data } = await axios(imgRequestOptions);
    const paths = data.fullPath.split('.blob.core.windows.net/');
    newCampaignPhotoId.splice(1, 1, paths[1]);
  }

  const azureCampaignPhotoId = newCampaignPhotoId[0]
    ? newCampaignPhotoId[0]
    : '';

  const formData: FormData = objectToFormData(campaignPageDetails);
  formData.append('mainPhoto', azureCampaignPhotoId);

  const requestOptions: AxiosRequestConfig = {
    method: 'post',
    data: formData,
    headers: authHeader(),
    url: '/Campaigns/CreateCampaign',
  };
  const { data } = await axios(requestOptions);
  return data;
}

export async function deleteCampaign(campaignPageId: string) {
  const requestOptions: AxiosRequestConfig = {
    method: 'post',
    data: qs.stringify({ campaignPageId }),
    headers: authHeader(),
    url: '/Campaigns/DeleteCampaign',
  };
  const { data } = await axios(requestOptions);
  return data;
}
