import * as React from 'react';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { DonationPageType } from '@root/enums';
import { GivingFrequency } from '@app/types';
import useStyles from './toggle.style';

export type Props = {
  toggleValue?: string | number;
  firstBtnVal?: string | number;
  secondBtnVal?: string | number;
  thirdBtnVal?: string | number;
  givingFrequencyCode?: number;
  handleToggle?: any;
  color?: string;
  firstBtn?: string;
  secondBtn?: string;
  thirdBtn?: string;
  donationPageTypeId?: DonationPageType;
  dataTestId?: string;
  showFirstBtn?: boolean;
  showSecondBtn?: boolean;
  showThirdBtn?: boolean;
  durations?: any;
};

const Toggle: React.FC<Props> = ({
  givingFrequencyCode = GivingFrequency.SingleDefaultAllowMonthlyAllowAnnual,
  toggleValue = 'once',
  handleToggle = () => {},
  color = null,
  donationPageTypeId,
  dataTestId = 'toggle',
  durations = {},
}) => {
  const { t } = useTranslation();
  const isDP = donationPageTypeId && donationPageTypeId === DonationPageType.DP;

  const fullSelection = !!(
    givingFrequencyCode ===
      GivingFrequency.AnnualDefaultAllowSingleAllowMonthly ||
    givingFrequencyCode ===
      GivingFrequency.MonthlyDefaultAllowSingleAllowAnnual ||
    givingFrequencyCode === GivingFrequency.SingleDefaultAllowMonthlyAllowAnnual
  );

  const classes = useStyles({ color, isDP, fullSelection });
  const rootProps = {
    className: classes.root,
    'data-testid': dataTestId,
  };

  return (
    <ToggleButtonGroup
      {...rootProps}
      type="radio"
      name="occurrence"
      value={toggleValue}
      onChange={handleToggle}
    >
      {Object.keys(durations).map((duration) => (
        <ToggleButton
          key={durations[duration].id}
          size="lg"
          className={classes.toggleBtn}
          aria-label={durations[duration].name}
          name={durations[duration].name}
          disabled={
            (durations[duration].id !== 0 &&
              givingFrequencyCode === GivingFrequency.SingleOnly) ||
            (durations[duration].id !== 1 &&
              givingFrequencyCode === GivingFrequency.MonthlyOnly) ||
            (durations[duration].id !== 2 &&
              givingFrequencyCode === GivingFrequency.AnnualOnly)
          }
          value={durations[duration].name}
        >
          {isDP
            ? t(`donate_${durations[duration].name}`)
            : t(durations[duration].name)}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default Toggle;
