import * as React from 'react';
import ParserHTML from '@components/atoms/parserHTML';
import useStyles from './campaignActionHeader.style';

export type Props = {
  actionHeader?: string;
  dataTestId?: string;
};

const campaignActionHeader: React.FC<Props> = ({
  actionHeader,
  dataTestId,
}) => {
  const classes = useStyles();

  const rootProps = {
    className: classes.root,
    'data-testid': dataTestId,
  };

  return actionHeader ? (
    <h2 {...rootProps}>
      <ParserHTML text={actionHeader} trim />
    </h2>
  ) : null;
};

export default campaignActionHeader;
