import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { CampaignPageDetails } from '@api/campaign';
import { RootState } from '@app/RootState';
import {
  setSelectedMembership,
  CampaignPageTypes,
} from '@modules/campaign/campaignSlice';

const { lazy, Suspense } = React;
const MembershipDetails = lazy(
  () => import('@components/organism/membershipDetails')
);
const Navigation = lazy(() => import('@components/molecules/navigation'));

interface MembershipScreenProps {
  pageDetails?: CampaignPageDetails;
}

const Membership: React.FC<MembershipScreenProps> = ({ pageDetails }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { selectedMembership, donationAmount, givingFrequency } = useSelector(
    (state: RootState) => state.campaign
  );

  const onIncludeMembershipChange = async (value: boolean) => {
    dispatch(
      setSelectedMembership({
        ...selectedMembership,
        includeMembership: value,
      })
    );
  };

  const onChange = async (
    selectMembershipCheckbox: boolean,
    value: any,
    selectedGroup: any
  ) => {
    if (selectMembershipCheckbox) {
      let includeMembership = false;
      if (selectedMembership) {
        includeMembership = selectedMembership.includeMembership;
      }
      dispatch(
        setSelectedMembership({
          membership: true,
          membershipAmount: value.amount,
          membershipAmountTax: value.amountTax,
          membershipBaseAmount: value.amountMembership,
          membershipId: value.membershipCategoryId,
          identifier: selectedGroup,
          includeMembership: includeMembership,
        })
      );
    } else {
      dispatch(setSelectedMembership(null));
    }
  };

  const onSubmit = () => {
    if (
      (pageDetails &&
        pageDetails.campaignPageTypeId === CampaignPageTypes.Master &&
        pageDetails.enableFundraising) ||
      pageDetails.parentCampaignPageId
    ) {
      history.push('showSupport');
    } else {
      history.push('payment');
    }
  };

  return pageDetails ? (
    <Suspense fallback={<div>{t('loading')}</div>}>
      <MembershipDetails
        selectedMembership={selectedMembership}
        handleChange={onChange}
        handleIncludeMembership={onIncludeMembershipChange}
        page={pageDetails}
        donationAmount={donationAmount}
        donationFrequency={givingFrequency}
        membershipGroups={pageDetails.membershipGroups}
      />
      <Navigation
        pageDetails={pageDetails}
        rightBtnOnClick={onSubmit}
        prev="contact"
      />
    </Suspense>
  ) : null;
};

export default Membership;
