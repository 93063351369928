import { MissionTheme, Font } from '@root/interfaces';
import globals from '../default';

require('typeface-crimson-text');
require('typeface-raleway');

const colors = {
  colorPrimary: '#050505',
  colorSecondary: '#E6E7E9',
  colorTertiary: '#E6E7E9',
  colorText: '#050505',
  colorBackground: '#E6E7E9',
};

const primaryFont: Font = {
  name: 'crimson-text',
  fontFamily: 'Crimson Text, serif',
};

const secondaryFont: Font = {
  name: 'raleway',
  fontFamily: 'Raleway, sans-serif',
  fontWeight: 600,
};

const theme: MissionTheme = {
  ...globals,
  ...colors,
  clientFullName: 'Archdiocese of Toronto - Archdiocese of Toronto',
  isSVG: true,
  isDisableFullHeader: true,
  extraBoldFrequency: true,
  useSecThemeColor: false,
  addCustomColor: false,
  headerBackgroundColor: 'white',
  headerTitleColor: '#050505',
  headerLogoHeight: 103,
  headingFontSize: '22px',
  headingBold: true,
  subHeadingFontSize: '18px',
  buttons: {
    ...globals.buttons,
    defaults: {
      ...globals.buttons.defaults,
      background: colors.colorPrimary,
    },
  },
  navButtons: {
    left: {
      ...globals.buttons,
      defaults: {
        ...globals.buttons.defaults,
        background: colors.colorPrimary,
      },
    },
    right: {
      ...globals.buttons,
      defaults: {
        ...globals.buttons.defaults,
        background: colors.colorPrimary,
      },
    },
  },
  primaryFont: primaryFont,
  headingFont: secondaryFont,
  globalFontSize: 16,
  logoBackgroundOpacity: 1,
  toggleFontFamily: secondaryFont.fontFamily,
};

export default theme;
