import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Accordion from '@components/atoms/accordion';
import { DonationPageType } from '@root/enums';
import { GivingFrequency } from '@app/types';
import { getDefaultFrequencyDetails } from '@root/utils';
import FrequencySelector from '@components/molecules/frequencySelector';
import InputAmountField from '@components/atoms/inputAmountField';
import { RootState } from '@app/RootState';
import useStyles from './donationGroup.style';

export type Props = {
  page?: any;
  color?: string;
  inputAmountPassed?: number;
  pageMinimumAmount?: number;
  customAmount?: boolean;
  givingFrequencyCode?: GivingFrequency;
  givingFrequency?: string;
  onChange?: any;
  locale?: string;
  donationPageTypeId?: DonationPageType;
  dataTestId?: string;
};

const DonationGroup: React.FC<Props> = ({
  page = null,
  inputAmountPassed = 1,
  pageMinimumAmount = 1,
  givingFrequencyCode = GivingFrequency.SingleDefaultAllowMonthly,
  givingFrequency = null,
  customAmount = false,
  locale,
  onChange = () => {},
  color = '',
  donationPageTypeId,
  dataTestId = 'donationGroup',
}) => {
  const { t } = useTranslation();
  const currency =
    page && page.transactionCurrency && page.transactionCurrency.isoCurrencyCode
      ? page.transactionCurrency.isoCurrencyCode
      : 'CAD';

  const [occurrence, setOccurrence] = React.useState(null);
  const [inputAmount, setInputAmount] = React.useState(
    inputAmountPassed && inputAmountPassed > 0 && inputAmountPassed.toFixed(2)
  );
  const { donationAmtAndFreq } = useSelector((state: RootState) => state.app);
  const { donationGroup } = useSelector((state: RootState) => state.campaign);
  const [selectedFrequency, setSelectedFrequency] = React.useState(
    donationGroup && donationGroup.givingFrequency
  );

  const classes = useStyles({ color, selectedFrequency });
  const rootProps = {
    className: classes.root,
    'data-testid': dataTestId,
  };

  React.useEffect(() => {
    const frequencyDetails = getDefaultFrequencyDetails(givingFrequencyCode);
    if (donationGroup) {
      setSelectedFrequency(givingFrequency);
    } else if (donationAmtAndFreq) {
      setSelectedFrequency(donationAmtAndFreq.givingFrequency);
    } else {
      setSelectedFrequency(frequencyDetails.selectedFrequencyName);
    }
  }, [givingFrequencyCode]);

  const handleToggle = (frequency) => {
    onChange(inputAmount, frequency);
    setSelectedFrequency(frequency);
  };

  const handleValueChange = (value) => {
    setInputAmount(value);
  };

  const handleOnFocus = (value) => {
    const focusValue = value.target.value;
    if (focusValue !== null || focusValue === inputAmount) {
      value.target.value = '';
      setInputAmount('');
    }
  };

  const handleMinimumAmount = () => {
    const convertAmount = inputAmount && inputAmount.replace(/,(?!.*,)/g, '.');
    let amount = convertAmount && Number(convertAmount);
    if (!amount || amount < pageMinimumAmount) {
      amount = pageMinimumAmount;
    }
    setInputAmount(amount.toFixed(2));
    onChange(amount.toFixed(2), givingFrequency);
  };

  React.useEffect(() => {
    if (selectedFrequency === 'monthly') {
      setOccurrence(`${currency}/${t('month').toUpperCase()}`);
    } else if (selectedFrequency === 'annually') {
      setOccurrence(`${currency}/${t('year').toUpperCase()}`);
    } else {
      setOccurrence(currency);
    }
  }, [inputAmount, selectedFrequency]);

  return (
    <div>
      <div {...rootProps}>
        <FrequencySelector
          givingFrequencyCode={givingFrequencyCode}
          givingFrequency={selectedFrequency}
          page={page}
          onChange={onChange}
          donationPageTypeId={donationPageTypeId}
          handleToggleFn={handleToggle}
        />
      </div>
      {page && page.campaignPageId && page.whyDonateMonthly ? (
        <Accordion text={page.whyDonateMonthly} />
      ) : null}
      <InputAmountField
        inputAmount={inputAmount}
        onFocus={handleOnFocus}
        onBlur={handleMinimumAmount}
        minimumAmount={pageMinimumAmount}
        occurrence={occurrence}
        locale={locale}
        handleChange={handleValueChange}
        customAmount={customAmount}
        pageDetails={page}
      />
    </div>
  );
};

export default React.memo(DonationGroup);
