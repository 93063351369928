import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import { CancellationCode } from '@app/types';
import { DonationPageDetails } from '@modules/donation/types';
import useStyles from './cancelPaymentSchedule.style';

interface UpdatePaymentScheduleProps {
  pageDetails: DonationPageDetails;
  handleOnChangeState: any;
  reason: string;
  note: string;
}

const CancelPaymentSchedule: React.FC<UpdatePaymentScheduleProps> = ({
  reason,
  note,
  handleOnChangeState,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const handleOnChange = (e) => {
    const updateStateValue = {
      value: e.currentTarget.value,
      name: e.currentTarget.name,
    };

    handleOnChangeState(updateStateValue);
  };

  return (
    <>
      <Form.Group controlId="cancelPaymentScheduleSelectOption">
        <Form.Label className={classes.lable}>Reason</Form.Label>
        <Form.Control
          as="select"
          value={reason}
          name="reason"
          onChange={handleOnChange}
        >
          <option value="">{t('select')}</option>
          {Object.keys(CancellationCode)
            .filter((key) => Number.isNaN(Number(key)))
            .map((key: any) => (
              <option key={CancellationCode[key]} value={CancellationCode[key]}>
                {t(key)}
              </option>
            ))}
        </Form.Control>
      </Form.Group>
      <Form.Group controlId="cancelPaymentScheduleTextarea">
        <Form.Label className={classes.lable}>Notes</Form.Label>
        <Form.Control
          as="textarea"
          placeholder={t('Notes')}
          value={note === null ? '' : note}
          name="notes"
          onChange={handleOnChange}
          rows={3}
        />
      </Form.Group>
    </>
  );
};

export default CancelPaymentSchedule;
