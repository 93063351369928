import { createUseStyles } from 'react-jss';
import { MissionTheme } from '@root/interfaces/MissionTheme';
import lifetimeGivingBackground from '../../../assets/images/contact-history/givinghistory.png';
import recentGiftBackground from '../../../assets/images/contact-history/recentgift.png';
import givingLevelBackground from '../../../assets/images/contact-history/givinglevel.png';
import membershipBackground from '../../../assets/images/contact-history/membership.png';

export default createUseStyles<any, any>((theme: MissionTheme) => ({
  root: {
    padding: 0,
  },
  detailCard: {
    marginBottom: '1.25rem',
    borderBottom: `6px solid ${theme.colorPrimary}`,
    flex: 1,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom right',
    backgroundSize: '75px',
    minHeight: 140,
    '& .card-title, & .card-text': {
      textTransform: 'uppercase',
      fontSize: 14,
      fontWeight: 700,
    },

    '& .card-subtitle': {
      textTransform: 'uppercase',
      fontSize: 20,
      fontWeight: 500,
      lineHeight: 1.4,
    },
  },
  detailCardSkeleton: {
    borderBottom: {
      color: `#ddd`,
    },
  },
  lifetimeGiving: {
    backgroundImage: `url(${lifetimeGivingBackground})`,
  },
  recentGift: {
    backgroundImage: `url(${recentGiftBackground})`,
  },
  givingLevel: {
    backgroundImage: `url(${givingLevelBackground})`,
  },
  membership: {
    backgroundImage: `url(${membershipBackground})`,
  },
  recurringGift: {},
  highlighted: {
    color: theme.colorPrimary,
  },
}));
