import { createUseStyles } from 'react-jss';
import { MissionTheme } from '@root/interfaces/MissionTheme';
import { InputStates } from '@root/interfaces/InputStates';

interface StyleProps {
  inputStyles?: InputStates;
}

export default createUseStyles<any, any>((theme: MissionTheme) => {
  const getStylesValues =
    (state: 'defaults' | 'valid' | 'active' | 'error' | 'disabled') =>
    (props: StyleProps) => {
      const { inputStyles } = props;

      const customStyles: InputStates = {
        defaults: inputStyles ? inputStyles.defaults : {},
        active: inputStyles ? inputStyles.active : {},
        valid: inputStyles ? inputStyles.valid : {},
        error: inputStyles ? inputStyles.error : {},
        disabled: inputStyles ? inputStyles.disabled : {},
      };

      const activeStyles = {
        ...theme.inputs.active,
        ...customStyles.active,
      };

      const errorStyles = {
        ...theme.inputs.error,
        ...customStyles.error,
      };

      const validStyles = {
        ...theme.inputs.valid,
        ...customStyles.valid,
      };

      return {
        '& .form-control': {
          outline: 'none',
          boxShadow: 'none',
          width: (props: { passwordField: boolean; currencyField: boolean }) =>
            props.passwordField || props.currencyField ? '80%' : '100%',
          justifyContent: 'flex-end',
          ...theme.inputs.defaults,
          ...customStyles.defaults,
          ...theme.inputs[state],
          ...customStyles[state],
          fontSize: theme.inputLabelSize,
          '&:focus': {
            ...activeStyles,
            '&::-webkit-search-cancel-button': {
              opacity: 0.3,
              pointerEvents: 'all',
            },
          },
          /* Chrome, Safari, Edge, Opera */
          '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
          },
          '&::-webkit-search-cancel-button': {
            '-webkit-appearance': 'none',
            height: '0.8em',
            width: '0.8em',
            borderRadius: '50em',
            background:
              'url(https://pro.fontawesome.com/releases/v5.10.0/svgs/solid/times-circle.svg) no-repeat 50% 50%',
            backgroundSize: 'contain',
            opacity: 0,
            pointerEvents: 'none',
          },

          /* Firefox */
          '&[type=number]': {
            '-moz-appearance': 'textfield',
          },
        },
        '& .is-invalid': {
          ...errorStyles,
        },
        '& .is-valid-custom': {
          ...validStyles,
        },
      };
    };

  return {
    default: getStylesValues('defaults'),
    valid: getStylesValues('valid'),
    active: getStylesValues('active'),
    error: () => {
      const output = getStylesValues('error');
      if (theme.isDP) {
        output['& .form-group'] = {
          backgroundColor: 'blue',
        };
      }
      return { ...output };
    },
    disabled: getStylesValues('disabled'),
    passwordFieldControl: {
      borderRadius: {},
    },
    passwordFieldText: {
      borderRadius: '0 3px 3px 0 !important',
    },
    smallMutedText: {
      width: '100vw',
      color: 'pink',
      '@media (min-width: 768px)': {
        width: '100%',
      },
    },
    inputGroup: {
      '& .invalid-feedback': {
        backgroundColor: '#dc3545',
        color: 'white',
        textAlign: 'end',
        paddingRight: 5,
        paddingLeft: 5,
        width: 'auto',
        position: 'relative',
        '&:after': {
          content: '""',
          width: 0,
          height: 0,
          borderLeft: '5px solid transparent',
          borderRight: '5px solid transparent',
          borderBottom: '6px solid #dc3545',
          position: 'absolute',
          top: -3,
          right: 10,
        },
      },
      // display: 'flex',
      justifyContent: 'flex-end',
    },
    formControl: {},
  };
});
