import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { CampaignPageDetails, SupportDetails } from '@api/campaign';
import { setSupportDetails } from '@modules/campaign/campaignSlice';
import { RootState } from '@app/RootState';

const { lazy, Suspense } = React;
const ShowSupportDetails = lazy(
  () => import('@components/organism/showSupportDetails')
);

interface ShowSupportScreenProps {
  pageDetails?: CampaignPageDetails;
  orderTotal?: number;
}

const ShowSupport: React.FC<ShowSupportScreenProps> = ({
  pageDetails,
  orderTotal,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { supportDetails } = useSelector((state: RootState) => state.campaign);

  const onSubmit = async (updatedSupportDetails: SupportDetails) => {
    dispatch(
      setSupportDetails({
        displayName: updatedSupportDetails.displayName,
        message: updatedSupportDetails.message,
        hideNameCheckbox: updatedSupportDetails.hideNameCheckbox,
        hideDonationAmountCheckbox:
          updatedSupportDetails.hideDonationAmountCheckbox,
        identifier: updatedSupportDetails.identifier,
      })
    );
    history.push('payment');
  };

  return pageDetails ? (
    <Suspense fallback={<div>{t('loading')}</div>}>
      <ShowSupportDetails
        page={pageDetails}
        handleSubmit={onSubmit}
        supportDetails={supportDetails}
        donationAmount={orderTotal}
      />
    </Suspense>
  ) : null;
};

export default ShowSupport;
