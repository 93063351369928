import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppThunk } from '@app/AppThunk';

import {
  getRemoteImage,
  getRemoteImageUrl,
  RemoteImage,
  RemoteImageCollection,
} from '@api/remoteImage';

interface ImageState {
  remoteImages: RemoteImageCollection;
  isLoading: boolean;
  error: string | null;
}

const initialState: ImageState = {
  remoteImages: {},
  isLoading: false,
  error: null,
};

function startLoading(state: ImageState) {
  state.isLoading = true;
}

function loadingFailed(state: ImageState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
}

const imageSlice = createSlice({
  name: 'image',
  initialState,
  reducers: {
    getRemoteImageStart: startLoading,
    getRemoteImageSuccess(state, action: PayloadAction<RemoteImage>) {
      state.remoteImages[action.payload.imageId] = action.payload;
      state.error = null;
      state.isLoading = false;
    },
    getRemoteImageFailure: loadingFailed,
  },
});

export const {
  getRemoteImageStart,
  getRemoteImageSuccess,
  getRemoteImageFailure,
} = imageSlice.actions;

export default imageSlice.reducer;

export const fetchRemoteImage =
  (imageId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getRemoteImageStart());
      const remoteImage = await getRemoteImage(imageId);
      dispatch(getRemoteImageSuccess(remoteImage));
    } catch (err) {
      dispatch(getRemoteImageFailure(err.toString()));
    }
  };

export const fetchRemoteImageUrl =
  (path: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getRemoteImageStart());
      const remoteImage = await getRemoteImageUrl(path);
      dispatch(getRemoteImageSuccess(remoteImage));
    } catch (err) {
      dispatch(getRemoteImageFailure(err.toString()));
    }
  };
