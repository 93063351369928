import { createUseStyles } from 'react-jss';
import { MissionTheme } from '@root/interfaces/MissionTheme';

export default createUseStyles<any, any>((theme: MissionTheme) => ({
  root: {
    padding: '0 2rem 3rem;',
    '@media screen and (min-width: 768px)': {
      padding: '0 4rem 3rem;',
    },
  },
  header: {
    marginBottom: '24px',
  },
  message: {
    color: theme.colorText,
  },
}));
