import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { QuestionCircleFill } from 'react-bootstrap-icons';
import ParserHTML from '@components/atoms/parserHTML';
import useStyles from './overlayHelpText.style';

export type Props = {
  helpText?: string;
  controlId?: string;
  inputField?: boolean;
};

const OverlayHelpText: React.FC<Props> = ({
  helpText = '',
  inputField = false,
  controlId = null,
}) => {
  const { t } = useTranslation();
  const innerPosition =
    (controlId && controlId === 'cvc' && inputField) ||
    (controlId && controlId === 'organizationName' && inputField);
  const classes = useStyles({ inputField, innerPosition });

  return (
    <OverlayTrigger
      overlay={
        <Popover id="help-popover">
          <Popover.Content>
            <ParserHTML text={helpText} trim />
          </Popover.Content>
        </Popover>
      }
    >
      <span className={classes.formHelp}>
        <QuestionCircleFill
          aria-label={`${t('aria_help_icon')} ${helpText}`}
          size={13}
        />
      </span>
    </OverlayTrigger>
  );
};

export default OverlayHelpText;
