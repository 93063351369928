import { MissionTheme } from '../../interfaces/MissionTheme';
import { Font } from '../../interfaces/Font';

const colors = {
  colorPrimary: '#7c7c7c',
  colorSecondary: '#7c7c7c',
  colorTertiary: '#7c7c7c',
  colorText: '#7c7c7c',
  colorBackground: '#7c7c7c',
};

const primaryFont: Font = {
  name: '',
  fontFamily: 'sans-serif',
};

const buttonStates = {
  defaults: {
    background: 'black',
    color: '#fff',
    border: colors.colorPrimary,
    width: '100%',
    height: 50,
    fontWeight: 700,
    fontSize: 14,
  },
  // hover: { color: ['white', '!important'] },
  hover: { color: 'white' },
  focus: { boxShadow: `none` },
  active: {},
  disabled: {
    boxShadow: `none`,
  },
};

const theme: MissionTheme = {
  // Client full name is added to alt tags amoung other things
  clientFullName: 'Mission CRM',
  // If the theme is using an SVG logo set to ture in client theme default
  isSVG: false,
  // If the theme disable just the client logo or the entire hearder section on mobile
  isDisableFullHeader: false,
  // Make Frequency button text extra bold
  extraBoldFrequency: false,
  /* Typography */
  primaryFont,
  globalFontSize: 14,
  headingFontSize: 40,
  subHeadingFontSize: 24,
  inputLabelSize: 14,
  // colors
  ...colors,

  /**
   * COMPONENTS
   */
  logoMaxWidth: 150,
  imageContainerHeight: 150,
  headerVerticalPadding: 30,

  /**
   * UI ELEMENTS
   */

  // Headings
  headingFont: primaryFont,
  headerBackgroundColor: 'white',
  headerTitleColor: '#7c7c7c',
  headerLogoHeight: 100,
  logoBackgroundOpacity: 0.8,

  // Toggle
  toggleFontFamily: primaryFont.fontFamily,

  // Buttons
  buttons: buttonStates,
  navButtons: {
    left: buttonStates,
    right: buttonStates,
  },

  // Inputs
  inputs: {
    defaults: {
      borderRadius: '3px !important',
      color: colors.colorText,
    },
    valid: {},
    active: {},
    error: {},
    disabled: {},
  },
  inputBorderColor: 'lightgrey',

  // InputAmountField
  inputAmountField: {
    defaults: {
      borderRadius: 0,
      fontStyle: 'none',
      fontSize: '1.15rem',
    },
    valid: {},
    active: {},
    error: {},
    disabled: {},
  },

  // Dropdown
  dropdown: {
    defaults: {
      borderRadius: 0,
      fontStyle: 'none',
      fontSize: '1.15rem',
    },
    valid: {},
    active: {},
    error: {},
    disabled: {},
  },
};

export default theme;
