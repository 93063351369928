import { configureStore } from '@reduxjs/toolkit';
// import * as Sentry from '@sentry/react';
import rootReducer from './rootReducer';

// ...

// const sentryReduxEnhancer = Sentry.createReduxEnhancer({
// Optionally pass options listed below
// });

const store = configureStore({
  reducer: rootReducer,
  // enhancers: sentryReduxEnhancer,
});

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./rootReducer', () => {
    // eslint-disable-next-line global-require
    const newRootReducer = require('./rootReducer').default;
    store.replaceReducer(newRootReducer);
  });
}

export type AppDispatch = typeof store.dispatch;

export default store;
