import * as React from 'react';
import { Form } from 'react-bootstrap';
import OverlayHelpText from '@components/atoms/overlayHelpText';
import useStyles from './floatingLabel.style';

export type Props = {
  children: React.ReactNode;
  label: string;
  value: string;
  color?: string;
  controlId?: string;
  helpText?: string;
  mutedText?: string;
  isInvalid?: boolean;
  dataTestId?: string;
  inputFieldSize?: string;
  currencyField?: boolean;
  stripeNumberElementFocus?: boolean;
  stripeExpiryElementFocus?: boolean;
  stripeCvcElementFocus?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>;

const FloatingLabel: React.FC<Props> = ({
  children,
  controlId = null,
  label = 'label',
  value = '123',
  color = '',
  helpText = '',
  mutedText = '',
  isInvalid = false,
  dataTestId = 'floatingLabel',
  inputFieldSize = null,
  currencyField = false,
  stripeNumberElementFocus = false,
  stripeExpiryElementFocus = false,
  stripeCvcElementFocus = false,
}) => {
  const [focus, setFocus] = React.useState(
    stripeNumberElementFocus ||
      stripeExpiryElementFocus ||
      stripeCvcElementFocus ||
      false
  );
  const classes = useStyles({
    focus,
    value,
    color,
    isInvalid,
    inputFieldSize,
    currencyField,
  });
  const rootProps = {
    className: classes.root,
    'data-testid': dataTestId,
  };

  React.useEffect(() => {
    setFocus(stripeNumberElementFocus);
  }, [stripeNumberElementFocus]);

  React.useEffect(() => {
    setFocus(stripeExpiryElementFocus);
  }, [stripeExpiryElementFocus]);

  React.useEffect(() => {
    setFocus(stripeCvcElementFocus);
  }, [stripeCvcElementFocus]);

  return (
    <div
      {...rootProps}
      // aria-hidden="true"
      onBlur={() =>
        setFocus(
          (stripeNumberElementFocus && false) ||
            (stripeExpiryElementFocus && false) ||
            (stripeCvcElementFocus && false) ||
            false
        )
      }
      onFocus={() =>
        setFocus(
          (stripeNumberElementFocus && true) ||
            (stripeExpiryElementFocus && true) ||
            (stripeCvcElementFocus && true) ||
            true
        )
      }
    >
      {children}
      <Form.Label
        aria-hidden="true"
        className={`${classes.label} ${mutedText && classes.hasMutedText}`}
      >
        {label}
      </Form.Label>
      {helpText && (
        <OverlayHelpText helpText={helpText} controlId={controlId} inputField />
      )}
    </div>
  );
};

export default FloatingLabel;
