import { createUseStyles } from 'react-jss';

export default createUseStyles<any, any>({
  root: {
    marginTop: (props: { topMargin: boolean }) => props.topMargin && '18px',
    marginBottom: (props: { isDP: boolean }) => props.isDP && '50px',
  },
  nextBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    marginBottom: '8px',
    '@media (max-width: 576px)': {
      justifyContent: 'center',
    },
    '& .btn span': {
      whiteSpace: ' no-wrap',
      display: 'inline-flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
  },
  donateAmountBtn: {
    '& .btn, & .btn:hover': {
      width: 'auto',
      padding: '10px 20px',
      maxWidth: '100%',
      '@media (max-width: 576px)': {
        width: '100%',
      },
    },
  },
  prevBtn: {
    display: 'flex',
    alignItems: 'flex-start',
    '& .btn:not(:disabled)': {
      justifyContent: 'center',
      '@media (min-width: 576px)': {
        justifyContent: (props: { isDP: boolean }) =>
          props.isDP ? 'flex-start' : 'center',
      },
    },
    '& .btn span': {
      whiteSpace: ' no-wrap',
      display: 'inline-flex',
      flexWrap: 'nowrap',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
  },
  arrowMargin: {
    marginBottom: 0.9,
  },
  redirectText: {
    width: '100%',
    '@media (max-width: 576px)': {
      textAlign: 'center',
    },
  },
});
