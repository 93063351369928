import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useStyles from './errorPage.style';

export type Props = {
  error: any;
  dataTestId?: string;
};

const ErrorPage: React.FC<Props> = ({
  error = null,
  dataTestId = 'ErrorPage',
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [homePage, setHomePage] = React.useState('https://missioncrm.ca');
  const [masterFriendlyUrl, setMasterFriendlyUrl] = React.useState(null);

  React.useEffect(() => {
    if (error && error.data) {
      if (
        error.data.clientInfo &&
        error.data.clientInfo.homePage &&
        error.data.clientInfo.homePage.length > 0
      ) {
        setHomePage(error.data.clientInfo.homePage);
      }
      if (error.data.masterFriendlyUrl) {
        setMasterFriendlyUrl(error.data.masterFriendlyUrl);
      }
    }
  }, [error]);

  const rootProps = {
    className: classes.root,
    'data-testid': dataTestId,
  };

  if (masterFriendlyUrl) {
    return <Redirect to={`/Campaign/${masterFriendlyUrl}/`} />;
  }

  return (
    <div {...rootProps}>
      <h1 className={classes.hTag}>{t('title_404')}</h1>
      <p className={classes.pTag}>{t('subtitle_404')}</p>
      <p className={`${classes.pTag} ${classes.bottomDiv}`}>
        {t('message_404')}
        <a className={classes.aTag} href={homePage}>
          {t('home_page')}
        </a>
        .
      </p>
    </div>
  );
};

export default ErrorPage;
