import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { CampaignPageDetails } from '@api/campaign';
import { RootState } from '@app/RootState';
import { setGiftAidCheckbox } from '@modules/campaign/campaignSlice';

const { lazy, Suspense } = React;
const GiftAidDetails = lazy(
  () => import('@components/organism/giftAidDetails')
);
const Navigation = lazy(() => import('@components/molecules/navigation'));

interface GiftAidScreenProps {
  pageDetails?: CampaignPageDetails;
}

const GiftAid: React.FC<GiftAidScreenProps> = ({ pageDetails }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { giftAidCheckbox, giftAidClaimAmount, donationAmount } = useSelector(
    (state: RootState) => state.campaign
  );

  const onChange = async (receivedGiftAidCheckbox: boolean) => {
    dispatch(setGiftAidCheckbox(receivedGiftAidCheckbox));
  };

  const onSubmit = () => {
    history.push('contact');
  };

  return pageDetails ? (
    <Suspense fallback={<div>{t('loading')}</div>}>
      <GiftAidDetails
        giftAidCheckbox={giftAidCheckbox}
        giftAidClaimAmount={giftAidClaimAmount}
        donationAmount={donationAmount}
        handleChange={onChange}
        page={pageDetails}
      />
      <Navigation
        pageDetails={pageDetails}
        rightBtnOnClick={onSubmit}
        prev="donation"
      />
    </Suspense>
  ) : null;
};

export default GiftAid;
