import { GivingFrequency } from '@root/app/types';

export default (givingFrequencyCode: GivingFrequency) => {
  const frequencyDetails = {
    showSingle: false,
    showMonthly: false,
    showAnnual: false,
    selectedFrequencyName: 'once',
    durations: [],
  };
  switch (givingFrequencyCode) {
    case GivingFrequency.SingleOnly:
      frequencyDetails.showSingle = true;
      frequencyDetails.showMonthly = false;
      frequencyDetails.showAnnual = false;
      frequencyDetails.selectedFrequencyName = 'once';
      frequencyDetails.durations = [
        ...(frequencyDetails.showSingle
          ? [
              {
                id: 0,
                name: 'once',
              },
            ]
          : []),
        ...(frequencyDetails.showMonthly
          ? [
              {
                id: 1,
                name: 'monthly',
              },
            ]
          : []),
        ...(frequencyDetails.showAnnual
          ? [
              {
                id: 2,
                name: 'annually',
              },
            ]
          : []),
      ];
      break;

    case GivingFrequency.SingleDefaultAllowMonthly:
      frequencyDetails.showSingle = true;
      frequencyDetails.showMonthly = true;
      frequencyDetails.showAnnual = false;
      frequencyDetails.selectedFrequencyName = 'once';
      frequencyDetails.durations = [
        ...(frequencyDetails.showSingle
          ? [
              {
                id: 0,
                name: 'once',
              },
            ]
          : []),
        ...(frequencyDetails.showMonthly
          ? [
              {
                id: 1,
                name: 'monthly',
              },
            ]
          : []),
        ...(frequencyDetails.showAnnual
          ? [
              {
                id: 2,
                name: 'annually',
              },
            ]
          : []),
      ];
      break;

    case GivingFrequency.SingleDefaultAllowMonthlyAllowAnnual:
      frequencyDetails.showSingle = true;
      frequencyDetails.showMonthly = true;
      frequencyDetails.showAnnual = true;
      frequencyDetails.selectedFrequencyName = 'once';
      frequencyDetails.durations = [
        ...(frequencyDetails.showSingle
          ? [
              {
                id: 0,
                name: 'once',
              },
            ]
          : []),
        ...(frequencyDetails.showMonthly
          ? [
              {
                id: 1,
                name: 'monthly',
              },
            ]
          : []),
        ...(frequencyDetails.showAnnual
          ? [
              {
                id: 2,
                name: 'annually',
              },
            ]
          : []),
      ];
      break;

    case GivingFrequency.MonthlyOnly:
      frequencyDetails.showSingle = false;
      frequencyDetails.showMonthly = true;
      frequencyDetails.showAnnual = false;
      frequencyDetails.selectedFrequencyName = 'monthly';
      frequencyDetails.durations = [
        ...(frequencyDetails.showSingle
          ? [
              {
                id: 0,
                name: 'once',
              },
            ]
          : []),
        ...(frequencyDetails.showMonthly
          ? [
              {
                id: 1,
                name: 'monthly',
              },
            ]
          : []),
        ...(frequencyDetails.showAnnual
          ? [
              {
                id: 2,
                name: 'annually',
              },
            ]
          : []),
      ];
      break;

    case GivingFrequency.MonthlyDefaultAllowSingle:
      frequencyDetails.showSingle = true;
      frequencyDetails.showMonthly = true;
      frequencyDetails.showAnnual = false;
      frequencyDetails.selectedFrequencyName = 'monthly';
      frequencyDetails.durations = [
        ...(frequencyDetails.showSingle
          ? [
              {
                id: 0,
                name: 'once',
              },
            ]
          : []),
        ...(frequencyDetails.showMonthly
          ? [
              {
                id: 1,
                name: 'monthly',
              },
            ]
          : []),
        ...(frequencyDetails.showAnnual
          ? [
              {
                id: 2,
                name: 'annually',
              },
            ]
          : []),
      ];
      break;

    case GivingFrequency.MonthlyDefaultAllowAnnual:
      frequencyDetails.showSingle = false;
      frequencyDetails.showMonthly = true;
      frequencyDetails.showAnnual = true;
      frequencyDetails.selectedFrequencyName = 'monthly';
      frequencyDetails.durations = [
        ...(frequencyDetails.showSingle
          ? [
              {
                id: 0,
                name: 'once',
              },
            ]
          : []),
        ...(frequencyDetails.showMonthly
          ? [
              {
                id: 1,
                name: 'monthly',
              },
            ]
          : []),
        ...(frequencyDetails.showAnnual
          ? [
              {
                id: 2,
                name: 'annually',
              },
            ]
          : []),
      ];
      break;

    case GivingFrequency.MonthlyDefaultAllowSingleAllowAnnual:
      frequencyDetails.showSingle = true;
      frequencyDetails.showMonthly = true;
      frequencyDetails.showAnnual = true;
      frequencyDetails.selectedFrequencyName = 'monthly';
      frequencyDetails.durations = [
        ...(frequencyDetails.showSingle
          ? [
              {
                id: 0,
                name: 'once',
              },
            ]
          : []),
        ...(frequencyDetails.showMonthly
          ? [
              {
                id: 1,
                name: 'monthly',
              },
            ]
          : []),
        ...(frequencyDetails.showAnnual
          ? [
              {
                id: 2,
                name: 'annually',
              },
            ]
          : []),
      ];
      break;

    case GivingFrequency.AnnualOnly:
      frequencyDetails.showSingle = false;
      frequencyDetails.showMonthly = false;
      frequencyDetails.showAnnual = true;
      frequencyDetails.selectedFrequencyName = 'annually';
      frequencyDetails.durations = [
        ...(frequencyDetails.showSingle
          ? [
              {
                id: 0,
                name: 'once',
              },
            ]
          : []),
        ...(frequencyDetails.showMonthly
          ? [
              {
                id: 1,
                name: 'monthly',
              },
            ]
          : []),
        ...(frequencyDetails.showAnnual
          ? [
              {
                id: 2,
                name: 'annually',
              },
            ]
          : []),
      ];
      break;

    case GivingFrequency.AnnualDefaultAllowMonthly:
      frequencyDetails.showSingle = false;
      frequencyDetails.showMonthly = true;
      frequencyDetails.showAnnual = true;
      frequencyDetails.selectedFrequencyName = 'annually';
      frequencyDetails.durations = [
        ...(frequencyDetails.showSingle
          ? [
              {
                id: 0,
                name: 'once',
              },
            ]
          : []),
        ...(frequencyDetails.showMonthly
          ? [
              {
                id: 1,
                name: 'monthly',
              },
            ]
          : []),
        ...(frequencyDetails.showAnnual
          ? [
              {
                id: 2,
                name: 'annually',
              },
            ]
          : []),
      ];
      break;

    case GivingFrequency.AnnualDefaultAllowSingleAllowMonthly:
      frequencyDetails.showSingle = true;
      frequencyDetails.showMonthly = true;
      frequencyDetails.showAnnual = true;
      frequencyDetails.selectedFrequencyName = 'annually';
      frequencyDetails.durations = [
        ...(frequencyDetails.showSingle
          ? [
              {
                id: 0,
                name: 'once',
              },
            ]
          : []),
        ...(frequencyDetails.showMonthly
          ? [
              {
                id: 1,
                name: 'monthly',
              },
            ]
          : []),
        ...(frequencyDetails.showAnnual
          ? [
              {
                id: 2,
                name: 'annually',
              },
            ]
          : []),
      ];
      break;

    default:
      frequencyDetails.showSingle = true;
      frequencyDetails.showMonthly = true;
      frequencyDetails.showAnnual = true;
      frequencyDetails.selectedFrequencyName = 'once';
      frequencyDetails.durations = [
        ...(frequencyDetails.showSingle
          ? [
              {
                id: 0,
                name: 'once',
              },
            ]
          : []),
        ...(frequencyDetails.showMonthly
          ? [
              {
                id: 1,
                name: 'monthly',
              },
            ]
          : []),
        ...(frequencyDetails.showAnnual
          ? [
              {
                id: 2,
                name: 'annually',
              },
            ]
          : []),
      ];
      break;
  }

  return frequencyDetails;
};
