import * as React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import '@root/i18n';
import store from '@app/store';
import TagManager from 'react-gtm-module';

const render = () => {
  const root = document.getElementById('root');
  const gtmId = root.dataset.gtmid;

  if (gtmId) {
    const tagManagerArgs = {
      gtmId: gtmId,
      dataLayerName: 'MCRMDataLayer',
    };

    TagManager.initialize(tagManagerArgs);
  }

  // eslint-disable-next-line global-require
  const App = require('@app/App').default;

  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    root
  );
};

render();

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./app/App', render);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// eslint-disable-next-line no-console
// reportWebVitals(console.log);
