import fontFace from '../tools/fontFace';

export default {
  '@font-face': [
    fontFace('tr', 'Klavika-Regular', 'normal', 'normal', 'Klavika-Regular'),
    fontFace(
      'tr',
      'ProximaNova-Regular',
      'normal',
      'normal',
      'ProximaNova-Regular'
    ),
  ],
};
