import * as React from 'react';
import { Row, Col } from 'react-bootstrap';
import NumberFormatter from '@components/atoms/numberFormatter';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { DonationPageType } from '@root/enums';
import { setProgressIndicator } from '@modules/donation/donation.slice';
import OverlayHelpText from '@components/atoms/overlayHelpText';
import useStyles from './orderSummary.style';

export type Props = {
  color?: string;
  height?: number;
  pageTitle?: string | null;
  totalAmount?: number;
  membershipAmount?: number;
  toPage?: string;
  dataTestId?: string;
  donationPageTypeId?: DonationPageType;
  helpText?: string;
  frequency?: string;
};

const OrderSummary: React.FC<Props> = ({
  color = '',
  height = 50,
  pageTitle = null,
  donationPageTypeId,
  totalAmount = null,
  membershipAmount = null,
  toPage = '.',
  frequency = 'once',
  dataTestId = 'orderSummary',
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isDP = donationPageTypeId === DonationPageType.DP;
  const classes = useStyles({ color, height, pageTitle, isDP });
  const rootProps = { className: classes.root, 'data-testid': dataTestId };

  // Element Refs
  const focusRef = React.useRef(null);

  return (
    <div {...rootProps}>
      <Row>
        {pageTitle && (
          <Col>
            <h2 role="banner" ref={focusRef} className={classes.titleColor}>
              {pageTitle}
            </h2>
          </Col>
        )}
        <Col xs={12} sm="auto">
          {pageTitle ? (
            <div className={classes.text}>
              <Row className={classes.givingAmountRow}>
                <Col xs="auto" className={classes.givingAmountCol}>
                  <Link
                    aria-label={t('aria_amount_summary')}
                    className={classes.givingAmount}
                    to={toPage}
                    onClick={() => {
                      dispatch(setProgressIndicator('donate'));
                    }}
                  >
                    <Trans
                      i18nKey={`your_${
                        frequency !== 'once' ? `${frequency}_` : ''
                      }donation`}
                    >
                      Your donation
                      <NumberFormatter bold value={totalAmount} hideLocale />
                    </Trans>
                  </Link>
                </Col>
                {isDP && (
                  <Col xs="auto" className={classes.helpTextCol}>
                    <OverlayHelpText helpText={t('summary_link_description')} />
                  </Col>
                )}
              </Row>

              {membershipAmount !== null && (
                <Row>
                  <Col>
                    <Link
                      aria-label={t('aria_member_summary')}
                      className={classes.givingAmount}
                      to="membership"
                      onClick={() => {
                        dispatch(setProgressIndicator('membership'));
                      }}
                    >
                      <Trans i18nKey="your_membership">
                        Your membership
                        <NumberFormatter
                          bold
                          value={membershipAmount}
                          hideLocale
                        />
                      </Trans>
                    </Link>
                  </Col>
                </Row>
              )}
            </div>
          ) : (
            <Link className={classes.givingAmount} to={toPage}>
              <Trans i18nKey="giving">
                Giving
                <NumberFormatter value={totalAmount} hideLocale />
                {/* </a> */}
              </Trans>
            </Link>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default OrderSummary;
