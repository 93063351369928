import { createUseStyles } from 'react-jss';
import { MissionTheme, ButtonStates } from '@root/interfaces/MissionTheme';

interface ButtonStyleProps {
  hidden: boolean;
  buttonStyles: ButtonStates;
}

export default createUseStyles<any, any>((theme: MissionTheme) => {
  const getStylesValues =
    (state: 'defaults' | 'hover' | 'focus' | 'active' | 'disabled') =>
    (props: ButtonStyleProps) => {
      const { buttonStyles, hidden } = props;
      const customStyles = {
        defaults: buttonStyles ? buttonStyles.defaults : {},
        hover: buttonStyles ? buttonStyles.hover : {},
        focus: buttonStyles ? buttonStyles.focus : {},
        active: buttonStyles ? buttonStyles.active : {},
        disabled: buttonStyles ? buttonStyles.disabled : {},
      };

      const defaults = {
        ...theme.buttons.defaults,
        ...customStyles.defaults,
        display: hidden ? 'none' : 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: 1,
        transition: 'all 250ms',
        '&:hover': {
          '@media(hover: hover)': {
            ...theme.buttons.hover,
            ...customStyles.hover,
          },
        },
        '&:not([disabled]):focus': {
          ...theme.buttons.focus,
          ...customStyles.focus,
        },
        '&:not([disabled])&:active': {
          ...theme.buttons.active,
          ...customStyles.active,
        },
        '&[disabled]': {
          ...theme.buttons.disabled,
          ...customStyles.disabled,
        },
        '&[disabled]:hover': {
          '@media(hover: hover)': {
            ...theme.buttons.disabled,
            ...customStyles.disabled,
          },
        },
      };

      return {
        ...defaults,
        ...theme.buttons[state],
        ...customStyles[state],
      };
    };

  return {
    default: getStylesValues('defaults'),
    focus: getStylesValues('focus'),
    active: getStylesValues('active'),
    disabled: getStylesValues('disabled'),
    label: ({ buttonStyles }) => {
      let { fontWeight, fontSize } = theme.buttons.defaults;
      if (
        buttonStyles &&
        buttonStyles.defaults &&
        buttonStyles.defaults.fontWeight
      ) {
        fontWeight = buttonStyles.defaults.fontWeight;
      }
      if (
        buttonStyles &&
        buttonStyles.defaults &&
        buttonStyles.defaults.fontSize
      ) {
        fontSize = buttonStyles.defaults.fontSize;
      }
      return { fontWeight, fontSize };
    },
  };
});
