import { DonationPageType, PageKind } from '@root/enums';
import getSymbolFromCurrency from 'currency-symbol-map';
import { GetOrderTotal } from '../api/campaign';

export { authHeader } from './auth-header';
export { getDateLocale } from './dateLocale';
export { getAmountFormatter } from './amountFormatter';
export { addressToBilling } from './addressToBilling';
export { default as getDefaultFrequencyDetails } from './getDefaultFrequencyDetails';

export const getCurrencyFormats = ({
  languageCode,
  currencySymbol,
  isoCurrencyCode,
}) => {
  const currencyFormat = {
    thousandSeparator: ',',
    decimalSeparator: '.',
    prefix: null,
    suffix: null,
  };

  switch (languageCode) {
    case 'en-CA':
    case 'en-GB':
    case 'en-US':
      currencyFormat.thousandSeparator = ',';
      currencyFormat.decimalSeparator = '.';
      currencyFormat.prefix = currencySymbol;
      break;
    case 'fr':
      currencyFormat.thousandSeparator = ' ';
      currencyFormat.decimalSeparator = ',';
      if (isoCurrencyCode === 'CAD') {
        currencyFormat.suffix = ` ${getSymbolFromCurrency(isoCurrencyCode)}`;
        break;
      }
      currencyFormat.suffix = ` ${currencySymbol}`;
      break;
    case 'de':
      currencyFormat.thousandSeparator = '';
      currencyFormat.decimalSeparator = ',';
      currencyFormat.suffix = ` ${currencySymbol}`;
      break;
    case 'nl':
      currencyFormat.thousandSeparator = '';
      currencyFormat.decimalSeparator = ',';
      currencyFormat.prefix = `${currencySymbol} `;
      break;
    case 'es':
      currencyFormat.thousandSeparator = ',';
      currencyFormat.decimalSeparator = '.';
      currencyFormat.prefix = currencySymbol;
      break;
    default:
      currencyFormat.thousandSeparator = ',';
      currencyFormat.decimalSeparator = '.';
      currencyFormat.prefix = currencySymbol;
      break;
  }
  return currencyFormat;
};

export const getOrderTotal = ({
  coverCosts,
  pageDetails,
  donationAmount,
  selectedMembership,
  givingFrequency,
  pageKind,
}: GetOrderTotal) => {
  let orderTotal = donationAmount;
  let costAmount = 0;
  let costPercentage = 0;
  let totalMembershipAmount = 0;

  if (pageDetails) {
    costAmount = pageDetails.coverCostAmount || 0;
    costPercentage = pageDetails.coverCostPercentage || 0;

    if (selectedMembership && selectedMembership.membership) {
      totalMembershipAmount +=
        givingFrequency !== 'once'
          ? selectedMembership.membershipBaseAmount / 12 +
            selectedMembership.membershipAmountTax / 12
          : selectedMembership.membershipBaseAmount +
            selectedMembership.membershipAmountTax;
    }

    if (coverCosts) {
      orderTotal += totalMembershipAmount;
      const percentage = costPercentage / 100;
      orderTotal += orderTotal * percentage;
      orderTotal += costAmount;
      orderTotal = Math.round((orderTotal + Number.EPSILON) * 100) / 100;
    }

    if (
      pageKind !== PageKind.Donation ||
      (pageDetails.donationPageTypeId &&
        pageDetails.donationPageTypeId !== DonationPageType.DP)
    ) {
      orderTotal += totalMembershipAmount;
    }
  }

  return orderTotal;
};

export const objectToFormData = (obj, formData = null, namespace = null) => {
  const fd = formData || new FormData();
  let formKey;

  Object.keys(obj).forEach((property) => {
    if (Object.prototype.hasOwnProperty.call(obj, property)) {
      formKey = namespace ? `${namespace}[${property}]` : property;
      // If property is an object, use recursively
      if (typeof obj[property] === 'object' && obj[property]) {
        objectToFormData(obj[property], fd, property);
      } else {
        fd.append(formKey, obj[property]);
      }
    }
  });
  return fd;
};
