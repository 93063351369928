import { createUseStyles } from 'react-jss';

export default createUseStyles<any, any>(() => ({
  main: {
    display: 'flex',
    flexFlow: 'row wrap',
    backgroundColor: '#fff',
    width: '100%',
    height: '100%',
    minHeight: (props: { isOCP: boolean }) => props.isOCP && '100vh',
    overflowX: 'hidden',
  },
  container: {
    margin: 0,
    padding: {
      right: 15,
      left: 15,
    },
  },
  pageContainer: {
    padding: {
      right: 15,
      left: 15,
    },
    '@media (min-width: 992px)': {
      padding: {
        right: 60,
        left: 60,
      },
    },
  },
  footerContainer: {
    alignSelf: 'flex-end',
    padding: 0,
  },
  opcGateSkeleton: {
    padding: '10px 0px',
  },
}));
