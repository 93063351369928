import { createUseStyles } from 'react-jss';
import { MissionTheme } from '@root/interfaces/MissionTheme';

export default createUseStyles<any, any>((theme: MissionTheme) => ({
  caption: {
    position: 'relative',
    marginBottom: 0,
    padding: {
      top: 0,
      bottom: 0,
    },
    bottom: '-30px',
    color: () => theme.colorText,
    '& p': {
      marginBottom: 0,
    },
  },
  cardImage: {
    height: 'auto',
    minHeight: 184,
    minWidth: 320,
    maxWidth: 360,
    display: 'block',
    position: 'relative',
    left: '50%',
    transform: 'translateX(-50%)',
  },
}));
