import React from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import Button from '@components/atoms/button';
import { CampaignPageTypes } from '@modules/campaign/campaignSlice';
import { useHistory, useRouteMatch } from 'react-router';

export type Props = {
  width?: number | string | null;
  page?: any;
};

const DonateButton: React.FC<Props> = ({ page = null }) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();

  const scrollToActions = () => {
    if (history.location.pathname !== match.url) {
      history.push(`${match.url}/`);
    }
    setTimeout(() => {
      const actionsElement = document.getElementById('actions');
      if (actionsElement) {
        actionsElement.scrollIntoView();
      }
    });
  };

  let donate_btn = t('donate_btn');
  if (page && page.campaignPageTypeId === CampaignPageTypes.Master) {
    donate_btn = t('master_donate_btn');
  } else if (page && page.campaignPageTypeId === CampaignPageTypes.Team) {
    donate_btn = t('team_donate_btn');
  } else if (
    page &&
    (page.campaignPageTypeId === CampaignPageTypes.TeamMember ||
      page.campaignPageTypeId === CampaignPageTypes.Individual)
  ) {
    if (page && page.userProfile) {
      let userName = page.userProfile && page.userProfile.firstName.trim();
      // Check if name ends with an 's' to correct possession
      if (i18n && i18n.language.substring(0, 2) === 'en') {
        userName =
          userName.charAt(userName.length - 1) === 's'
            ? `${userName}'`
            : `${userName}'s`;
      }
      donate_btn = i18next.t('team_member_donate_btn', {
        name: userName,
      });
    } else {
      donate_btn = t('donate_btn');
    }
  }

  return <Button onClick={scrollToActions}>{donate_btn}</Button>;
};

export default DonateButton;
