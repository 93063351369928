import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@app/RootState';
import { DonationPageType } from '@root/enums';
import { DonationPageDetails } from '@modules/donation/types';
import { CampaignPageDetails } from '@api/campaign';
import { setSelectedMembership, fetchMembershipGroups } from '@app/app.slice';
import { setProgressIndicator } from '@modules/donation/donation.slice';

const { lazy, Suspense } = React;
const MembershipDetails = lazy(
  () => import('@components/organism/membershipDetails')
);
const Navigation = lazy(() => import('@components/molecules/navigation'));

interface MembershipScreenProps {
  pageDetails: DonationPageDetails & CampaignPageDetails;
}
const Membership: React.FC<MembershipScreenProps> = ({ pageDetails }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { selectedMembership, donationAmtAndFreq, membershipGroups } =
    useSelector((state: RootState) => state.app);

  const onIncludeMembershipChange = async (value: boolean) => {
    dispatch(
      setSelectedMembership({
        ...selectedMembership,
        includeMembership: value,
      })
    );
  };

  React.useEffect(() => {
    if (pageDetails.showMembership) {
      dispatch(fetchMembershipGroups());
    }
  }, [pageDetails]);

  let prevScreen = 'contact';
  if (pageDetails.donationPageTypeId === DonationPageType.OCP) {
    if (pageDetails && pageDetails.showGiftAid) {
      prevScreen = 'giftAid';
    } else {
      prevScreen = 'donate';
    }
  }

  const onChange = (
    selectMembershipCheckbox: boolean,
    value: any,
    selectedGroup: any
  ) => {
    if (selectMembershipCheckbox) {
      let includeMembership = false;
      if (selectedMembership) {
        includeMembership = selectedMembership.includeMembership;
      }
      dispatch(
        setSelectedMembership({
          membership: true,
          membershipAmount: value.amount,
          membershipAmountTax: value.amountTax,
          membershipBaseAmount: value.amountMembership,
          membershipId: value.membershipCategoryId,
          identifier: selectedGroup,
          includeMembership: includeMembership,
        })
      );
    } else {
      dispatch(setSelectedMembership(null));
    }
  };

  const onSubmit = () => {
    // if (
    //   (pageDetails &&
    //     pageDetails.campaignPageTypeId &&
    //     pageDetails.campaignPageTypeId === CampaignPageTypes.Master &&
    //     pageDetails.enableFundraising) ||
    //   pageDetails.parentCampaignPageId
    // ) {
    //   history.push('showSupport');
    // } else {
    let nextScreen = 'payment';
    if (
      pageDetails.donationPageTypeId === DonationPageType.DP &&
      (pageDetails.showDescription || pageDetails.showCustomQuestions)
    ) {
      nextScreen = 'additionalInfo';
      dispatch(setProgressIndicator(nextScreen));
    }
    history.push(nextScreen);
    // }
  };

  const groups = membershipGroups || pageDetails.membershipGroups || [];
  return (
    <Suspense fallback={<div>{t('loading')}</div>}>
      <MembershipDetails
        selectedMembership={selectedMembership}
        page={pageDetails}
        membershipGroups={groups}
        handleChange={onChange}
        handleIncludeMembership={onIncludeMembershipChange}
        donationAmount={donationAmtAndFreq.donationAmount}
        donationFrequency={donationAmtAndFreq.givingFrequency}
      />
      <Navigation
        pageDetails={pageDetails}
        rightBtnOnClick={onSubmit}
        prev={prevScreen}
        leftBtnOnClick={() => {
          if (pageDetails.donationPageTypeId === DonationPageType.DP) {
            dispatch(setProgressIndicator(prevScreen));
          }
        }}
      />
    </Suspense>
  );
};

export default Membership;
