import fontFace from '../tools/fontFace';

export default {
  '@font-face': [
    fontFace(
      'Steelfish',
      'Steelfish',
      'normal',
      'normal',
      'SteelfishRg-Regular'
    ),
    fontFace('bbbsc', 'Steelfish', 'normal', 'italic', 'SteelfishRg-Italic'),
    fontFace('bbbsc', 'Steelfish', '700', 'normal', 'SteelfishRg-Bold'),
    fontFace('bbbsc', 'Steelfish', '700', 'italic', 'SteelfishRg-BoldItalic'),
    fontFace('bbbsc', 'Steelfish', '300', 'normal', 'SteelfishRg-Regular'),
    fontFace('bbbsc', 'Steelfish', '300', 'italic', 'SteelfishRg-Italic'),
    fontFace('bbbsc', 'Steelfish', '200', 'normal', 'SteelfishRg-Regular'),
    fontFace('bbbsc', 'Steelfish', '200', 'italic', 'SteelfishRg-Italic'),
    fontFace('bbbsc', 'Steelfish', '500', 'normal', 'SteelfishRg-Bold'),
    fontFace('bbbsc', 'Steelfish', '500', 'italic', 'SteelfishRg-BoldItalic'),
    fontFace('bbbsc', 'Steelfish', '600', 'normal', 'SteelfishEb-Regular'),
    fontFace('bbbsc', 'Steelfish', '600', 'italic', 'SteelfishEb-Italic'),
    fontFace('bbbsc', 'Steelfish', '800', 'normal', 'SteelfishEb-Regular'),
    fontFace('bbbsc', 'Steelfish', '800', 'italic', 'SteelfishEb-Italic'),
  ],
};
