import * as React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const { lazy, Suspense } = React;
const ResetPasswordForm = lazy(
  () => import('@components/molecules/resetPasswordForm')
);

interface ResetPasswordScreenProps {}

const ResetPasswordScreen: React.FC<ResetPasswordScreenProps> = () => {
  const { t } = useTranslation();

  return (
    <Suspense fallback={<div>{t('loading')}</div>}>
      <Container fluid>
        <Row>
          <Col md={{ span: 6, offset: 3 }}>
            <h1>{t('forgot_password_title')}</h1>
          </Col>
          <Col md={{ span: 6, offset: 3 }}>
            <ResetPasswordForm />
          </Col>
        </Row>
      </Container>
    </Suspense>
  );
};

export default ResetPasswordScreen;
