import * as React from 'react';
import { Container, Jumbotron, Col, Row } from 'react-bootstrap';
import { CardImage } from 'react-bootstrap-icons';
import Skeleton from '@components/atoms/skeleton';

import useStyles from './campaignStats.style';

const CampaignStatsSkeleton = () => {
  const classes = useStyles({
    backgroundImage: null,
  });
  return (
    <Container fluid className={classes.containerMain}>
      <Row className={classes.wrapper}>
        <Col className={classes.clearCol}>
          <Col xs="auto" lg={9} className={classes.jumboCol}>
            <Jumbotron className={classes.root}>
              <h1>
                <Skeleton />
              </h1>

              <Row className="mt-3">
                <Col lg={5} className={classes.videoContainer}>
                  <div className={classes.cardImage}>
                    <CardImage size={30} />
                  </div>
                </Col>
                <Col lg={7} className="mt-3">
                  <Row>
                    <Col>
                      <Skeleton />
                    </Col>
                    <Col className="text-right">
                      <Skeleton />
                    </Col>
                  </Row>

                  <Row className={`${classes.progressBarContainer} mt-4`}>
                    <Col>
                      <Skeleton height={30} />
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col xs={4} className={classes.statContainer}>
                      <Skeleton count={2} />
                    </Col>

                    <Col xs={4} className={classes.statContainer}>
                      <Skeleton count={2} />
                    </Col>

                    <Col xs={4} className={classes.statContainer}>
                      <Skeleton count={2} />
                    </Col>
                  </Row>
                  <Row className={classes.buttonContainer}>
                    <Col xs={4} className={classes.statContainer}>
                      <Skeleton height={30} />
                    </Col>
                    <Col xs={4} className={classes.statContainer}>
                      <Skeleton height={30} />
                    </Col>
                    <Col xs={4} className={classes.statContainer}>
                      <Skeleton height={30} />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Jumbotron>
          </Col>
        </Col>
      </Row>
    </Container>
  );
};

export { CampaignStatsSkeleton };
