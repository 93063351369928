import { PaymentSchedulePost } from '@root/modules/donation/types';
import axios, { AxiosRequestConfig } from 'axios';
import qs from 'qs';

export async function getDonationPageDetails(
  friendlyUrl: string,
  defaultDesignation?: string
) {
  let url = `/donation/getDonation/${friendlyUrl}`;
  if (defaultDesignation) {
    url += `?des=${defaultDesignation}`;
  }
  const { data } = await axios.get(url);
  return data;
}

export async function getPaymentSchedule(constituentId: string) {
  const url = `/donation/getPaymentSchedule/?constituentId=${constituentId}`;
  const { data } = await axios.get(url);
  return data.success ? data.data : null;
}

export async function updatePaymentSchedule(
  paymentSchedulePost: PaymentSchedulePost
) {
  const requestOptions: AxiosRequestConfig = {
    method: 'post',
    data: qs.stringify(paymentSchedulePost),
    url: '/Donation/UpdatePaymentSchedule',
  };
  const { data } = await axios(requestOptions);
  return data.success ? data.data : null;
}
