import * as React from 'react';
import { Spinner, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { RootState } from '@app/RootState';
import useStyles from './overlaySpinnerText.style';

export type Props = {
  loading: boolean;
  text?: string;
  color?: string;
  dataTestId?: string;
};

const OverlaySpinnerText: React.FC<Props> = ({
  loading = false,
  text = '',
  color = '',
  dataTestId = 'overlaySpinnerText',
}) => {
  const { ariaFocus } = useSelector((state: RootState) => state.donation);

  // Element Refs
  const focusRef = React.useRef(null);

  React.useEffect(() => {
    if (loading && ariaFocus === 0) {
      focusRef.current.focus();
    }
  });

  const classes = useStyles({ color });

  const rootProps = {
    className: classes.root,
    'data-testid': dataTestId,
  };

  return loading ? (
    <Row {...rootProps}>
      <Col className={classes.subRoot}>
        <Spinner className={classes.spinnerColor} animation="border" />
        <h2
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex={0}
          ref={focusRef}
          className={classes.text}
        >
          {text}
        </h2>
      </Col>
    </Row>
  ) : null;
};

export default OverlaySpinnerText;
