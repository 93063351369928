import { createUseStyles } from 'react-jss';
import { MissionTheme } from '@root/interfaces/MissionTheme';

export default createUseStyles<any, any>((theme: MissionTheme) => ({
  root: {
    margin: 0,
    backgroundColor: 'white',
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    opacity: 0.8,
    zIndex: 1000,
  },
  subRoot: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    textAlign: 'center',
    marginTop: '20px',
    color: 'black',
    fontWeight: 700,
  },
  spinnerColor: {
    color: (props: { color: string }) => props.color || theme.colorPrimary,
  },
}));
