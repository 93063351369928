import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import {
  setDonationAmount,
  setGivingFrequency,
  setGiftAidClaimAmount,
  setSelectedPackageId,
  setDonationGroup,
} from '@modules/campaign/campaignSlice';
import { GivingFrequency } from '@app/types';
import { CampaignPageDetails } from '@api/campaign';
import { RootState } from '@app/RootState';

const { lazy, Suspense } = React;
const Navigation = lazy(() => import('@components/molecules/navigation'));
const DonationGroup = lazy(() => import('@components/organism/donationGroup'));

interface DonationScreenProps {
  pageDetails: CampaignPageDetails;
}

const Donation: React.FC<DonationScreenProps> = ({ pageDetails }) => {
  const { t, i18n } = useTranslation();
  const locale = i18n.language;
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();
  const { selectedCampaignActionId, donationGroup } = useSelector(
    (state: RootState) => state.campaign
  );
  const nextScreen =
    pageDetails && pageDetails.showGiftAid ? 'giftAid' : 'contact';
  const { minimumAmount, defaultAmount, packageId, givingFrequencyCode } =
    pageDetails &&
    pageDetails.campaignPageActions.find(
      (x) => x.campaignPageActionId === selectedCampaignActionId
    );

  const frequencyCode =
    givingFrequencyCode === GivingFrequency.SingleOnly ||
    givingFrequencyCode === GivingFrequency.SingleDefaultAllowMonthly
      ? 'once'
      : 'monthly';
  const [stateDonationGroup, setStateDonationGroup] = React.useState({
    inputAmount: donationGroup ? donationGroup.donationAmount : defaultAmount,
    givingFrequency: donationGroup
      ? donationGroup.givingFrequency
      : frequencyCode,
  });

  const onChange = async (
    receivedInputAmount: string,
    receivedFrequency: string
  ) => {
    setStateDonationGroup({
      inputAmount: Number(receivedInputAmount),
      givingFrequency: receivedFrequency,
    });
  };

  const onSubmit = () => {
    dispatch(setDonationAmount(stateDonationGroup.inputAmount));
    dispatch(setGivingFrequency(stateDonationGroup.givingFrequency));
    dispatch(setGiftAidClaimAmount(stateDonationGroup.inputAmount * 0.25)); // 25% of the donation amount
    dispatch(setSelectedPackageId(packageId || ''));
    dispatch(
      setDonationGroup({
        donationAmount: stateDonationGroup.inputAmount,
        givingFrequency: stateDonationGroup.givingFrequency,
      })
    );
    history.push(nextScreen);
  };

  return (
    <Suspense fallback={<div>{t('loading')}</div>}>
      {pageDetails && pageDetails.campaignPageActions
        ? pageDetails.campaignPageActions.map((values) =>
            values.campaignPageActionId === selectedCampaignActionId &&
            values.defaultAmount &&
            values.minimumAmount &&
            values.givingFrequencyCode ? (
              <div key={values.campaignPageActionId}>
                <div style={{ marginTop: '40px' }} />
                <DonationGroup
                  locale={locale}
                  pageMinimumAmount={values.minimumAmount || 1}
                  inputAmountPassed={
                    stateDonationGroup
                      ? stateDonationGroup.inputAmount
                      : values.minimumAmount
                  }
                  givingFrequencyCode={values.givingFrequencyCode}
                  givingFrequency={
                    stateDonationGroup
                      ? stateDonationGroup.givingFrequency
                      : donationGroup && donationGroup.givingFrequency
                  }
                  customAmount={values.customAmount}
                  page={pageDetails}
                  onChange={onChange}
                />
              </div>
            ) : null
          )
        : null}
      <Navigation
        pageDetails={pageDetails}
        rightBtnOnClick={onSubmit}
        rightBtnDisabled={stateDonationGroup.inputAmount < minimumAmount}
        prev={`/Campaign/${match.params.friendlyUrl}/`}
      />
    </Suspense>
  );
};
export default Donation;
