import de from './de';
import en_CA from './en_CA';
import en_GB from './en_GB';
import en_US from './en_US';
import es from './es';
import fr_CA from './fr_CA';
import fr from './fr';
import nb_NO from './nb_NO';
import nl from './nl';

export default {
  de: { translation: de },
  'de-DE': { translation: de },
  'en-CA': { translation: en_CA },
  'en-GB': { translation: en_GB },
  'en-US': { translation: en_US },
  es: { translation: es },
  fr: { translation: fr },
  'fr-CA': { translation: fr_CA },
  'nb-NO': { translation: nb_NO },
  nl: { translation: nl },
};
