import * as React from 'react';
import { Image as BSImage, Card, Carousel, ImageProps } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@app/RootState';
import { fetchRemoteImage, fetchRemoteImageUrl } from './imageSlice';
import useStyles from './image.style';

export type Props = {
  height?: number | string;
  width?: number | string;
  remoteImageId?: string;
  remoteImageUrl?: string;
  card?: boolean;
  caption?: boolean | string;
} & ImageProps;

const Image: React.FC<Props> = ({
  height,
  width,
  remoteImageId,
  remoteImageUrl,
  caption = false,
  card = false,
  fluid = true,
  src,
  alt,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const image = useSelector(
    (state: RootState) => state.images.remoteImages[remoteImageId]
  );

  React.useEffect(() => {
    if (remoteImageId && !image) {
      dispatch(fetchRemoteImage(remoteImageId));
    }
  }, [remoteImageId, dispatch]);

  React.useEffect(() => {
    if (remoteImageUrl && !image) {
      dispatch(fetchRemoteImageUrl(remoteImageUrl));
    }
  }, [remoteImageUrl, dispatch]);

  if (!image && !src) {
    return <div>{t('loading_image')}</div>;
  }
  const renderImage = () =>
    card ? (
      <Card.Img
        className={classes.cardImage}
        variant="top"
        src={image ? image.imageValue : src}
        alt={image ? image.identifier : alt}
      />
    ) : (
      <BSImage
        height={height}
        width={width}
        src={image ? image.imageValue : src}
        alt={image ? image.identifier : alt}
        fluid={fluid}
      />
    );

  const renderCaption = () => {
    if (!caption) {
      return null;
    }
    let captionText = image && image.imageCaption ? image.imageCaption : null;
    if (!captionText && typeof caption === 'string') {
      captionText = caption;
    }
    return captionText ? (
      <Carousel.Caption className={classes.caption}>
        <p>{captionText}</p>
      </Carousel.Caption>
    ) : null;
  };

  return (
    <>
      {renderImage()}
      {renderCaption()}
    </>
  );
};

export default Image;
