import { createUseStyles } from 'react-jss';

export default createUseStyles<any, any>({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 3,
    marginBottom: 5,
  },
  dropDown: {
    paddingLeft: 10,
    border: '1px solid gray',
  },
  dropDownMenu: {
    width: '100%',
  },
  icon: {
    marginRight: 5,
    marginBottom: 2,
  },
});
