import * as yup from 'yup';

// Phone Regex
const phoneRegEx =
  /^((\+?\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}/;
export const createValidationSchema = (
  currentPage: string,
  mandateTelephone: boolean
) => {
  const validationObject: any = {
    email: yup
      .string()
      .email('Please enter a valid email address')
      .required('Phone number is required')
      .trim()
      .strict(),
  };

  if (currentPage !== 'forgot_password') {
    validationObject.password =
      currentPage === 'sign_up'
        ? yup
            .string()
            .required('password_validation')
            .matches(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
              'pwd_match'
            )
        : yup.string().required();
  }

  if (currentPage === 'sign_up') {
    validationObject.firstName = yup
      .string()
      .ensure()
      .required()
      .trim()
      .strict();
    validationObject.lastName = yup
      .string()
      .ensure()
      .required()
      .trim()
      .strict();
    if (mandateTelephone) {
      validationObject.telephone1 = yup
        .string()
        .matches(phoneRegEx, 'Phone number is not valid')
        .required('Phone number is required')
        .trim()
        .strict();
    }
  }

  return yup.object(validationObject);
};
