import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '@app/AppThunk';
import qs from 'qs';
import {
  getDonationPageDetails,
  getPaymentSchedule,
  updatePaymentSchedule,
} from '@api/donation';
import { setUpdateComplete, fetchContactPaymentMethods } from '@app/app.slice';
import { PaymentMethodPost, ContactDetails } from '@root/app/types';
import { createPaymentMethod } from '@root/api/contact';
import { PageKind } from '@root/enums';
import {
  DonationState,
  DonationPageDetails,
  PaymentSchedule,
  PaymentSchedulePost,
} from './types';

const queryString = qs.parse(window.location.search, {
  ignoreQueryPrefix: true,
});

const initialState: DonationState = {
  donationPageDetails: null,
  paymentSchedule: null,
  isLaoding: false,
  error: null,
  selectedAmount: false,
  progressIndicator: 'donate',
  designationId: null,
  greatestNeed: true,
  campaignId: queryString.cmp ? queryString.cmp : null,
  appealId: queryString.apl ? queryString.apl : null,
  packageId: queryString.pck ? queryString.pck : null,
  defaultDesignation: queryString.des ? queryString.des : null,
  queryString,
  expandedContent: false,
};

function startLoading(state: DonationState) {
  state.isLaoding = true;
}

function loadingFailed(state: DonationState, action: PayloadAction<string>) {
  state.isLaoding = false;
  state.error = action.payload;
}

const donationSlice = createSlice({
  name: 'donation',
  initialState,
  reducers: {
    setPaymentScheduleStart: startLoading,
    setPaymentScheduleSuccess(
      state: DonationState,
      action: PayloadAction<PaymentSchedule>
    ) {
      state.paymentSchedule = action.payload;
      state.isLaoding = false;
      state.error = null;
    },
    setPaymentScheduleFailure: loadingFailed,
    getDonationPageDetailsStart: startLoading,
    getDonationPageDetailsSuccess(
      state: DonationState,
      action: PayloadAction<DonationPageDetails>
    ) {
      state.donationPageDetails = action.payload;
      state.isLaoding = false;
      state.error = null;
    },
    getDonationPageDetailsFailure: loadingFailed,

    getPaymentScheduleStart: startLoading,
    getPaymentScheduleSuccess(
      state: DonationState,
      action: PayloadAction<PaymentSchedule>
    ) {
      state.paymentSchedule = action.payload;
      state.isLaoding = false;
      state.error = null;
    },
    getPaymentScheduleFailure: loadingFailed,
    setDesignationId(state, action: PayloadAction<string>) {
      state.designationId = action.payload;
    },
    setGreatestNeed(state, action: PayloadAction<boolean>) {
      state.greatestNeed = action.payload;
    },
    setProgressIndicator(state, action: PayloadAction<string>) {
      state.progressIndicator = action.payload;
    },
    setSelectedAmount(state, action: PayloadAction<boolean>) {
      state.selectedAmount = action.payload;
    },
    setExpandedContent(state, action: PayloadAction<boolean>) {
      state.expandedContent = action.payload;
    },
  },
});

const {
  getDonationPageDetailsFailure,
  getDonationPageDetailsStart,
  getDonationPageDetailsSuccess,
  getPaymentScheduleFailure,
  getPaymentScheduleStart,
  getPaymentScheduleSuccess,
  setPaymentScheduleStart,
  setPaymentScheduleFailure,
  setPaymentScheduleSuccess,
} = donationSlice.actions;

export const {
  setDesignationId,
  setGreatestNeed,
  setSelectedAmount,
  setProgressIndicator,
  setExpandedContent,
} = donationSlice.actions;

export default donationSlice.reducer;

// Fetching DonationPageDetails data
export const fetchDonationPageDetails =
  (friendlyUrl: string, defaultDesignation?: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getDonationPageDetailsStart());
      // Fetch donation page details
      const donationPageDetails: DonationPageDetails =
        await getDonationPageDetails(friendlyUrl, defaultDesignation);
      dispatch(
        getDonationPageDetailsSuccess({
          ...donationPageDetails,
          kind: PageKind.Donation,
        })
      );
    } catch (err) {
      dispatch(getDonationPageDetailsFailure(err.toString()));
    }
  };

// Fetching PaymentSchedule data
export const fetchPaymentSchedule =
  (constituentId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getPaymentScheduleStart());
      const paymentSchedule: PaymentSchedule = await getPaymentSchedule(
        constituentId
      );
      dispatch(getPaymentScheduleSuccess(paymentSchedule));
    } catch (err) {
      dispatch(getPaymentScheduleFailure(err.toString()));
    }
  };

// Post PaymentSchedule
export const postPaymentSchedule =
  (
    paymentSchedulePost: PaymentSchedulePost,
    contactDetails?: ContactDetails
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setPaymentScheduleStart());
      const resp = await updatePaymentSchedule(paymentSchedulePost);
      dispatch(setPaymentScheduleSuccess(resp));
      if (contactDetails && contactDetails.contactId) {
        dispatch(
          fetchContactPaymentMethods(contactDetails && contactDetails.contactId)
        );
        dispatch(
          fetchPaymentSchedule(contactDetails && contactDetails.contactId)
        );
      }

      dispatch(setUpdateComplete(true));
      setTimeout(() => {
        dispatch(setUpdateComplete(false));
      }, 5000);
    } catch (error) {
      dispatch(setPaymentScheduleFailure(error.toString()));
    }
  };

export const newPaymentMethod =
  (
    paymentMethod: PaymentMethodPost,
    contactDetails?: ContactDetails
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setPaymentScheduleStart());
      const resp = await createPaymentMethod(paymentMethod);
      if (resp) {
        dispatch(setPaymentScheduleSuccess(resp));
        dispatch(
          fetchPaymentSchedule(contactDetails && contactDetails.contactId)
        );
        dispatch(
          fetchContactPaymentMethods(contactDetails && contactDetails.contactId)
        );
      } else {
        dispatch(
          setPaymentScheduleFailure('Failed to update the payment info.')
        );
      }
    } catch (error) {
      dispatch(setPaymentScheduleFailure(error.toString()));
    }
  };
