import { createUseStyles } from 'react-jss';
import { MissionTheme } from '@root/interfaces/MissionTheme';

export default createUseStyles<any, any>((theme: MissionTheme) => ({
  root: {
    flexShrink: 0,
    // marginLeft: '10px',
    // marginRight: '10px',
    width: ({ width }) => width || '100%',
    height: '468px',
    borderBottom: '8px solid #999999',
  },
  message: {
    fontSize: '0.9rem',
    lineHeight: 'normal',
    marginBottom: '10px',
  },

  btn: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    marginRight: '20px',
    marginLeft: '20px',
    marginBottom: '20px',
    marginTop: '10px',
  },
  imagePlaceholder: {
    position: 'relative',
    // top: 0,
    height: '182px',
    overflow: 'hidden',
    // display: 'flex',
    // justifyContent: 'center',
    backgroundColor: (props: { color: string }) =>
      props.color || theme.colorPrimary,
  },
}));
