import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Col, InputGroup } from 'react-bootstrap';
import { EyeFill, EyeSlashFill } from 'react-bootstrap-icons';
import { TransactionCurrency } from '@app/App';
import FloatingLabel from '@components/atoms/floatingLabel';
import cx from 'classnames';
import { useMediaQuery } from 'react-responsive';
import useStyles from './inputFormGroup.styles';

export type Props = {
  label?: string;
  ariaLabel?: string;
  value: string;
  controlId: string;
  handleChange?: any;
  handleBlur?: any;
  handleClear?: any;
  onKeyPress?: any;
  onKeyUp?: any;
  showError?: any;
  feedback?: string;
  mutedText?: string;
  maxLength?: number;
  type?: string;
  xs?: number;
  xsOffset?: number;
  sm?: number;
  md?: number;
  inputFieldSize?: string;
  color?: string;
  helpText?: string;
  isInvalid?: boolean;
  currencyField?: boolean;
  passwordField?: boolean;
  toggleShowPassword?: any;
  showPassword?: boolean;
  showClearBtn?: boolean;
  showErrorStyle?: boolean;
  receivedRef?: any;
  dataTestId?: string;
  as?: any;
  rows?: any;
  placeholder?: any;
  // states
  valid?: boolean;
  error?: boolean;
  disabled?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>;

const voidFunction = () => {};

const InputFormGroup: React.FC<Props> = ({
  label = 'label',
  ariaLabel = '',
  value = '',
  controlId = '',
  handleChange = voidFunction,
  handleBlur = voidFunction,
  onKeyPress = voidFunction,
  onKeyUp = voidFunction,
  showError = null,
  feedback = 'Invalid value',
  mutedText = '',
  maxLength,
  type = 'text',
  inputFieldSize,
  xs = 0,
  xsOffset = 0,
  sm = 0,
  md = 0,
  color = '',
  helpText = '',
  currencyField = false,
  passwordField = false,
  toggleShowPassword = voidFunction,
  showPassword = false,
  showErrorStyle = true,
  isInvalid = false,
  receivedRef,
  className = null,
  as,
  rows,
  placeholder,
  // states
  valid = false,
  disabled = false,
  autoComplete,
}) => {
  const { t } = useTranslation();
  const transactionCurrency = React.useContext(TransactionCurrency);
  const { currencySymbol } = transactionCurrency;
  const classes = useStyles({ controlId, passwordField, currencyField });
  const isMd = useMediaQuery({
    query: '(min-width: 768px)',
  });

  const formGroupClasses = cx(className, {
    [classes.default]: true,
    [classes.valid]: valid,
    // [classes.active]: active,
    [classes.error]: showError,
    [classes.disabled]: disabled,
  });

  const regex = new RegExp('([*$])');

  return (
    <Form.Group
      as={Col}
      md={md}
      xs={{ span: xs, offset: xsOffset }}
      sm={sm}
      controlId={controlId}
      className={formGroupClasses}
    >
      <FloatingLabel
        label={label}
        value={value}
        controlId={controlId}
        color={color}
        helpText={helpText}
        mutedText={mutedText}
        isInvalid={isInvalid || showError || !showErrorStyle}
        inputFieldSize={inputFieldSize}
        currencyField={currencyField}
      >
        <InputGroup className={classes.inputGroup}>
          {currencyField && (
            <InputGroup.Prepend>
              <InputGroup.Text>{currencySymbol}</InputGroup.Text>
            </InputGroup.Prepend>
          )}
          <Form.Control
            autoComplete={autoComplete}
            aria-label={
              regex.test(label)
                ? label.replace('*', ` ${t('aria_required')}`)
                : ariaLabel || label
            }
            ref={receivedRef}
            size={inputFieldSize === 'lg' ? 'lg' : null}
            type={type}
            step={type === 'number' ? '1' : undefined}
            name={controlId}
            maxLength={maxLength}
            value={value}
            onChange={handleChange}
            onKeyPress={onKeyPress}
            onKeyUp={onKeyUp}
            onBlur={handleBlur}
            as={as}
            rows={rows}
            placeholder={placeholder}
            isInvalid={isInvalid || showError || !showErrorStyle}
            disabled={disabled}
            className={classes.formControl}
          />

          {passwordField && (
            <InputGroup.Append
              onClick={() => toggleShowPassword(showPassword, controlId)}
              style={{ cursor: 'pointer' }}
            >
              <InputGroup.Text className={classes.passwordFieldText}>
                {showPassword ? <EyeFill /> : <EyeSlashFill />}
              </InputGroup.Text>
            </InputGroup.Append>
          )}
          {showErrorStyle && (
            <Form.Control.Feedback type="invalid">
              {feedback}
            </Form.Control.Feedback>
          )}
        </InputGroup>

        <Form.Text
          className="text-muted"
          style={{ width: isMd ? '710px' : '100%' }}
        >
          {mutedText}
        </Form.Text>
      </FloatingLabel>
    </Form.Group>
  );
};

export default React.memo(InputFormGroup);
