import * as React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { CardImage } from 'react-bootstrap-icons';
import Skeleton from '@components/atoms/skeleton';

import useStyles from './actionGroup.style';

const ActionGroupSkeleton = () => {
  const classes = useStyles({});
  return (
    <Row className={classes.root}>
      <Col>
        <ActionSkeleton />
      </Col>
      <Col>
        <ActionSkeleton />
      </Col>
      <Col>
        <ActionSkeleton />
      </Col>
      <Col>
        <ActionSkeleton />
      </Col>
    </Row>
  );
};

const ActionSkeleton = () => {
  const classes = useStyles({});
  return (
    <Card>
      <div className={classes.cardImage}>
        <CardImage size={30} />
      </div>
      <Card.Body>
        <Skeleton />
        <hr />
        <Skeleton count={4} />
      </Card.Body>
    </Card>
  );
};

export { ActionGroupSkeleton };
