import { PageKind } from '@root/enums';
import { DonationPageBackgroundCollection } from '@root/interfaces';

import defaults from './defaults';
import createDonationPageStyles from './createDonationPageStyles';

const theme = (backgrounds?: DonationPageBackgroundCollection) => {
  return {
    defaults,
    [PageKind.Donation]: createDonationPageStyles(backgrounds),
    [PageKind.Campaign]: {
      ...defaults,
      isCP: true,
      buttonProps: {
        background: 'black',
        color: '#fff',
      },
    },
    [PageKind.Event]: {
      ...defaults,
    },
  };
};

export default theme;
