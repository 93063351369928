import * as React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { RootState } from '@app/RootState';
import { PageKind } from '@root/enums';
import CampaignMessage from '@components/atoms/campaignMessage';
import CampaignActionHeader from '@components/atoms/campaignActionHeader';
import CampaignRouter from '@modules/campaign/router';
import globalStyles from '@styles/global';

import { CampaignStatsSkeleton } from '@components/organism/campaignStats/campaignStats.skeleton';
import { CampaignMessageSkeleton } from '@components/atoms/campaignMessage/campaignMessage.skeleton';
import { HeaderSkeleton } from '@root/components/molecules/header/header.skeleton';
import { FooterSkeleton } from '@components/atoms/footer/footer.skeleton';
import { CarouselSkeleton } from '@components/molecules/carousel/carousel.skeleton';
import SignInModal from '@components/organism/signInModal';
import CampaignForm from '@components/organism/campaignForm';
import { CampaignPageTypes } from '@modules/campaign/campaignSlice';
import { getOrderTotal } from '@utils/index';

import Header from '@root/components/molecules/header';
import Footer from '@components/atoms/footer';
import OrderSummary from '@components/atoms/orderSummary';

interface CampaignLayoutScreenProps {
  friendlyUrl?: any;
  classes: any;
  contentSpan: number;
  showGivingAmount: boolean;
}

const { lazy, Suspense } = React;
const CampaignStats = lazy(() => import('@components/organism/campaignStats'));
const EventOverview = lazy(() => import('@components/organism/eventOverview'));
const P2pDetails = lazy(() => import('@components/organism/p2pDetails'));

const LeaderboardsDetails = lazy(
  () => import('@components/organism/leaderboardsDetails')
);
const CampaignSupportersDetails = lazy(
  () => import('@components/organism/campaignSupportersDetails')
);

const CampaignLayout: React.FC<CampaignLayoutScreenProps> = ({
  friendlyUrl,
  classes,
  contentSpan = 12,
  showGivingAmount = false,
}) => {
  const [formType, setFormType] = React.useState(0);
  const [showSignIn, setShowSignIn] = React.useState<boolean>(false);
  const [showCampaignForm, setShowCampaignForm] =
    React.useState<boolean>(false);
  const [showSupportMessages, setShowSupportMessages] =
    React.useState<boolean>(false);

  const {
    campaignPageDetails,
    supportMessages,
    loadingSupportMessages,
    isLoading,
    donationAmount,
    selectedMembership,
    givingFrequency,
  } = useSelector((state: RootState) => state.campaign);

  const { coverCosts } = useSelector((state: RootState) => state.app);

  const { isLoggedIn, profile } = useSelector((state: RootState) => state.user);

  let updatedDonationAmount = donationAmount;

  if (selectedMembership && selectedMembership.includeMembership) {
    let membershipAmount =
      selectedMembership.membershipBaseAmount +
      selectedMembership.membershipAmountTax;
    if (givingFrequency !== 'once') {
      membershipAmount /= 12;
    }
    updatedDonationAmount = donationAmount - membershipAmount;
  }

  const orderTotal = getOrderTotal({
    coverCosts,
    pageDetails: campaignPageDetails,
    donationAmount: updatedDonationAmount,
    selectedMembership,
    givingFrequency,
    pageKind: PageKind.Campaign,
  });

  const onShowSignIn = () => setShowSignIn(true);
  const onCloseSignIn = () => {
    setShowSignIn(false);
  };
  const onShowCampaignForm = () => setShowCampaignForm(true);
  const onCloseCampaignForm = () => setShowCampaignForm(false);
  const onFormType = (type) => {
    setFormType(type);
  };

  const campaignPageType = null;
  const childFriendlyUrl = null;
  globalStyles();

  React.useEffect(() => {
    if (
      campaignPageDetails &&
      (campaignPageDetails.enableFundraising ||
        (campaignPageDetails.parentCampaignPage &&
          campaignPageDetails.parentCampaignPage.enableFundraising)) &&
      supportMessages &&
      supportMessages.data &&
      supportMessages.data.campaignPageDonations &&
      supportMessages.data.campaignPageDonations.length
    ) {
      setShowSupportMessages(true);
    } else {
      setShowSupportMessages(false);
    }
  }, [supportMessages, campaignPageDetails]);

  React.useEffect(() => {
    if (profile && showSignIn && profile) {
      if (formType !== 0) {
        setShowCampaignForm(true);
      }
      setShowSignIn(false);
    } else {
      setFormType(0);
    }
  }, [profile]);

  return (
    <>
      <Container className={classes.container}>
        <Suspense fallback={<HeaderSkeleton />}>
          {isLoading ? (
            <HeaderSkeleton />
          ) : (
            <Header
              page={campaignPageDetails}
              onShowSignIn={onShowSignIn}
              isLoggedIn={isLoggedIn}
              updatedData={supportMessages && supportMessages.data}
            />
          )}
        </Suspense>
        <Row>
          <Suspense fallback={<CampaignStatsSkeleton />}>
            {isLoading ? (
              <CampaignStatsSkeleton />
            ) : (
              <CampaignStats
                campaignPageDetails={campaignPageDetails}
                updatedData={supportMessages && supportMessages.data}
                loadingUpdatedData={loadingSupportMessages}
                onShowSignIn={onShowSignIn}
                onShowCampaignForm={onShowCampaignForm}
                onSelect={onFormType}
                childPage={
                  campaignPageDetails &&
                  campaignPageDetails.campaignPageTypeId &&
                  campaignPageDetails.campaignPageTypeId !==
                    CampaignPageTypes.Master
                }
              />
            )}
          </Suspense>
        </Row>

        <Row>
          <Container as="main" className={classes.main} fluid>
            <Container fluid>
              <Suspense fallback={<CampaignMessageSkeleton />}>
                {isLoading ? (
                  <CampaignMessageSkeleton />
                ) : (
                  <>
                    {campaignPageDetails &&
                    ((campaignPageDetails.enableFundraising &&
                      campaignPageDetails.campaignPageTypeId !== null) ||
                      campaignPageDetails.campaignPageTypeId ===
                        CampaignPageTypes.Team) ? (
                      <P2pDetails
                        page={campaignPageDetails}
                        loadingChildPages={loadingSupportMessages}
                        childPages={
                          supportMessages &&
                          supportMessages.data &&
                          supportMessages.data.childCampaignPages
                        }
                      />
                    ) : (
                      <CampaignMessage
                        headerMessage={
                          campaignPageDetails
                            ? campaignPageDetails.headerMessage
                            : null
                        }
                        message={
                          campaignPageDetails
                            ? campaignPageDetails.message
                            : null
                        }
                      />
                    )}
                  </>
                )}
              </Suspense>
            </Container>

            <Container className={classes.actionContainer} fluid>
              <Suspense fallback={<div> </div>}>
                {!isLoading &&
                  campaignPageDetails &&
                  campaignPageDetails.campaignPageTypeId ===
                    CampaignPageTypes.Master &&
                  (campaignPageDetails.allowIndividualCampaigns ||
                    campaignPageDetails.allowTeamCampaigns) && (
                    <LeaderboardsDetails
                      page={campaignPageDetails}
                      loadingChildPages={loadingSupportMessages}
                      childPages={
                        supportMessages &&
                        supportMessages.data &&
                        supportMessages.data.childCampaignPages
                      }
                    />
                  )}
              </Suspense>
            </Container>

            <Container className={classes.campaignSupportersContainer} fluid>
              <Suspense fallback={<div> </div>}>
                {!isLoading && showSupportMessages && (
                  <CampaignSupportersDetails
                    page={
                      supportMessages &&
                      supportMessages.data &&
                      supportMessages.data.campaignPageDonations
                    }
                  />
                )}
              </Suspense>
            </Container>

            <Suspense fallback={<div> </div>}>
              {!isLoading && (
                <EventOverview
                  event={campaignPageDetails ? campaignPageDetails.event : null}
                />
              )}
            </Suspense>

            <Container
              id="actions"
              className={`${classes.actionContainer} ${classes.screensPadding}`}
              fluid
            >
              <CampaignActionHeader
                actionHeader={
                  campaignPageDetails ? campaignPageDetails.actionHeader : null
                }
              />

              <Row
                className={`${classes.actionScreen} ${classes.screens}  justify-content-center`}
              >
                <Col className={classes.colPadding} lg={contentSpan}>
                  {showGivingAmount && (
                    <OrderSummary totalAmount={orderTotal} />
                  )}
                  <CampaignRouter
                    campaignPageDetails={campaignPageDetails}
                    friendlyUrl={friendlyUrl}
                    campaignPageType={campaignPageType}
                    childFriendlyURL={childFriendlyUrl}
                    isLoading={isLoading}
                    orderTotal={orderTotal}
                  />
                </Col>
              </Row>
            </Container>
          </Container>
        </Row>
        <Row>
          <Container fluid className={classes.carouselContainer}>
            <Suspense fallback={<CarouselSkeleton />}>
              {isLoading ? (
                <CarouselSkeleton />
              ) : (
                campaignPageDetails &&
                campaignPageDetails.carouselImageIds &&
                campaignPageDetails.carouselImageIds.length > 0 && (
                  <CampaignSupportersDetails
                    page={campaignPageDetails}
                    type="images"
                    imageIds={campaignPageDetails.carouselImageIds}
                  />
                )
              )}
            </Suspense>
          </Container>
        </Row>
        <Suspense fallback={<FooterSkeleton />}>
          {isLoading ? (
            <FooterSkeleton />
          ) : (
            <Footer
              page={campaignPageDetails}
              color="#e0e0e0"
              elHeight="auto"
            />
          )}
        </Suspense>
      </Container>
      <Suspense fallback={null}>
        {showSignIn ? (
          <SignInModal
            show
            onClose={onCloseSignIn}
            configurationId={campaignPageDetails.configurationId}
            mandateTelephone={campaignPageDetails.mandateTelephone}
            showTelephone={campaignPageDetails.showTelephone}
          />
        ) : null}
      </Suspense>
      {campaignPageDetails ? (
        <CampaignForm
          show={showCampaignForm}
          pageType={formType}
          page={campaignPageDetails}
          handleClose={onCloseCampaignForm}
          modalType={
            campaignPageDetails.campaignPageTypeId === CampaignPageTypes.Team
              ? 'joinTeam'
              : 'startFundraising'
          }
        />
      ) : null}
    </>
  );
};

export default CampaignLayout;
