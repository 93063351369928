import * as React from 'react';
import { Form, Col, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  // PaymentDetails,
  PaymentMethod,
  PaymentMethodsType,
  PaymentMethodPost,
  ContactDetails,
} from '@app/types';
import {
  DonationPageDetails,
  PaymentSchedule,
  PaymentSchedulePost,
} from '@modules/donation/types';
import { DonationPageType } from '@root/enums';
import {
  postPaymentSchedule,
  newPaymentMethod,
} from '@modules/donation/donation.slice';

import { RootState } from '@app/RootState';
// import { getContactDetailsSuccess } from '@root/app/app.slice';
import OverlaySpinnerText from '@components/atoms/overlaySpinnerText';

const { lazy, Suspense } = React;
const CreditCardForm = lazy(
  () => import('@components/organism/creditCardForm')
);
const Navigation = lazy(() => import('@components/molecules/navigation'));

interface UpdatePaymentInfoProps {
  pageDetails: DonationPageDetails;
  donationPageTypeId: DonationPageType;
}

const UpdatePaymentInfo: React.FC<UpdatePaymentInfoProps> = ({
  pageDetails,
  donationPageTypeId,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const paymentMethods: PaymentMethod[] = useSelector(
    (state: RootState) => state.app.paymentMethods
  );
  const contactDetails: ContactDetails = useSelector(
    (state: RootState) => state.app.contactDetails
  );
  const paymentSchedule: PaymentSchedule = useSelector(
    (state: RootState) => state.donation.paymentSchedule
  );
  const isLoading = useSelector((state: RootState) => state.donation.isLaoding);
  const error = useSelector((state: RootState) => state.donation.error);
  const [useExistingCreditCard, setUseExistingCreditCard] =
    React.useState(false);
  const [paymentMethodId, setPaymentMethodId] = React.useState(null);
  // const [creditCardNumber, setCreditCardNumber] = React.useState(null);
  // const [expMM, setExpMM] = React.useState(null);
  // const [expYY, setExpYY] = React.useState(null);

  const handleSubmit = (e) => {
    if (useExistingCreditCard && paymentMethodId) {
      e.preventDefault();
      const paymentSchedulePost: PaymentSchedulePost = {
        paymentScheduleId: paymentSchedule.paymentScheduleId,
        paymentMethodId: paymentMethodId,
      };
      paymentSchedulePost.paymentMethodId = paymentMethodId;

      dispatch(postPaymentSchedule(paymentSchedulePost, contactDetails));
    } else {
      const paymentMethod: PaymentMethodPost = {
        contactId: contactDetails.contactId,
        donationPageId: pageDetails.donationPageId,
        paymentScheduleId: paymentSchedule.paymentScheduleId,
        isoCurrencyCode: pageDetails.transactionCurrency.isoCurrencyCode,
        creditCardNo: e.creditCardNo,
        cvc: e.cvc,
        expMM: e.expMM,
        expYY: e.expYY,
      };
      dispatch(newPaymentMethod(paymentMethod, contactDetails));
    }
  };

  return (
    <div>
      <OverlaySpinnerText
        text={t('processingPaymentSchedule')}
        loading={isLoading}
      />
      {!paymentMethods || !paymentSchedule ? (
        <Alert variant="danger">{t('notPaymentMethodsMessage')}</Alert>
      ) : (
        <>
          {error && <Alert variant="danger">{error}</Alert>}

          {paymentMethods && paymentMethods.length > 0 && (
            <Form.Row>
              <Form.Group as={Col} controlId="useExistingCreditCard">
                <Form.Check
                  checked={useExistingCreditCard}
                  label={t('use_existing_credit_card')}
                  onChange={() =>
                    setUseExistingCreditCard(!useExistingCreditCard)
                  }
                />
              </Form.Group>
            </Form.Row>
          )}
          {useExistingCreditCard ? (
            <Form noValidate onSubmit={handleSubmit}>
              <Suspense fallback={<div>{t('loading')}</div>}>
                <Form.Row>
                  <Form.Group as={Col} controlId="paymentMethodId">
                    <Form.Control
                      as="select"
                      onChange={(e) =>
                        setPaymentMethodId(
                          e.target.value === t('select') ? null : e.target.value
                        )
                      }
                    >
                      <option value={null}>{t('select')}</option>
                      {paymentMethods &&
                        paymentMethods
                          .filter(
                            (x) => x.type === PaymentMethodsType.CreditCard
                          )
                          .map((x) => (
                            <option
                              key={x.paymentMethodId}
                              value={x.paymentMethodId}
                            >
                              {x.name}
                            </option>
                          ))}
                    </Form.Control>
                  </Form.Group>
                </Form.Row>
                <Navigation
                  pageDetails={pageDetails}
                  rightBtnClass="submit-button"
                  type="submit"
                  rightBtnText="Update Credit Card"
                  rightBtnDisabled={
                    !!(
                      useExistingCreditCard &&
                      (paymentMethodId === t('select') ||
                        paymentMethodId === null)
                    )
                  }
                  leftBtnHidden
                  showArrows={false}
                />
              </Suspense>
            </Form>
          ) : (
            <Suspense fallback={<div>{t('loading')}</div>}>
              <CreditCardForm
                page={pageDetails}
                handleSubmit={handleSubmit}
                submitLabel="Update Credit Card"
                donationPageTypeId={donationPageTypeId}
              />
            </Suspense>
          )}
        </>
      )}
    </div>
  );
};

export default UpdatePaymentInfo;
