import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  AddressVerificationServiceType,
  PaymentGatewayType,
  PaymentCountryContextCode,
  CustomerType,
} from '@root/enums';
import { CampaignPageDetails, AddressVerification } from '@api/campaign';
import { ContactDetails } from '@app/types';
import {
  getContactDetailsSuccess,
  setAddressSuggestions,
  setCustomerDetails,
  fetchVerificationAddressResults,
} from '@app/app.slice';
import { CampaignPageTypes } from '@modules/campaign/campaignSlice';
import { ContactFormSkeleton } from '@components/organism/contactForm/contactForm.skeleton';
import { RootState } from '@app/RootState';
import { addressToBilling, objectToFormData } from '@utils/index';

const { lazy, Suspense } = React;
const ContactForm = lazy(() => import('@components/organism/contactForm'));
const OverlaySpinnerText = lazy(
  () => import('@components/atoms/overlaySpinnerText')
);

interface ContactScreenProps {
  pageDetails: CampaignPageDetails;
}

const Contact: React.FC<ContactScreenProps> = ({ pageDetails }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const prevScreen =
    pageDetails && pageDetails.showGiftAid ? 'giftAid' : 'donation';
  const [loading, setLoading] = React.useState(false);
  const [p2p, setP2P] = React.useState(false);
  const [country, setCountry] = React.useState(null);
  const [addressResutls, setAddressResults] = React.useState(null);
  const [addressVerificationService, setAddressVerificationService] =
    React.useState(null);

  const {
    contactDetails,
    updateCompleted,
    addressSuggestions,
    verificationAddressResult,
    stripeIntent,
  } = useSelector((state: RootState) => state.app);

  React.useEffect(() => {
    if (
      (pageDetails.campaignPageTypeId === CampaignPageTypes.Master &&
        pageDetails.enableFundraising) ||
      pageDetails.parentCampaignPageId
    ) {
      setP2P(true);
    }
    if (pageDetails.addressVerification) {
      switch (true) {
        case pageDetails.addressVerification ===
          AddressVerificationServiceType.Melissa:
          setAddressVerificationService(pageDetails.addressVerification);
          break;
        case pageDetails.addressVerification ===
          AddressVerificationServiceType.CanadaPost:
          setAddressVerificationService(pageDetails.addressVerification);
          break;
        default:
          setAddressVerificationService(null);
      }
    }
    switch (true) {
      case pageDetails.transactionCurrency.isoCurrencyCode === 'USD':
        setCountry(PaymentCountryContextCode.US);
        break;
      case pageDetails.transactionCurrency.isoCurrencyCode === 'CAD':
        setCountry(PaymentCountryContextCode.CA);
        break;
      default:
        setCountry(null);
        break;
    }
  }, [pageDetails]);

  React.useEffect(() => {
    if (verificationAddressResult) {
      const results = JSON.parse(verificationAddressResult.data.results);
      if (
        verificationAddressResult.errorType === 'complete' &&
        contactDetails &&
        contactDetails.next !== 'screen'
      ) {
        if (
          addressVerificationService === AddressVerificationServiceType.Melissa
        ) {
          dispatch(setAddressSuggestions(results || null));
        }
        const address = results[0];
        // FOR CANADA POST
        dispatch(
          getContactDetailsSuccess({
            ...contactDetails,
            address1Line1: address.Line1,
            address1City: address.City,
            address1StateOrProvince: address.Province,
            address1PostalCode: address.PostalCode,
            address1Country: address.CountryName,
          })
        );
      } else if (verificationAddressResult.errorType !== 'no-match') {
        dispatch(setAddressSuggestions(results || []));
      }
    }
  }, [verificationAddressResult]);

  const onSubmit = async (values: ContactDetails) => {
    const token = 'localhost';
    const contactDetailsPost: any = addressToBilling(values);
    dispatch(getContactDetailsSuccess(values));
    if (pageDetails.paymentGatewayCode === PaymentGatewayType.Stripe) {
      dispatch(
        setCustomerDetails({
          ...stripeIntent,
          customerDetails: {
            customerName: `${values.firstName} ${values.lastName}` || null,
            customerEmail: values.emailAddress1 || null,
            customerPhone: values.telephone1 || null,
            customerId: null,
            customerType:
              values.isCompanyGift === 'yes' && values.organizationName
                ? CustomerType.Account
                : CustomerType.Contact,
          },
          address: {
            city: values.address1City || null,
            country: PaymentCountryContextCode[country],
            line1: values.address1Line1 || null,
            line2: values.address1Line2 || null,
            postalCode: values.address1PostalCode || null,
            state: values.address1StateOrProvince || null,
          },
        })
      );
    }
    if (values && values.next === 'complete') {
      dispatch(getContactDetailsSuccess(values));
      return;
    }

    const orderDetails: AddressVerification = {
      ...contactDetailsPost,
      campaignPageId: pageDetails.campaignPageId,
      canadaPostId: values.canadaPostId,
      action: 'autocomplete',
      next: values.next === 'screen' ? 'Retrieve' : values.next,
    };

    const formData: FormData = objectToFormData(orderDetails);
    if (token != null) {
      dispatch(fetchVerificationAddressResults(formData, 'Campaign'));
    }

    if (values && values.next === 'screen') {
      setLoading(true);
      dispatch(getContactDetailsSuccess({ ...contactDetails, ...values }));

      let next;
      if (
        pageDetails.showMembership &&
        pageDetails.membershipGroups &&
        pageDetails.membershipGroups.length > 0
      ) {
        next = 'membership';
      } else {
        next = p2p ? 'showSupport' : 'payment';
      }
      history.push(next);
    }
  };

  React.useEffect(() => {
    if (loading && !updateCompleted) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [updateCompleted, loading]);

  React.useEffect(() => {
    if (addressSuggestions) {
      setAddressResults(addressSuggestions);
    }
  }, [addressSuggestions]);

  return (
    <Suspense fallback={<ContactFormSkeleton />}>
      <OverlaySpinnerText text={t('processingCD')} loading={loading} />

      <ContactForm
        page={pageDetails}
        contactDetails={contactDetails}
        selectedNewGift
        addressSuggestions={addressResutls}
        onSubmit={onSubmit}
        prevScreen={prevScreen}
      />
    </Suspense>
  );
};

export default Contact;
