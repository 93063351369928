import { createUseStyles } from 'react-jss';
import { MissionTheme } from '@root/interfaces/MissionTheme';

export default createUseStyles<any, any>((theme: MissionTheme) => ({
  'select-group': (props?: { isCol: boolean }) => {
    return theme.isDP
      ? {
          // ...theme.dropdown.defaults,
          '&:after': {
            content: '""',
            'pointer-events': 'none',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            right: props && props.isCol ? 5 : '.75rem',
            width: 50,
            height: '100%',
            backgroundImage: `
              linear-gradient(45deg, transparent 50%, ${
                theme.setButtonTextColor
                  ? theme.buttonTextColor
                  : theme.colorPrimary
              } 50%),
              linear-gradient(135deg, ${
                theme.setButtonTextColor
                  ? theme.buttonTextColor
                  : theme.colorPrimary
              } 50%, transparent 50%),
              linear-gradient(to right, transparent, transparent)`,
            backgroundPosition: `
              calc(100% - 20px) calc(1em + 2px),
              calc(100% - 15px) calc(1em + 2px),
              100% 0`,
            backgroundSize: `
              5px 5px,
              5px 5px,
              2.5em 2.5em`,
            backgroundRepeat: 'no-repeat',
          },
        }
      : {};
  },

  'has-value': () =>
    theme.isDP
      ? {
          // ...theme.dropdown.active,
          '&:after': {
            backgroundImage: `
            linear-gradient(45deg, transparent 50%, #fff 50%),
            linear-gradient(135deg, #fff 50%, transparent 50%),
            linear-gradient(to right, transparent, transparent)`,
          },
        }
      : {},
  'select-box': () => {
    return theme.isDP
      ? {
          height: 'auto',
          '&, &:focus, &:active': {
            outline: 'none',
            boxShadow: 'none',
            borderColor: 'transparent',
            '-webkit-appearance': 'none',
            '-moz-appearance': 'none',
            background: 'transparent',
            // color: theme.dropdown.defaults.color,
            // fontStyle: theme.dropdown.defaults.fontStyle,
            ...theme.dropdown.defaults,
          },

          '$has-value &': {
            // color: theme.dropdown.active.color,
            // fontStyle: theme.dropdown.active.fontStyle,
            ...theme.dropdown.active,
          },
        }
      : {};
  },
  'select-option': () =>
    theme.isDP
      ? {
          color: theme.colorPrimary,
          fontStyle: 'normal',
          '&:hover': {
            background: 'red',
          },
        }
      : {},
}));
