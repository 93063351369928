import * as React from 'react';
import { Alert, Form, Col, Spinner } from 'react-bootstrap';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@app/RootState';
import { login, signup, forgotPassword } from '@modules/user/user.slice';

import { createValidationSchema } from './validationSchema';
import useStyles from './signInForm.style';

const { lazy, Suspense } = React;
const InputFormGroup = lazy(
  () => import('@components/molecules/inputFormGroup')
);
const Button = lazy(() => import('@components/atoms/button'));

export type Props = {
  onChangePage: any;
  configurationId?: string;
  mandateTelephone: boolean;
  showTelephone: boolean;
};

const SignInForm: React.FC<Props> = ({
  onChangePage = () => {},
  configurationId = null,
  mandateTelephone = false,
  showTelephone = false,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { error, isLoading, passwordResetRequested } = useSelector(
    (state: RootState) => state.user
  );
  const [showPassword, setShowPassword] = React.useState(false);
  const [initialValues, setInitialValues] = React.useState({});
  const [currentPage, setCurrentPage] = React.useState('sign_in');
  const [validationSchema, setValidationSchema] = React.useState(
    createValidationSchema(currentPage, mandateTelephone)
  );
  const [passwordError, setPasswordError] = React.useState(
    passwordResetRequested
  );

  React.useEffect(() => {
    setPasswordError(passwordResetRequested);
  }, [passwordResetRequested]);

  const handleFormSubmit = (values) => {
    if (currentPage === 'sign_in') {
      dispatch(login(values.email, values.password));
    } else if (currentPage === 'sign_up') {
      dispatch(
        signup(
          values.email,
          values.password,
          values.firstName,
          values.lastName,
          values.telephone1,
          configurationId
        )
      );
    } else if (currentPage === 'forgot_password') {
      dispatch(forgotPassword(values.email));
    }
  };

  React.useEffect(() => {
    let tempInitialValues: any = { email: '', password: '' };
    if (currentPage === 'sign_up') {
      tempInitialValues = {
        ...tempInitialValues,
        firstName: '',
        lastName: '',
        telephone1: '',
      };
    } else if (currentPage === 'forgot_password') {
      delete tempInitialValues.password;
    }
    setInitialValues(tempInitialValues);
    setValidationSchema(createValidationSchema(currentPage, mandateTelephone));
    onChangePage(currentPage);
  }, [currentPage]);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  function formatPhoneNumber(value) {
    if (!value) return value;
    const cardNumber = value.replace(/[^\d]/g, '');
    return `${cardNumber.slice(0, 16)}`;
  }

  const showError = (error, touched) => {
    let showError: boolean = false;
    if (error && touched) {
      showError = true;
    }
    return showError;
  };

  const classes = useStyles();

  return initialValues ? (
    <Formik
      validationSchema={validationSchema}
      onSubmit={handleFormSubmit}
      initialValues={initialValues}
      enableReinitialize
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        setFieldValue,
      }) => (
        <Form noValidate onSubmit={handleSubmit} style={{ paddingTop: 15 }}>
          {error && error !== '401_error' && !isLoading ? (
            <Alert variant="danger">{t(error)}</Alert>
          ) : null}
          {!error && passwordError && currentPage === 'forgot_password' ? (
            <Alert
              variant="success"
              onClose={() => setPasswordError(null)}
              dismissible
            >
              {t('password_reset_success', { email: passwordError })}
            </Alert>
          ) : null}
          <Suspense fallback={<div>{t('loading')}</div>}>
            {currentPage === 'sign_up' && (
              <>
                <Form.Row>
                  <InputFormGroup
                    autoComplete="given-name"
                    type="search"
                    maxLength={80}
                    label={`${t('first_name')}*`}
                    value={values.firstName}
                    controlId="firstName"
                    xs={12}
                    inputFieldSize="lg"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    showError={showError(errors.firstName, touched.firstName)}
                    feedback={t('first_name_validation')}
                  />
                </Form.Row>
                <Form.Row>
                  <InputFormGroup
                    autoComplete="family-name"
                    type="search"
                    maxLength={100}
                    label={`${t('last_name')}*`}
                    value={values.lastName}
                    controlId="lastName"
                    xs={12}
                    inputFieldSize="lg"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    showError={showError(errors.lastName, touched.lastName)}
                    feedback={t('last_name_validation')}
                  />
                </Form.Row>
                {showTelephone && (
                  <Form.Row>
                    <InputFormGroup
                      autoComplete="tel"
                      label={mandateTelephone ? `${t('phone')}*` : t('phone')}
                      value={values.telephone1}
                      controlId="telephone1"
                      type="search"
                      maxLength={50}
                      xs={12}
                      inputFieldSize="lg"
                      handleChange={(e) => {
                        setFieldValue(
                          'telephone1',
                          formatPhoneNumber(e.target.value)
                        );
                      }}
                      handleBlur={handleBlur}
                      showError={showError(
                        errors.telephone1,
                        touched.telephone1
                      )}
                      feedback={t('phone_validation')}
                    />
                  </Form.Row>
                )}
              </>
            )}

            <Form.Row>
              <InputFormGroup
                autoComplete="email"
                type="search"
                maxLength={100}
                label={`${t('email')}*`}
                value={values.email}
                controlId="email"
                xs={12}
                inputFieldSize="lg"
                handleChange={handleChange}
                handleBlur={handleBlur}
                showError={showError(errors.email, touched.email)}
                feedback={t('email_validation')}
              />
            </Form.Row>

            {currentPage !== 'forgot_password' && (
              <Form.Row>
                <InputFormGroup
                  autoComplete={
                    currentPage === 'sign_up'
                      ? 'new-password'
                      : 'current-password'
                  }
                  label={`${t('password')}*`}
                  type={showPassword ? 'search' : 'password'}
                  maxLength={250}
                  passwordField
                  showPassword={showPassword}
                  toggleShowPassword={toggleShowPassword}
                  value={values.password}
                  controlId="password"
                  xs={12}
                  inputFieldSize="lg"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  showError={showError(errors.password, touched.password)}
                  feedback={t(errors.password ? `${errors.password}` : '')}
                />
              </Form.Row>
            )}

            {currentPage === 'sign_in' && (
              <Form.Row>
                <Form.Group as={Col} className={classes.forgotPass}>
                  <p
                    onClick={() => setCurrentPage('forgot_password')}
                    className={classes.linkBtn}
                  >
                    {t('forgot_password')}
                  </p>
                </Form.Group>
              </Form.Row>
            )}

            <Button
              type="submit"
              disabled={
                currentPage === 'forgot_password'
                  ? !values.email
                  : (currentPage === 'sign_up' &&
                      (!values.firstName ||
                        !values.lastName ||
                        (mandateTelephone && !values.telephone1))) ||
                    !values.email ||
                    !values.password
              }
            >
              {isLoading ? (
                <Spinner animation="border" role="status">
                  <span className="sr-only">{t('loading')}</span>
                </Spinner>
              ) : (
                t(
                  (currentPage === 'forgot_password' &&
                    'forgot_password_btn') ||
                    currentPage
                )
              )}
            </Button>
            <hr />
            <Form.Row>
              <Form.Group as={Col} className={classes.signUpCol}>
                {configurationId &&
                  currentPage !== 'forgot_password' &&
                  t(currentPage === 'sign_in' ? 'dont_have' : 'already_have')}
                <p
                  onClick={() =>
                    setCurrentPage(
                      (currentPage === 'sign_in' && 'sign_up') ||
                        (currentPage === 'sign_up' && 'sign_in') ||
                        (currentPage === 'forgot_password' && 'sign_in')
                    )
                  }
                  className={classes.linkBtn}
                >
                  {!configurationId
                    ? t((currentPage === 'forgot_password' && 'go_back') || '')
                    : t(
                        (currentPage === 'sign_in' && 'sign_up') ||
                          (currentPage === 'sign_up' && 'sign_in') ||
                          (currentPage === 'forgot_password' && 'go_back')
                      )}
                </p>
              </Form.Group>
            </Form.Row>
          </Suspense>
        </Form>
      )}
    </Formik>
  ) : null;
};

export default SignInForm;
