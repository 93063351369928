import * as React from 'react';
import { useRouteMatch, useParams, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ThemeProvider } from 'react-jss';
import { TransactionCurrency } from '@app/App';

import { defaultTheme } from '@styles/themes';

import CampaignLayout from '@components/layouts/campaignLayout';
import ErrorPage from '@components/organism/errorPage';

import { IRouteParams } from '@app/IRouteParams';
import { RootState } from '@app/RootState';
import i18n from '@root/i18n';
import {
  setContactSignUp,
  setPaymentNotice,
  setDoubleTheDonation,
} from '@app/app.slice';
import {
  fetchCampaignPageDetails,
  fetchSupportMessages,
} from '@modules/campaign/campaignSlice';

import { fetchUserProfile } from '@modules/user/user.slice';

import { PageKind } from '@root/enums';
import loadTheme from '@root/style/loadTheme';
import { routes } from './router';
import useStyles from './campaign.style';

export const BASE_PATH = '/Campaign/:friendlyUrl+';

const CampaignModule = () => {
  const location = useLocation();
  const { friendlyUrl, campaignPageType } = useParams<IRouteParams>();
  const [theme, setTheme] = React.useState(defaultTheme);
  const pageType = campaignPageType || 'master';
  const dispatch = useDispatch();

  const { clientCode, doubleTheDonation } = useSelector(
    (state: RootState) => state.app
  );

  const {
    campaignPageDetails,
    errorPage,
    error: campaignDetailsError,
  } = useSelector((state: RootState) => state.campaign);

  React.useEffect(() => {
    const actionRoute = friendlyUrl.substr(
      friendlyUrl.lastIndexOf('/') + 1,
      friendlyUrl.lastIndexOf('/') + friendlyUrl.length
    );
    let isActionRoute = false;
    if (actionRoute === '') {
      isActionRoute = false;
    } else {
      isActionRoute = routes.includes(
        routes.find((route) => route.path === actionRoute)
      );
    }

    if (!isActionRoute) {
      dispatch(
        fetchCampaignPageDetails(
          pageType === 'master' &&
            routes.includes(routes.find((route) => route.path === actionRoute))
            ? friendlyUrl.substr(0, friendlyUrl.lastIndexOf('/') + 1)
            : friendlyUrl
        )
      );
      dispatch(
        fetchSupportMessages(
          pageType === 'master' &&
            routes.includes(routes.find((route) => route.path === actionRoute))
            ? friendlyUrl.substr(0, friendlyUrl.lastIndexOf('/') + 1)
            : friendlyUrl
        )
      );
    }
  }, [friendlyUrl, dispatch]);

  React.useEffect(() => {
    if (
      campaignPageDetails &&
      campaignPageDetails.languageCode &&
      campaignPageDetails.languageCode !== i18n.language
    ) {
      i18n.changeLanguage(campaignPageDetails.languageCode);
      if (campaignPageDetails && campaignPageDetails.enableFundraising) {
        dispatch(fetchUserProfile());
      }
    }
    if (campaignPageDetails) {
      if (campaignPageDetails.showPaymentNotice) {
        dispatch(
          setPaymentNotice(
            !!(
              campaignPageDetails.paymentNotice &&
              campaignPageDetails.showPaymentNotice &&
              campaignPageDetails.setAcceptNotice
            )
          )
        );
      }

      if (campaignPageDetails.signup) {
        dispatch(setContactSignUp(campaignPageDetails.setSignUp));
      }

      if (
        !doubleTheDonation.active &&
        campaignPageDetails.showDoubleTheDonation &&
        campaignPageDetails.doubleTheDonationPublicKey
      ) {
        dispatch(
          setDoubleTheDonation({
            ...doubleTheDonation,
            active: true,
          })
        );
      }
    }
  }, [campaignPageDetails]);

  React.useEffect(() => {
    if (clientCode && campaignPageDetails) {
      const clientTheme = loadTheme({
        clientCode,
        pageKind: PageKind.Campaign,
      });

      setTheme(clientTheme);
    }
  }, [clientCode, campaignPageDetails]);

  let matchedRoute = null;
  let showGivingAmount = false;
  routes.forEach((route) => {
    const currentRoute = useRouteMatch(`${BASE_PATH}/${route.path}`);

    if (currentRoute && route.component !== 'Actions') {
      matchedRoute = 'matched';
      if (
        currentRoute.path === `${BASE_PATH}/contact` ||
        currentRoute.path === `${BASE_PATH}/payment` ||
        currentRoute.path === `${BASE_PATH}/membership` ||
        currentRoute.path === `${BASE_PATH}/showSupport`
      ) {
        showGivingAmount = true;
      }
    }
  });

  const classes = useStyles({ matchedRoute });

  const pathParts = location.pathname.split('/');
  const currentRoute = pathParts[pathParts.length - 1];
  let contentSpan = 12;
  if (currentRoute === 'contact') {
    contentSpan = 10;
  } else if (matchedRoute) {
    contentSpan = 8;
  }

  const currencySymbol =
    campaignPageDetails &&
    campaignPageDetails.transactionCurrency &&
    campaignPageDetails.transactionCurrency.currencySymbol
      ? campaignPageDetails.transactionCurrency.currencySymbol
      : '$';
  const isoCurrencyCode =
    campaignPageDetails &&
    campaignPageDetails.transactionCurrency &&
    campaignPageDetails.transactionCurrency.isoCurrencyCode
      ? campaignPageDetails.transactionCurrency.isoCurrencyCode
      : 'CAD';
  const languageCode =
    (campaignPageDetails && campaignPageDetails.languageCode) || 'en-CA';

  const transactionCurrency = {
    currencySymbol,
    isoCurrencyCode,
    languageCode,
  };

  return errorPage === '404_error' ? (
    <ErrorPage error={campaignDetailsError} />
  ) : (
    <ThemeProvider theme={theme}>
      <TransactionCurrency.Provider value={transactionCurrency}>
        <CampaignLayout
          classes={classes}
          friendlyUrl={friendlyUrl}
          contentSpan={contentSpan}
          showGivingAmount={showGivingAmount}
        />
      </TransactionCurrency.Provider>
    </ThemeProvider>
  );
};

export default CampaignModule;
