import { createUseStyles } from 'react-jss';
import { MissionTheme } from '@root/interfaces/MissionTheme';

export default createUseStyles<any, any>((theme: MissionTheme) => ({
  formHelp: (props: { inputField: boolean; innerPosition: boolean }) => {
    const rightPosition = theme.isDP && props.innerPosition ? '10px' : '-15px';
    return props.inputField
      ? {
          position: 'absolute',
          right: rightPosition,
          top: '18px',
          transform: 'translateY(-50%)',
        }
      : {};
  },
}));
