import { createUseStyles } from 'react-jss';
import { MissionTheme } from '@root/interfaces/MissionTheme';

export default createUseStyles<any, any>((theme: MissionTheme) => ({
  forgotPass: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  signUpCol: {
    display: 'flex',
    justifyContent: 'center',
  },
  signUpLink: {
    marginLeft: 5,
  },
  linkBtn: {
    marginBottom: 0,
    marginLeft: 5,
    color: theme.colorPrimary,
    cursor: 'pointer',
    '&:hover': {
      color: '#176cc0',
      textDecoration: 'underline',
    },
  },
}));
