import * as React from 'react';
import { Row, Col } from 'react-bootstrap';
import Skeleton from '@components/atoms/skeleton';
import useStyles from './ocpLayout.style';

const DonationOCPSkeleton = () => {
  const classes = useStyles();
  return (
    <>
      <Row>
        <Col>
          <div className={classes.opcGateSkeleton}>
            <Skeleton height={30} />
          </div>
        </Col>
        <Col xs="2">
          <div className={classes.opcGateSkeleton}>
            <Skeleton height={30} />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={classes.opcGateSkeleton}>
            <Skeleton height={30} />
          </div>
        </Col>
      </Row>
    </>
  );
};

export { DonationOCPSkeleton };
