import axios, { AxiosRequestConfig } from 'axios';
import qs from 'qs';
import { ContactDetails, PaymentMethodPost } from '@app/types';

export async function search(constituentNumber: string) {
  const { data } = await axios.get(
    `/contact/search?constituentNumber=${constituentNumber}`
  );
  if (data.success) {
    return data.data;
  }
  throw new Error(data.message);
}

export async function updateContactDetails(contactDetailsPost: ContactDetails) {
  const requestOptions: AxiosRequestConfig = {
    method: 'post',
    data: qs.stringify(contactDetailsPost),
    url: '/Donation/UpdateContact',
  };
  const { data } = await axios(requestOptions);
  return data.success ? data.data : null;
}

export async function getPaymentMethods(constituentId: string) {
  const { data } = await axios.get(
    `/contact/getPaymentMethods?constituentId=${constituentId}`
  );
  if (data.success) {
    return data.data;
  }
  throw new Error(data.message);
}

export async function createPaymentMethod(paymentMethod: PaymentMethodPost) {
  const requestOptions: AxiosRequestConfig = {
    method: 'post',
    data: qs.stringify(paymentMethod),
    url: '/Donation/CreatePaymentMethod',
  };
  const { data } = await axios(requestOptions);
  return data.success ? data.data : null;
}
