import axios from 'axios';

export interface RemoteImage {
  imageId?: string;
  fileName?: string;
  identifier?: string;
  imageValue?: string;
  imageCaption?: string;
}

export interface RemoteImageCollection {
  [imageId: string]: RemoteImage;
}

export async function getRemoteImage(imageId: string) {
  const url = `/image/getImage/${imageId}`;
  const { data } = await axios.get<RemoteImage>(url);
  return data;
}

export async function getRemoteImageUrl(path: string) {
  const url = `/image/getImageUrl?path=${path}`;
  const { data } = await axios.get<string>(url);

  return { imageId: path, imageValue: data };
}
