import * as React from 'react';
import { Button as BSButton } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useSelector } from 'react-redux';
import { RootState } from '@app/RootState';
import cx from 'classnames';
import useStyles from './button.style';

export type Props = {
  children: React.ReactNode;
  onClick?: () => void;
  id?: string;
  to?: string;
  type?: string;
  block?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  dataTestId?: string;
  variant?: string;
  buttonStyles?: any;
  elMinHeight?: string | number;
  active?: boolean;
  isDP?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const Button: React.FC<Props> = ({
  children,
  onClick = () => ({}),
  to = null,
  id = null,
  block = false,
  disabled = false,
  hidden = false,
  type = 'submit',
  dataTestId = 'button',
  variant = null,
  elMinHeight,
  buttonStyles,
  active = false,
  isDP = false,
}) => {
  const classes = useStyles({
    hidden,
    buttonStyles,
    isDP,
    block,
  });

  const { ariaFocus } = useSelector((state: RootState) => state.donation);

  const handleClick = () => {
    if (!disabled && onClick) onClick();
  };

  const rootProps: any = {
    type,
    onClick: handleClick,
    disabled,
    hidden,
    block,
    'data-testid': dataTestId,
    variant: variant || 'custom',
  };

  if (!variant) {
    rootProps.className = cx({
      [classes.default]: !active && !disabled,
      [classes.focus]: (ariaFocus === 2 && type === 'submit') || active,
      [classes.active]: active,
      [classes.disabled]: disabled,
    });
  }

  return to ? (
    <LinkContainer to={to} style={{ minHeight: elMinHeight && elMinHeight }}>
      <BSButton {...rootProps}>
        <span className={classes.label}>{children}</span>
      </BSButton>
    </LinkContainer>
  ) : (
    <BSButton
      id={id}
      tabIndex={0}
      {...rootProps}
      style={{ minHeight: elMinHeight && elMinHeight }}
    >
      <span className={classes.label}>{children}</span>
    </BSButton>
  );
};

export default Button;
