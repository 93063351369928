import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Parser from 'html-react-parser';
import { Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { TransactionCurrency } from '@app/App';
import {
  getProcessingResult,
  createPaymentLogObject,
  setApplicationInsightsLog,
} from '@api/app';
import { setCoverCosts, setCompletionDetails } from '@app/app.slice';
import { CampaignPageDetails, CampaignPagePost } from '@api/campaign';
import { RootState } from '@app/RootState';
import { objectToFormData } from '@utils/index';
import { SignUp, PageKind, PaymentType } from '@root/enums';
import { FrequencyType } from '@root/enums/FrequencyType';

const { lazy, Suspense } = React;
const PaymentDetails = lazy(
  () => import('@components/organism/paymentDetails')
);
const OverlaySpinnerText = lazy(
  () => import('@components/atoms/overlaySpinnerText')
);
interface PaymentScreenProps {
  pageDetails: CampaignPageDetails;
  orderTotal?: number;
}

const Payment: React.FC<PaymentScreenProps> = ({ pageDetails, orderTotal }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const transactionCurrency = React.useContext(TransactionCurrency);
  const { languageCode } = transactionCurrency;
  const history = useHistory();
  const {
    donationAmount,
    giftAidCheckbox,
    giftAidClaimAmount,
    givingFrequency,
    selectedMembership,
    selectedPackageId,
    packageId,
    campaignId,
    appealId,
    designationId,
    supportDetails,
    supportMessages,
  } = useSelector((state: RootState) => state.campaign);

  const {
    contactSignUp,
    contactDetails,
    acceptNotice,
    coverCosts,
    completionDetails,
  } = useSelector((state: RootState) => state.app);

  const [loading, setLoading] = React.useState(false);
  const [submitResult, setSubmitResult] = React.useState(null);
  const [navigator, setNavigator] = React.useState<string>(null);

  const creditCard =
    pageDetails && pageDetails.showCreditCard ? 'creditCard' : null;
  const googlePay = pageDetails && pageDetails.showGoogle ? 'googlePay' : null;

  const failureMessage =
    pageDetails &&
    pageDetails.termsOfReference &&
    pageDetails.termsOfReference.failureMessage;

  React.useEffect(() => {
    if (pageDetails) {
      dispatch(
        setCoverCosts(
          !!(
            pageDetails &&
            pageDetails.termsOfReference &&
            pageDetails.termsOfReference.coverCostsMessage &&
            pageDetails.showCoverCosts &&
            pageDetails.setCoverCosts
          )
        )
      );
    }
  }, []);

  React.useEffect(() => {
    if (!completionDetails) {
      return;
    }

    setSubmitResult(null);
    setLoading(false);
    history.push('confirmation');
  }, [completionDetails]);

  React.useEffect(() => {
    if (!navigator && window && window.navigator) {
      const navigatorDetails: any = {};
      const { connection, deviceMemory } = window.navigator;
      if (connection || deviceMemory) {
        Object.assign(navigatorDetails, {
          downlink: connection.downlink || null,
          effectiveType: connection.effectiveType || null,
          onchange: connection.onchange || null,
          rtt: connection.rtt || null,
          saveData: connection.saveData || null,
          deviceMemory: deviceMemory || null,
        });
        setNavigator(JSON.stringify(navigatorDetails));
      }
    }
  }, [window && window.navigator, navigator]);

  const onSubmit = async (updatedPaymentDetails: any, token: string) => {
    setLoading(true);
    const currentDateTime = new Date();
    const isRecurringPayment = givingFrequency !== 'once';
    let updatedDonationAmount = donationAmount;

    if (selectedMembership && selectedMembership.includeMembership) {
      let membershipAmount =
        selectedMembership.membershipBaseAmount +
        selectedMembership.membershipAmountTax;
      if (givingFrequency !== 'once') {
        membershipAmount /= 12;
      }
      updatedDonationAmount = donationAmount - membershipAmount;
    }
    const membershipBaseAmount =
      (selectedMembership &&
        (isRecurringPayment
          ? selectedMembership.membershipBaseAmount / 12
          : selectedMembership.membershipBaseAmount)) ||
      0;

    const membershipAmountTax =
      (selectedMembership &&
        (isRecurringPayment
          ? selectedMembership.membershipAmountTax / 12
          : selectedMembership.membershipAmountTax)) ||
      0;

    const fundraisingTotal =
      (supportMessages &&
        supportMessages.data &&
        supportMessages.data.totalAmountRaised) + updatedDonationAmount;

    const orderDetails: CampaignPagePost = {
      campaignPageId: pageDetails.campaignPageId,
      packageId: selectedPackageId,
      recaptchaToken: token,
      isRecurring: isRecurringPayment,
      organizationName: contactDetails.organizationName || '',
      myEmployer:
        contactDetails.employerMatchesDonationCheckbox &&
        contactDetails.employer
          ? contactDetails.employer
          : '',
      amount: updatedDonationAmount.toFixed(2),
      billingCity: contactDetails.address1City || '',
      billingCountry: contactDetails.address1Country || '',
      billingLine1: contactDetails.address1Line1 || '',
      billingLine2: contactDetails.address1Line2 || '',
      billingPostalCode: contactDetails.address1PostalCode || '',
      billingStateOrProvince: contactDetails.address1StateOrProvince || '',
      coverCostsCheckbox: coverCosts ? 'on' : 'off',
      costPercentage:
        pageDetails.coverCostPercentage && `${pageDetails.coverCostPercentage}`,
      costAmount:
        pageDetails.coverCostAmount && `${pageDetails.coverCostAmount}`,
      isoCurrencyCode: pageDetails.transactionCurrency.isoCurrencyCode || '',
      creditCardNo: updatedPaymentDetails.creditCardNo || '',
      cvc: updatedPaymentDetails.cvc || '',
      dedicate: contactDetails.dedicate || '',
      emailAddress1: contactDetails.emailAddress1 || '',
      employerMatchesDonationCheckbox:
        contactDetails.employerMatchesDonationCheckbox ? 'on' : 'off',
      expMM: updatedPaymentDetails.expMM || '',
      expYY: updatedPaymentDetails.expYY || '',
      firstName: contactDetails.firstName || '',
      giftAidAcceptCheckbox: giftAidCheckbox ? 'on' : 'off',
      giftAidClaimAmount: giftAidCheckbox ? giftAidClaimAmount : 0,
      lastName: contactDetails.lastName,
      nameofHonouree: contactDetails.nameOfHonouree || '',
      recipientEmail: contactDetails.recipientEmail || '',
      salutation: contactDetails.salutation || '',
      paymentNoticeCheckbox:
        acceptNotice || updatedPaymentDetails.acceptNoticeCheckbox
          ? 'on'
          : 'off',
      signUpCheckbox:
        contactSignUp === null
          ? ''
          : (contactSignUp && true && SignUp.Yes) || SignUp.No,
      specialMessage: contactDetails.specialMessage || '',
      telephone1: contactDetails.telephone1 || '',
      matchDonationCheckbox: contactDetails.employerMatchesDonationCheckbox
        ? 'on'
        : 'off',
      anonymousCheckbox: contactDetails.anonymousCheckbox,
      membershipAmount:
        (selectedMembership && selectedMembership.membershipAmount) || 0,
      membershipAmountTax: Number(membershipAmountTax.toFixed(2)),
      membershipBaseAmount: Number(membershipBaseAmount.toFixed(2)),
      membershipCategoryId:
        (selectedMembership && selectedMembership.membershipId) || '',
      fundraisingTotal: fundraisingTotal.toFixed(2),
      name: pageDetails.displayTitle,
      acknowledgementPaymentIdentityType: 0,
    };

    // Tribute
    if (contactDetails.dedicate) {
      orderDetails.nameofHonouree = contactDetails.nameOfHonouree;
      if (contactDetails.recipientEmail) {
        orderDetails.recipientEmail = contactDetails.recipientEmail;
      }
      if (
        contactDetails.tribute &&
        (contactDetails.tribute === 'email' ||
          contactDetails.tribute === 'mail')
      ) {
        orderDetails.tributeOptionsCode =
          contactDetails.tribute === 'email' ? 703650000 : 703650001;
      }
      if (contactDetails.tributeAddressee) {
        orderDetails.tributeAddressee = contactDetails.tributeAddressee;
        orderDetails.tributeLine1 = contactDetails.tributeLine1 || '';
        orderDetails.tributeLine2 = contactDetails.tributeLine2 || '';
        orderDetails.tributeCity = contactDetails.tributeCity;
        orderDetails.tributeStateOrProvince =
          contactDetails.tributeStateOrProvince;
        orderDetails.tributePostalCode = contactDetails.tributePostalCode;
        orderDetails.tributeCountry = contactDetails.tributeCountry;
      }

      if (contactDetails.specialMessage) {
        orderDetails.specialMessage = contactDetails.specialMessage;
      }
    }

    if (
      contactDetails.doubleDonationCompanyId &&
      contactDetails.doubleDonationCompanyName
    ) {
      orderDetails.doubleTheDonation = true;
    }

    if (isRecurringPayment) {
      orderDetails.frequencyType = FrequencyType.Monthly; // Will need to be updated once recurring annually is added to Campaign Page
    }

    if (updatedPaymentDetails && updatedPaymentDetails.designationId) {
      orderDetails.designationId = updatedPaymentDetails.designationId;
    }

    if (updatedPaymentDetails && updatedPaymentDetails.payToken) {
      orderDetails.payToken = updatedPaymentDetails.payToken;
    }

    if (updatedPaymentDetails && updatedPaymentDetails.paymentType) {
      orderDetails.paymentType = updatedPaymentDetails.paymentType;
    }

    if (updatedPaymentDetails && updatedPaymentDetails.stripeId) {
      orderDetails.stripeId = updatedPaymentDetails.stripeId;
    }

    if (updatedPaymentDetails && updatedPaymentDetails.paymentStatus) {
      orderDetails.paymentStatus = updatedPaymentDetails.paymentStatus;
    }

    if (
      contactDetails.organizationName !== null &&
      contactDetails.organizationName !== '' &&
      contactDetails.isCompanyGift === 'yes'
    ) {
      orderDetails.isCompanyGift = true;
    }

    if (appealId) {
      orderDetails.appealId = appealId;
    }

    if (packageId) {
      orderDetails.packageId = packageId;
    }

    if (campaignId) {
      orderDetails.campaignId = campaignId;
    }

    if (designationId) {
      orderDetails.designationId = designationId;
    }

    if (supportDetails) {
      orderDetails.supportDetails = supportDetails;
    }

    const runApplicationLog = async (message) => {
      const formData: FormData = objectToFormData({
        pageId: pageDetails.campaignPageId,
        message: message,
      });
      const applicationLog = await setApplicationInsightsLog(
        formData,
        PageKind[PageKind.Campaign]
      );
      return applicationLog;
    };

    const runCreatePaymentLogObject = async (formData) => {
      const logResult = await createPaymentLogObject(
        formData,
        PageKind[PageKind.Campaign]
      );
      return logResult;
    };

    const formData: FormData = objectToFormData(orderDetails);

    if (token != null) {
      setSubmitResult(null);
      const runPaymentProcessing = async (formData) => {
        const result = await getProcessingResult(
          formData,
          PageKind[PageKind.Campaign]
        );
        return result;
      };
      runPaymentProcessing(formData)
        .then((result) => {
          if (result && result.success) {
            dispatch(
              setCompletionDetails({
                contactDetails: {
                  ...contactDetails,
                },
                pageId:
                  pageDetails.kind === PageKind.Donation
                    ? pageDetails.donationPageId
                    : pageDetails.campaignPageId,
                pageKind: pageDetails.kind,
                amount: orderDetails.amount,
                frequencyType: !isRecurringPayment
                  ? null
                  : FrequencyType.Monthly,
                recurring: isRecurringPayment,
                paymentType: PaymentType[orderDetails.paymentType],
                transctionId: result.data,
                currentDateTime: currentDateTime.toString(),
              })
            );
          } else {
            setLoading(false);
            setSubmitResult('failed');
          }
        })
        .catch((err) => {
          console.error('Error:', err);
          formData.append('error', err.toString());
          formData.append('navigator', navigator);

          runApplicationLog(
            `Error: ${err.toString()} Navigator: ${navigator && navigator}`
          )
            .then(() => {
              runCreatePaymentLogObject(formData)
                .then(() => {
                  setLoading(false);
                  history.push('unexpectedError');
                })
                .catch(() => {
                  setLoading(false);
                  history.push('unexpectedError');
                });
            })
            .catch(() => {
              runCreatePaymentLogObject(formData)
                .then(() => {
                  setLoading(false);
                  history.push('unexpectedError');
                })
                .catch(() => {
                  setLoading(false);
                  history.push('unexpectedError');
                });
            });
        });
    }
  };

  return (
    <Suspense fallback={<div>{t('loading')}</div>}>
      {submitResult && (
        <Alert variant="danger">
          {(failureMessage && Parser(failureMessage)) || t('failure_message')}
        </Alert>
      )}
      <OverlaySpinnerText text={t('processing')} loading={loading} />
      <GoogleReCaptchaProvider
        reCaptchaKey={pageDetails.recaptchaSiteKey}
        useEnterprise
        language={
          languageCode === 'en-CA' ||
          languageCode === 'en-US' ||
          languageCode === 'en-AU' ||
          languageCode === 'en-GB'
            ? 'en'
            : languageCode
        }
      >
        <PaymentDetails
          page={pageDetails}
          onSubmit={onSubmit}
          processingError={!!submitResult}
          paymentMethod={creditCard || googlePay}
          orderTotal={orderTotal}
        />
      </GoogleReCaptchaProvider>
    </Suspense>
  );
};
export default Payment;
