import { createUseStyles } from 'react-jss';

export default createUseStyles<any, any>(() => ({
  lable: {
    marginTop: '2rem',
  },
  amountTotals: {
    marginTop: 20,
  },
}));
