import { MissionTheme } from '../../interfaces/MissionTheme';
import defaultStyle from './default';

const theme: MissionTheme = {
  ...defaultStyle,
  colorPrimary: '#ec4d30',
  colorSecondary: '#7c7c7c',
  headerBackgroundColor: 'white',
  headerTitleColor: '#7c7c7c',
};

export default theme;
