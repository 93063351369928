import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { CampaignPageDetails } from '@api/campaign';

export type RouteProps = {
  campaignPageDetails: CampaignPageDetails;
  component: any;
  path: string;
  friendlyUrl: string;
  campaignPageType?: string;
  childFriendlyURL?: string;
  url: string;
  isLoading: boolean;
  orderTotal?: number;
};

const CampaignRoute: React.FC<RouteProps> = ({
  campaignPageDetails,
  component,
  campaignPageType,
  childFriendlyURL,
  path,
  url,
  isLoading,
  orderTotal,
}) => {
  const Component = component;
  // Actions Route
  if (Component.name === 'Actions') {
    return (
      <Route path={path}>
        {url.charAt(url.length - 1) === '/' ? (
          <Component
            pageDetails={campaignPageDetails}
            isLoading={isLoading}
            orderTotal={orderTotal}
          />
        ) : (
          <Redirect to={`${url}/`} />
        )}
      </Route>
    );
  }

  let redirectUrl = url.substr(0, url.lastIndexOf('/') + 1);

  if (campaignPageType && childFriendlyURL) {
    redirectUrl += `${campaignPageType}/${childFriendlyURL}/`;
  }

  return (
    <Route path={path}>
      {campaignPageDetails ? (
        <Component
          pageDetails={campaignPageDetails}
          isLoading={isLoading}
          orderTotal={orderTotal}
        />
      ) : (
        <Redirect to={`${redirectUrl}`} />
      )}
    </Route>
  );
};

export default CampaignRoute;
