// ENGLISH CANADA (en_CA)

export default {
  // START Aria translation
  // aria_enter: 'Beginning donation section',
  // aria_flow:
  // 'Starting {{routeName}} section. Section {{sectionIndex}} of {{totalSteps}}',
  input_amount_field_name: 'Input Amount Field',
  aria_input_amount_field:
    'Input field: Enter custom donation amount (to the nearest dollar)',
  aria_amount_summary: 'Order amount summary',
  aria_member_summary: 'Membership amount summary',
  aria_help_icon: 'Information/Help Icon',
  aria_required: 'Required',
  aria_designation_button: 'Designate to',
  aria_designation_selection_label: 'Select another designation',
  aria_year: '2 digit expiry year',
  aria_month: '2 digit expiry month',
  aria_security_code:
    'Credit card security code. 3 or 4 digit code found on the back of your card',
  aria_unexpected_error_link:
    'Please click here and contact our donor services team immediately.',
  // END Aria translation
  internatl_error_500:
    '500 - Internal Server Error Occurred. Please contact our team immediately',
  stripe_promise_error: `Failed to load Stripe Promise`,
  loading: 'Loading...',
  loading_image: 'Loading image...',
  verification: 'Verification in progress...',
  timeout: 'A timeout error has occurred. Please try again later.',
  company: 'Company Name',
  company_valication: 'Please enter the Company Name',
  company_help_text:
    'The Company or Organization name which will appear on the acknowledgement and/or receipt.',
  employer: 'My Employer',
  salutation: 'Salutation',
  first_name: 'First Name',
  first_name_validation: 'Please enter your first name',
  last_name: 'Last Name',
  last_name_validation: 'Please enter your last name',
  email: 'Email',
  email_validation: 'Please enter a valid email address',
  email_help_text:
    'Your acknowledgement and/or tax receipt will be emailed here.',
  phone: 'Phone',
  phone_validation:
    'Please enter a valid phone number without decimals or dashes',
  address: 'Address',
  address2: 'Address 2',
  address_validation: 'Please enter your address',
  suite: 'Suite',
  city: 'City',
  city_validation: 'Please enter your city',
  province: 'Province',
  province_validation: 'Please enter your Province',
  postal_code: 'Postal Code',
  postal_code_validation: 'Please enter a valid postal code',
  country: 'Country',
  country_validation: 'Please enter a valid country',
  amount_raised: 'Amount Raised',
  goal: 'Goal',
  dedication_checkbox: 'Yes, my donation is in honour or in memory of someone',
  honour_dedication: 'In honour of...',
  memory_dedication: 'In memory of...',
  show_honour_message:
    "You've indicated you're making this donation in honour of someone",
  show_memory_message:
    "You've indicated you're making this donation in memory of someone",
  honouree_label: 'Please enter their name',
  honouree_validation: 'Please enter a valid name',
  recipient_email: "Enter the recipient's email address",
  special_message: 'Share your special message here. (Optional)',
  acknowledgment_checkbox: `Yes, I’d like to send a notification of my gift`,
  recipient_email_validation:
    "Please enter recipient's email or physical address",
  special_message_validation: 'Please enter a message for the recipient',
  employer_matches_checkbox: 'My employer will match donations',
  anonymousHelptext:
    'This will hide your name and donor information from all public communications; however, the charity will still receive your information.',
  giftAid_checkbox:
    "Yes, I'd like to add a gift aid of <1>{{gift}}</1> (25% of the donation) on top of my <2>{{donation}}</2> donation.",
  month: 'Month',
  year: 'Year',
  privacy_policy: 'Privacy Policy',
  terms_conditions: 'Terms and Conditions',
  minimum_amount: 'Minimum amount',
  donate_btn: 'Donate',
  start_p2p_btn: 'Start Fundraising',
  next_btn: 'Next',
  prev_btn: 'Previous',
  thank_you: 'Thank you, {{name}}!',
  donation_received: 'Your donation has been received.',
  confirmationDetails_thank_you: 'Thank you, {{name}}',
  confirmationDetails_donation_received:
    'Your donation of <1>{{donationAmount}}</1> has been received.',
  confirmationDetails_receipt:
    'An acknowledgement will be sent to <em>{{email}}</em>.',
  donate_once: 'Donate\nOnce',
  donate_monthly: 'Donate\nMonthly',
  donate_annually: 'Donate\nAnnually',
  finish_btn: 'Finish',
  cancel_btn: 'Cancel',
  redirectMessage: 'You will be redirected in:',
  seconds: 'seconds',
  processing: 'Processing your donation ...',
  processingEP: 'Processing your registration ...',
  processingCD: 'Updating your Contact Information ...',
  processingPaymentSchedule: 'Updating your Payment Information ...',
  card_number: 'Card Number',
  card_validation: 'Please enter a valid credit card number',
  expiry_date_mm: 'MM',
  expiry_date_yy: 'YY',
  expiry_date_mmyy: 'MM/YY',
  expiry_date_mm_validation:
    'Please enter a valid expiration date (MM). The first digit is 0 for single-digit months.',
  expiry_date_yy_validation: 'Please enter a valid expiration date (YY).',
  card_security_code: 'CVV',
  card_security_code_validation: 'Please enter the CVV',
  giving: 'Giving <1>{{currency}}</1>',
  share_btn: 'Share',
  share_on: 'Share On',
  now_booking: 'Now Booking',
  book_tickets: 'Book Tickets',
  use_existing_credit_card: 'Use existing credit card',
  select: 'Select',
  card: 'Card',
  next: 'Next',
  last: 'Last',
  cardType: 'Card Type',
  expiry: 'Expiry',
  apple_pay: 'Apple Pay',
  google_pay: 'Google Pay',
  donate: 'Donate',
  of_goal: 'of Goal',
  supporters: 'Supporters',
  days_left: 'Days Left',
  minimum_donation_of: 'Minimum donation of',
  top_fundraising_teams: 'Top Fundraising Teams',
  top_individual_fundraisers: 'Top Individual Fundraisers',
  individual_subtitle: 'Start Your Individual Fundraiser Today!',
  team_subtitle: 'Start Your Team Fundraiser Today!',
  campaign_title: "Let's Go!",
  individual_fundraiser_btn: 'Create Individual Page',
  team_fundraiser_btn: 'Create Team Page',
  individual_fundraiser_tab: 'Individual Pages',
  team_fundraiser_tab: 'Team Pages',
  individual_page_name_label: 'Individual Fundraising Page Name',
  team_page_name_label: 'Team Fundraising Page Name',
  team_member_page_name_label: 'Team Member Fundraising Page Name',
  individual_page_goal_label: 'Individual Fundraising Goal',
  team_page_goal_label: 'Team Fundraising Goal',
  team_member_page_goal_label: 'Team Member Fundraising Goal',
  team_campaign_name: 'Team Fundraising Name',
  campaign_name: 'Fundraising Page Name',
  campaign_name_validation: 'Please enter a valid fundraising campaign name',
  campaign_goal: 'Fundraising Goal',
  team_campaign_goal: 'Team Fundraising Goal',
  campaign_goal_validation: 'Please enter a valid fundraising goal',
  campaign_url: 'Campaign URL',
  start_date: 'Start Date',
  end_date: 'End Date',
  create_campaign_btn: 'Create Fundraising Page',
  update_campaign_btn: 'Update Fundraising Page',
  delete_campaign_btn: 'Delete this page',
  cancel_changes_btn: 'Cancel Changes',
  password: 'Password',
  password_validation: 'Please enter your password',
  password_requirements:
    'Password must contain at least 8 characters, one uppercase, one number and one special case character',
  sign_in: 'Sign In',
  forgot_password: 'Forgot password?',
  sign_up: 'Sign Up',
  dont_have: "Don't have an account?",
  already_have: 'Already have an account?',
  go_back: 'Go back',
  forgot_password_title: 'Reset Password',
  forgot_password_btn: 'Send Reset Instructions',
  download_media_kit: 'Download Media Kit',
  learn_to_fundraise: 'Learn to Fundraise',
  help_center: 'Help Center',
  days_left_to_donate: 'Days Left to Donate',
  days_left_to_fundraise: 'Days Left to Fundraise',
  quick_links: 'Quick Links',
  edit_campaign_btn: 'Edit',
  view_campaign_btn: 'View',
  share_my_page_btn: 'Share',
  team_campaigns: 'Team Campaigns',
  your_campaigns: 'Your Fundraising Pages',
  activity: 'Activity',
  settings: 'Settings',
  birthday: 'Birthday (optional)',
  save_changes: 'Save Changes',
  edit_profile_title: 'Edit/Update Profile',
  profile_photo: 'Profile Photo',
  upload_photo: 'Upload Photo',
  manage_prefs_title: 'Manage Preferences',
  donation_updates: 'Donation Updates & Upcoming Events',
  phone_campaigns: 'Phone Campaigns',
  updates_via_mail: 'Updates Via Mail',
  old_password: 'Old Password',
  new_password: 'New Password',
  old_password_validation: 'Please enter your old Password',
  new_password_validation: 'Please enter your new Password',
  change_password_title: 'Change Password',
  edit_profile: 'Edit Profile',
  my_profile: 'My Profile',
  edit_campaign: 'Edit Campaign',
  log_out: 'Log out',
  start_a_campaign_btn: 'Start a Campaign',
  campaign_photo: 'Fundraising Background Image',
  recommended_photo_size: 'Recommended photo size: 1240 x 600 pixels',
  mission_statement: 'Mission Statement',
  characters_remaining: '{{count}} characters remaining',
  edit_campaign_title_individual: 'Edit your Individual Fundraising Page',
  edit_campaign_title_team: 'Edit your Team Fundraising Page',
  edit_campaign_title_team_member: 'Edit your Team member Fundraising Page',
  deletion_confirmation_question:
    'Are you sure you want to delete this fundraising page?',
  join_team_btn: 'Join the Team',
  join_team_subtitle: 'Join {{name}}',
  donated_by: 'Donated By',
  campaign_supporters: 'Campaign Supporters',
  display_name:
    'Enter a display name here - it will appear on the fundraising page',
  leave_message:
    "Share your message of support - this will appear on the organization or fundraisers' page so they know they received a gift from you (optional)",
  support_help_text:
    "Messages without a name will be displayed as 'Donated by Anonymous'",
  hide_name_checkbox: 'Do not display my name on the Campaign page',
  show_support_title: 'Share a message of support',
  show_support_subtitle: 'Let fundraisers know what you gave and why.',
  creating_campaign: 'Creating your Campaign ...',
  failed_campaign_creation: 'Failed to create Campaign',
  join_team_checkbox: 'Allow others to join your team',
  credentials_error: 'Invalid email address or password',
  email_exists: 'This email address has already been registered',
  about_campaign: 'About the Campaign',
  teams: 'Teams',
  team_members: 'Team Members',
  participants: 'Participants',
  anonymous: 'Anonymous',
  fundraising_team_for: 'Fundraising team for:',
  fundraising_for: 'Fundraising for:',
  fundraising_team_member: 'Member of:',
  no_team_members: 'No one has signed up as a member of this team.',
  fundraising_dates: 'Fundraising Dates',
  return_back_btn: 'Return Back to Profile',
  changes_saved_btn: 'Changes Saved!',
  no_changes_btn: 'No Changes to Save',
  password_reset_success:
    'Password reset instructions have been sent to {{email}}.',
  total_amount_raised: 'Total Amount Raised',
  amount: '<1>{{currencySymbol}}{{amount}}</1>',
  individual_member_of: 'Individual member of:',
  team_member_of: 'Team member of:',
  role_team_member: '(Role: Team Member)',
  role_team_captain: '(Role: Team Captain)',
  no_individual_pages: 'You do not have any individual fundraising pages.',
  no_team_pages: 'You do not have any team fundraising pages.',
  no_pages_to_display: 'No fundraising pages to display.',
  join_as_individual_btn: 'Join as Individual',
  create_a_team_btn: 'Create a Team',
  expired: 'expired',
  share_on_facebook: 'Share on Facebook',
  share_on_twitter: 'Share on Twitter',
  share_on_linkedin: 'Share on LinkedIn',
  failure_message: 'Failed to process payment',
  search: 'Search',
  constituentNumber: 'Constituent Number',
  contact_not_found: 'Constituent Not Found',
  failed_password_change: 'Old password does not match',
  success_password_change: 'Password updated successfully',
  lifetimeGiving: 'Lifetime',
  givingHistory: 'Giving History',
  thisYearGiving: 'This Year',
  lastGiftAmount: 'Last Gift Amount',
  recurringGift: 'Recurring Gift',
  creditCard: 'Credit Card',
  childRecurringGift: 'Child Recurring Gift Status',
  membership: 'Membership',
  fetching_pages: 'Fetching fundraising pages...',
  title_404: '404',
  subtitle_404: "Sorry, we couldn't find your page.",
  message_404: 'Take me back to -',
  home_page: 'Home page',
  update_contact_info: 'Update Contact Info',
  update_payment_info: 'Update Payment Info',
  update_payment_schedule: 'Update Payment Schedule',
  update: 'Update',
  update_saved_message: 'Update saved successfully',
  new_gift_btn: 'New Gift',
  new_gift_title: 'Creating a New Gift',
  create_new_contact_btn: 'Create Gift for New Donor',
  cancel_donation: 'Cancel Donation',
  back: 'Back',
  start_by: 'Start by entering a',
  or: 'Or',
  FeltPressure: 'Donor felt pressure from fundraiser',
  NoLongerAfford: 'No longer can afford',
  UnderMinimumWage: 'Under minimum age',
  SpouseObjected: 'Spouse objected',
  ChangedMind: 'Changed mind',
  IntendedShortTerm: 'Donor intended for a short term donation',
  IntendedOneTime: 'Donor intended a one-time donation',
  Deceased: 'Deceased',
  Misunderstood: 'Misunderstood commitment',
  OtherCharities: 'Donating to other charities',
  PaymentDeclined: 'Payment declined',
  Complaint: 'Complaint',
  AccountClosed: 'Account closed',
  AlreadyDonating: 'Already donating',
  NoReason: 'No reason given',
  continue_session: 'Keep me signed in',
  session_timeout: 'Session Timeout',
  session_message:
    'The current session is about to expire in <1>{{countdown}}</1> seconds.',
  continue_confirmation: 'Would you like to continue?',
  master_donate_btn: 'Donate to this Campaign',
  team_donate_btn: 'Donate to the Team',
  team_member_donate_btn: 'Donate to {{name}} Fundraiser',
  note: 'Note:',
  bg_image_note:
    'This image appears as a background on the fundraising pages. It is partially covered by the Fundraising Stats Component (Box) on desktop-sized screens, and about 90% covered on smaller screen sizes.',
  sample_campaign_stats:
    'This is a sample Fundraising Stats Component to demo how the background image will appear on a fundraising page.',
  preview: 'Preview',
  donation_through: 'Donation was made through',
  donation: 'Donation',
  view_fundraising_page: 'View Fundraising Page',
  how_often_donate: 'How often would you like to donate?',
  select_amount_donate: "Select the amount you'd like to give:",
  direct_your_gift: 'How would you like to direct your gift?',
  once: 'Once',
  monthly: 'Monthly',
  annually: 'Annually',
  why_donate_monthly: 'What impact does a monthly donation have?',
  eft: 'EFT',
  bank: 'Bank',
  bank_check: 'Bank Check',
  bank_name: 'Bank Name',
  acc_no: 'Account Number',
  routing_no: 'Routing Number',
  bsb_no: 'BSB Number',
  sort_code: 'Sort Code',
  transit_no: 'Transit Number',
  inst_no: 'Institution Number',
  chequing: 'chequing',
  savings: 'savings',
  select_acc_type: 'Select Account Type',
  acc_no_validation: 'Please enter a valid account number',
  routing_no_validation: 'Please enter a valid routing Number',
  sort_code_validation: 'Please enter a valid sort code',
  bsb_no_validation: 'Please enter a valid BSB number',
  inst_no_validation: 'Please enter a valid institution number',
  tran_no_validation: 'Please enter a valid transit number',
  bank_name_validation: 'Bank name is required',
  Completed: 'Completed',
  Terminated: 'Cancelled',
  Failed: 'Failed',
  TransactionRefund: 'Refund',
  InProgress: 'In Progress',
  notPaymentScheduleMessage: 'No payment schedule available',
  notPaymentMethodsMessage: 'No payment method available',
  Amount: 'Amount',
  Name: 'Name',
  Team: 'Team',
  gift_aid: 'Gift Aid',
  donor_details: 'Donor Details',
  payment_details: 'Payment Options',
  Total: 'Total',
  other_btn: 'Other',
  anonymous_checkbox: 'Make my donation anonymous',
  your_donation: 'Your donation <1>{{currency}}</1>',
  your_monthly_donation: 'Your monthly donation <1>{{currency}}</1>',
  your_annually_donation: 'Your annual donation <1>{{currency}}</1>',
  your_membership: 'Your membership <1>{{currency}}</1>',
  include_membership_checkbox:
    'Include my membership in my <1>{{donation}}</1> <2>{{donationFrequency}}</2> donation.',
  share_generosity: 'Share your generosity and inspire others:',
  help_further: 'Want to help further?',
  get_involved_title: 'Get involved',
  set_up_fundraising_btn: 'Set up my fundraising campaign',
  start_fundraising_accordian_title: 'Support a campaign',
  donateMonthlyLink: "I'd like to donate monthly",
  donateAnnuallyLink: "I'd like to donate annually",
  enter_amount: 'Please enter amount',
  selection_label: 'Other',
  order_label: 'Please select',
  membership_order_label: 'Please select (optional)',
  support_display_all: 'Please display my name, message and donation amount.',
  support_hide_amount:
    'Please display my name and message only (hides donation amount).',
  support_hide_name:
    'Please display my donation amount and message only (hides name).',
  support_hide_all: 'No, please hide my name, message and donation amount.',
  greatest_need: 'Greatest Need',
  background_image_alt_text: 'dynamic background theme image',
  summary_link_description: `Click 'Your donation' to change the amount of your gift`,
  unexpected_error_message: `An error has occurred. But your transaction has been processed and will appear on your statement. <1>Do not try again.</1><br /><br /><2>Click here</2> or contact our donor services team immediately to advise you've received this message.`,
  applePay_error:
    'Apple Pay is only available when enabled with iOS compatible devices (iPhone/iPad/Mac).',
  applePay__donate: 'Donate with',
  applePay_plain: 'Pay',
  googlePay_error: 'GooglePay is not enabled and/or available on this device.',
  additionalInfo: 'Additional Information',
  additional_info_msg:
    'If you have any comments related to your donation, please include them here.',
  notes_or_direction: 'Notes or Direction',
  pion_additional_info_msg: 'Your comments here',
  pion_notes_or_direction: `Type in the missionary's name if you do not know the account number, or if you have other comments:`,
  halifaxyarmouth_additional_info_msg: 'Please add the name of your parish',
  halifaxyarmouth_notes_or_direction: `Please add the name of your parish.`,
  readMore: 'Read more',
  showLess: 'Show less',
  isCompanyGift: 'This is a corporate donation',
  yes: 'Yes',
  no: 'No',
  tribute_type: 'Would you like us to send a card?',
  no_sendTribute: 'No,\nthank you',
  email_sendTribute: 'Yes,\nby email',
  mail_sendTribute: 'Yes,\nby mail',
  addressee_name: 'Send Card To',
  addressee_name_validation: `Please enter recipient's name`,
  required_pwd: 'Please Enter your password',
  pwd_match:
    'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
  MissionCRM_label: 'Mission CRM label',
  recaptcha_token_error:
    'Failed to retreive reCAPTCHA Token at this time. Please try agian later.',
  recaptcha_failure:
    'Bad Request, bad recaptcha token. Please double check your information and try again.',
  required_message: 'Responses to the questions below are required.', // Google translation
  custom_questions_error: 'Responses to all questions are required', // Google translation
  double_donation: 'See if your company will match your donation!',
};
