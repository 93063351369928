import * as React from 'react';
import {
  Card,
  FormText,
  Accordion as BootstrapAccordion,
} from 'react-bootstrap';
import ParserHTML from '@components/atoms/parserHTML';
import { useTranslation } from 'react-i18next';

export type Props = {
  text: any;
  dataTestId?: string;
};

const Accordion: React.FC<Props> = ({
  text = null,
  dataTestId = 'accordion',
}) => {
  const { t } = useTranslation();

  const rootProps = {
    'data-testid': dataTestId,
  };

  return (
    <BootstrapAccordion {...rootProps}>
      <BootstrapAccordion.Toggle as={FormText} eventKey="0">
        {`> ${t('why_donate_monthly')}`}
      </BootstrapAccordion.Toggle>
      <BootstrapAccordion.Collapse eventKey="0">
        <Card.Body>{text && <ParserHTML text={text} />}</Card.Body>
      </BootstrapAccordion.Collapse>
    </BootstrapAccordion>
  );
};

export default Accordion;
