import * as React from 'react';
import { Dropdown } from 'react-bootstrap';
import { PersonFill } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router-dom';
import { RootState } from '@app/RootState';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchUserProfile,
  logout,
  setPagePermissions,
  refreshToken,
} from '@modules/user/user.slice';
import {
  setSelectedCampaignPageId,
  CampaignPageTypes,
} from '@modules/campaign/campaignSlice';
import { getUserPagePermissions } from '@utils/user-permissions';
import moment from 'moment';
import useStyles from './userNavigation.style';

const { lazy } = React;
const TimeoutModal = lazy(() => import('@components/molecules/timeoutModal'));

let initialInterval;

export type Props = {
  profile?: any;
  color?: string;
  containerHeight?: number;
  showDefaultHeader?: boolean;
  isProfilePage?: boolean;
  dataTestId?: string;
};

const UserNavigation: React.FC<Props> = ({
  color = null,
  containerHeight = 150,
  showDefaultHeader = false,
  isProfilePage = false,
  dataTestId = 'userNavigation',
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { isLoggedIn, profile, pagePermissions, isLoading } = useSelector(
    (state: RootState) => state.user
  );
  const { campaignPageDetails } = useSelector(
    (state: RootState) => state.campaign
  );
  const [canEdit, setCanEdit] = React.useState<boolean>(false);

  const mobileSignIn = useMediaQuery({ maxWidth: 767 });

  React.useEffect(() => {
    dispatch(
      setPagePermissions(
        getUserPagePermissions(
          campaignPageDetails,
          profile ? profile.pageOwnerId : null
        )
      )
    );

    const isReset = history.location.pathname.includes('resetPassword');
    if (isLoggedIn && !profile && !isReset) {
      dispatch(fetchUserProfile());
    }
  }, [campaignPageDetails, profile, isLoggedIn]);

  React.useEffect(() => {
    setCanEdit(pagePermissions.canEdit);
  }, [pagePermissions]);

  const handleLogout = () => {
    dispatch(logout());
    if (
      !showDefaultHeader &&
      campaignPageDetails &&
      campaignPageDetails.campaignPageTypeId !== CampaignPageTypes.Master
    ) {
      history.push(`/Campaign/${campaignPageDetails.masterFriendlyUrl}/`);
    }
  };
  const handleRefresh = () => dispatch(refreshToken());
  const handleEditPage = () => {
    dispatch(setSelectedCampaignPageId(campaignPageDetails.campaignPageId));
    history.push('/user/editCampaign');
  };
  const classes = useStyles({ color, containerHeight });

  const [timeoutModalOpen, setTimeoutModalOpen] = React.useState(false);
  const [timeoutCountdown, setTimeoutCountdown] = React.useState(0);

  const clearInitialInterval = () => {
    clearInterval(initialInterval);
  };
  const logoutSession = () => {
    setTimeoutModalOpen(false);
    handleLogout();
    clearInitialInterval();
  };

  const continueSession = () => {
    setTimeoutModalOpen(false);
    handleRefresh();
    clearInitialInterval();
  };

  React.useEffect(() => {
    clearInitialInterval();
    const expiryDate = moment(localStorage.getItem('expiry-date'));
    initialInterval = setInterval(() => {
      const diff = expiryDate.diff(moment(), 'seconds');
      if (diff > 0 && diff < 121) {
        if (!timeoutModalOpen) {
          setTimeoutModalOpen(true);
          setTimeoutCountdown(diff);
        }
      } else if (diff <= 0) {
        setTimeoutModalOpen(false);
        logoutSession();
      }
    }, 1000);
  }, [isLoading]);

  const rootProps = {
    className: classes.root,
    'data-testid': dataTestId,
  };

  let profileName;
  if (profile) {
    if (!mobileSignIn) {
      if (profile.firstName.length + profile.lastName.length <= 50) {
        profileName = `${profile.firstName} ${profile.lastName}`;
      } else {
        profileName = profile.firstName;
      }
    } else {
      profileName = profile.firstName;
    }
  }

  return (
    <div {...rootProps}>
      {isLoggedIn ? (
        <Dropdown>
          <Dropdown.Toggle
            className={classes.dropDown}
            variant="custom"
            id="dropdown-basic"
          >
            <PersonFill size={15} className={classes.icon} />

            <span>
              {(mobileSignIn && profileName && profileName.length >= 11) ||
              (isProfilePage && profileName && profileName.length >= 11)
                ? `${profileName.substring(0, 10)}...`
                : profileName}
            </span>
          </Dropdown.Toggle>

          <Dropdown.Menu className={classes.dropDownMenu}>
            <Dropdown.Item href="/user/profile">{t('activity')}</Dropdown.Item>
            <Dropdown.Item href="/user/profile?tab=settings">
              {t('settings')}
            </Dropdown.Item>
            {canEdit ? (
              <Dropdown.Item onClick={handleEditPage}>
                {t('edit_campaign')}
              </Dropdown.Item>
            ) : null}
            <Dropdown.Item onClick={logoutSession}>
              {t('log_out')}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ) : null}
      <TimeoutModal
        countdown={timeoutCountdown}
        onContinue={() => continueSession()}
        onLogout={() => logoutSession()}
        show={timeoutModalOpen}
      />
    </div>
  );
};

export default UserNavigation;
