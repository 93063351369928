import { createUseStyles } from 'react-jss';
import { MissionTheme } from '@root/interfaces/MissionTheme';

export default createUseStyles<any, any>((theme: MissionTheme) => ({
  root: {
    backgroundColor: (props: { color: string }) =>
      props.color || theme.headerBackgroundColor || 'lightgray',

    '@media (max-width: 576px)': {
      minHeight: 205,
      padding: 20,
    },
    '@media (min-width: 576px)': {
      height: (props: { containerHeight: number }) =>
        `${props.containerHeight}px` || '150px',
      paddingLeft: '40px',
      paddingRight: '40px',
    },
  },
  topBanner: {
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 575px)': {
      justifyContent: 'center',
      paddingBottom: 10,
    },
  },
  logoImage: {
    maxHeight: theme.headerLogoHeight || '80px',
    width: '90%',
    height: 'auto',
    maxWidth: 225,
    margin: 'auto',
  },
  shareCol: {
    display: 'flex',
    justifyContent: (props: { showLogoOnly: boolean; isDP: boolean }) =>
      props.showLogoOnly && !props.isDP ? 'flex-start' : 'flex-end',
    alignItems: 'center',
    '@media (max-width: 575px)': {
      justifyContent: 'center',
    },
    '@media (max-width: 768px)': {
      justifyContent: (props: { isDP: boolean }) =>
        props.isDP ? 'flex-start' : 'center',
    },
  },
  shareDonationCol: {
    padding: 0,
    justifyContent: (props: { isDP: boolean }) => props.isDP && 'flex-start',
  },
  shareATag: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginLeft: 6,
    '&:hover': {
      textDecoration: 'none',
    },
  },
  shareIcon: {
    color: (props: { color: string }) => props.color || theme.headerTitleColor,
  },
  shareH4Tag: {
    marginTop: 8,
    textAlign: 'center',
    fontWeight: 600,
    color: (props: { color: string }) => props.color || theme.headerTitleColor,
  },
  modalBody: {
    display: 'flex',
    justifyContent: 'center',
  },
  spanSpace: { marginRight: 10 },
  skeletonRow: {
    padding: 0,
  },
  skeletonCol: {
    padding: 0,
    margin: 0,
    marginTop: '-4px',
    marginBottom: '-2px',
    boxShadow: '0 8px 6px -6px black',
  },
  btnMargin: {
    marginTop: 5,
    '& button': {
      minHeight: 50,
      height: 'auto',
      '& span': {
        wordBreak: 'break-word',
      },
    },
  },
  hiddenHeader: {
    position: 'relative',
    marginBottom: (props: { isDP: boolean }) => !props.isDP && -110,
  },

  hiddenDiv: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
    backgroundColor: 'white',
    borderRadius: '0 0 10px 10px',
    padding: (props: { isDP: boolean }) =>
      props.isDP ? '8.89%' : '20px 20px 15px',

    boxShadow:
      // [horizontal offset] [vertical offset] [blur radius] [optional spread radius] [color]
      (props: { isDP: boolean }) =>
        !props.isDP &&
        '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',

    '&:before': {
      content: '""',
      position: 'absolute',
      borderRadius: '0 0 10px 10px',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: -1,
      backgroundColor: 'white',
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      borderRadius: '0 0 10px 10px',
      boxShadow:
        // [horizontal offset] [vertical offset] [blur radius] [optional spread radius] [color]
        (props: { isDP: boolean }) =>
          props.isDP && '0px 1px 4px 1px rgba(0, 0, 0, 0.2)',
      top: '3.55487vw',
      left: 0,
      width: '100%',
      height: 'calc(100% - 3.55487vw);',
      zIndex: -2,
      '@media (min-width: 640px)': {
        top: 23,
        height: 'calc(100% - 23px);',
      },
    },
  },
  hiddenDivDonation: {
    maxWidth: 225,
    minWidth: '90%',
    maxHeight: 164,
    minHeight: 100,
    backgroundColor: 'white',
  },

  headerLink: {
    display: 'flex',
    alignSelf: 'center',
  },
}));
