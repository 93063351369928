import fontFace from '../tools/fontFace';

export default {
  '@font-face': [
    fontFace(
      'Whitney',
      'WhitneySSm-Book',
      'normal',
      'normal',
      'WhitneySSm-Book_Web'
    ),
    fontFace(
      'Whitney',
      'WhitneySSm-BookItalic',
      'normal',
      'normal',
      'WhitneySSm-BookItalic_Web'
    ),
    fontFace(
      'Whitney',
      'WhitneySSm-Bold',
      'normal',
      'normal',
      'WhitneySSm-Bold_Web'
    ),
    fontFace(
      'Whitney',
      'WhitneySSm-BoldItalic',
      'normal',
      'normal',
      'WhitneySSm-BoldItalic_Web'
    ),
    fontFace(
      'Whitney',
      'WhitneySSm-Light',
      'normal',
      'normal',
      'WhitneySSm-Light_Web'
    ),
    fontFace(
      'Whitney',
      'WhitneySSm-LightItalic',
      'normal',
      'normal',
      'WhitneySSm-LightItalic_Web'
    ),
    fontFace(
      'Whitney',
      'WhitneySSm-Medium',
      'normal',
      'normal',
      'WhitneySSm-Medium_Web'
    ),
    fontFace(
      'Whitney',
      'WhitneySSm-MediumItalic',
      'normal',
      'normal',
      'WhitneySSm-MediumItalic_Web'
    ),
    fontFace(
      'Whitney',
      'WhitneySSm-Semibold',
      'normal',
      'normal',
      'WhitneySSm-Semibold_Web'
    ),
    fontFace(
      'Whitney',
      'WhitneySSm-SemiboldItalic',
      'normal',
      'normal',
      'WhitneySSm-SemiboldItalic_Web'
    ),
    fontFace(
      'Whitney',
      'WhitneySSm-Black',
      'normal',
      'normal',
      'WhitneySSm-Black_Web'
    ),
    fontFace(
      'Whitney',
      'WhitneySSm-BlackItalic',
      'normal',
      'normal',
      'WhitneySSm-BlackItalic_Web'
    ),
  ],
};
