/* eslint-disable no-unused-vars */
export enum PaymentCountryContextCode {
  AU = 703650000,
  CA,
  FR,
  DE,
  NL,
  NO,
  CH,
  GB,
  US,
}
