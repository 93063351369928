import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import Skeleton from '@components/atoms/skeleton';

import useStyles from './contactHistory.style';

const ContactHistorySkeleton = () => {
  const classes = useStyles();

  return (
    <Container className={classes.root}>
      <Row>
        <Col md={6} className="d-flex">
          <Card
            className={`${classes.detailCard} ${classes.detailCardSkeleton}`}
          >
            <Card.Body>
              <Card.Title>
                <Skeleton />
              </Card.Title>
              <Card.Subtitle className={classes.highlighted}>
                <Skeleton />
              </Card.Subtitle>
              <div>
                <Skeleton />
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} className="d-flex">
          <Card
            className={`${classes.detailCard} ${classes.detailCardSkeleton}`}
          >
            <Card.Body>
              <Card.Title>
                <Skeleton />
              </Card.Title>
              <Card.Subtitle className={classes.highlighted}>
                <Skeleton />
              </Card.Subtitle>
              <div>
                <Skeleton />
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} className="d-flex">
          <Card
            className={`${classes.detailCard} ${classes.detailCardSkeleton}`}
          >
            <Card.Body>
              <Card.Title>
                <Skeleton />
              </Card.Title>

              <Card.Subtitle>
                <span className={classes.highlighted}>
                  <Skeleton />
                </span>{' '}
              </Card.Subtitle>

              <Card.Subtitle>
                <span className={classes.highlighted}>
                  <Skeleton />
                </span>{' '}
              </Card.Subtitle>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} className="d-flex">
          <Card
            className={`${classes.detailCard} ${classes.detailCardSkeleton}`}
          >
            <Card.Body>
              <Card.Title>
                <Skeleton />
              </Card.Title>

              <Card.Subtitle>
                <span className={classes.highlighted}>
                  <Skeleton />
                </span>{' '}
                <Skeleton />
              </Card.Subtitle>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} className="d-flex">
          <Card
            className={`${classes.detailCard} ${classes.detailCardSkeleton}`}
          >
            <Card.Body>
              <Card.Title>
                <Skeleton />
              </Card.Title>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} className="d-flex">
          <Card
            className={`${classes.detailCard} ${classes.detailCardSkeleton}`}
          >
            <Card.Body>
              <Card.Title>
                <Skeleton />
              </Card.Title>
              <Card.Subtitle>
                <Skeleton />
              </Card.Subtitle>

              <Card.Subtitle className={classes.highlighted}>
                <Skeleton />
              </Card.Subtitle>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export { ContactHistorySkeleton };
