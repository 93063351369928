import { MissionTheme, Font } from '@root/interfaces';
import globals from '../default';

require('typeface-crimson-text');
require('typeface-dosis');

const colors = {
  colorPrimary: '#CC3333',
  colorSecondary: '#545456',
  colorText: '#7c7c7c',
  ButtonTextColor: '#FFFFFF',
  colorBackground: '#545456',
};

const primaryFont: Font = {
  name: 'crimson-text',
  fontFamily: 'Crimson Text, serif',
};

const secondaryFont: Font = {
  name: 'Dosis',
  fontFamily: 'dosis, sans-serif',
};

const theme: MissionTheme = {
  ...globals,
  ...colors,
  clientFullName: 'Pioneers Canada',
  isSVG: true,
  isDisableFullHeader: false,
  extraBoldFrequency: false,
  useSecThemeColor: false,
  addCustomColor: false,
  headerBackgroundColor: 'white',
  headerTitleColor: '#7c7c7c',
  headerLogoHeight: 103,
  headingFontSize: '1.5rem',
  headingBold: true,
  subHeadingFontSize: '1rem',
  primaryFont: primaryFont,
  headingFont: secondaryFont,
  buttons: {
    ...globals.buttons,
    defaults: {
      ...globals.buttons.defaults,
      background: colors.colorPrimary,
    },
  },
  navButtons: {
    left: {
      ...globals.buttons,
      defaults: {
        ...globals.buttons.defaults,
        background: colors.colorPrimary,
      },
    },
    right: {
      ...globals.buttons,
      defaults: {
        ...globals.buttons.defaults,
        background: colors.colorPrimary,
      },
    },
  },
  globalFontSize: 16,
  toggleFontFamily: secondaryFont.fontFamily,
};

export default theme;
