import * as React from 'react';
import { Carousel as BootstrapCarousel } from 'react-bootstrap';
import { CardImage } from 'react-bootstrap-icons';

import useStyles from './carousel.style';

const CarouselSkeleton = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <BootstrapCarousel>
        <BootstrapCarousel.Item>
          <div className={classes.cardImage}>
            <CardImage size={30} />
          </div>
        </BootstrapCarousel.Item>
      </BootstrapCarousel>
    </div>
  );
};

export { CarouselSkeleton };
