import fontFace from '../tools/fontFace';

export default {
  '@font-face': [
    fontFace(
      'mission',
      'Helvetica Black',
      'normal',
      'normal',
      'HelveticaBlack'
    ),
    fontFace(
      'mission',
      'Helvetica Black Oblique',
      'normal',
      'normal',
      'HelveticaBlackOblique'
    ),
    fontFace('mission', 'Helvetica Bold', 'normal', 'normal', 'HelveticaBold'),
    fontFace(
      'mission',
      'Helvetica Bold Oblique',
      'normal',
      'normal',
      'HelveticaBoldOblique'
    ),
    fontFace(
      'mission',
      'Helvetica Light',
      'normal',
      'normal',
      'HelveticaLight'
    ),
    fontFace(
      'mission',
      'Helvetica Light Oblique',
      'normal',
      'normal',
      'HelveticaLightOblique'
    ),
    fontFace(
      'mission',
      'Helvetica Oblique',
      'normal',
      'normal',
      'HelveticaOblique'
    ),
    fontFace(
      'mission',
      'Helvetica Roman',
      'normal',
      'normal',
      'HelveticaRoman'
    ),
  ],
};
