import { MissionTheme } from '@root/interfaces/MissionTheme';

export default (theme: MissionTheme) => {
  const headerStyles = {
    'h1, h2, h3, h4, h5, h6': {
      fontFamily: theme.headingFont.fontFamily,
      fontWeight: theme.headingFont.fontWeight || 'normal',
      lineHeight: 1,
      letterSpacing: theme.headingFont.letterSpacing,
      color: theme.colorText,
      width: '100%',
    },
  };
  for (let i = 1; i <= 6; i += 1) {
    headerStyles[`h${i}`] = {
      fontSize: `${2 - (i - 1) * 0.2}em`,
    };
  }
  return headerStyles;
};
