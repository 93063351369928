import * as React from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import globalStyles from '@styles/global';

import Profile from '@modules/user/screens/Profile';
import SignIn from '@modules/user/screens/SignIn';
import EditCampaign from '@modules/campaign/screens/EditCampaign';
import ResetPassword from '@modules/user/screens/ResetPassword';

export type RouterProps = {};

const UserRouter: React.FC<RouterProps> = () => {
  const { path } = useRouteMatch();
  globalStyles();
  return (
    <Switch>
      <Route path={`${path}/signin`} component={SignIn} />
      <Route path={`${path}/profile`} component={Profile} />
      <Route path={`${path}/editCampaign`} component={EditCampaign} />
      <Route path={`${path}/resetPassword`} component={ResetPassword} />
      <Redirect to={`${path}/profile`} />
    </Switch>
  );
};

export default UserRouter;
