import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import Skeleton from '@components/atoms/skeleton';

import useStyles from './header.style';

const HeaderSkeleton = () => {
  const classes = useStyles({
    color: null,
    containerHeight: 140,
    logoHeight: 80,
  });
  return (
    <Row className={classes.skeletonRow}>
      <Col className={classes.skeletonCol}>
        <Skeleton height="26vw" borderRadius maxHeight={140} />
      </Col>
    </Row>
  );
};

export { HeaderSkeleton };
