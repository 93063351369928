import * as React from 'react';
import { Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { defaultTheme } from '@styles/themes';
import { ThemeProvider } from 'react-jss';

import { HeaderSkeleton } from '@root/components/molecules/header/header.skeleton';
import { RootState } from '@app/RootState';
import { PageKind } from '@root/enums';
import loadTheme from '@root/style/loadTheme';
import { fetchUserProfile } from './user.slice';

import UserRouter from './router';
import useStyles from './user.style';

const { lazy, Suspense } = React;
const Header = lazy(() => import('@root/components/molecules/header'));

export const BASE_PATH = '/user';

const UserModule = () => {
  const history = useHistory();
  const { profile, isLoggedIn } = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const [theme, setTheme] = React.useState(defaultTheme);
  const clientCode: string = useSelector(
    (state: RootState) => state.app.clientCode
  );

  React.useEffect(() => {
    const isReset = history.location.pathname.includes('resetPassword');
    if (!profile && isLoggedIn && !isReset) {
      dispatch(fetchUserProfile());
    }
  }, [dispatch, isLoggedIn]);

  const classes = useStyles();

  React.useEffect(() => {
    if (clientCode && profile) {
      const clientTheme = loadTheme({
        clientCode,
        pageKind: PageKind.Campaign,
      });

      setTheme(clientTheme);
    }
  }, [clientCode, profile]);

  const page =
    profile &&
    profile.campaignPages &&
    profile.campaignPages.length > 0 &&
    profile.campaignPages[0];

  return (
    <ThemeProvider theme={theme}>
      <Container className={classes.container}>
        <Suspense fallback={<HeaderSkeleton />}>
          <Header
            showDefaultHeader
            isProfilePage
            isLoggedIn={isLoggedIn}
            page={page}
          />
        </Suspense>
        <Row>
          <Container as="main" className={classes.main} fluid>
            <UserRouter />
          </Container>
        </Row>
      </Container>
    </ThemeProvider>
  );
};

export default UserModule;
