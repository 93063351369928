import { createUseStyles } from 'react-jss';

export default createUseStyles<any, any>({
  root: {
    padding: '2rem 0',
  },
  cardImage: {
    height: '13rem',
    backgroundColor: 'lightgray',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
