import { createUseStyles } from 'react-jss';
import { MissionTheme } from '@root/interfaces/MissionTheme';
import { InputStates } from '@root/interfaces/InputStates';

interface StyleProps {
  inputStyles?: InputStates;
}

export default createUseStyles<any, any>((theme: MissionTheme) => {
  const getStylesValues =
    (state: 'defaults' | 'valid' | 'active' | 'error' | 'disabled') =>
    (props: StyleProps) => {
      const { inputStyles } = props;

      const customStyles: InputStates = {
        defaults: inputStyles ? inputStyles.defaults : {},
        active: inputStyles ? inputStyles.active : {},
        valid: inputStyles ? inputStyles.valid : {},
        error: inputStyles ? inputStyles.error : {},
        disabled: inputStyles ? inputStyles.disabled : {},
      };

      const activeStyles = {
        ...theme.dropdown.active,
        ...customStyles.active,
      };

      return {
        '& .form-control': {
          outline: 'none',
          boxShadow: 'none',
          fontStyle: 'italic',
          ...theme.dropdown.defaults,
          ...customStyles.defaults,
          ...theme.dropdown[state],
          ...customStyles[state],
          '&.value-selected': {
            ...activeStyles,
          },
          '-webkit-appearance': 'none',
          '-moz-appearance': 'none',
        },
        '&:before': {
          content: '""',
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          right: '.25rem',
          width: 50,
          height: '100%',
          backgroundImage: `
          linear-gradient(45deg, transparent 50%, ${theme.colorPrimary} 50%),
          linear-gradient(135deg, ${theme.colorPrimary} 50%, transparent 50%),
          linear-gradient(to right, transparent, transparent)`,
          backgroundPosition: `
          calc(100% - 20px) calc(1em + 2px),
          calc(100% - 15px) calc(1em + 2px),
          100% 0`,
          backgroundSize: `
          5px 5px,
          5px 5px,
          2.5em 2.5em`,
          backgroundRepeat: 'no-repeat',
        },
        '&.value-selected:before': {
          backgroundImage: `
          linear-gradient(45deg, transparent 50%, #fff 50%),
          linear-gradient(135deg, #fff 50%, transparent 50%),
          linear-gradient(to right, transparent, transparent)`,
        },
      };
    };

  return {
    default: getStylesValues('defaults'),
    valid: getStylesValues('valid'),
    active: getStylesValues('active'),
    error: getStylesValues('error'),
    disabled: getStylesValues('disabled'),
    root: {
      marginTop: 16,
      '& .btn': {
        minHeight: 36,
      },
    },
    textColor: {
      color: theme.colorPrimary,
    },
    subTitlesWeight: {
      fontWeight: theme.headingBold ? 600 : 400,
    },
    sectionTag: {
      fontSize: theme.subHeadingFontSize,
      marginBottom: 16,
      '@media (max-width: 576px)': {
        margin: '16px 0',
      },
    },
    optionColors: {
      fontStyle: 'normal',
      color: theme.colorPrimary,
    },
    donationPageLayoutRow: {
      marginLeft: -8,
      marginRight: -8,
      '& .form-group': {
        paddingRight: 8,
        paddingLeft: 8,
      },
    },
    singleOrderSelection: {
      paddingRight: '0px !important',
      paddingLeft: '0px !important',
    },
  };
});
