import * as React from 'react';
import LoadingSkeleton, { SkeletonTheme } from 'react-loading-skeleton';

export type Props = {
  count?: number;
  color?: string;
  height?: number | string | null;
  maxHeight?: number | string | null;
  width?: number | string | null;
  circle?: boolean;
  highlightColor?: string;
  borderRadius?: boolean;
  dataTestId?: string;
};

const Skeleton: React.FC<Props> = ({
  count = 1,
  color = '#ddd',
  height = null,
  width = null,
  circle = false,
  borderRadius = false,
  maxHeight = null,
  highlightColor = '#ccc',
  dataTestId = 'skeleton',
}) => {
  return (
    <SkeletonTheme
      color={color}
      highlightColor={highlightColor}
      data-testid={dataTestId}
    >
      <LoadingSkeleton
        style={{
          borderRadius: borderRadius ? 0 : '',
          maxHeight: maxHeight || '',
        }}
        height={height}
        width={width}
        count={count}
        circle={circle}
      />
    </SkeletonTheme>
  );
};

export default Skeleton;
