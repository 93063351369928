import { combineReducers } from '@reduxjs/toolkit';

import remoteImageReducer from '@components/atoms/image/imageSlice';
import campaignReducer from '@modules/campaign/campaignSlice';
import userReducer from '@modules/user/user.slice';
import donationReducer from '@modules/donation/donation.slice';
import appReducer from '@app/app.slice';

const rootReducer = combineReducers({
  app: appReducer,
  images: remoteImageReducer,
  campaign: campaignReducer,
  donation: donationReducer,
  user: userReducer,
});

export default rootReducer;
