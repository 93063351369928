import * as React from 'react';
import { Container } from 'react-bootstrap';
import Skeleton from '../skeleton';
import useStyles from './campaignMessage.style';

const CampaignMessageSkeleton = () => {
  const classes = useStyles({});
  return (
    <Container className={classes.root}>
      <Skeleton height={35} />
      <div style={{ marginBottom: 20 }} />
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </Container>
  );
};

export { CampaignMessageSkeleton };
