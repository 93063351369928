import { MissionTheme } from '../../interfaces/MissionTheme';
import { Font } from '../../interfaces/Font';
import defaultStyle from './default';

require('typeface-roboto');

const primaryFont: Font = {
  name: 'Roboto',
  fontFamily: '"Roboto", serif',
};

const theme: MissionTheme = {
  ...defaultStyle,
  colorPrimary: '#01457B',
  colorSecondary: '#7c7c7c',
  ...primaryFont,
  headingFont: primaryFont,
  headerBackgroundColor: '#01457B',
  headerTitleColor: 'white',
};

export default theme;
