import { CampaignPageDetails, ChildCampaignPageDetails } from '@api/campaign';
import { CampaignPageTypes } from '@modules/campaign/campaignSlice';

export const userHasIndividualPageForCampaign = (
  campaignPages: ChildCampaignPageDetails[],
  pageOwnerId: string
): boolean =>
  campaignPages.some(
    (x) =>
      x.campaignPageTypeId === CampaignPageTypes.Individual &&
      x.pageOwnerId === pageOwnerId
  );

export const userHasTeamPageForCampaign = (
  campaignPages: ChildCampaignPageDetails[],
  pageOwnerId: string
): boolean =>
  campaignPages.some(
    (x) =>
      x.campaignPageTypeId === CampaignPageTypes.Team &&
      x.pageOwnerId === pageOwnerId
  );

export const userIsTeamMember = (
  campaignPages: ChildCampaignPageDetails[],
  pageOwnerId: string
): boolean =>
  campaignPages.some(
    (x) =>
      x.campaignPageTypeId === CampaignPageTypes.TeamMember &&
      x.pageOwnerId === pageOwnerId
  );

export const canCreateTeam = (campaignPage?: CampaignPageDetails): boolean => {
  if (!campaignPage || !campaignPage.allowTeamCampaigns) {
    return false;
  }
  const canCreate = true;
  return canCreate;
};

export const canCreateIndividual = (
  campaignPage?: CampaignPageDetails,
  pageOwnerId?: string
): boolean => {
  if (
    !campaignPage ||
    !campaignPage.enableFundraising ||
    !campaignPage.allowIndividualCampaigns
  ) {
    return false;
  }
  let canCreate = true;
  if (pageOwnerId) {
    const hasIndividual = userHasIndividualPageForCampaign(
      campaignPage.childCampaignPages,
      pageOwnerId
    );
    if (
      hasIndividual ||
      campaignPage.campaignPageTypeId === CampaignPageTypes.Individual ||
      campaignPage.campaignPageTypeId === CampaignPageTypes.TeamMember
    ) {
      canCreate = false;
    }
  }

  return canCreate;
};

export const canJoinTeam = (
  campaignPage?: CampaignPageDetails,
  pageOwnerId?: string
): boolean => {
  if (
    !campaignPage ||
    campaignPage.campaignPageTypeId !== CampaignPageTypes.Team
  ) {
    return false;
  }
  let canCreate = true;
  const hasIndividual = userIsTeamMember(
    campaignPage.childCampaignPages,
    pageOwnerId
  );
  if (hasIndividual && pageOwnerId) {
    canCreate = false;
  }
  return canCreate;
};

export const getUserPagePermissions = (
  campaignPage: CampaignPageDetails,
  pageOwnerId: string
) => ({
  canCreateTeam: canCreateTeam(campaignPage),
  canCreateIndividual: canCreateIndividual(campaignPage, pageOwnerId),
  canJoinTeam: canJoinTeam(campaignPage, pageOwnerId),
  canEdit:
    campaignPage && pageOwnerId && campaignPage.pageOwnerId === pageOwnerId,
});
