import * as React from 'react';
import { Row, Col, Container, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';
import { useHistory } from 'react-router-dom';
import { CampaignPageDetails, CampaignPageCRUDViewModel } from '@api/campaign';

import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@app/RootState';
import {
  createNewCampaignPage,
  deleteCurrentCampaignPage,
} from '@modules/campaign/campaignSlice';

const { lazy, Suspense } = React;
const EditCampaignBox = lazy(
  () => import('@components/organism/editCampaignBox')
);

interface EditCampaignScreenProps {
  pageDetails?: CampaignPageDetails;
}

const EditCampaign: React.FC<EditCampaignScreenProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    profile,
    error: profileError,
    isLoggedIn,
  } = useSelector((state: RootState) => state.user);
  const { selectedCampaignPageId } = useSelector(
    (state: RootState) => state.campaign
  );
  const recaptchaRef: any = React.useRef();
  const { error, isLoading, childPageDeletion } = useSelector(
    (state: RootState) => state.campaign
  );
  const [useRecaptcha, setUseRecaptcha] = React.useState(false);

  const page =
    profile &&
    profile.campaignPages.find(
      (e) => e.campaignPageId === selectedCampaignPageId
    );

  const recaptchaSiteKey =
    page && page.recaptchaSiteKey ? page.recaptchaSiteKey : null;

  React.useEffect(() => {
    if (!isLoggedIn) {
      history.push('/user/signin');
    }
  }, [isLoggedIn]);

  React.useEffect(() => {
    setUseRecaptcha(window.location.hostname !== 'localhost');
    if (!selectedCampaignPageId) {
      cancelChanges();
    }
  }, []);

  React.useEffect(() => {
    if (childPageDeletion && childPageDeletion.success) {
      cancelChanges();
    }
  }, [childPageDeletion]);

  const deleteCampaignSubmit = async () => {
    const token = useRecaptcha
      ? await recaptchaRef.current.executeAsync()
      : 'localhost';

    if (token != null) {
      dispatch(deleteCurrentCampaignPage(page.campaignPageId));
    }
  };

  const cancelChanges = () => {
    history.push('profile');
  };

  const onSubmit = async (values, newPhoto) => {
    const token = useRecaptcha
      ? await recaptchaRef.current.executeAsync()
      : 'localhost';

    const campaignFormDetails: CampaignPageCRUDViewModel = {
      name: values.campaignName,
      goal: values.campaignGoal,
      campaignPageTypeId: (page && page.campaignPageTypeId) || '',
      campaignPageId: page.campaignPageId,
      parentCampaignPageId: (page && page.parentCampaignPageId) || '',
      grandParentCampaignPageId: (page && page.grandParentCampaignPageId) || '',
      isNewCampaign: false,
      message: values.missionStatement,
      displayJoinTheTeam: values.allowJoinTeamCheckbox,
    };

    if (token != null) {
      dispatch(
        createNewCampaignPage(
          campaignFormDetails,
          newPhoto,
          page.azureWebApiPadlockToken,
          page.azureWebApiUrl
        )
      );
    }
  };

  return (
    <Suspense fallback={<div>{t('loading')}</div>}>
      <Container fluid>
        <Row style={{ backgroundColor: '#E0E0E0' }}>
          <Col lg>
            {error && !isLoading ? (
              <Alert variant="danger">{t(error)}</Alert>
            ) : null}
            {profileError || (
              <EditCampaignBox
                page={page}
                onSubmit={onSubmit}
                isLoading={isLoading}
                cancelChanges={cancelChanges}
                deleteCampaignSubmit={deleteCampaignSubmit}
                deletionError={
                  childPageDeletion &&
                  !childPageDeletion.success &&
                  childPageDeletion.message
                }
              />
            )}
          </Col>
        </Row>
        {useRecaptcha && recaptchaSiteKey ? (
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey={recaptchaSiteKey}
          />
        ) : null}
      </Container>
    </Suspense>
  );
};

export default EditCampaign;
