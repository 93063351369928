import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '@app/AppThunk';
import moment from 'moment';

import {
  Profile,
  ILogin,
  ILogout,
  getUserProfile,
  editUserProfile,
  userLogin,
  userRefreshToken,
  requestPasswordReset,
  sendPasswordReset,
  userSignUp,
} from '@api/user';

const token = localStorage.getItem('auth-token');

export interface UserPagePermissions {
  canCreateTeam: boolean;
  canCreateIndividual: boolean;
  canJoinTeam: boolean;
  canEdit: boolean;
}

interface UserState {
  profile: Profile | null;
  isLoading: boolean;
  error: string | null;
  passwordResetRequested: string;
  loginToken: string | null;
  isLoggedIn: boolean;
  selectedCampaignPageId: string | null;
  passwordChanged: any;
  profileChanged: any;
  pagePermissions: UserPagePermissions;
}

const initialState: UserState = {
  profile: null,
  isLoading: false,
  error: null,
  passwordResetRequested: null,
  loginToken: token,
  isLoggedIn: !!token,
  selectedCampaignPageId: null,
  passwordChanged: null,
  profileChanged: null,
  pagePermissions: {
    canCreateTeam: false,
    canCreateIndividual: false,
    canJoinTeam: false,
    canEdit: false,
  },
};

function startLoading(state: UserState) {
  state.isLoading = true;
}

function loadingFailed(state: UserState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // Login
    userLoginStart: startLoading,
    userLoginSuccess(state, action: PayloadAction<ILogin>) {
      state.isLoggedIn = true;
      // state.isLoading = false;
      state.error = null;
      const expiryDate = moment();
      localStorage.setItem(
        'expiry-date',
        expiryDate.add(action.payload.expires_in, 'seconds').format()
      );
      localStorage.setItem('auth-token', action.payload.access_token);
      localStorage.setItem('refresh-token', action.payload.refresh_token);
    },
    userLoginFailure: loadingFailed,

    // Refresh Token
    refreshTokenStart: startLoading,
    refreshTokenSuccess(state, action: PayloadAction<ILogin>) {
      state.isLoggedIn = true;
      state.isLoading = false;
      state.error = null;
      const expiryDate = moment();
      localStorage.setItem(
        'expiry-date',
        expiryDate.add(action.payload.expires_in, 'seconds').format()
      );
      localStorage.setItem('auth-token', action.payload.access_token);
      localStorage.setItem('refresh-token', action.payload.refresh_token);
    },
    refreshTokenFailure: loadingFailed,

    // Logout
    userLogoutStart: startLoading,
    userLogoutSuccess(state, action: PayloadAction<ILogout>) {
      if (action.payload.success) {
        state.isLoggedIn = false;
        state.isLoading = false;
        state.error = null;
        state.profile = null;
        state.loginToken = null;
        state.passwordChanged = null;
        state.profileChanged = null;
        localStorage.removeItem('expiry-date');
        localStorage.removeItem('auth-token');
        localStorage.removeItem('refresh-token');
      }
    },
    userLogoutFailure: loadingFailed,

    // Signup
    userSignupStart: startLoading,
    userSignupSuccess(state) {
      // action: PayloadAction<ILogin>
      state.isLoggedIn = false;
      state.isLoading = false;
      state.error = null;
    },
    userSignupFailure: loadingFailed,

    // Reset Password
    requestPasswordResetStart: startLoading,
    requestPasswordResetSuccess(state, action: PayloadAction<string>) {
      state.passwordResetRequested = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    requestPasswordResetFailure: (
      state: UserState,
      action: PayloadAction<string>
    ) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    resetPasswordStart: startLoading,
    resetPasswordSuccess(state, action: PayloadAction<boolean>) {
      state.passwordChanged = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    resetPasswordFailure: (state: UserState, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
      state.passwordChanged = action.payload;
    },

    // Get Profile
    getUserProfileStart: startLoading,
    getUserProfileSuccess(state, action: PayloadAction<Profile>) {
      state.profile = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    getUserProfileFailure(state, action) {
      loadingFailed(state, action);
      state.isLoggedIn = false;
    },

    // Edit Profile
    editUserProfileStart: startLoading,
    editUserProfileSuccess(state, action: PayloadAction<any>) {
      // state.isLoading = false;
      // state.error = null;
      state.passwordChanged = action.payload;
      state.profileChanged = null;
    },
    editUserProfileFailure: (state: UserState, action: PayloadAction<any>) => {
      state.isLoading = false;
      if (
        (action.payload && action.payload.passwordUpdated) ||
        (action.payload.errorType && action.payload.errorType === 'password')
      ) {
        state.passwordChanged = action.payload.success;
      } else {
        state.profileChanged = action.payload.message;
      }
    },

    // Set UserPagePermission
    setPagePermissions(state, action: PayloadAction<UserPagePermissions>) {
      state.pagePermissions = action.payload;
      // state.isLoading = false;
      state.error = null;
    },

    // Set selected CampaignPageId
    setSelectedCampaignPageId(state, action: PayloadAction<string>) {
      state.selectedCampaignPageId = action.payload;
      state.isLoading = false;
      state.error = null;
    },
  },
});

export const {
  userLoginStart,
  userLoginSuccess,
  userLoginFailure,
  userLogoutStart,
  userLogoutSuccess,
  userLogoutFailure,
  refreshTokenStart,
  refreshTokenSuccess,
  refreshTokenFailure,
  getUserProfileStart,
  getUserProfileSuccess,
  getUserProfileFailure,
  editUserProfileStart,
  editUserProfileSuccess,
  editUserProfileFailure,
  requestPasswordResetStart,
  requestPasswordResetSuccess,
  requestPasswordResetFailure,
  resetPasswordStart,
  resetPasswordSuccess,
  resetPasswordFailure,
  userSignupStart,
  userSignupSuccess,
  userSignupFailure,
  setPagePermissions,
  setSelectedCampaignPageId,
} = userSlice.actions;

export default userSlice.reducer;

export const fetchUserProfile = (): AppThunk => async (dispatch) => {
  try {
    // dispatch(getUserProfileStart());
    const resp = await getUserProfile();
    if (!resp.success && resp.errorType === 'customerid') {
      dispatch(getUserProfileFailure(resp.message));
    } else {
      dispatch(getUserProfileSuccess(resp.data));
      // dispatch(userLoginFailure(`No more`));
    }
  } catch (error) {
    const errMessage =
      error && error.response && `${error.response.status}_error`;
    dispatch(getUserProfileFailure(errMessage));
  }
};

export const postUserProfile =
  (
    email: string,
    firstName: string,
    lastName: string,
    telephone1: string,
    oldPassword?: any,
    password?: any,
    profilePictureId?: any,
    configurationId?: any,
    padlockToken?: string,
    webApiUrl?: string
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(editUserProfileStart());
      const resp = await editUserProfile(
        email,
        firstName,
        lastName,
        telephone1,
        oldPassword,
        password,
        profilePictureId,
        configurationId,
        padlockToken,
        webApiUrl
      );
      if (resp.success) {
        dispatch(fetchUserProfile());
        dispatch(editUserProfileSuccess(password === '' ? resp : resp.success));
      } else {
        dispatch(editUserProfileFailure(resp));
      }
    } catch (error) {
      dispatch(editUserProfileFailure(error.toString()));
    }
  };

export const login =
  (username: string, password: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(userLoginStart());
      const resp = await userLogin(username, password);
      dispatch(userLoginSuccess(resp));
    } catch (error) {
      let errorMessage = error.toString();
      if (error.response && error.response.data) {
        errorMessage = error.response.data.error_description;
      }
      dispatch(userLoginFailure(errorMessage));
    }
  };

export const refreshToken = (): AppThunk => async (dispatch) => {
  try {
    dispatch(refreshTokenStart());
    const resp = await userRefreshToken();
    dispatch(refreshTokenSuccess(resp));
  } catch (error) {
    let errorMessage = error.toString();
    if (error.response && error.response.data) {
      errorMessage = error.response.data.error_description;
    }
    dispatch(refreshTokenFailure(errorMessage));
  }
};

export const signup =
  (
    username: string,
    password: string,
    firstName: string,
    lastName: string,
    telephone1: string,
    configurationId: string
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(userLoginStart());
      const resp = await userSignUp(
        username,
        password,
        firstName,
        lastName,
        telephone1,
        configurationId
      );
      if (resp.success) {
        dispatch(userSignupSuccess());
        dispatch(login(username, password));
      } else {
        dispatch(userSignupFailure(resp.message.toString()));
      }
    } catch (error) {
      dispatch(userSignupFailure(error.toString()));
    }
  };

export const forgotPassword =
  (email: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(requestPasswordResetStart());
      await requestPasswordReset(email);
      dispatch(requestPasswordResetSuccess(email));
    } catch (error) {
      dispatch(requestPasswordResetFailure(error.toString()));
    }
  };

export const resetPassword =
  (resetToken: string, email: string, newPassword: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(resetPasswordStart());
      const resp = await sendPasswordReset(resetToken, email, newPassword);
      dispatch(resetPasswordSuccess(resp.success));
      if (resp.success) {
        dispatch(login(email, newPassword));
      } else {
        dispatch(resetPasswordFailure(resp.message));
      }
    } catch (error) {
      dispatch(resetPasswordFailure(error));
    }
  };

export const logout = (): AppThunk => async (dispatch) => {
  try {
    dispatch(userLogoutStart());
    dispatch(userLogoutSuccess({ success: true }));
  } catch (error) {
    dispatch(userLogoutFailure(error.toString()));
  }
};
