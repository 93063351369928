import { MissionTheme, Font } from '@root/interfaces';
import globals from '../default';

require('typeface-montserrat');

const primaryFont: Font = {
  name: 'Montserrat',
  fontFamily: 'Montserrat, serif',
};

const colors = {
  colorPrimary: '#0098CD',
  colorSecondary: '#7c7c7c',
  colorTertiary: '#7c7c7c',
  colorText: '#7c7c7c',
  colorBackground: '#7c7c7c',
};

const theme: MissionTheme = {
  ...globals,
  ...colors,
  clientFullName: 'Northwest Center',
  isSVG: false,
  isDisableFullHeader: false,
  extraBoldFrequency: false,
  useSecThemeColor: false,
  addCustomColor: false,
  primaryFont: primaryFont,
  headingFont: primaryFont,
  headerBackgroundColor: 'white',
  headerTitleColor: '#7c7c7c',
  headerLogoHeight: 103,
  headingFontSize: '1.5rem',
  headingBold: true,
  subHeadingFontSize: '1rem',
  buttons: {
    ...globals.buttons,
    defaults: {
      ...globals.buttons.defaults,
      background: colors.colorPrimary,
    },
  },
  navButtons: {
    left: {
      ...globals.buttons,
      defaults: {
        ...globals.buttons.defaults,
        background: colors.colorPrimary,
      },
    },
    right: {
      ...globals.buttons,
      defaults: {
        ...globals.buttons.defaults,
        background: colors.colorPrimary,
      },
    },
  },
  globalFontSize: 14,
  toggleFontFamily: primaryFont.fontFamily,
};

export default theme;
