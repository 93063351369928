import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
  container: {
    margin: 0,
    padding: {
      right: 15,
      left: 15,
    },
    '@media (min-width: 576px)': {
      maxWidth: 540,
    },
    '@media (min-width: 768px)': {
      maxWidth: 720,
    },
    '@media (min-width: 992px)': {
      maxWidth: 960,
    },
    '@media (min-width: 1200px)': {
      maxWidth: 1240,
    },
  },
  main: {
    padding: {
      bottom: 30,
    },
    backgroundColor: '#fff',
    width: '100%',
  },
}));
