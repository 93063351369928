import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@app/RootState';
import {
  // CustomQuestionsCategories,
  CustomQuestions,
} from '@app/types';
import { DonationPageType } from '@root/enums';
import { DonationPageDetails } from '@modules/donation/types';
import { setAdditionalInformation, setCustomQuestions } from '@app/app.slice';
import { setProgressIndicator } from '@modules/donation/donation.slice';

const { lazy, Suspense } = React;
const AdditionalInfoDetails = lazy(
  () => import('@components/organism/additionalInfoDetails')
);

interface MembershipScreenProps {
  pageDetails: DonationPageDetails;
}
const AdditionalInfo: React.FC<MembershipScreenProps> = ({ pageDetails }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [load, setLoad] = React.useState<boolean>(false);
  const {
    additionalInformation,
    preferenceQuestionsArray,
    preferenceQuestions,
  } = useSelector((state: RootState) => state.app);

  React.useEffect(() => {
    if (preferenceQuestionsArray && !preferenceQuestions) {
      const preferenceQuestions: CustomQuestions =
        preferenceQuestionsArray.reduce(
          (obj, item) =>
            Object.assign(obj, { [item.preferenceQuestionId]: { ...item } }),
          {}
        );

      Object.keys(preferenceQuestions).forEach((id) => {
        const options = preferenceQuestions[id].checkboxQuestionOptions;
        Object.assign(preferenceQuestions[id], {
          checkboxQuestionOptions: { ...options },
        });
      });

      dispatch(setCustomQuestions(preferenceQuestions));
    }
  }, [pageDetails, preferenceQuestionsArray]);

  React.useEffect(() => {
    switch (true) {
      case pageDetails &&
        pageDetails.showDescription &&
        !pageDetails.showCustomQuestions:
        setLoad(true);
        break;
      case preferenceQuestions &&
        pageDetails &&
        pageDetails.showCustomQuestions &&
        !pageDetails.showDescription:
        setLoad(true);
        break;
      case preferenceQuestions &&
        pageDetails &&
        pageDetails.showCustomQuestions &&
        pageDetails.showDescription:
        setLoad(true);
        break;
      default:
        setLoad(false);
        break;
    }
  }, [pageDetails, preferenceQuestions]);

  const onChange = (value: any, formQuestions: CustomQuestions) => {
    dispatch(
      setAdditionalInformation({
        transactionDescription: value.transactionDescription,
      })
    );
    dispatch(setCustomQuestions(formQuestions));
    onSubmit();
  };

  const onSubmit = () => {
    if (pageDetails.donationPageTypeId === DonationPageType.DP) {
      dispatch(setProgressIndicator('payment'));
    }
    history.push('payment');
  };

  return load ? (
    <Suspense fallback={<div>{t('_loading')}</div>}>
      <AdditionalInfoDetails
        additionalInformation={additionalInformation}
        preferenceQuestions={preferenceQuestions}
        page={pageDetails}
        handleChange={onChange}
        onSubmit={onSubmit}
      />
    </Suspense>
  ) : null;
};

export default AdditionalInfo;
