import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import NumberFormatter from '@components/atoms/numberFormatter';
import { useMediaQuery } from 'react-responsive';
import { DonationPageType } from '@root/enums';
import { DonationPageDetails } from '@modules/donation/types';
import { setSelectedAmount } from '@modules/donation/donation.slice';
import { CampaignPageDetails } from '@api/campaign';
import { RootState } from '@app/RootState';
import Button from '@components/atoms/button';
import InputAmountField from '@components/atoms/inputAmountField';
import useStyles from './amountSelector.style';
// import classes from '*.module.css';

export type Props = {
  page?: DonationPageDetails & CampaignPageDetails;
  color?: string;
  amount?: number;
  minimumAmount?: number;
  defaultAmount?: number;
  customAmount?: boolean;
  givingFrequency?: string;
  onChange?: any;
  donationPageTypeId?: DonationPageType;
  dataTestId?: string;
  occurrence?: string;
  locale?: string;
  donationPageAmounts?: any[];
};

const AmountSelector: React.FC<Props> = ({
  page = null,
  minimumAmount,
  defaultAmount,
  givingFrequency,
  occurrence,
  locale,
  onChange = () => {},
  color = '',
  donationPageAmounts = [],
}) => {
  const { t } = useTranslation();
  const classes = useStyles({ color });
  const dispatch = useDispatch();
  const isXs = useMediaQuery({
    query: '(max-width: 575px)',
  });
  const hideDecimalPlaces = page && page.hideDonationAmountDecimal;
  const { selectedAmount, ariaFocus } = useSelector(
    (state: RootState) => state.donation
  );
  const [selectAmount, setSelectAmount] = React.useState(false);
  const [inputAmount, setInputAmount] = React.useState(
    defaultAmount && defaultAmount === 0
      ? (Math.round(0 * 100) / 100).toFixed(2)
      : defaultAmount.toFixed(2)
  );

  // Element Refs
  const focusRef = React.useRef(null);

  React.useEffect(() => {
    if (ariaFocus === 1) {
      focusRef.current.focus();
    }
  }, [ariaFocus]);

  React.useEffect(() => {
    // check if amount is in donation page amounts. If not, set select input = true
    if (page) {
      const exists = donationPageAmounts.some(
        (x) => x.defaultAmount === defaultAmount
      );
      if (!selectAmount && selectedAmount && exists) {
        setSelectAmount(!exists);
      } else if (!selectAmount && !selectedAmount && !exists) {
        setSelectAmount(true);
      } else {
        setSelectAmount(!exists);
      }

      if (defaultAmount !== -1) {
        setInputAmount(defaultAmount.toFixed(2));
      }
    }
  }, [defaultAmount]);

  const handleOnFocus = (value) => {
    const focusValue = value.target.value;
    if (focusValue !== null || focusValue === inputAmount) {
      value.target.value = '';
      setInputAmount('');
    }
  };

  const handleMinimumAmount = () => {
    const convertAmount = inputAmount && inputAmount.replace(/,(?!.*,)/g, '.');
    let amount = convertAmount && Number(convertAmount);
    if (!amount || amount < minimumAmount) {
      amount = minimumAmount;
    }
    setInputAmount(amount.toFixed(2));
    onChange(amount.toFixed(2), givingFrequency);
  };

  const handleButton = (e) => {
    const otherButton = e === 'other';
    if (otherButton) {
      setSelectAmount(true);
      setInputAmount(minimumAmount.toFixed(2));
      dispatch(setSelectedAmount(true));
      onChange(minimumAmount.toFixed(2), givingFrequency);
    } else {
      setSelectAmount(false);
      dispatch(setSelectedAmount(false));
      setInputAmount(e.toFixed(2));
      onChange(e, givingFrequency);
    }
  };

  const handleValueChange = (value) => {
    if (selectAmount && !selectedAmount) {
      if (Number(inputAmount) < minimumAmount) {
        setInputAmount(minimumAmount.toFixed(2));
      } else {
        dispatch(setSelectedAmount(true));
        setInputAmount(value && value.replace(/,(?!.*,)/g, '.'));
        onChange(value && value.replace(/,(?!.*,)/g, '.'), givingFrequency);
      }
    }
    setInputAmount(value);
  };

  const OtherButton = () => (
    <Button
      className={classes.amountButton}
      active={selectAmount || selectedAmount}
      block
      onClick={() => {
        handleButton('other');
      }}
    >
      {t('other_btn')}
    </Button>
  );

  return (
    <section className={classes.donationPageAmounts}>
      <Row>
        <Col>
          <h2
            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
            tabIndex={0}
            ref={focusRef}
            className={`${classes.sectionTag} ${classes.subTitlesWeight}`}
          >
            {t('select_amount_donate')}
          </h2>
          <Row className={`${classes.amountButtonRow}`}>
            {donationPageAmounts.map((amount: any) => (
              <Col
                xs={6}
                sm={3}
                key={amount.donationPageAmountId}
                className={`${classes.amountButton} mb-3`}
              >
                <Button
                  onClick={() => {
                    handleButton(amount.defaultAmount);
                  }}
                  active={
                    amount.defaultAmount &&
                    inputAmount === amount.defaultAmount.toFixed(2) &&
                    !selectAmount &&
                    !selectedAmount
                  }
                  block
                >
                  <NumberFormatter
                    value={amount.defaultAmount}
                    hideDecimalPlaces={hideDecimalPlaces}
                    hideLocale
                  />
                </Button>
              </Col>
            ))}
            {isXs && (
              <>
                <Col xs={6} sm={4} className={`${classes.amountButton} mb-3`}>
                  <OtherButton />
                </Col>

                <Col xs={12} className={`${classes.amountButton} mb-3`}>
                  {(selectAmount || selectedAmount) && (
                    <InputAmountField
                      inputAmount={
                        selectAmount || selectedAmount ? inputAmount : ''
                      }
                      defaultAmount={selectedAmount ? inputAmount : ''}
                      minimumAmount={minimumAmount}
                      occurrence={occurrence}
                      handleChange={handleValueChange}
                      onBlur={handleMinimumAmount}
                      onFocus={handleOnFocus}
                      locale={locale}
                      customAmount={selectAmount || selectedAmount}
                      pageDetails={page}
                    />
                  )}
                </Col>
              </>
            )}
          </Row>
          {!isXs ? (
            <Row className={classes.amountButtonRow}>
              <Col className={`${classes.amountButton} mb-3`} sm={3}>
                <OtherButton />
              </Col>
              <Col
                sm={8}
                lg={8}
                xl={6}
                className={`${classes.amountButton} mb-3`}
              >
                {(selectAmount || selectedAmount) && (
                  <InputAmountField
                    inputAmount={
                      selectAmount || selectedAmount ? inputAmount : ''
                    }
                    defaultAmount={selectedAmount ? inputAmount : ''}
                    minimumAmount={minimumAmount}
                    occurrence={occurrence}
                    handleChange={handleValueChange}
                    onBlur={handleMinimumAmount}
                    onFocus={handleOnFocus}
                    locale={locale}
                    customAmount={selectAmount || selectedAmount}
                    pageDetails={page}
                  />
                )}
              </Col>
            </Row>
          ) : null}
        </Col>
      </Row>
    </section>
  );
};

export default React.memo(AmountSelector);
