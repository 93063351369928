import { createUseStyles } from 'react-jss';
import { MissionTheme } from '@root/interfaces/MissionTheme';

export default createUseStyles<any, any>((theme: MissionTheme) => ({
  textColor: {
    // useSecThemeColor set boolean in theme default
    color: theme.useSecThemeColor ? theme.colorSecondary : theme.colorPrimary,
  },

  headerMessage: {
    fontSize: theme.headingFontSize,
    lineHeight: 1.2,
    marginBottom: 0,
    fontWeight: theme.headingBold ? 600 : 400,
  },
  pTag: {
    marginTop: 15,
    marginBottom: 0,
  },
  truncate: {
    height: 'auto',
    position: 'relative',
  },
  truncateButton: {
    position: 'relative',
    paddingTop: 5,
    zIndex: 4,
    height: 'auto',
  },

  buttonLink: {
    borderBottom: `1px solid ${theme.colorPrimary}`,
  },
  less: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
  },

  loadLess: {
    position: 'relative',
  },

  more: {
    position: 'relative',
    height: 'auto',
  },

  readMore: {
    maxHeight: 1000,
    opacity: '1',
    backgroundColor: 'white',
    zIndex: 3,
    transition:
      'max-height 1s ease-in-out, opacity 1.25s ease-in-out, background-color 1.25s, z-index 1s',
    '@media (min-width: 640px)': {
      maxHeight: 600,
      transition:
        'max-height 0.75s ease-in-out, opacity 1.25s ease-in-out, background-color 1.25s, z-index 1s',
    },
  },

  readLess: {
    maxHeight: 58,
    opacity: '0',
    zIndex: 0,
    backgroundColor: 'transparent',
    transition:
      'max-height 0.75s ease-in-out, opacity 1.25s, background-color 1.25s, z-index 1s',
  },
}));
