import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { DonationPageType } from '@root/enums';
import { DonationPageDetails } from '@modules/donation/types';
import {
  setDesignationId,
  setGreatestNeed,
  setProgressIndicator,
} from '@modules/donation/donation.slice';
import { setDonationAmtAndFreq, setGiftAidClaimAmount } from '@app/app.slice';
import { GivingFrequency } from '@app/types';
import { RootState } from '@app/RootState';
import DonationHeaderMessage from '@components/atoms/donationHeaderMessage';
import DonationGroup from '@components/organism/donationGroup';
import AmountSelector from '@components/molecules/amountSelector';
import FrequencySelector from '@components/molecules/frequencySelector';
import DesignationSelector from '@components/molecules/designationSelector';
import { getDefaultFrequencyDetails } from '@root/utils';

const { lazy, Suspense } = React;
const Navigation = lazy(() => import('@components/molecules/navigation'));

interface DonationScreenProps {
  pageDetails: DonationPageDetails;
  donationPageTypeId: DonationPageType;
}

const Donate: React.FC<DonationScreenProps> = ({
  pageDetails,
  donationPageTypeId,
}) => {
  const { t, i18n } = useTranslation();
  const locale = i18n.language;
  const dispatch = useDispatch();
  const history = useHistory();

  if (!pageDetails) {
    return <div>{t('loading')}</div>;
  }

  // From useSeletor { value in state  }
  const { donationAmtAndFreq } = useSelector((state: RootState) => state.app);
  const { queryString, designationId, greatestNeed } = useSelector(
    (state: RootState) => state.donation
  );

  // Setting component state
  const [frequencyCode, setFrequencyCode] = React.useState('once');
  const [givingFrequencyCode, setGivingFrequencyCode] = React.useState(null);
  const [nextScreen, setNextScreen] = React.useState(null);
  const [occurrence, setOccurrence] = React.useState(null);
  const [inputAmount, setInputAmount] = React.useState(
    donationAmtAndFreq
      ? donationAmtAndFreq.donationAmount
      : pageDetails.defaultAmount
  );

  const [stateDonationAmtAndFreq, setStateDonationAmtAndFreq] = React.useState({
    inputAmount:
      (donationAmtAndFreq && donationAmtAndFreq.donationAmount) ||
      (pageDetails && pageDetails.defaultAmount) ||
      '',
    givingFrequency: donationAmtAndFreq
      ? donationAmtAndFreq.givingFrequency
      : frequencyCode,
  });

  const ifDesignation =
    pageDetails &&
    pageDetails.enableDesignationSelection &&
    donationPageTypeId === DonationPageType.DP &&
    !(greatestNeed || designationId);

  React.useEffect(() => {
    if (donationAmtAndFreq && donationAmtAndFreq.donationAmount) {
      setInputAmount(donationAmtAndFreq.donationAmount);
    } else if (queryString && queryString.amt) {
      const amt = Number(queryString.amt);
      setInputAmount(amt);
    } else {
      setInputAmount(pageDetails.defaultAmount);
    }
  }, [donationAmtAndFreq, queryString]);

  let donationPageAmounts: any[] = [];

  if (queryString.inc) {
    const increment = Number(queryString.inc);
    const numberOfOptions = pageDetails.donationPageAmounts.length;
    let nextValue =
      queryString && queryString.amt
        ? Number(queryString.amt)
        : pageDetails.minimumAmount;

    for (let i = 0; i < numberOfOptions; i += 1) {
      donationPageAmounts.push({
        defaultAmount: nextValue,
        donationpageId: pageDetails.donationPageId,
        donationPageAmountId: `donation-amount-${i + 1}`,
      });
      nextValue += increment;
    }
  } else {
    donationPageAmounts = pageDetails.donationPageAmounts;
  }

  const currency =
    pageDetails &&
    pageDetails.transactionCurrency &&
    pageDetails.transactionCurrency.isoCurrencyCode
      ? pageDetails.transactionCurrency.isoCurrencyCode
      : 'CAD';

  const prevScreen =
    donationPageTypeId === DonationPageType.OCP ? 'contact' : null;

  const onChange = async (
    receivedInputAmount: string,
    receivedFrequency: string
  ) => {
    setInputAmount(Number(receivedInputAmount));
    setFrequencyCode(receivedFrequency);
    setStateDonationAmtAndFreq({
      inputAmount: receivedInputAmount,
      givingFrequency: frequencyCode,
    });
  };

  const onDesignationSet = (designationId, greatestNeed?: boolean) => {
    dispatch(setDesignationId(designationId));
    dispatch(setGreatestNeed(greatestNeed));
  };

  const onSubmit = () => {
    const minimumAmount = pageDetails.minimumAmount
      ? pageDetails.minimumAmount
      : 1;
    let donationAmount = inputAmount;
    if (inputAmount < minimumAmount) {
      donationAmount = minimumAmount;
    }
    dispatch(
      setDonationAmtAndFreq({
        donationAmount,
        givingFrequency: frequencyCode,
      })
    );
    dispatch(setGiftAidClaimAmount(donationAmount * 0.25));
    dispatch(setProgressIndicator(nextScreen));
    history.push(nextScreen);
  };

  React.useEffect(() => {
    /**
     * Set frequency based on query string
     * s = single only (703650000)
     * sm = default single, allow monthly (703650001)
     * ms = default monthly, allow single (703650002)
     * m = monthly only (703650003)
     * sma = default single, allow monthly, allow annual (703650004)
     * msa = default monthly, allow single, allow annual (703650005)
     * asm = default annual, allow single, allow monthly (703650006)
     * ma = default monthly, allow annual (703650007)
     * am = default annual, allow monthly (703650008)
     * a = allow annual (703650009)
     */
    if (queryString.frq) {
      const code = GivingFrequency[queryString.frq];
      const frequencyDetails = getDefaultFrequencyDetails(
        GivingFrequency[code]
      );

      if (code) {
        setGivingFrequencyCode(code);
        setFrequencyCode(frequencyDetails.selectedFrequencyName);
      }
    } else {
      setGivingFrequencyCode(pageDetails.givingFrequencyCode);
    }
  }, [queryString]);

  React.useEffect(() => {
    if (frequencyCode === 'monthly') {
      setOccurrence(`${currency}/${t('month').toUpperCase()}`);
    } else if (frequencyCode === 'annually') {
      setOccurrence(`${currency}/${t('year').toUpperCase()}`);
    } else {
      setOccurrence(currency);
    }
  }, [frequencyCode]);

  React.useEffect(() => {
    if (donationAmtAndFreq) {
      setFrequencyCode(donationAmtAndFreq.givingFrequency);
    }
  }, [donationAmtAndFreq]);

  React.useEffect(() => {
    if (pageDetails) {
      if (pageDetails && !donationAmtAndFreq) {
        const frequencyDetails = getDefaultFrequencyDetails(
          givingFrequencyCode || pageDetails.givingFrequencyCode
        );
        setFrequencyCode(frequencyDetails.selectedFrequencyName);
      }

      if (
        pageDetails.defaultDesignationId &&
        !pageDetails.enableDesignationSelection
      ) {
        onDesignationSet(pageDetails.defaultDesignationId, false);
      }

      let next = 'contact';
      if (pageDetails.donationPageTypeId === DonationPageType.OCP) {
        if (pageDetails && pageDetails.showGiftAid) {
          next = 'giftAid';
        } else if (pageDetails && pageDetails.showMembership) {
          next = 'membership';
        } else {
          next = 'payment';
        }
      } else if (pageDetails.donationPageTypeId === DonationPageType.DP) {
        if (pageDetails && pageDetails.showGiftAid) {
          next = 'giftAid';
        }
      }
      setNextScreen(next);
    }
  }, [pageDetails, givingFrequencyCode]);

  return pageDetails ? (
    <Suspense fallback={<div>{t('loading')}</div>}>
      {donationPageTypeId === DonationPageType.OCP && (
        <DonationGroup
          inputAmountPassed={
            (donationAmtAndFreq && donationAmtAndFreq.donationAmount) ||
            pageDetails.defaultAmount ||
            1
          }
          pageMinimumAmount={pageDetails.minimumAmount || 1}
          givingFrequencyCode={givingFrequencyCode}
          givingFrequency={frequencyCode}
          customAmount={pageDetails.customAmount}
          locale={locale}
          page={pageDetails}
          onChange={onChange}
          donationPageTypeId={donationPageTypeId}
        />
      )}
      {donationPageTypeId === DonationPageType.DP && (
        <>
          <DonationHeaderMessage page={pageDetails} />
          <FrequencySelector
            defaultAmount={inputAmount}
            givingFrequencyCode={givingFrequencyCode}
            givingFrequency={frequencyCode}
            page={pageDetails}
            onChange={onChange}
            donationPageTypeId={donationPageTypeId}
          />
          <AmountSelector
            defaultAmount={
              (queryString && Number(queryString.amt)) ||
              (donationAmtAndFreq && donationAmtAndFreq.donationAmount) ||
              pageDetails.defaultAmount ||
              1
            }
            minimumAmount={pageDetails.minimumAmount || 1}
            page={pageDetails}
            givingFrequency={frequencyCode}
            occurrence={occurrence}
            customAmount={pageDetails.customAmount}
            onChange={onChange}
            locale={locale}
            donationPageTypeId={donationPageTypeId}
            donationPageAmounts={donationPageAmounts}
          />
          {pageDetails &&
            pageDetails.enableDesignationSelection &&
            pageDetails.designationFilters.length > 0 && (
              <DesignationSelector
                defaultDesignationId={
                  designationId || pageDetails.defaultDesignationId
                }
                designationLabel={
                  pageDetails.defaultDesignationLabel || t('greatest_need')
                }
                selectionLabel={pageDetails.designationSelectionLabel}
                designationFilters={pageDetails.designationFilters}
                onDesignationSet={onDesignationSet}
                greatestNeed={greatestNeed}
              />
            )}
        </>
      )}
      <Navigation
        pageDetails={pageDetails}
        uppercase={donationPageTypeId === DonationPageType.DP}
        rightBtnOnClick={onSubmit}
        rightBtnDisabled={
          Number(stateDonationAmtAndFreq.inputAmount) <
            pageDetails.minimumAmount || ifDesignation
        }
        next={nextScreen}
        prev={prevScreen}
        leftBtnHidden={
          pageDetails &&
          pageDetails.donationPageTypeId &&
          donationPageTypeId === DonationPageType.DP
        }
      />
    </Suspense>
  ) : (
    <div>Skeleton</div>
  );
};
export default Donate;
