import * as React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';

import Actions from '@modules/campaign/screens/Actions';
import Confirmation from '@modules/campaign/screens/Confirmation';
import Contact from '@modules/campaign/screens/Contact';
import Donation from '@modules/campaign/screens/Donation';
import GiftAid from '@modules/campaign/screens/GiftAid';
import UnexpectedError from '@components/organism/unexpectedError';
import Membership from '@modules/campaign/screens/Membership';
import EditCampaign from '@modules/campaign/screens/EditCampaign';
import ShowSupport from '@modules/campaign/screens/ShowSupport';
import Payment from '@modules/campaign/screens/Payment';
import { CampaignPageDetails } from '@api/campaign';

import CampaignRoute from './CampaignRoute';

import { BASE_PATH } from '..';

const screens = {
  Actions,
  Confirmation,
  Contact,
  Donation,
  GiftAid,
  Membership,
  UnexpectedError,
  EditCampaign,
  ShowSupport,
  Payment,
};

export const routes = [
  {
    path: 'donation',
    component: 'Donation',
  },
  {
    path: 'giftAid',
    component: 'GiftAid',
  },
  {
    path: 'contact',
    component: 'Contact',
  },
  {
    path: 'membership',
    component: 'Membership',
  },
  {
    path: 'payment',
    component: 'Payment',
  },
  {
    path: 'confirmation',
    component: 'Confirmation',
  },
  {
    path: 'unexpectedError',
    component: 'UnexpectedError',
  },
  {
    path: 'editCampaign',
    component: 'EditCampaign',
  },
  {
    path: 'showSupport',
    component: 'ShowSupport',
  },
  {
    path: '',
    component: 'Actions',
  },
];

export type RouterProps = {
  campaignPageDetails: CampaignPageDetails;
  friendlyUrl: string;
  campaignPageType: string;
  childFriendlyURL: string;
  isLoading: boolean;
  orderTotal?: number;
};

const CampaignRouter: React.FC<RouterProps> = ({
  campaignPageDetails,
  friendlyUrl,
  campaignPageType,
  childFriendlyURL,
  isLoading = false,
  orderTotal,
}) => {
  const url = () => {
    const match = useRouteMatch();
    if (window.location.host === 'localhost:3000') {
      return match.url;
    }
    if (/\/$/.test(match.url)) {
      return match.url;
    }
    return `${match.url}/`;
  };

  return (
    <Switch>
      {routes.map((route) => (
        <CampaignRoute
          key={route.component}
          campaignPageDetails={campaignPageDetails}
          component={screens[route.component]}
          path={`${BASE_PATH}/${route.path}`}
          friendlyUrl={friendlyUrl}
          campaignPageType={campaignPageType}
          childFriendlyURL={childFriendlyURL}
          url={url()}
          isLoading={isLoading}
          orderTotal={orderTotal}
        />
      ))}
    </Switch>
  );
};

export default CampaignRouter;
