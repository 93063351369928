import { createUseStyles } from 'react-jss';
import { MissionTheme } from '@root/interfaces/MissionTheme';

export default createUseStyles<any, any>((theme: MissionTheme) => ({
  root: {
    positin: 'relative',
    marginBotton: '12px',
    width: '100%',
  },
  label: {
    fontSize: (props: { focus: boolean; value: string }) =>
      props.focus || (props.value && props.value.length !== 0)
        ? '11px'
        : theme.inputLabelSize,
    position: 'absolute',
    zIndex: 3,
    pointerEvents: 'none',
    left: '12px',
    color: (props: { focus: boolean; value: string }) =>
      props.focus || props.value ? theme.colorPrimary : theme.colorText,
    top: (props: {
      focus: boolean;
      value: string;
      isInvalid: boolean;
      inputFieldSize: string;
    }) => {
      const topVal = 19;
      return props.focus || (props.value && props.value.length !== 0)
        ? 0
        : topVal;
    },
    transform: 'translateY(-50%)',
    transition: '0.2s ease all',
    padding: (props: { focus: boolean; value: string }) =>
      props.focus || (props.value && props.value.length !== 0) ? '0 3px' : 0,

    backgroundColor: (props: { focus: boolean; value: string }) =>
      props.focus || (props.value && props.value.length !== 0)
        ? 'white'
        : 'transparent',
    marginLeft: (props: { currencyField: boolean }) =>
      props.currencyField ? 33 : 3,
  },
  hasMutedText: {
    '&$label': {
      top: (props: {
        focus: boolean;
        value: string;
        isInvalid: boolean;
        inputFieldSize: string;
      }) => {
        const topVal = 19;
        return props.focus || (props.value && props.value.length !== 0)
          ? 0
          : topVal;
      },
    },
  },

  formHelp: {
    color: (props: { color: string }) => props.color || theme.colorPrimary,
    position: 'absolute',
    right: '-24px',
    top: '18px',
    transform: 'translateY(-50%)',
    padding: 2,
    '& svg': {
      width: 20,
      height: 20,
    },
  },
}));
