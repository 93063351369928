import * as React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '@app/RootState';
import { GearFill, Wrench } from 'react-bootstrap-icons';
import useStyles from './unexpectedError.style';

export type Props = {
  pageDetails: any;
  dataTestId?: string;
};

const UnexpectedError: React.FC<Props> = ({
  pageDetails = null,
  dataTestId = 'UnexpectedError',
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [homePage, setHomePage] = React.useState('https://missioncrm.ca');

  const { ariaFocus } = useSelector((state: RootState) => state.donation);

  // Element Refs
  const focusRef = React.useRef(null);

  React.useEffect(() => {
    if (ariaFocus === 0) {
      focusRef.current.focus();
    }
  });

  React.useEffect(() => {
    if (pageDetails) {
      if (
        pageDetails.clientRedirectUrl &&
        pageDetails.clientRedirectUrl.length > 0
      ) {
        setHomePage(pageDetails.clientRedirectUrl);
      } else if (
        pageDetails.clientInfo &&
        pageDetails.clientInfo.homePage &&
        pageDetails.clientInfo.homePage.length > 0
      ) {
        setHomePage(pageDetails.clientInfo.homePage);
      }
    }
  }, [pageDetails]);

  const rootProps = {
    className: classes.root,
    'data-testid': dataTestId,
  };

  return (
    <div {...rootProps}>
      <div className={classes.pTag}>
        <span>
          <Trans i18nKey="unexpected_error_message">
            Please
            <span style={{ textDecoration: 'underline' }}>Do not</span>
            <a aria-label={t('aria_unexpected_error_link')} href={homePage}>
              Click here
            </a>
          </Trans>
        </span>
      </div>
      <div className={classes.gear}>
        <GearFill size={100} color="black" />
        <Wrench size={100} color="black" />
      </div>
    </div>
  );
};

export default UnexpectedError;
