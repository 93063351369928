export default (
  name: string,
  fontFamily: string,
  fontWeight: string,
  fontStyle: string,
  filename: string
) => ({
  fontFamily,
  fontWeight,
  fontStyle,
  fontDisplay: 'swap',
  src: `url("${
    process.env.PUBLIC_URL
  }/assets/fonts/${name.toLowerCase()}/${filename}.woff") format('woff')`,
});
