import * as React from 'react';
import { Form, Col } from 'react-bootstrap';
import Navigation from '@components/molecules/navigation';
import Skeleton from '@components/atoms/skeleton';
import useStyles from './contactForm.style';

const ContactFormSkeleton = () => {
  const classes = useStyles({});

  return (
    <Form>
      <Form.Row>
        <Col className={classes.formGroupSkeleton} md={6}>
          <div className={classes.inputSkeleton}>
            <Skeleton height={30} />
          </div>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col className={classes.formGroupSkeleton} md={6}>
          <div className={classes.inputSkeleton}>
            <Skeleton height={30} />
          </div>
        </Col>

        <Col className={classes.formGroupSkeleton} md={6}>
          <div className={classes.inputSkeleton}>
            <Skeleton height={30} />
          </div>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col className={classes.formGroupSkeleton} md={6}>
          <div className={classes.inputSkeleton}>
            <Skeleton height={30} />
          </div>
          <div className={classes.inputSkeleton}>
            <Skeleton />
          </div>
        </Col>

        <Col className={classes.formGroupSkeleton} md={6}>
          <div className={classes.inputSkeleton}>
            <Skeleton height={30} />
          </div>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col className={classes.formGroupSkeleton} md={3}>
          <div className={classes.inputSkeleton}>
            <Skeleton height={30} />
          </div>
        </Col>

        <Col className={classes.formGroupSkeleton} md={9}>
          <div className={classes.inputSkeleton}>
            <Skeleton height={30} />
          </div>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col className={classes.formGroupSkeleton} md={6}>
          <div className={classes.inputSkeleton}>
            <Skeleton height={30} />
          </div>
        </Col>

        <Col className={classes.formGroupSkeleton} md={6}>
          <div className={classes.inputSkeleton}>
            <Skeleton height={30} />
          </div>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col className={classes.formGroupSkeleton} md={6}>
          <div className={classes.inputSkeleton}>
            <Skeleton height={30} />
          </div>
        </Col>
      </Form.Row>

      <Navigation leftBtnHidden rightBtnDisabled />
    </Form>
  );
};

export { ContactFormSkeleton };
