import React from 'react';
import { Alert, Col, Fade, Form, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { contactSearch, setSelectedNewGift } from '@app/app.slice';
import { RootState } from '@app/RootState';
import Button from '@components/atoms/button';
import InputFormGroup from '@components/molecules/inputFormGroup';
import useStyles from './contactSearch.style';

const ContactSearch = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    selectedNewGift,
    contactSearchError,
    contactDetails,
    updateCompleted,
    isLoadingContactDetails,
  } = useSelector((state: RootState) => state.app);
  const [constituentNumber, setConstituentNumber] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [elMinHeight, setElMinHeight] = React.useState(38);

  // Element Refs
  const constituentNumberInput = React.useRef(null);

  const handleChange = (e) => {
    setConstituentNumber(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedNewGift) {
      dispatch(setSelectedNewGift(false));
    }
    if (constituentNumber.length) {
      dispatch(contactSearch(constituentNumber));
    }
  };

  React.useEffect(() => {
    if (
      !selectedNewGift &&
      !contactSearchError &&
      contactDetails &&
      !isLoadingContactDetails
    ) {
      history.push('contact');
    }
  }, [contactDetails, isLoadingContactDetails]);

  React.useEffect(() => {
    if (updateCompleted) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [updateCompleted]);

  // Match button height with input filed
  React.useEffect(() => {
    if (constituentNumberInput.current) {
      const inputHeight =
        constituentNumberInput.current.children[0].children[0].offsetHeight;
      setElMinHeight(inputHeight);
    }
  }, [
    constituentNumberInput,
    constituentNumberInput.current
      ? constituentNumberInput.current.children[0].children[0].offsetHeight
      : 38,
  ]);

  return (
    <Form onSubmit={handleSubmit}>
      {contactSearchError && (
        <Alert variant="danger">{t(contactSearchError)}</Alert>
      )}

      <Fade in={open} timeout={1000} unmountOnExit>
        <Alert
          id="example-fade-text"
          aria-controls="example-collapse-text"
          aria-expanded={open}
          variant="success"
        >
          {t('update_saved_message')}
        </Alert>
      </Fade>

      <Form.Row ref={constituentNumberInput}>
        <InputFormGroup
          xs={9}
          className={classes.constituentNumber}
          label={t('constituentNumber')}
          value={constituentNumber}
          controlId="constituentNumber"
          handleChange={handleChange}
          showError={false}
        />

        <Col xs="3">
          <Button
            elMinHeight={elMinHeight}
            type="submit"
            buttonStyles={{
              defaults: { height: '38px' },
            }}
            disabled={constituentNumber.length === 0 || isLoadingContactDetails}
          >
            {isLoadingContactDetails && !selectedNewGift ? (
              <Spinner animation="border" role="status">
                <span className="sr-only">{t('loading')}</span>
              </Spinner>
            ) : (
              t('search')
            )}
          </Button>
        </Col>
      </Form.Row>
    </Form>
  );
};

export default ContactSearch;
