import * as React from 'react';
import {
  Switch,
  useLocation,
  useRouteMatch,
  withRouter,
} from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { createUseStyles } from 'react-jss';
import OCPGate from '@components/pages/OCPGate';
import Contact from '@components/pages/Contact';
import Membership from '@components/pages/Membership';
import AdditionalInfo from '@components/pages/AdditionalInfo';
import GiftAid from '@components/pages/GiftAid';
import Donate from '@components/pages/Donate';
import Payment from '@components/pages/Payment';
import Confirmation from '@components/pages/Confirmation';
import UnexpectedError from '@components/organism/unexpectedError';
import PaymentSchedule from '@root/components/pages/PaymentSchedule';
import UpdatePaymentInfo from '@components/pages/UpdatePaymentInfo';
import { DonationPageType } from '@root/enums';
import { DonationPageDetails } from '../types';
import DonationRoute from './DonationRoute';

export type RouterProps = {
  donationPageDetails: DonationPageDetails;
  friendlyUrl: string;
  donationPageTypeId: DonationPageType;
  hasContactDetails: boolean;
  selectedNewGift: boolean;
  orderTotal?: number;
};
const duration = 250;
const useStyles = createUseStyles({
  transitionGroup: { position: 'relative', backgroundColor: '#fff' },
  routeSection: {
    backgroundColor: '#fff',
    position: 'absolute',
    width: '100%',
    top: 0,
    left: 0,
  },
  page: {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0,
    backgroundColor: '#fff',
    position: 'absolute',
    width: '100%',
    top: 0,
    left: 0,
  },

  pageEnter: {
    opacity: 0.01,
  },
  pageEnterActive: {
    opacity: 1,
    transition: `opacity ${duration}ms ease-in-out`,
  },
  pageExit: {
    opacity: 1,
  },
  pageExitActive: {
    opacity: 0.01,
    transition: `opacity ${duration}ms ease-in-out`,
  },
});

const DonateRouter: React.FC<RouterProps> = ({
  donationPageDetails,
  donationPageTypeId,
  friendlyUrl,
  hasContactDetails,
  selectedNewGift,
  orderTotal,
}) => {
  const isDP = donationPageTypeId === DonationPageType.DP;
  const isOCP = donationPageTypeId === DonationPageType.OCP;
  // CON000490
  const basePath = `/${isOCP ? 'donationCaller' : 'donation'}/:friendlyUrl`;
  const routeMatch = useRouteMatch();
  const location = useLocation();

  const routes = [
    {
      path: 'confirmation',
      component: 'Confirmation',
    },
    {
      path: 'membership',
      component: 'Membership',
    },
    {
      path: 'additionalInfo',
      component: 'AdditionalInfo',
    },
    {
      path: 'giftAid',
      component: 'GiftAid',
    },
    {
      path: 'payment',
      component: 'Payment',
    },
    {
      path: 'contact',
      component: 'Contact',
    },
    {
      path: 'payment-schedule',
      component: 'PaymentSchedule',
    },
    {
      path: 'update-payment-info',
      component: 'UpdatePaymentInfo',
    },
    {
      path: 'donate',
      component: 'Donate',
    },
    {
      path: 'unexpectedError',
      component: 'UnexpectedError',
    },
    {
      path: isDP ? '/' : '',
      component: isOCP ? 'OCPGate' : 'Donate',
    },
  ];

  const pages = {
    OCPGate,
    Donate,
    Contact,
    Membership,
    AdditionalInfo,
    GiftAid,
    Payment,
    Confirmation,
    UnexpectedError,
    PaymentSchedule,
    UpdatePaymentInfo,
  };

  const classes = useStyles();

  if (isOCP) {
    return (
      <Switch>
        {routes.map((route) => (
          <DonationRoute
            key={route.component}
            isDefault={route.path === ''}
            path={`${basePath}/${route.path}`}
            donationPageDetails={donationPageDetails}
            donationPageTypeId={donationPageTypeId}
            component={pages[route.component]}
            friendlyUrl={friendlyUrl}
            url={routeMatch.url}
            hasContactDetails={hasContactDetails}
            selectedNewGift={selectedNewGift}
            orderTotal={orderTotal}
          />
        ))}
      </Switch>
    );
  }

  return (
    <div>
      <TransitionGroup className={classes.transitionGroup}>
        <CSSTransition
          key={location.key}
          timeout={{ enter: duration, exit: duration }}
          className="page"
          classNames={{
            enter: classes.pageEnter,
            enterActive: classes.pageEnterActive,
            exit: classes.pageExit,
            exitActive: classes.pageExitActive,
          }}
        >
          <section>
            <Switch location={location}>
              {routes.map((route) => (
                <DonationRoute
                  key={route.component}
                  isDefault={route.path === ''}
                  path={`${basePath}/${route.path}`}
                  donationPageDetails={donationPageDetails}
                  donationPageTypeId={donationPageTypeId}
                  component={pages[route.component]}
                  friendlyUrl={friendlyUrl}
                  url={routeMatch.url}
                  hasContactDetails={hasContactDetails}
                  selectedNewGift={selectedNewGift}
                  orderTotal={orderTotal}
                />
              ))}
            </Switch>
          </section>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
};

export default withRouter(DonateRouter);
