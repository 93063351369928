import { createUseStyles } from 'react-jss';
import { MissionTheme } from '@root/interfaces/MissionTheme';

export default createUseStyles<any, any>((theme: MissionTheme) => {
  const getStylesValues =
    (state: 'defaults' | 'valid' | 'active' | 'error' | 'disabled') =>
    (props) => {
      const options = theme.inputAmountField[state];
      const { isDP } = props;
      return {
        background: options.background,
        height: isDP ? 39 : 'auto !important',
        '& .form-control': {
          outline: 'none',
          boxShadow: 'none',
        },
        '& .input-group, & .form-control, & .input-group-prepend, & .input-group-text':
          {
            ...theme.inputAmountField[state],
            background: 'transparent',
          },
        '& .input-group-prepend, & .input-group-text': {
          outline: 'none',
          boxShadow: 'none',
          fontSize: theme.inputAmountField.defaults.fontSize,
        },
        '& .form-control::placeholder': {
          color: theme.colorPrimary,
          opacity: 1,
        },
        '& .form-text': {
          background: 'white',
        },
      };
    };

  return {
    default: getStylesValues('defaults'),
    valid: getStylesValues('valid'),
    active: getStylesValues('active'),
    error: getStylesValues('error'),
    disabled: getStylesValues('disabled'),
    textSmall: {
      fontSize: '0.88rem !important',
    },
  };
});
