import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Col, Row } from 'react-bootstrap';
import { DonationPageDetails } from '@modules/donation/types';
import { CampaignPageDetails } from '@api/campaign';
import { DonationPageType } from '@root/enums';
// import cx from 'classnames';
import Button from '@components/atoms/button';
import SelectBox from '@components/molecules/selectBox';
import useStyles from './designationSelector.style';

export type Props = {
  page?: DonationPageDetails & CampaignPageDetails;
  color?: string;
  defaultDesignationId?: string;
  greatestNeed?: boolean;
  designationLabel?: string;
  selectionLabel?: string;
  designationFilters?: any[];
  onDesignationSet?: any;
  onChange?: any;
  donationPageTypeId?: DonationPageType;
  dataTestId?: string;
  // states
  valid?: boolean;
  error?: boolean;
  disabled?: boolean;
};

const DesignationSelector: React.FC<Props> = ({
  designationLabel,
  selectionLabel,
  greatestNeed,
  designationFilters = [],
  onDesignationSet = () => {},
  color = '',
  defaultDesignationId,
  dataTestId = 'donationGroup',
  // states
  // valid = false,
  // error = false,
  // disabled = false,
}) => {
  const { t } = useTranslation();
  const classes = useStyles({ color });
  // const isDP = page.donationPageTypeId === DonationPageType.DP;
  const rootProps = {
    className: classes.root,
    'data-testid': dataTestId,
  };
  const [filterOptions, setFilterOptions] = React.useState([]);
  const [designationOptions, setDesignationOptions] = React.useState([]);
  const [stateGreatestNeed, setStategreatestNeed] =
    React.useState(greatestNeed);
  const [setDesignationFilter, setStateDesignationFilter] =
    React.useState(null);

  const [singleFilter, setSingleFilter] = React.useState(false);
  const [singleOrder, setSingleOrder] = React.useState(false);
  const [singleButton, setSingleButton] = React.useState(false);
  const [setDesignationId, setStateDesignationId] = React.useState(null);
  const [uniqueDesignation, setUniqueDesignation] = React.useState(true);
  const [showDesigSec, setShowDesigSec] = React.useState(true);
  const [elMinHeight, setElMinHeight] = React.useState(36);

  // Element Refs
  const singleOrderSelect = React.useRef(null);

  React.useEffect(() => {
    if (designationFilters.length === 1) {
      setSingleFilter(true);
      setStateDesignationFilter(designationFilters[0].designationFilterId);
      onDesignationSet(null, true);
    } else {
      const options = [];
      for (let index = 0; index < designationFilters.length; index += 1) {
        const designation = designationFilters[index];
        options.push({
          label: designation.name,
          value: designation.designationFilterId,
        });
      }
      setFilterOptions(options);
    }
  }, [designationFilters]);

  React.useEffect(() => {
    if (defaultDesignationId && uniqueDesignation) {
      // if query string des is VALID but not exists in filters then
      onDesignationSet(defaultDesignationId, false);
      setShowDesigSec(false);
    } else {
      setShowDesigSec(true);
    }
  }, [uniqueDesignation]);

  React.useEffect(() => {
    if (setDesignationFilter) {
      const filter =
        designationFilters[
          designationFilters.findIndex(
            (x) => x.designationFilterId === setDesignationFilter
          )
        ];
      const orders = filter.designationOrders;
      const options = [];
      if (orders.length === 1) {
        options.push({
          label: orders[0].label,
          value: orders[0].designationId,
        });
        onDesignationSet(orders[0].designationId, false);
        if (defaultDesignationId === orders[0].designationId) {
          setSingleButton(true);
          setUniqueDesignation(false);
        }
        if (defaultDesignationId && uniqueDesignation) {
          onDesignationSet(defaultDesignationId, false);
        } else {
          onDesignationSet(orders[0].designationId, false);
        }

        setSingleOrder(true);
        setDesignationOptions(options);
        setStateDesignationId(orders[0].designationId);
      } else {
        for (let index = 0; index < orders.length; index += 1) {
          const order = orders[index];
          options.push({
            label: order.label,
            value: order.designationId,
          });
        }
        setDesignationOptions(options);
      }
    }
  }, [setDesignationFilter]);

  const handleOnChange = (e) => {
    const isTraget = e.currentTarget;
    const filterChange = isTraget && isTraget.name === 'filter';
    const orderChange = isTraget && isTraget.name === 'order';

    if (filterChange) {
      if (!e.target.value) {
        setStateDesignationFilter(null);
        setStateDesignationId(null);
        // onDesignationSet(null, false);
      }
      setStategreatestNeed(false);
      setStateDesignationId(null);
      setStateDesignationFilter(e.target.value);
      onDesignationSet(null, false);
    } else if (orderChange) {
      if (!e.target.value) {
        setStateDesignationId(null);
        // onDesignationSet(null, false);
      }
      const designationId = e.target.value;
      setStategreatestNeed(false);
      setStateDesignationId(designationId);
      onDesignationSet(designationId, false);
    } else {
      setStategreatestNeed(e.currentTarget.checked);
      setStateDesignationFilter(null);
      setStateDesignationId(null);
      onDesignationSet(null, true);
    }
  };

  React.useEffect(() => {
    if (defaultDesignationId) {
      let defaultSelected = false;
      for (
        let filterIndex = 0;
        filterIndex < designationFilters.length;
        filterIndex += 1
      ) {
        if (defaultSelected) {
          break;
        }
        const { designationOrders, designationFilterId } =
          designationFilters[filterIndex];
        for (
          let designationIndex = 0;
          designationIndex < designationOrders.length;
          designationIndex += 1
        ) {
          const { designationId } = designationOrders[designationIndex];
          if (designationId === defaultDesignationId) {
            defaultSelected = true;
            setUniqueDesignation(false);
            setStateDesignationFilter(designationFilterId);
            setStateDesignationId(designationId);
            onDesignationSet(designationId, false);
            setStategreatestNeed(false);
            break;
          }
        }
      }
    }
  }, []);

  // Match greatest need button height with selectboxs
  React.useEffect(() => {
    if (singleOrderSelect.current) {
      const sosHeight =
        singleOrderSelect.current.children[0].children[0].offsetHeight;
      setElMinHeight(sosHeight);
    }
  }, [
    singleOrderSelect,
    singleOrderSelect.current
      ? singleOrderSelect.current.children[0].children[0].offsetHeight
      : 36,
  ]);

  return (
    showDesigSec && (
      <section {...rootProps}>
        <Row>
          <Col>
            <h2 className={`${classes.sectionTag} ${classes.subTitlesWeight}`}>
              {t('direct_your_gift')}
            </h2>
            <Form>
              <Form.Row className={classes.donationPageLayoutRow}>
                <Form.Group
                  as={Col}
                  sm={singleFilter && singleOrder ? 6 : 3}
                  controlId="designationGreatestNeed"
                >
                  <Button
                    elMinHeight={elMinHeight}
                    tabIndex={0}
                    aria-label={t('aria_designation_button') + designationLabel}
                    type="button"
                    onClick={() => {
                      setSingleButton(false);
                      setStategreatestNeed(true);
                      setStateDesignationFilter(null);
                      setStateDesignationId(null);
                      onDesignationSet(null, true);
                    }}
                    active={stateGreatestNeed}
                  >
                    {designationLabel}
                  </Button>
                </Form.Group>
                {singleFilter && singleOrder ? (
                  <Form.Group
                    ref={singleOrderSelect}
                    as={Col}
                    controlId="designationOrder"
                  >
                    <Button
                      tabIndex={0}
                      aria-label={
                        t('aria_designation_button') +
                        designationOptions[0].label
                      }
                      type="button"
                      onClick={() => {
                        setStategreatestNeed(false);
                        setSingleButton(true);
                        setStateDesignationFilter(setDesignationFilter);
                        setStateDesignationId(designationOptions[0].value);
                        onDesignationSet(designationOptions[0].value, false);
                      }}
                      active={singleButton}
                    >
                      {designationOptions[0].label}
                    </Button>
                  </Form.Group>
                ) : (
                  <Col
                    ref={singleOrderSelect}
                    className={classes.singleOrderSelection}
                  >
                    <SelectBox
                      // tabIndex={0}
                      groupProps={{
                        controlId: 'designationFilterSelectOption',
                      }}
                      // aria-label={t('aria_designation_selection_label')}
                      options={
                        singleFilter ? designationOptions : filterOptions
                      }
                      placeholder={selectionLabel || t('selection_label')}
                      onChange={handleOnChange}
                      value={
                        singleFilter
                          ? setDesignationId || ''
                          : setDesignationFilter || ''
                      }
                      name={singleFilter ? 'order' : 'filter'}
                    />
                  </Col>
                )}
              </Form.Row>

              {setDesignationFilter && !singleFilter && (
                <Form.Row>
                  <SelectBox
                    // tabIndex={0}
                    groupProps={{
                      as: Col,
                      controlId: 'designationOrderSelectOption',
                    }}
                    sm={{ offset: 3 }}
                    options={designationOptions}
                    name="order"
                    value={setDesignationId || ''}
                    placeholder={`- ${t('order_label')} -`}
                    onChange={handleOnChange}
                  />
                </Form.Row>
              )}
            </Form>
          </Col>
        </Row>
      </section>
    )
  );
};

export default React.memo(DesignationSelector);
